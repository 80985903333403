import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UilBox } from "@iconscout/react-unicons";

const stockStatus = [
  {
    'tooltip': "Out of stock",
    'className': "text-danger",
  },
  {
    'tooltip': "Low stock",
    'className': "text-warning",
  },
  {
    'tooltip': "In stock",
    'className': "text-success",
  }
]

export default function WarehouseStockStatus({name, stock, stockWarning}) {
  
  function getStockStatus() {
    if (stock <= 0) {
      return 0;
    } else if (stock < stockWarning) {
      return 1;
    } else {
      return 2;    
    }
  }
  
  return(
    <>
      <span className="me-2">{name}: {stock}</span>
      <OverlayTrigger placement="top" key="top" overlay={<Tooltip id="tooltip-top">{ stockStatus[getStockStatus()]?.tooltip }</Tooltip>}>
        <span><UilBox width="16" className={ `align-middle ${stockStatus[getStockStatus()]?.className}` } /></span>
      </OverlayTrigger>
    </>
  )
}