import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Paths } from "../../../routes";
import configurations from "../../../configurations";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { UilEllipsisH, UilReceiptAlt, UilEye, UilAngleRight, UilBrowser, UilDownloadAlt, UilAngleDown, } from "@iconscout/react-unicons";

import { notify } from "../../../scripts/notify";
import { dateTimeFormat } from "../../../scripts/utils";
import ActionBar from "../../components/global/ActionBar";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../layouts/PageHeader";
import ErrorPage from "../../components/errors/ErrorPage";
import { MetaDataContext } from "../../../web/context/metaData";

import { useOrderListQuery } from "../../stores/orders/list";
import { useChannelListQuery } from "../../stores/channel/list";
import { useGetOrdersQuery } from "../../stores/channel/getOrders";
import { useUpdateStatusQuery } from "../../stores/orders/updateStatus";
import { useCreateInvoiceQuery } from "../../stores/invoices/createInvoice";
import FilterComponent from "../../components/common/FilterComponent";


export default function Listing() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {data:preferences} = useContext(MetaDataContext);

  const [ orderQuery, orderQueryData, { searchParams, setSearchParams, setPageNumber, setSort, filters, setFilters}, ] = useOrderListQuery();
  const [updateStatus] = useUpdateStatusQuery();
  const [statusCheck, setStatusCheck] = useState({ status: "", orders: [] });
  const [orders, setOrders] = useState([]);
  const [channelQuery, channelQueryData] = useChannelListQuery({ defaultValue: { limit: "all" } });
  const [getOrdersQuery] = useGetOrdersQuery();
  const [createInvoice] = useCreateInvoiceQuery();
  const [filtersData, setFiltersData] = useState([]);
  const [checkFilter, setCheckFilter] = useState(false);
  const [compare, setCompare] = useState("");

  
  function fetchOrder(id, channel_type) {
    getOrdersQuery.mutate({ id: id, type: channel_type });
  }

  const generateInvoice = (id) => {
    createInvoice.mutate({ id:id, from:'order' })
  }

  const onChangeHandler = (data) => {
    setOrders(data);
  };

 
  const updateOrderStatus = (e, status) => {
    setStatusCheck((prevProps) => ({
      ...prevProps,
      orders: [],
    }));
    orders.map((order) => {
        setStatusCheck((prevProps) => ({
          orders: [
            ...prevProps.orders,
            {
              order_id: order.order_id,
              channel_id: order.channel_id,
              id: order.id,
            },
            
          ],
          status: status
        }));
    });
  };

  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: "channel_name",
      name: "Channel Name",
      selector: (row) => row.channel_name,
    },
    {
      id: "order_id",
      name: "Order Id",
      cell: (row) => (
        <Link to={Paths.Order.pages.view.path + row.id}> #{row.order_id} </Link>
      ),
    },
    {
      id: "status",
      name: "Status",
      cell: (row) => (
        <div className={`badge rounded-pill ${ configurations.OrderStatus[row.order_status].class }`} >
          {configurations.OrderStatus[row.order_status].status}
        </div>
      ),
    },
    {
      id: "order_placed",
      name: "Order Placed",
      cell: (row) => dateTimeFormat(row.placed_at, 'long'),
    },
    {
      id: "order_items",
      name: "Order Items",
      cell: (row) => (
        <div>
          {" "}
          {row.order_products.slice(0, 2).map((product, index) => (
            <div className="d-flex justify-content-between" key={index}>
              <div className="text-truncate w-75">
                {product.name} {product.value ? "-" + product.value : ""}
              </div>
              <div>x{product.quantity}</div>
            </div>
          ))}
          {row.order_products.length <= 1 && !_.isNull(row.addon) && !_.isEmpty(row.addon)
            ? JSON.parse(row.addon).map((item, index) =>
                row.order_products.length != 0 ? (
                  row.order_products.length > index ? (
                    <div className="d-flex justify-content-between" key={index}>
                      <div className="text-truncate w-75"> {" "} {item.product_name}{" "} </div>
                      <div>x{item.quantity}</div>
                    </div>
                  ) : null
                ) : index <= 1 ? (
                  <div className="d-flex justify-content-between" key={index}>
                    <div className="text-truncate w-75"> {" "} {item.product_name}{" "} </div>
                    <div>x{item.quantity}</div>
                  </div>
                ) : null
              )
            : null}
          {row.order_products.length + (!_.isNull(row.addon) && !_.isEmpty(row.addon) ? JSON.parse(row.addon).length : 0) > 2 && (
            <div className="mb-2">
              <Link to={Paths.Order.pages.view.path + row.id}>
                +
                {row.order_products.length +
                  (!_.isNull(row.addon) && !_.isEmpty(row.addon)
                    ? JSON.parse(row.addon).length
                    : 0) -
                  2}{" "}
                more items
              </Link>
            </div>
          )}
        </div>
      ),
    },
    {
      id: "order_total",
      name: "Order Total",
      cell: (row) =>
        preferences.currency_symbol + row.order_total,
    },
    {
      name: "",
      id: "action",
      cell: (row) => (
        <div className="dropdown text-end">
          {row.channel_type == 4 ? (
            <>
              <button
                className="btn btn-link"
                id="dropdownMenu"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {<UilEllipsisH size="16" className="text-secondary" />}
              </button>
              <ul className="dropdown-menu " aria-labelledby="dropdownMenu">
                {!_.isNull(row.invoice_url) ? (
                  <a
                    href={row.invoice_url}
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <li className="dropdown-item fw-bold cursor ">
                      {
                        <UilEye
                          size="16"
                          className="text-secondary me-1 mb-1"
                        />
                      }{" "}
                      View Invoice
                    </li>
                  </a>
                ) : (
                  <button onClick={() => generateInvoice(row.id)} className="btn btn-link text-decoration-none p-0">
                    <li className="dropdown-item fw-bold cursor ">
                      {
                        <UilReceiptAlt
                          size="16"
                          className="text-secondary me-1 mb-1"
                        />
                      }
                      Generate Invoice
                    </li>
                  </button>
                )}
              </ul>
            </>
          ) : (
            <button
              className="btn"
              type="button"
              id="order-action"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              disabled={true}
            >
              {<UilEllipsisH size="16" className="text-secondary" />}
            </button>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!_.isEmpty(statusCheck.status)) {
      if (_.isEmpty(statusCheck.orders)) {
        notify("error", "Please select orders to update.");
        return;
      }
      notify("warning", "Please wait while we process this request...");
      updateStatus.mutate(statusCheck);
      setStatusCheck({ status: "", orders: [] });
      setOrders([]);

    }
  }, [statusCheck.status]);

  const findFilter = () => {
    setFilters([...Object.values(filtersData)]);
    setPageNumber(1);
  };

  useEffect(() => {
    if (_.isEmpty(filtersData) && checkFilter) {
      setFilters([]);
      setPageNumber(1);
    } 
  }, [filtersData]);


  return (
    <>
      {orderQuery.isError && <ErrorPage innerPage={false} error="500" />}

      <PageHeader
        title="Orders"
        subtitle="All Orders"
        component={
          <div className="d-flex justify-content-end align-items-center mb-4">
            <div className="dropdown me-3">
              <button className="btn bg-white border px-3" type="button" id="order-action" data-bs-toggle="dropdown" aria-expanded="false" >
                <UilDownloadAlt width="16" className="me-2 text-secondary" />
                Get orders <UilAngleDown width="16" />
              </button>
              <ul className="dropdown-menu" aria-labelledby="order-action">
                {!_.isEmpty(channelQueryData?.data) ? (
                  channelQueryData.data.map(
                    (channel, index) =>
                      channel.channel_type != 4 && (
                        <li key={index}>
                          <button className="dropdown-item " value={channel.id} onClick={(e) => fetchOrder(channel.id, channel.channel_type) } >
                            {channel.channel_name}
                          </button>
                        </li>
                      )
                  )
                ) : (
                  <li className="text-center">
                    <span>No Channel</span>
                  </li>
                )}
              </ul>
            </div>

            <Link to={Paths.Order.pages.create.path} className="text-decoration-none" >
              <button type="button" className="btn btn-secondary px-5 ms-3">
                Create a new order
              </button>
            </Link>
          </div>
        }
      />

      <ActionBar searchParams={searchParams} setSearchParams={handleSearch} sortOptions={configurations.Sort} setSort={setSort} placeholder="Search by Order ID"
        component={
          <div className="col-md d-flex justify-content-end pe-0">
            <div className="dropdown me-3">
              <button className="btn px-0" type="button" id="batch-setting" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                <UilBrowser width="15" className="text-secondary" /> Batch settings
              </button>
              <ul className="nav-item dropdown-menu" aria-labelledby="batch-setting" >
                <li className="dropdown-item">
                  <div>
                    Order Status <UilAngleRight />
                  </div>
                  <ul className="submenu dropdown-menu">
                    {_.sortBy(configurations.OrderStatus, ["sort_order"]).map(
                      (status, index) =>
                        !_.isEmpty(status) && !_.isEmpty(status.status) && (
                          <li key={index}>
                            <button className="dropdown-item small" id={index} onClick={(e) => updateOrderStatus(e, status.status) } >
                              <span className={`badge rounded-pill ${status.class}`} > {status.status} </span>
                            </button>
                          </li>
                        )
                    )}
                  </ul>
                </li>
              </ul>
            </div>
            <FilterComponent
              runFilter={findFilter}
              insertData={setFiltersData}
              activeFilters={filtersData}
              setCheckFilter={setCheckFilter}
              checkFilter={checkFilter}
              filters={configurations.OrderFilters}
              setCompare={setCompare}
              compare={compare}
            />
          </div>
        }
      />

      <DataTable
        data={orderQueryData?.data}
        columns={columns}
        selectable={true}
        onSelectRow={onChangeHandler}
        isLoading={orderQuery.isFetching}
        pagination={true}
        paginationMeta={orderQuery?.data?.meta}
        onChangePage={(page) => setPageNumber(page)}
        currentPage={orderQueryData?.meta?.current_page}
        totalPage={orderQueryData?.meta?.last_page}
        perPage={orderQueryData?.meta?.per_page}
      />
    </>
  );
}
