 const inputErrorMessage = {
  old_password : 'The old password field is required',
  password : 'The new password field is required',
  password_confirmation : 'The confirm password field is required',
  unmatched_password : "Passwords don't match",
  user_name: "The name field is required.",
}

const labelData = {
  old_password : 'Old password',
  new_password : 'New password',
  password_confirmation : 'Confirm Password'
}


export default{
  inputErrorMessage,
  labelData,
}