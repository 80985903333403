import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { UilPen, UilTrashAlt, UilEllipsisH, UilCodeBranch } from "@iconscout/react-unicons";

import { Paths } from "../../../routes";
import configurations from "../../../configurations";

import { confirmDelete } from "../../utils/scripts";
import DataTable from "../../components/common/DataTable";
import ActionBar from "../../components/global/ActionBar";
import PageHeader from "../../layouts/PageHeader";
import ErrorPage from "../../components/errors/ErrorPage";
import { useChannelListQuery } from "../../stores/channel/list";
import { useDeleteChannelQuery } from "../../stores/channel/delete";
import InfoIcon from "../../components/common/InfoIcon";


export default function Listing() {
  
  const navigate = useNavigate();
  const [ channelQuery, channelQueryData, {searchParams, setSearchParams, setPageNumber, setSort }, ] = useChannelListQuery();
  const deleteChannel = useDeleteChannelQuery();

  function handleDelete(id) {
    confirmDelete( 'channel' , () => deleteChannel.mutate(id));
  } 
  
  function handleEdit(id) {
    navigate(Paths.ChannelsRefactor.pages.edit.path + id);
  } 

  function handleProductMapping(id) {
    navigate(Paths.ChannelsRefactor.pages.ProductMap.path + id);
  } 

  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: "channel_name",
      name: "Channel Name",
      cell: (row) => (
        <td>
          <InfoIcon type={row.channel_type} name={row.channel_name} />

        </td>
      ),
    },
    {
      id: "channel_username",
      name: "Username",
      selector: (row) => row.channel_username,
    },
    {
      id: "date_added",
      name: "Date Added",
      selector: (row) => row.date,
    },
    {
      name: "",
      id: "action",
      cell: (row) => (
         row.channel_type != 4 &&
        <ul className="dropdown text-end mb-0">
          <button className="btn btn-link" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" >
            <span> <UilEllipsisH size="16" className="text-secondary" /> </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end py-0">
          { row.channel_type != 2 &&
            <li className="fw-bold cursor dropdown-item py-2" onClick={() => handleProductMapping(row.id)}>
              <span> <UilCodeBranch size="14" className="text-secondary me-1 mb-1" /> Map Products </span>
            </li> 
          }
            <li className="fw-bold cursor dropdown-item py-2"  onClick={() => handleEdit(row.id)}>
              <span> <UilPen size="14" className="text-secondary me-1 mb-1" /> Edit Channel </span>
            </li>
            <li className="fw-bold cursor dropdown-item py-2" onClick={() => handleDelete(row.id)}>
              <span> <UilTrashAlt size="14" className="text-danger me-1 mb-1" /> Delete Channel </span>
            </li>
          </ul>
        </ul>
      ),
    },
  ];

  return (
    <>
      {channelQuery.isError && <ErrorPage innerPage={false} error="500" />}

      <PageHeader title="channels" subtitle="All channels" component={<Link to={Paths.ChannelsRefactor.pages.select.path} className="btn btn-secondary">Add a channel</Link>}/>

      <ActionBar
        searchParams={searchParams}
        setSearchParams={handleSearch}
        sortOptions={configurations.ChannelSort}
        setSort={setSort}
        placeholder="Search by Channel name"
      />
      <DataTable
        data={channelQueryData?.data}
        columns={columns}
        isLoading={channelQuery.isFetching}
        pagination={true}
        paginationMeta={channelQueryData?.meta}
        onChangePage={(page) => setPageNumber(page)}
      />
    </>
  );
}
