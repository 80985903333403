import React, { useState, useEffect, useContext, useReducer, useCallback } from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { UilTimesCircle } from "@iconscout/react-unicons";
import Select from 'react-select';

import ReactCreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import _ from "lodash";

import lang from "../../../lang/en/Theme/Colors";
import { preventNonNumeric, onlyPercentage, checkFloat } from "../../../scripts/utils";
import apiClient from "../../stores/header";

import { useMetaData } from "../../stores/products/metaData";
import { MetaDataContext } from "../../../web/context/metaData";
import { getList } from "../../stores/products/list";
import { useQueryClient } from "@tanstack/react-query";


export default function CreatableSelectProducts({ selectedProducts,  onChange = () => {}, validator, disabled = false, forceUpdate = false }) {

  const queryClient = useQueryClient()
  const {data: preferences} = useContext(MetaDataContext);

  const productMetaData = useMetaData();
  
  const [, doForceUpdate] = useState(forceUpdate)
  
  const [validateRef, {validateIf}] = validator;	
  
  const selectStyle = {
    control: base => ({
      ...base,
      border: 0,
      backgroundColor: "rgba(1, 1, 1, 0.0)",
      boxShadow: 'none'
    }),
    menu: base => ({
      ...base, 
      zIndex: 999 
    }),
    singleValue: (base, state) => ({
      ...base,
      color:lang.colors.secondary
    }),
    placeholder: base => ({
      ...base, 
      color: lang.colors.gray500
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? lang.colors.white : state.isDisabled ? lang.colors.gray500 : lang.colors.secondary,
    }),
  }; 

  const formatOptionLabel = (option, { context }) => {
    return context === "menu" && option.__isNew__ ? (
			<span className="text-dark">
				Add Custom Item "{option.product_name}"
			</span>
    ) : (
        <div className="d-flex justify-content-between">
          <span>{option.product_name}</span>
           {(option.total_stock <= 0 && !option.is_composite) && <span className="text-danger">Out of stock</span>}
        </div>
    );
  }

  function  createCustomItem (inputValue, optionLabel)  {
    var newObject = {
      'id': optionLabel,
      'product_name': inputValue,
      'unit_price': 0,
      'discount': 0,
      'discount_type': 'percent',
      'discount_cost': 0,
      'max_quantity':100,
      '__isNew__': true
    }
    return newObject;
  }

  function handleInputChange(event, index) {
    event.persist();  
    var data = [...selectedProducts];
    if (_.isEmpty(event.target.value) && event.target.type == 'number') {
      event.target.value = parseFloat(event.target.value);
    }
    if (event.target.value.charAt(0) != " "){
      data[index][event.target.name] = event.target.value;
      onChange(data);
    }
  };

  const isValidNewOption = (inputValue) => {
    if (inputValue && inputValue.charAt(0) === ' ') {
      return false;
    }else{
      return inputValue.length > 0
    }
  };
  
  function handleProductSelect(value, action, index) {
    var data = [...selectedProducts];
   
    data[index] = {
      'product_id': value.id,
      'product_name': value.product_name,
      'quantity': 1,
      'discount': 0,
      'discount_type': 'percent',
      'is_composite': value.is_composite ?? value.type == 'composite',
      'type': (action == 'select-option') ? 'product' : 'addon',
    }

    if (action == 'select-option') {
      data[index] = {
        ...data[index],
      'unit_price': value.selling_price,
        ...(!value.is_composite && value.warehouses) && {
            'warehouses': value.warehouses,
            ...(_.size(value.warehouses) == 1) && {'selected_warehouse' : value.warehouses[0], 'max_quantity': value.warehouses[0]['stock']}
        },
        'tax_class': value.tax_class 
        ?? _.find(productMetaData?.data?.taxes, ["tax_application", "inclusive"])?.id
          ?? (_.find(productMetaData?.data?.taxes, ["tax_application", "exclusive"]))?.id
          ?? (_.find(productMetaData?.data?.taxes, ["id", 0]))?.id ,
      }
    } else {
      data[index] = {
        ...data[index],
            ...(_.size(productMetaData?.data?.taxes) == 1) && {'tax_class' : productMetaData?.data?.taxes[0].id, 'max_quantity': 100},
        'tax_class': value.tax_class ?? _.find(productMetaData?.data?.taxes, ["tax_application", "inclusive"])?.id
          ?? (_.find(productMetaData?.data?.taxes, ["tax_application", "exclusive"]))?.id
          ?? (_.find(productMetaData?.data?.taxes, ["id", 0]))?.id,
      }
    }

    validateRef.current.purgeFields()
    if (value.product_name.charAt(0) != " ") {
      onChange(data);
    }
  }

  function handleSelect(value, index, type) {
    var data =  [...selectedProducts];

    if (type == "warehouse") {
      data[index] = {
        ...data[index],
        'selected_warehouse': value,
        'max_quantity': value.stock,
      }
    }

    if (type == "tax_class") {
      data[index] = {
        ...data[index],
        'tax_class': value.id,
        'max_quantity': 100,
      }
    }

    onChange(data);
  }

  function calculation (index) {
    var amount = '-';
    var data = selectedProducts[index];

    if (data.quantity && data.unit_price) {
      amount = data.quantity * data.unit_price;

      if (data.discount > 0) {
        if (data.discount_type == 'percent') {
          amount = (data.quantity * (parseFloat(data.unit_price) - (parseFloat(data.discount) / 100) * parseFloat(data.unit_price)));
        } 
  
        if (data.discount_type == 'fixed') {
          amount = (data.quantity * (parseFloat(data.unit_price) - parseFloat(data.discount)));
        }
      }

      if (amount < 0)  {
        amount = 0;
      } else {
        amount = amount.toFixed(2);
      }
    }

    return amount;
  }

  function handleRemove(index) {
    if (!disabled) {      
      var data = [...selectedProducts];
      data.splice(index, 1);

      validateRef.current.purgeFields()
      onChange(data);
    }
  }

  function findMaxQuantity(value) {
    return _.find(value.warehouses, ['id', value.selected_warehouse?.id])?.stock ?? 100
  }
    
  
  const loadOptions = _.debounce((searchParams, callback) => {
    (async () => {
      let query = await queryClient.fetchQuery(['search-product', {searchParams}], ({queryKey, signal}) => getList(queryKey, signal), {staleTime: Infinity})
      callback(query.data)
    })()
  }, 500)

  
  return (
    <> 
      <div className="row border-bottom-0 mx-0 p-0 text-uppercase fw-bold text-light">
        <div className="col-6 text-start">Product Name</div>
        <div className="col-1 text-start">Quantity</div>
        <div className="col-2 text-start">Discount</div>
        <div className="col-1 text-start">Unit Price</div>
        <div className="col-1 text-start">Price</div>
        <div className="col-1 text-start"></div>
      </div>
      {selectedProducts ?
        [...selectedProducts, {}].map((value, index) => (
          <div className="row border mx-0"  key={index}>
            <div className='col-6 border-end'>
              <div className="row">
                <div className="col-8">
                  <AsyncCreatableSelect
                    name={value.id}
                    styles={selectStyle}
                    placeholder='Start typing here....'
                    value={ value.product_name ? value : value.products ? value.products :null}
                    isSearchable={true}
                    isDisabled={disabled}
                    isOptionDisabled={option => option.total_stock <= 0 && !option.is_composite}
                    createOptionPosition={"first"}
                    allowCreateWhileLoading={true}
                    loadOptions={loadOptions}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => `${option.product_name}`}
                    getNewOptionData={createCustomItem}
                    formatOptionLabel = {formatOptionLabel} 
                    onChange={(option, { action }) => handleProductSelect(option, action, index) }
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    noOptionsMessage={() => null}
                    isValidNewOption={isValidNewOption}
                  />

                  {value.product_name &&
                    <div className="mb-3">
                      <textarea
                        placeholder="Add item description(optional)"
                        rows="1"
                        name="description"                                    
                        className={`form-control border-0 focus-secondary`}
                        style={{resize: 'none'}}
                        value={value.description || ""}
                        onChange={event => handleInputChange(event, index)}
                        disabled={disabled}
                        />                      
                      {validateIf(value.description, 'max:250', value.product_name, 'description'+'_'.repeat(index))} 
                    </div>
                  }
                </div>
                {(!value.is_composite && value.type != 'composite') &&
                  <div className="col-4">               
                    {value.type == 'product' &&
                      <label htmlFor={`product_${index}`} className="d-flex flex-column h-100 fw-normal">
                          <Select
                            id={`product_${index}`}
                            isSearchable={true}
                            options={value.warehouses}
                            value={value.selected_warehouse || null}
                            styles={selectStyle}
                            components={{IndicatorSeparator:()=>null}}
                            placeholder='Warehouse'
                            isDisabled={ disabled}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => `${option.name}`}
                            isOptionDisabled={option => option.stock <= 0}
                            onChange={(value) => { handleSelect(value, index, 'warehouse') }}  
                            />
                          {validateIf(value.selected_warehouse, 'required', value.product_name && value.type == 'product', 'warehouse'+'_'.repeat(index))}
                      </label>
                    }
                    {value.type == 'addon' &&
                      <label htmlFor={`product_${index}`} className="d-flex flex-column h-100 fw-normal">
                          <Select
                            key={value.tax_class}
                            id={`tax_${index}`}
                            placeholder="Select tax"
                            options={productMetaData?.data?.taxes}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.tax_name}
                            value={_.find(productMetaData?.data?.taxes, ['id', value.tax_class])}
                            isSearchable={true}
                            isDisabled={ disabled}
                            styles={selectStyle}
                            onChange={(value) => { handleSelect(value, index, 'tax_class') }} 
                            components={{IndicatorSeparator:()=>null}}
                            />                       
                            {validateIf(value.tax_class, 'required', value.product_name && value.type == 'addon', 'tax_class'+'_'.repeat(index))}
                      </label>
                    }
                  </div>  
                }
              </div>
            </div>
            <div className='col-1 border-end'>
              {value.product_name &&
                <label htmlFor={`quantity_${index}`} className="d-flex flex-column h-100 fw-normal">
                  <input
                    id={`quantity_${index}`}
                    className="form-control border-0 px-0 "
                    type="number"
                    name="quantity"
                    min="1"
                    step="1"
                    autoComplete='off'
                    max={findMaxQuantity(value) ?? 100}
                    value={value.quantity ?? ""}
                    onKeyPress={(e) => {value.type !== 'addon' ? (preventNonNumeric(e)) :(checkFloat(e))}}
                    onChange={event=>handleInputChange(event, index)}
                    disabled={ disabled} 
                    />
                  {validateIf(value.quantity, `required|between:1,${findMaxQuantity(value) ?? 100},num`, value.product_name, 'quantity'+'_'.repeat(index))}
                </label>
              }
            </div>
            <div className="col-2 border-end">
              {value.product_name &&
              <label htmlFor={`discount_${index}`} className="fw-normal d-block h-100">
                <div className="row">
                  <div className="col-auto">
                    <select
                      name="discount_type"
                      onChange={(event) => handleInputChange(event, index)}
                      className="form-control border-0 cursor discount"
                      disabled={disabled}
                      value={value.discount_type}
                    >
                      <option value="percent">%</option>
                      <option value="fixed">{preferences.currency_symbol}</option>
                    </select>
                  </div>
                  <div className="col">
                  <input
                      id={`discount_${index}`}
                      className="form-control border-0 px-0 "
                      type="number"
                      name="discount"
                      min="0"
                      max={(value.discount_type == "percent") ? (100) : (value.unit_price)}
                      autoComplete='off'
                      value={value.discount ?? ""}
                      maxLength="4"
                      onKeyPress={(value.discount_type == "percent") ? ((e) => onlyPercentage(e)) : ((e) => preventNonNumeric(e))}
                      onChange={event => handleInputChange(event, index)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-12">
                    {(value.discount_type == "percent") ? (
                      validateIf(value.discount,"between:0,100,num", value.product_name, 'discount')
                      ) : (
                      validateIf(value.discount,`between:0,${value.unit_price ?? 100},num`, value.product_name, 'discount'+'_'.repeat(index))
                    )}
                  </div>
                </div>
              </label>
              }
            </div>
            <div className="col-1 border-end">
                <label htmlFor={`unit_price_${index}`} className="d-flex flex-column h-100 fw-normal">
                  <input
                    id={`unit_price_${index}`}
                    className="form-control border-0 px-0 text-start"
                    type="number"
                    name="unit_price"
                    min="0"
                    autoComplete='off'
                    value={value?.unit_price ?? ''}
                    onKeyPress={(e) => checkFloat(e)}
                    onChange={event=>handleInputChange(event, index)}
                    disabled={disabled}
                  />
                  {validateIf(value.unit_price,'required|min:0,num', value.product_name, 'unit price'+'_'.repeat(index))}
              </label>
            </div>
            <div className="col-1 border-end">
              {value.product_name && <div className="d-flex h-100 form-control border-0 px-0">{calculation(index)}</div>}
            </div>
            <div className="col-1">
              {selectedProducts.length != index && 
                <div className="d-flex h-100">
                  <button type ="button" className="btn text-primary fw-bold d-inline p-0" disabled={disabled} onClick={() => handleRemove(index)}>{ < UilTimesCircle size="14" className="text-primary"/> }</button>
                </div>
              }
            </div>
          </div>
        ))
      : <></>}
    </>
  )
}

CreatableSelectProducts.propTypes = {
  selectedProducts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  validator: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  forceUpdate: PropTypes.bool
};