import _ from "lodash";
import React, {  useState, useEffect } from "react";

import ProfileIcon from "../../../components/common/ProfileIcon";
import PageHeader from "../../../layouts/PageHeader";
import { useValidator } from "../../../utils/validator";
import { useLogoUpdateQuery } from "../../../stores/setting/businessInformation";

export default function Logo(props) {
  const [file, setFile] = useState([]);
  const [errors, setErrors] = useState([]);
  const [inputs, setInputs] = useState({});

  const [, forceUpdate] = useState(false);
  const [validator, { validate }] = useValidator(errors);
  const [logoUpdate, { createErrors }] = useLogoUpdateQuery();

  useEffect(() => {
    if (props?.data?.isFetched) {
      setInputs((prevProps) => ({
        ...prevProps,
        logo: props?.data?.data?.business_data?.logo,
        businessName: props?.data?.data?.business_data?.business_name,
      }));
    }
  }, [props?.data?.data]);

  const onFileChange = (event) => {
    event.persist();
    if (!_.isNull(event.target)) {
      setFile(event.target.files[0]);
    }
  };

  const onSubmitLogo = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      const logo = file;
      logoUpdate.mutate({ logo });
    }
    validator.current.showMessages();

    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  return (
    <div>
      <section className="bg-white p-5 rounded mb-4 border">
        <PageHeader
          subtitle="Business Information"
          subtext="Information about your company that will be displayed on invoices, estimates and other documents created by Innoventory"
        />
        <form onSubmit={onSubmitLogo} encType="multipart/form-data">
          <div className="row">
            <div className="col-md-6">
              <label>Current logo</label>
              {inputs.logo ? (
                <div className="">
                  <img className="img-fluid" src={inputs.logo} alt="" />
                </div>
              ) : (
                <div>
                  <ProfileIcon
                    text={inputs.businessName ? inputs.businessName : ""}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div>
                  <label htmlFor="logo">Upload logo</label>
                  <span className="text-light">
                    <small>
                      (PNG or JPG no bigger than 1000px wide and tall.)
                    </small>
                  </span>
                  <input
                    type="file"
                    className="form-control"
                    id="logo"
                    name="logo"
                    onChange={(e) => onFileChange(e)}
                  />
                </div>
                {validate(
                  file,
                  "file_type:image/jpeg,image/png,image/svg|file_size:2",
                  { title: "logo", onServer: errors["logo"] }
                )}
              </div>
              <div className="col-md-6 pt-4">
                <button className="btn btn-secondary px-5">Change logo</button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
