import React, { useContext, useEffect, useState,useRef } from "react";


import api from "../../../api";
import config from "../../../configurations";
import { notify } from "../../../scripts/notify"
import SimpleReactValidator from 'simple-react-validator';
import { preventNonNumeric } from "../../../scripts/utils"
import { MetaDataContext } from "../../../web/context/metaData";


export default function CouponCreationForm(props) {
  const [inputs, setInputs] = useState({
    id: (props.inputs && props.inputs.id) || "",
    name: (props.inputs && props.inputs.name) || "",
    amount: (props.inputs && props.inputs.amount) || "",
  });

  const [errors, setErrors] = useState({});
  const [, formError]=useState(false);

  const validator = useRef(new SimpleReactValidator());

  const handleInputChange = event => {
    event.preventDefault();
    setErrors({});
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.coupons
        .add(inputs)
        .then((response) => {
          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInputs({
              id: "",
              name: "",
              amount: "",
            });
            props.callback();
            props.closeModal();
          } else {
            for (let [field, message] of Object.entries(response.data.errors)) {
              // notify("danger", message[0]);
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          notify();
        });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.coupons
        .edit(inputs)
        .then((response) => {

          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInputs({
              id: "",
              name: "",
              amount: "",
            });
            props.callback();
            props.closeModal();
          } else {
            for (let [field, message] of Object.entries(response.data.errors)) {
            setErrors((prevProps) => ({
              ...prevProps,
              [field]: message[0]
            }));
          }
          }
        })
        .catch(function () {
          notify();
        });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };
  const metaData = useContext(MetaDataContext);
  useEffect(() => {
  }, [metaData]);

  return (
    <div>
      <section className="">
        <form onSubmit={props.isEdit ? (handleEditSubmit) : (handleSubmit)}>
          <div className="row mb-4">
            <div className="col-lg-6  ">
              <label className="fw-bold me-3 ">Coupon/Vouchers code</label>
              <input
                placeholder="Coupon/Vouchers name"
                type="text"
                name="name"
                autoComplete='off'
                className="form-control"
                value={inputs.name}
                onChange={event => handleInputChange(event)}
                ref={props.refName}
              />
              {validator.current.message("name", inputs.name, "required|max:100", { className: "small text-danger" })}
              <div className="small text-danger">{errors.name}</div>
            </div>
            <div className="col-lg-6  ">
              <label className="fw-bold me-3 ">Amount</label>
              <input
                placeholder={metaData.metaData.preference.currency.currency_symbol}
                type="number"
                name="amount"
                className="form-control"
                min="0"
                autoComplete='off'
                onKeyPress={(e) => preventNonNumeric(e)}
                value={inputs.amount}
                onChange={event => handleInputChange(event)}
              />
              {validator.current.message("amount", inputs.amount, "required|min:1,num", { className: "small text-danger" })}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-secondary w-100" >
                {props.isEdit ? "Save changes" : "Add "}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
