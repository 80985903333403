import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import apiClient from "../header";


export function useInvoiceListQuery({
  enabled = true,
  defaultValue = {
    pageNumber: 1,
    limit: 15,
    sort: null,
    searchParams: undefined,
    filters: [],
  },
} = {}) {

  const [pageNumber, setPageNumber] = useState(defaultValue.pageNumber);
  const [limit, setLimit] = useState(defaultValue.limit);
  const [searchParams, setSearchParams] = useState(defaultValue.searchParams);
  const [sort, setSort] = useState(defaultValue.sort);
  const [filters, setFilters] = useState(defaultValue.filters);

  function getList(signal) {
    const query = apiClient
      .get(`invoices/all`, {
        params: { limit: limit, page: pageNumber, search: searchParams, sort: sort, filters: filters, }, signal,
      })
      .then((result) => result.data);
    return query;
  }

  function handleSuccess(data) {
    document.dispatchEvent( new CustomEvent("invoice-listing-fetched", { 'detail': data.data }) );
  }

  const query = useQuery(
    ["invoice-list", { searchParams, pageNumber, sort, filters, limit }], ({ signal }) => getList(signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
      enabled: enabled,
    }
  );

  return [
    query,
    {
      searchParams: searchParams,
      setSearchParams: setSearchParams,

      limit: limit,
      setLimit: setLimit,

      setPageNumber: setPageNumber,

      sort: sort,
      setSort: setSort,

      filters: filters,
      setFilters: setFilters,
    },
  ];
}
