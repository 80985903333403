import React, { useState, useEffect, useRef } from "react";
import { UilPen, UilTrashAlt, UilMoneyBill } from "@iconscout/react-unicons";
import { Modal } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Skeleton from "react-loading-skeleton";

import { notify } from "../../../../scripts/notify";
import api from "../../../../api";

import Loader from "../../../components/global/Loader";
import PageHeader from "../../../layouts/PageHeader";
import PageInfo from "../../../../components/pagination/PageInfo";
import ReactPagination from "../../../../components/pagination/ReactPagination";
import Form from "./Form";
import ModalComponent from "../../../components/common/ModalComponent";
import { useTaxDeleteQuery, useTaxListQuery } from "../../../stores/setting/tax";
import DataTable from "../../../components/common/DataTable";

export default function Listing() {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({});
  const refName = useRef();

  const [query, { setPageNumber }] = useTaxListQuery({
    defaultValue: { limit: 15 },
  });
  const deleteTax = useTaxDeleteQuery();

  const add = () => {
    setOpen(true);
    setInputs({
      tax_id: "",
      tax_name: "",
      tax_amount: "",
      tax_type: "",
      tax_application: "",
    });
  };

  const edit = (id, tax_name, tax_amount, tax_type, tax_application) => {
    setOpen(true);
    setInputs({
      tax_id: id,
      tax_name: tax_name,
      tax_amount: tax_amount,
      tax_type: { label: tax_type, value: tax_type, name: "tax_type" },
      tax_application: {
        label: tax_application,
        value: tax_application,
        name: "tax_application",
      },
    });
  };

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleDelete = (id) => {
    deleteTax.mutate(id);
  };

  const columns = [
    {
      id: "tax_name",
      name: "Tax Name",
      selector: (row) => row.tax_name,
    },
    {
      id: "tax_amount",
      name: "Tax Percentage",
      selector: (row) => row.tax_amount,
    },
    {
      id: "tax_type",
      name: "Tax Type",
      selector: (row) => row.tax_type,
    },
    {
      id: "tax_application",
      name: "Tax Application",
      selector: (row) => row.tax_application,
    },
    
    {
      id: "action",
      name: "",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Edit tax</Tooltip>}
          >
            <span
              className="me-3 "
              onClick={() => {
                setIsEdit(true);
                edit(
                  row.id,
                  row.tax_name,
                  row.tax_amount,
                  row.tax_type,
                  row.tax_application
                );
              }}
              id="edit"
            >
              {<UilPen size="14" className="text-secondary active-icon" onClick={() => {
                setIsEdit(true);
                edit(
                  row.id,
                  row.tax_name,
                  row.tax_amount,
                  row.tax_type,
                  row.tax_application
                );
              }} />}
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Delete tax</Tooltip>}
          >
            <span className="cursor active-icon" id="delete">
              {
                <UilTrashAlt
                  size="14"
                  onClick={() => handleDelete(row.id)}
                  className="text-danger"
                />
              }
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];



  return (
    <React.Fragment>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Tax Types" subtext="Manage tax types" />

          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()}>
              {" "}
              Add Tax Type{" "}
            </button>
          </div>
        </div>

        <div className="border rounded-3 overflow-hidden">
          <DataTable
            data={query?.data?.data}
            columns={columns}
            isLoading={query.isLoading}
            pagination={true}
            paginationMeta={query?.data}
            onChangePage={(page) => setPageNumber(page)}
          />
        </div>
      </div>
      {open ? (
        <ModalComponent
          show={open}
          onHide={closeModal}
          onEntered={() => refName.current.focus()}
          title={inputs.id ? "Edit shipping method" : "Add Shipping Method"}
          body={
            <Form
              inputs={inputs}
              isEdit={isEdit}
              closeModal={closeModal}
              refName={refName}
            />
          }
        />
      ) : null}
    </React.Fragment>
  );
}
