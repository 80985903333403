import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import apiClient from "../../header";

function createTrackpod(data) {
  return apiClient
    .post(`integrations/trackpod/add`, { inputs: data })
    .then((result) => result.data);
}

export function useTrackpodCreateQuery() {

  const[id, setId] = useState(null);
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();

  function handleSuccess(data) {
    setErrors({})
    if (data.status) {
      setId(data.id)
      queryClient.invalidateQueries(["integration-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((newTrackpod) => createTrackpod(newTrackpod), {
    retry: false,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
      id : id
    },
  ];
}
