import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notify } from "../../../scripts/notify";

import apiClient from "../header";

function deleteCategory(id) {    
  const query =  apiClient.post(`categories/delete`, {
    id: id,
  }).then(
    (result) => result.data,
  );
  return query;
}

export function useDeleteCategoryQuery() {
  const queryClient = useQueryClient()
  
  function handleSuccess(data) {
    notify(data.type, data.message);
    queryClient.invalidateQueries(['category-list']);
    queryClient.invalidateQueries(['product-metadata']);
  }

  return useMutation((categoryId) => deleteCategory(categoryId), {
    retry: false,
    onSuccess: handleSuccess
  });
}

