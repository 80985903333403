import React, { useEffect } from "react";
import { useGetProductsQuery, } from "../../stores/channel/getProducts";

export default function GetProducts({ step, setStep, id, name, type }) {
  const [getProductsQuery, { fetched }] = useGetProductsQuery();

  const fetchProducts = async () => {
    getProductsQuery.mutate({ id: id, type: type });
  };

  useEffect(() => {
    if (fetched) {
      setStep(step + 1);
    }
  }, [fetched]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <section className="my-4 bg-white p-5">
            <h4 className="mb-4">Fetch {name} Products</h4>
            <div className="text-light fw-bold">
              <p className="mb-4">
                Fetch all products assigned to the {name} Channel.
              </p>
              <p className="mb-4">
                We will send you a notification once all the products have been
                fetched as this process may take upto 2 hours.
              </p>
            </div>
            <div className="d-grid gap-3">
              <button
                className="btn btn-secondary"
                onClick={() => fetchProducts()}
              >
                Get Products
              </button>
              <a
                className="cursor mb-0 text-center text-decoration-none"
                onClick={() => setStep(step + 1)}
              >
                <small>Skip this step</small>
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
