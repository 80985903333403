import _ from "lodash";
import React from "react";

import PageHeader from "../../layouts/PageHeader";

import GeneralSettings from "./GeneralSettings";
import NotificationTriggers from "./NotificationTriggers";

export default function NotificationSettings() {
  return (
    <>
      <div className="mb-4">
        <PageHeader title="Notifications" subtitle="Settings" />
          <GeneralSettings />
          <NotificationTriggers/>
      </div>
    </>
  );
}
