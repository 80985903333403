import { useState, useEffect } from "react";
import { UilTimesCircle } from "@iconscout/react-unicons";
import { useQueryClient } from "@tanstack/react-query";
import AsyncSelect from 'react-select/async';
import _ from "lodash";

import lang from "../../../lang/en/Theme/Colors"; 
import { preventNonNumeric, checkFloat } from "../../../scripts/utils";

import { getList } from "../../stores/products/list";


export default function SelectProducts({ defaultValue,  onChange = () => {}, validator, disabled = false, forceUpdate = false }) {
  
  const queryClient = useQueryClient();
  
  const [validate, {validateIf}] = validator;  
  
  const [selectedProducts, setSelectedProduct] = useState(defaultValue);
  const [, doForceUpdate] = useState(forceUpdate);
  
  const selectStyle = {
    control: base => ({
      ...base,
      border: 0,
      backgroundColor: "rgba(1, 1, 1, 0.0)",
      boxShadow: 'none'
    }),
    menu: base => ({
      ...base, 
      zIndex: 999 
    }),
    singleValue: (base, state) => ({
      ...base,
      color:lang.colors.secondary
    }),
    placeholder: base => ({
      ...base, 
      color: lang.colors.gray500
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? lang.colors.white : lang.colors.secondary,
    }),
  }; 


  const formatOptionLabel = (option, { context }) => {
    return context === "menu" && option.__isNew__ ? (
			<span className="text-dark">
				Add Custom Item "{option.product_name}"
			</span>
    ) : (
      <div>
        <div>
          <span>{option.product_name}</span>
        </div>
      </div>
    );
  }

  function handleInputChange(event, index) {
    event.persist();  

    var tempSelectedProduct = [...selectedProducts];
    tempSelectedProduct[index][event.target.name] = parseFloat(event.target.value);

    setSelectedProduct(tempSelectedProduct);
  };

  function handleProductSelect(value, action, index) {
    var tempSelectedProduct = [...selectedProducts];
    
    tempSelectedProduct[index] = {
      'product_id': value.id,
      'product_name': value.product_name,
      'quantity':value.quantity ?? 1,
      'total_stock': value.total_stock,
    }

    if (_.findLastIndex(tempSelectedProduct) === index) {
      tempSelectedProduct[index+1] = {}
    }

    setSelectedProduct(tempSelectedProduct)
  }

  function handleRemove(index) {
    if (!disabled) {
      var tempSelectedProduct = [...selectedProducts];
      tempSelectedProduct.splice(index, 1);
      setSelectedProduct(tempSelectedProduct);
    }
  }
    
  const loadOptions = _.debounce((searchParams, callback) => {
    (async () => {
      let filters = {"composite":"hide"};
      let query = await queryClient.fetchQuery(['search-product', {searchParams, filters}], ({queryKey, signal}) => getList(queryKey, signal), {staleTime: Infinity})
      callback(query.data)
    })()
  }, 500);  
  
  useEffect(() => {
    if (validate.current.allValid() && defaultValue != selectedProducts) {
      let data = _.filter(selectedProducts, function(obj) { return !_.isEmpty(obj); });
      if (!_.size(data)) {
        data = [{}];
      }
      onChange(data);
    }
  }, [selectedProducts]);   
  
  return (
    <div key={defaultValue}>     
      <div className="row border border-bottom-0 mx-0 text-uppercase fw-bold text-light">
        <div className='col-9 border-end'>Product </div>
        <div className='col-2 border-end'>Quantity</div>
        <div className="col-1"></div>
      </div>
      {selectedProducts ?
        selectedProducts.map((value, index) => (
        <div key={index}>
            <div className="row border mx-0">
              <div className='col-9 border-end'>
                <div className="row">
                  <div className="col-8">
                    <AsyncSelect
                      name={value.id}
                      styles={selectStyle}
                      placeholder='Start typing here....'
                      value={ value.product_name ? value : value.products ? value.products :null}
                      isSearchable={true}
                      isDisabled={disabled}
                      loadOptions={loadOptions}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => `${option.product_name}`}
                      formatOptionLabel = {formatOptionLabel} 
                      onChange={(option, { action }) => handleProductSelect(option, action, index) }
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                  </div>
                  <div className="col-4">               
                  {value.product_name && 
                    <div className="mt-2 text-end" >
                      <span className="text-light">
                        Total Stock : {value.total_stock}
                      </span>
                    </div>
                  }
                  </div>  
                </div>
              </div>
              <div className='col-2 border-end'>
                {value.product_name &&
                  <label htmlFor={`quantity_${index}`} className="d-flex flex-column h-100 fw-normal">
                    <input
                      id={`quantity_${index}`}
                      className="form-control border-0 px-0 "
                      type="number"
                      name="quantity"
                      min="1"
                      step="1"
                      autoComplete='off'
                      max={value.total_stock}
                      value={value.quantity ?? ""}
                      onKeyPress={(e) => {value.type !== 'addon' ? (preventNonNumeric(e)) :(checkFloat(e))}}
                      onChange={event=>handleInputChange(event, index)}
                      disabled={ disabled} 
                      />
                    {validateIf(value.quantity, `required|between:1,${value.max_quantity ?? 100},num`, value.product_name, 'quantity')}
                  </label>
                }
              </div>
              <div className="col-1">
                {selectedProducts.length-1 != index && 
                  <div className="d-flex h-100">
                    <button type ="button" className="btn text-primary fw-bold d-inline p-0" disabled={disabled} onClick={() => handleRemove(index)}>{ < UilTimesCircle size="14" className="text-primary"   /> }</button>
                  </div>
                }
              </div>
            </div>

        </div>
        ))
      : <></>}
    </div>
  )}