import React from "react";
import { UilCreditCard } from "@iconscout/react-unicons";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";

import lang from "../../../lang/en/Theme/Colors";
import CustomSelect from "../global/CustomSelect";

export default function Payment({ paymentMethod, payment_method, payment_details, handleInputChanges, isDisabled = false, modal = true, validator, handleSelectNewPayment, shouldValidate = true }) {
  // console.log(validateField);
   const formatOptionLabel = (option, { context }) => {
    return context === "menu" && option.__isNew__ ? (
      <span>Add {option.value}</span>
    ) : (
      <div>
        <div>
          <span>{option.name} </span>
        </div>
      </div>
    );
  };

  return (
    <div className={`ps-0 ${modal ? "col-12" : "col-6"}`}>
      <div className={`px-4 py-3 bg-white ${modal ? "" : "border"}`}>
        <div className="row">
          <div className="col-12 dropdown mb-3">
            <div className="fw-bold  d-flex align-items-center mb-1">
              { <UilCreditCard size="14" color={lang.colors.secondary} className="me-1" /> }
              Payment Method
            </div>
            {modal ? (
              <>
              <CustomSelect
                name="payment_method"
                placeholder="Select Payment Method"
                isSearchable={true}
                isDisabled={isDisabled}
                closeMenuOnSelect={true}
                options={paymentMethod}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                value={_.find(paymentMethod, function (obj) {
                  return obj.name === payment_method;
                })}
                onChange={(e) => handleInputChanges("payment_method", e.name)}
              />
          {/* {console.log(validateField)}
              {validateField ? ""
              : validator.current.message("payment method", payment_method, "required", { className: "small text-danger" })} */}
              </>
            ) : (
              <CreatableSelect
                cacheOptions
                defaultOptions
                closeMenuOnSelect={true}
                options={paymentMethod}
                isSearchable={true}
                placeholder="Select Payment Method"
                isDisabled={isDisabled}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                value={_.find(paymentMethod, function (obj) {
                  return obj.name === payment_method;
                })}
                getNewOptionData={(inputValue, optionLabel) => ({
                  id: optionLabel,
                  value: inputValue,
                  __isNew__: true,
                })}
                onChange={(option, { action }) => {handleSelectNewPayment(option, action);}}
                noOptionsMessage={() => null}
                formatOptionLabel={formatOptionLabel}
              />
            )}
          
            {shouldValidate &&  validator.current.message("payment method", payment_method, "required", { className: "small text-danger" } )}
          </div>
          <div className="col-md-12">
            <label className="fw-bold mb-1">Payment Details</label>
            <div>
              <textarea
                placeholder="Payment Details"
                rows="1"
                cols="180"
                name="payment_details"
                className="form-control"
                value={payment_details || ""}
                disabled={isDisabled}
                onChange={(event) =>
                  handleInputChanges("payment_details", event.target.value)
                }
              />
              {validator.current.message( "payment details", payment_details, "max:255", { className: "small text-danger" } )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
