import React, { useState, useRef, useContext } from "react";
import { UilPen, UilTrashAlt } from "@iconscout/react-unicons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import DataTable from "../../../components/common/DataTable";
import PageHeader from "../../../layouts/PageHeader";
import { MetaDataContext } from "../../../../web/context/metaData";
import ModalComponent from "../../../components/common/ModalComponent";

import Form from "./Form";
import { useCouponDeleteQuery, useCouponListQuery } from "../../../stores/setting/coupon";


export default function Listing() {
  const {data: preferences} = useContext(MetaDataContext);
  const refName = useRef();

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({
    id: "",
    name: "",
    amount: "",
  });

  const [query, { setPageNumber }] = useCouponListQuery();
  const deleteCoupon = useCouponDeleteQuery();

  const add = () => {
    setOpen(true);
    setInputs({
      id: "",
      name: "",
      amount: "",
    });
  };

  const edit = (id, name, amount) => {
    setOpen(true);
    setInputs({
      id: id,
      name: name,
      amount: amount,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleDelete = (id) => {
    deleteCoupon.mutate(id);
  };

  const columns = [
    {
      id: "name",
      name: "Coupons Name",
      selector: (row) => row.name,
    },
    {
      id: "amount",
      name: "Amount",
      cell: (row) =>
      preferences.currency_symbol + row.amount,
    },
    {
      id: "action",
      name: "",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit coupons</Tooltip>} >
            <span className="me-3 cursor active-icon" onClick={() => { setIsEdit(true); edit(row.id, row.name, row.amount); }} id="edit" >
              {<UilPen size="14" className="text-secondary" />}
            </span>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete coupons</Tooltip>} >
            <span className="cursor active-icon" id="delete">
              { <UilTrashAlt size="14" onClick={() => handleDelete(row.id)} className="text-danger" /> }
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Coupons" subtext="Manage coupons" />
          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()}>Add Coupon</button>
          </div>
        </div>

        <div className="border rounded-3 overflow-hidden">
          <DataTable
            data={query?.data?.data}
            columns={columns}
            isLoading={query.isLoading}
            pagination={true}
            paginationMeta={query?.data}
            onChangePage={(page) => setPageNumber(page)}
          />
        </div>
      </div>

      {open ? (
        <ModalComponent
          show={open}
          onHide={closeModal}
          onEntered={() => refName.current.focus()}
          title={inputs.id ? "Edit Coupon" : "Add Coupon"}
          body={
            <Form
              inputs={inputs}
              isEdit={isEdit}
              closeModal={closeModal}
              refName={refName}
            />
          }
        />
      ) : null}
    </React.Fragment>
  );
}
