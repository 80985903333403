import axios from "axios";
import { useNavigate } from "react-router-dom";
import localStorage from "../../localstorage";
import { Paths } from "../../routes";
import { notify } from "../../scripts/notify";


// const navigate = useNavigate();

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: "Bearer " + localStorage.token.get(),
    'X-Tenant': localStorage.business.get()
  },
});

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status == 401) {
      localStorage.token.logout()
      notify('error', 'This action is unauthorized.');
      setTimeout(() => {
        window.location.href = Paths.Login.path;
      }, 2000)
     
      return axios.request(error.config);
    }
    
    return Promise.reject(error); 
  }
);

export default API;