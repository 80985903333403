import React, { useEffect, useState } from "react";
import _ from 'lodash';

import { useValidator } from "../../../utils/validator";
import { useEbayCreateQuery } from "../../../stores/channel/ebay/create";


export default function Add({ step, setStep, setId }){

  let windowObjectReference = null;
  let previousUrl = null;
  let OauthCode = true;


  const initial  = {
    channel_name: "",
    code: "",
  };

  const [ebayCreateQuery, { createErrors, id }] = useEbayCreateQuery();


  const [inputs, setInputs] = useState(initial);
  const [errors, setErrors] = useState({});
  const [validator, { validate }] = useValidator(errors);
  const [, forceUpdate] = useState();


  const handleInputChange = (event) => {
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
    if (errors[event.target.name]) {
      setErrors((prevProps) => ({
        ...prevProps,
        [event.target.name]: "",
      }));
    }
  };


	function getAccess(event) {
		event.preventDefault();
		if (validator.current.allValid()) {			
			OauthCode = true;
			var scope1 = 'https://api.ebay.com/oauth/api_scope/sell.fulfillment';
			var scope2 = 'https://api.ebay.com/oauth/api_scope/sell.inventory';
			var scope3 = 'https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly';
			var scopes = 'https://api.ebay.com/oauth/api_scope ' + scope1 + ' ' + scope2 + ' ' + scope3;

			// var client_id = 'SuhethMe-unified-PRD-9b454faf4-0ff24ca8';
			// var redirect_uri = 'Suheth_Menon-SuhethMe-unifie-uuwvsgj';

			var client_id = 'SuhethMe-unified-SBX-e1b3d5ec1-860749d0';
			var redirect_uri = 'Suheth_Menon-SuhethMe-unifie-pasuuehf';

			var url = 'https://auth.sandbox.ebay.com/oauth2/authorize?client_id='+ client_id +'&redirect_uri='+ redirect_uri +'&scope='+scopes+'&response_type=code&prompt=login'
			// window.open(url,'Ebay Auth','toolbar=no, menubar=no, width=600, height=900, top=100, left=100')
			openSignInWindow(url, 'Ebay Auth')
		} else {
			validator.current.showMessages();
      setErrors(true);
		}
	}

	
  function openSignInWindow(url, name) {
    // remove any existing event listeners
    window.removeEventListener('message', getOauthCode);
    // window features
    const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      windowObjectReference.focus();
    }

    window.addEventListener('message', event => getOauthCode(event), true);
    previousUrl = url;
  };
	
  const getOauthCode = (event) => {
	
    // remove any existing event listeners
    window.removeEventListener('message', getOauthCode);
    const { data } = event;
		if (typeof data !== 'object' && OauthCode && validator.current.allValid()) {
			OauthCode = false;
      ebayCreateQuery.mutate({ 'code': data, 'channel_name': inputs.channel_name })
		}
      validator.current.showMessages();
      forceUpdate(true);
    
	};

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (!_.isNull(id)) {
      setId(id);
      setStep(step + 1);
    }
  }, [id]);



  return (
    <div>
			{/* <input type="button" value="" onClick={getAccess()}/> */}
      <div className="row">
        <div className="col-lg-6 amazon-channel-integration">
          <section className="bg-white p-5">
            <h4 className="mb-4">Channel Details</h4>
            <form onSubmit={getAccess}>

              <div className="mb-3">
                <label className="" htmlFor="channel_name">Channel Name</label>
                <input
                  placeholder="Enter Channel Name"
                  type="text"
                  name="channel_name"
                  className="form-control"
                  value={inputs?.channel_name || ''}
                  onChange={handleInputChange}
                />
                 {validate(inputs.channel_name, "required", { title: "channel_name", onServer:  errors["channel_name"] })}

              </div>

              <div className="d-grid mb-3">
                <button className="btn btn-secondary">Authorize Channel</button>
              </div>

              <div>
                <p className="small text-light">
                  Once you click the authorize button, you will be redirected to a new window to login to your Ebay sellers account. Once you complete the authorization you will be redirected here.
                </p>
              </div>

            </form>
          </section>

        </div>
      </div>
    </div>
  );
};


