
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { objectToFormData } from "../../../scripts/utils";
import { Paths } from "../../../routes";
import { toast } from "react-toastify";

function createQuotation(data) {
  const formData = new FormData
  objectToFormData(data, formData);
  
  return apiClient.post(`quotations/add`, formData)
  .then(
    (result) => result.data,
  );
}


export function useQuotationCreateQuery() {
  const [errors, setErrors] = useState({});
	const navigate = useNavigate();
  const queryClient = useQueryClient();

  var toastify;

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true })
    if (data.status) {
      queryClient.invalidateQueries(['quotations-list']);
      setTimeout(() => {
        navigate(Paths.Quotations.path);
      }, 2000)
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors(prevProps => ({
        ...prevProps,
          [error]: message[0]
        }));
      }
    }
  }

  function handleError(data) {
    toast.update(toastify, { render: 'Something went wrong', type: 'error', isLoading: false, autoClose: true, closeButton: true })
  }
  
  function handleProcessing(data) {
    toastify = toast.loading("Please wait...")
  }
  
  const mutate =  useMutation((newQuotation) => createQuotation(newQuotation), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
    onError: handleError
  });

  return [
    mutate,
    {
      'createErrors': errors,
    },
  ]
}