import React, { useState, useContext } from "react";
import _, { functions } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { UilEllipsisH, UilTrashAlt, UilPen, UilBox } from '@iconscout/react-unicons';

import { Paths } from '../../../routes';
import configurations from '../../../configurations';
import { MetaDataContext } from "../../../web/context/metaData";

import { useProductListQuery } from '../../stores/products/list';
import { useDeleteProductQuery } from '../../stores/products/delete';

import ActionBar from '../../components/global/ActionBar';
import DataTable from '../../components/common/DataTable';
import PageHeader from '../../layouts/PageHeader'
import ErrorPage from '../../components/errors/ErrorPage';
import UpdateStock from '../../components/product/UpdateStock';
import ModalComponent from '../../components/common/ModalComponent';
import WarehouseStockStatus from '../../components/product/WarehouseStockStatus';


export default function Listing() {
 
  const navigate = useNavigate();
  const {data: preferences} = useContext(MetaDataContext);

  const [productQuery, productQueryData, {searchParams, setSearchParams, setPageNumber, setSort, setFilters}] = useProductListQuery({defaultValue: {limit: 15}});
  const deleteProduct = useDeleteProductQuery();

  const [stockData, setStockData] = useState({
    product_id: null,
    product_name: "",
    warehouses: [],
    openModel: false
  });
  
  function handleDelete(id) {
    deleteProduct.mutate(id);
  } 

  function handleEdit(id, isComposite) {
    isComposite ? navigate(Paths.Product.pages.editComposite.path + id) : navigate(Paths.Product.pages.edit.path + id)
  } 

  function openModal(product_id, product_name, productWarehouses){
    setStockData({
      product_id: product_id,
      product_name: product_name,
      warehouses: productWarehouses,
      openModel: true
    });
  }

  function handleClose() {
    setStockData({openModel: false });
  }  

  function handleFilters(value) {
    if (value) {
      if (Array.isArray(value)) {
        var filterData = [];
        value.map(data => filterData[data.key] = value.map(item => item.value));
        setFilters((prevProps) => ({
          ...prevProps,
          ...filterData,
        }));  
      } else {
        setFilters((prevProps) => ({
          ...prevProps,
          [value.key]: value.value
        }));  
      }
    } else {
      setFilters([])
    }
  }

  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: 'product_name',
      name: 'Product Name',
      selector: row => row.product_name,
      width: '500px',
    },
    {
      id: 'total_stock',
      name: 'Total Stock',
      cell: row => (
        !row?.is_composite &&
          row?.warehouses?.map((warehouse) => (
            <div key={warehouse.name}>
              <WarehouseStockStatus name={warehouse.name} stock={warehouse.stock} stockWarning={warehouse.warning_threshold}/>
            </div>
          ))
      ),
    },
    {
      id: 'selling_price',
      name: 'Selling Price',
      selector: row => preferences.currency_symbol + row.selling_price,
    },
    {
      name: '',
      id: 'action',
      cell: row => (
        <ul className="dropdown text-end mb-0">
          <button className="btn btn-link" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" >
            <span><UilEllipsisH size="16" className="text-secondary"/></span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end py-0">
            {!row?.is_composite && (
            <li className="fw-bold cursor dropdown-item py-2"  onClick={() => openModal(row.id, row.product_name, row.warehouses)}>
                <span><UilBox size="14" className="text-secondary me-1 mb-1" />Stock Update</span>
            </li>)}
            <li className="fw-bold cursor dropdown-item py-2" onClick={() => handleEdit(row.id, row?.is_composite)} >
              <span><UilPen size="14" className="text-secondary me-1 mb-1"/>Edit Product</span>
            </li>
            <li className="fw-bold cursor dropdown-item py-2" onClick={() => handleDelete(row.id)} >
                <span><UilTrashAlt size="14" className="text-danger me-1 mb-1"/>Delete Product </span>
            </li>
          </ul>
        </ul>
      ),
    }, 
  ]
  
  return (
    <>
      {productQuery.isError && <ErrorPage innerPage={false} error='500'/> }
      <PageHeader 
        title='Products' 
        subtitle='All Products' 
        component={<Link to={Paths.Product.pages.create.path} className="btn btn-secondary">Create Product</Link>} 
      />
      
      <ActionBar searchParams={searchParams} setSearchParams={handleSearch} sortOptions={configurations.SortForProduct} setSort={setSort} filterOptions={configurations.FilterForProduct} setFilters={handleFilters} placeholder="Search by Product name"/>
      <DataTable 
        data={productQueryData?.data} 
        columns={columns} 
        isLoading={productQuery.isLoading}
        pagination={true}
        paginationMeta={productQueryData?.meta}
        onChangePage={page=> setPageNumber(page)}
      />

      <ModalComponent 
        show={stockData.openModel} 
        title={`Stock Update ` + stockData.product_name }
        body={<UpdateStock data={stockData} onUpdate={handleClose}/>} 
        onHide={handleClose} 
      />

    </>
  )  

}