import _ from "lodash";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UilUpload, UilFile, UilTrashAlt, UilPaperclip, } from "@iconscout/react-unicons";

import lang from "../../../lang/en/Theme/Colors";


export default function FileAttachments({ value, onChange, validator, isDisabled = false}) {

  const [inputs, setInputs] = useState([]);
  const [errors, setErrors] = useState({});
  const [fileValidate, { validate }] = validator;
  const [, forceUpdate] = useState();
  const MAX_LENGTH = 5;

  function add(event) {
    event.persist();
    if (!_.isNull(event.target.files)) {
      for (let i = 0; i < event.target.files.length; i++) {
        setInputs((prevProps) => [...prevProps, event.target.files[i]]);
      }
    }
    forceUpdate(true);
  }

  function remove(event, index) {
    event.preventDefault();
    setInputs(
      (prevProps) => (
        (prevProps = _.remove(prevProps, function (obj, key) {
          return [key] != index;
        })),
        [...prevProps]
      )
    );
    setErrors((prevProps) => ({
      ...prevProps,
      limit: "",
    }));
  }

  useEffect(() => {
    if (!_.isEmpty(inputs)) {
      if (inputs.length > MAX_LENGTH) {
        var err = "Exceeded the limit to Upload files";
        setErrors((prevProps) => ({
          ...prevProps,
          limit: err,
        }));
      }
    }
  }, [inputs]);

  useEffect(() => {
    setInputs(value);
  }, [value]);

  useEffect(() => {
    fileValidate.current.purgeFields();
    onChange(inputs);
  }, [inputs]);

  function FileDrop() {
    return (
      <>
        <div className="row">
          {!isDisabled && (
            <div className="col-6">
              <div className="fw-bold mt-2 d-flex align-items-center mb-1">
                { <UilPaperclip size="14" color={lang.colors.secondary} className="me-1" /> }
                Add a File Attachment
              </div>
              <div className="small text-gray-500">
                Upload a JPG, JPEG, PNG, PDF file of not more than 2 MB. Add maximum 5 files.
              </div>
              <label htmlFor="product_image" className="py-3 w-100">
                <div className="bg-lighter border border-2 rounded text-center p-5 cursor">
                  {<UilUpload size="30" className="me-1 text-secondary" />}
                  <div className="fw-bold mt-2 text-decoration-underline">
                    Click here to add a image
                  </div>
                </div>
              </label>
              <input
                className="form-control d-none cursor"
                type="file"
                id="product_image"
                onChange={(e) => add(e)}
                multiple={true}
                disabled={isDisabled}
                accept="image/*, application/pdf"
              />
              {/* <span>{validate("limit", "max:5")}</span> */}
            </div>
          )}
          <div className="col-lg-6 ps-0">
            <div className="row">
              <div className="fw-bold mt-2 d-flex align-items-center mb-5 ms-2">
                Uploads
              </div>
            </div>
            <div className="row">
              {_.isEmpty(inputs) ? (
                <div className="col-6">
                  <div className="text-gray-500 w-100 ms-2">
                    No files uploaded
                  </div>
                </div>
              ) : (
                <div className="">
                  {inputs.map((data, index) => (
                    <File data={data} index={index} key={index} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  function File({ data, index = 0 }) {
    return (
      <div className="col-lg-12 mb-3" key={index}>
        <div className="border border-gray-200 rounded p-2 d-flex justify-content-between">
          <div>
            <span>
              <UilFile size="14" className="me-1 mb-1 text-primary" />
            </span>
            <span className="text-primary ms-2">{data.name}</span>
          </div>
          <div className="text-end">
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete file</Tooltip>} >
              <button className="btn text-secondary fw-bold d-inline p-0" onClick={(e) => remove(e, index)} disabled={isDisabled} >
                {<UilTrashAlt size="16" className="me-1 text-danger" />}
              </button>
            </OverlayTrigger>
          </div>
        </div>
        {!("path" in data) && validate(data, "file_type:image/jpeg,image/png,application/pdf|file_size:2", { title: "upload_files "+'_'.repeat(index) })}
      </div>
    );
  }


  return (
    <>
      <FileDrop />
    </>
  );
}
