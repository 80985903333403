import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';


import { MetaDataContext } from "../../../web/context/metaData";
import { abbreviateNumber } from '../../../scripts/utils';
import localstorage from '../../../localstorage';
import configurations from '../../../configurations';

import CardComponent from '../../layouts/CardLayout';
import DataTable from '../common/DataTable';


export default function SalesbyCategory(props) {

  const settings = JSON.parse(localstorage.settings.get());
  let userSetting = settings || {};
  const [pageNumber, setPageNumber] = useState(settings ? settings.salesPageNo : 1);
  const metaData = useContext(MetaDataContext);
  const [filter, setFilter] = useState(null);

  const pageChange =(pageNo) => {
    setFilter(
      prevProps => ({
        ...prevProps,
        key: props.filter_key,
        pageNumber:pageNo
    }) );
    userSetting = {
      ...userSetting,
      salesPageNo: pageNo
    };
    localstorage.settings.set(userSetting);
    setPageNumber(pageNo);
  };

  const columns = [
    {
      id: "category_name",
      name: "Category Name",
      cell: (row) => row.category_name,
    },
    {
      id: "order_numbers",
      name: "No. of Orders",
      cell: (row) => row.order_count,
    },
    {
      id: "sales_amount",
      name: "Total sales amount",
      cell: (row) => abbreviateNumber(row.sales_amount, props.withCurrency),
    },
    {
      id: "sales_in_cubic_meter",
      name: "Sales in Cubic meters",
      cell: (row) => (
        <>
          {abbreviateNumber(row.sales_in_cubic_meter, false)}
          {row.sales_in_cubic_meter ? (
            <>
            <> </>
              <span
                className="p"
                dangerouslySetInnerHTML={{
                  __html: configurations.Units.symbol,
                }}
              />
            </>
          ) : null}
        </>
      ),
    },
  ];

  
  useEffect(() => {
    if (filter) {
      props.fetchFilteredData(filter);
    }
  }, [filter]);

  useEffect(() => {
  }, [metaData]);

  return (
    <>
      <CardComponent title={props.title} pagination={false}>
        <div className="table border mb-4 ">
        <DataTable
          data={props?.data?.data}
          columns={columns}
          isLoading={props.isLoading}
          onChangePage={(page) => pageChange(page)}
          pagination={true}
          paginationMeta={props?.data}
        />
        </div>
      </CardComponent>
    </>
  );

}
