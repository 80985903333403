import _ from "lodash";
import React, { useState, useEffect } from "react";

import { useValidator } from "../../../utils/validator";
import { useOpencartCreateQuery } from "../../../stores/channel/opencart/create";


export default function Add({ step, setStep, setId }) {
  const initial = {
    channel_name: "",
    channel_username: "",
    api_key: "",
    api_url: "",
  };

  const [opencartCreateQuery, { createErrors, id }] = useOpencartCreateQuery();

  const [, forceUpdate] = useState();
  const [inputs, setInputs] = useState(initial);
  const [errors, setErrors] = useState({});
  const [validator, { validate }] = useValidator(errors);

  const handleInputChange = (event) => {
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
    if (errors[event.target.name]) {
      setErrors((prevProps) => ({
        ...prevProps,
        [event.target.name]: "",
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      opencartCreateQuery.mutate(inputs);
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (!_.isNull(id)) {
      setId(id);
      setStep(step + 1);
    }
  }, [id]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <section className="bg-white p-5">
            <h4 className="mb-4">Add a Channel</h4>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="" htmlFor="channel_name">
                  Channel Name
                </label>
                <input
                  placeholder="Enter Channel Name"
                  type="text"
                  name="channel_name"
                  className="form-control"
                  value={inputs?.channel_name || ""}
                  onChange={handleInputChange}
                />
                {validate(inputs.channel_name, "required", { title: "channel_name", onServer: errors["channel_name"] })}
              </div>

              <div className="mb-3">
                <label className="" htmlFor="channel_username">
                  OpenCart username
                </label>
                <input
                  placeholder="Enter OpenCart Username"
                  type="text"
                  name="channel_username"
                  className="form-control"
                  value={inputs?.channel_username || ""}
                  onChange={handleInputChange}
                />
                {validate(inputs.channel_username, "required", { title: "channel_username" })}
              </div>

              <div className="mb-3">
                <label htmlFor="api_key" className="me-4">
                  OpenCart API Key
                </label>
                <a
                  target="__blank"
                  href="http://docs.opencart.com/en-gb/system/users/api/#:~:text=For%20using%20OpenCart%20API%20you,set%20%22Status%22%20to%20enable."
                  className="text-info fw-bold"
                >
                  <small>Find API key here</small>
                </a>
                <input
                  type="text"
                  name="api_key"
                  className="form-control"
                  placeholder="Enter OpenCart API Key"
                  value={inputs?.api_key || ""}
                  onChange={handleInputChange}
                />
                {validate(inputs.api_key, "required", { title: "api_key", onServer: errors["api_key"] })}
              </div>

              <div className="mb-3">
                <label className="" htmlFor="api_url">
                  OpenCart URL
                </label>
                <input
                  type="text"
                  name="api_url"
                  className="form-control"
                  placeholder="Enter OpenCart Url"
                  value={inputs?.api_url || ""}
                  onChange={handleInputChange}
                />
                {validate(inputs.api_url, "required", { title: "api_url",  onServer: errors["api_url"] })}
              </div>
                <span className="small text-danger">{errors["Errors"]}</span>

              <div className="d-grid">
                <button className="btn btn-secondary">Add Channel</button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
}
