import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, Cell } from 'recharts';

import configurations from '../../../configurations';
import colors from "../../../lang/en/Theme/Colors";

import { abbreviateNumber } from "../../../scripts/utils";
import CardComponent from '../../layouts/CardLayout';
import NoDataFound from '../global/NoDataFound';

export default function BarChartHorizontalBlock(props) {

  var data = _.map(props.data, (o) => (_.extend({ color: configurations.OrderStatus[o.order_status].color, name: configurations.OrderStatus[o.order_status].status, sort_order: configurations.OrderStatus[o.order_status].sort_order }, o)));
  data = _.sortBy(data, 'sort_order');
  const CustomTooltip = ({ active, payload, label }) => {

    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-light p-2">
          <p className="label text-break small">{`${label}`}</p>
          <p className="text-secondary">{`${props.name} : ${abbreviateNumber(payload[0].value, props.withCurrency)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <CardComponent filter_key={props.filter_key} title={props.title} footer={props.footer}  >

      {!_.isEmpty(props.data) ? (

        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={data} layout="vertical">
            <CartesianGrid stroke={colors.colors.gray400} strokeDasharray="3 3" />
            <XAxis type="number" dy={10} dataKey='order_count' allowDecimals={false} />
            <YAxis type="category" width={80} dataKey="name" tick={props.settings.font} allowDecimals={false} />
            <Tooltip content={CustomTooltip} labelStyle={props.settings.font} cursor={{ fill: colors.colors.gray200 }} />
            <Bar name={props.name} dataKey="order_count" barSize={15} fill={colors.colors.chartColors[0]} >
              {
                data.map((o, index) => (
                  <Cell key={`cell-${index}`} fill={o.color} />
                ))
              }
            </Bar>
          </BarChart>

        </ResponsiveContainer>
      ) : (
          <NoDataFound />
      )

      }

    </CardComponent>

  );
}
