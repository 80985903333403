import React from 'react';

import { Paths } from "../../../routes";
import List from '../../components/common/List';
import PageHeader from '../../layouts/PageHeader';
import Trackpod from '../../../assets/img/pages/integration/trackpod.svg';


export default function Add() {
  return (
    <>

      <PageHeader title={'integrations'} subtitle={'Add an Integration'} subtext={'You can now easily intergrate multiple applications to our centralised inventory management system.'} />

      <div className="row">
        <List link={Paths.Integration.pages.createTrackpod.path} icon={Trackpod} name='TrackPod' option = 'integration' />
      </div>

    </>
  )
}
