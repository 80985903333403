import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import _ from 'lodash';
import colors from "../../../lang/en/Theme/Colors";

import CardComponent from '../../layouts/CardLayout';
import NoDataFound from '../global/NoDataFound';
import { abbreviateNumber } from '../../../scripts/utils';


export default function StackedBarChartBlock(props) {


  const tickFormatter = (value) => {
    const limit = 20; // put your maximum character
    if (value.length < limit) return value;
    return value.substring(0, limit) + '...';
  }

  return (
    <>
      <CardComponent filter_key={props.filter_key} title={props.title} footer={props.footer}>
        {!_.isEmpty(props.data) ? (
          <ResponsiveContainer width="100%" height={390} className="bar-chart-block">
            <BarChart data={props.data}
              width={400}
              height={300}
              margin={{
                top: 20,
                right: 20,
                left: 20,
                bottom: 20,
              }}
            >
              <CartesianGrid stroke={colors.colors.gray400} strokeDasharray="3 3" />
              <XAxis dataKey={props.XAxisKey} tickFormatter={tickFormatter} width={30} dy={10} interval={0} tick={{ fontSize: 10 }} allowDecimals={false} />
              <YAxis type="number" tickFormatter={(value) => abbreviateNumber(value, props.withCurrency)} allowDecimals={false} />
              <Legend iconSize={15} layout="horizontal" verticalAlign="bottom" align="center" wrapperStyle={{ position:'relative' }} />
              <Tooltip 
                formatter={function (value, name) {
                  return `${abbreviateNumber(value, props.withCurrency) }`;
                }}
                wrapperStyle={{ width: '30%' }}
               />
              <Bar  dataKey="Innoventory" stackId="a" fill={colors.colors.chartColors[0]} barSize={80}  />
              <Bar dataKey="Opencart" stackId="a" fill={colors.colors.chartColors[1]} barSize={80}  />
              <Bar dataKey="Amazon" stackId="a" fill={colors.colors.chartColors[2]} barSize={80}  />
              <Bar dataKey="Ebay" stackId="a" fill={colors.colors.chartColors[3]} barSize={80}  />
            </BarChart>
          </ResponsiveContainer>
        ) : (
            <NoDataFound />
        )
        }
      </CardComponent>
    </>
  );
}
