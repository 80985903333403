import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import apiClient from "../../header";


export function useAmazonCreateQuery() {
  
  const [id, setId] = useState(null);
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  
  function create(data) {
    return apiClient
      .post(`channels/amazon/add`, { data})
      .then((result) => result.data);
  }

  function handleSuccess(data) {
    setErrors({})
    if (data.status) {
      setId(data.id)
      queryClient.invalidateQueries(["channel-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((data) => create(data), {
    retry: false,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
      id : id
    },
  ];
}
