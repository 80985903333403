import ReactSelect from "react-select";
import CustomSelectComponent from "./CustomSelectComponent";

export default function Sort({options, control, ...rest}) {
  return (
    <div>
      <CustomSelectComponent
        {...rest} 
        type='1'
        options={options}
        placeholder={'Sort by'}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        components={{
          IndicatorSeparator:() => null
        }}
        onChange={({ value }) => control(value)}
      />
    </div>
  );
}
