import Select from 'react-select';

import lang from "../../../lang/en/Theme/Colors";

export default function CustomSelect(props){

  const selectStyle = {
    placeholder: base => ({
      ...base, 
      color: lang.colors.gray500
    }),
  }; 

  return (
    <Select
      id= {props.id}
      name= {props.name}
      placeholder= {props.placeholder}
      className ={props.className ?? ""}
      isSearchable={props.isSearchable}
      isDisabled={props.isDisabled ?? false}
      isMulti={props.isMulti ?? false}
      isClearable ={props.isClearable ?? false}
      isLoading={props.isLoading}
      closeMenuOnSelect={props.closeMenuOnSelect ?? false}
      options={props.options ?? ""}
      getOptionValue={props.getOptionValue}
      getOptionLabel={props.getOptionLabel}
      value = {props.value}
      styles={selectStyle}
      onChange={props.onChange}
      components={props.components ?? null}
    />
  );
};
