import { NavLink, useLocation } from "react-router-dom";
import _ from 'lodash'
import React from 'react';
import { UilUser, UilUsersAlt, UilUniversity, UilReceipt, UilBuilding, UilCog, UilUserMd, UilTruckLoading, UilRuler, UilTruck, UilPricetagAlt, UilTransaction, UilEnvelope, UilBell, UilEdit } from "@iconscout/react-unicons";

export default function TabsLayout(props) {
  const icons = (name) => {
    switch (name) {
      case "Account Settings": return <UilUserMd size="14" className="me-2 " />;
      case "Business Information": return <UilUniversity size="14" className="me-2 mb-1" />;
      case "Customization": return <UilEdit size="14" className="me-2 mb-1" />;
      case "Preferences": return <UilCog size="14" className="me-2 mb-1" />;
      case "Users": return <UilUser size="14" className="me-2 mb-1" />;
      case "User Groups": return <UilUsersAlt size="14" className="me-2 mb-1" />;
      case "Warehouses": return <UilBuilding size="14" className="me-2 mb-1" />;
      case "Manufacturers": return <UilTruckLoading size="14" className="me-2 mb-1" />;
      case "Tax Types": return <UilReceipt size="14" className="me-2 mb-1" />;
      case "Units": return <UilRuler size="14" className="me-2 mb-1" />;
      case "Shipping Methods": return <UilTruck size="14" className="me-2 mb-1" />;
      case "Payment Methods": return <UilTransaction size="14" className="me-2 mb-1" />;
      case "Coupons": return <UilPricetagAlt size="14" className="me-2 mb-1" />;
      case "Mail SMTP": return <UilEnvelope size="14" className="me-2 mb-1" />;
      case "Notifications": return <UilBell size="14" className="me-2 mb-1" />;
      default: return <UilCog size="16" className="me-2 mb-1" />;
    }
  }
	
  return (
    <>
       
      {
        props.tabs.map((tab, index) => (
          <NavLink
            key={tab.name + index}
            to={tab.path}
            // isActive={() => _.includes(props.activeRoute, tab.path)}
            className="btn btn-link rounded text-decoration-none fw-bold text-start nav-link"
          >
            <div className="d-flex ">
              <span style={{ minWidth:'20px'}}>{icons(tab.name)}</span>
              <span>{tab.name}</span>
            </div>
            
          </NavLink>
        ))
        
      }
    </>
  );
}