import React, { useState, useEffect, useContext } from "react";

import config from "../../configurations";
import colors from "../../lang/en/Theme/Colors";
import { UilCalendarAlt, UilAngleDown } from "@iconscout/react-unicons";
import { DateRange } from "react-date-range";

import PageHeader from "../../refactoring/layouts/PageHeader";
import BarChartHorizontalBlock from "../../refactoring/components/charts/BarChartHorizontalBlock";
import BarChartBlock from "../../refactoring/components/charts/BarChartBlock";
import PieChartBlock from "../../refactoring/components/charts/PieChartBlock";
import { MetaDataContext } from "../../web/context/metaData";
import SimpleNumber from "../../refactoring/components/report/SimpleNumber";


export default function OrderAnalysis() {
  const metaData = useContext(MetaDataContext);
  useEffect(() => {
  }, [metaData]);
  const [currency, setCurrency] = useState(metaData.metaData.preference.currency.currency_symbol);
  const chartSettings = {
    stroke: [3],
    font: {
      fontSize: 12,
      fill: colors.colors.gray500
    }
  };

  const [dashboardData, setdashboardData] = useState({
    orderSales: {},
    orderPending: {},
    orderProcessing: {},
    orderComplete: {},
    totalSales: [],
    totalOrders: [],
    skuByRevenue: [],
    salesByChannel: [],
    quantityByStatus: []
  });
  const [staticData, setStaticData] = useState({
    plankLength: {},
    oilingAddon: {},
    edgingAddon: {},
    customCutAddon: {},
    topProducts: {},
    customServices: {}
  });

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)),
    endDate: new Date(),
    key: 'selection',
  });
  return (
    <>
      <PageHeader title="Dashboard" subtitle="Order Analysis" />
      <div className="row mb-4">
        <div className="col-md d-flex justify-content-end">
          <div className="dropdown">
            {/* <button className="btn bg-lighter px-3" type="button" id="dashboard-range" data-bs-auto-close="outside" data-bs-toggle="dropdown" aria-expanded="false" >
              <UilCalendarAlt width="15" className="me-2 text-secondary" />
               {moment(selectionRange.startDate).format('D MMMM')} - {moment(selectionRange.endDate).format('D MMMM')} <UilAngleDown width="16" />
            </button>
            <div className="dropdown-menu" aria-labelledby="dashboard-range" >
              <DateRange ranges={[selectionRange]} onChange={handleSelect} />
            </div> */}
          </div>
        </div>
      </div>



      <div className="row">
        <div className="col mb-4">
          <SimpleNumber data={dashboardData.orderPending} title="Orders pending"  />
        </div>
        <div className="col mb-4">
          <SimpleNumber data={dashboardData.orderProcessing} title="Orders processing" />
        </div>
        <div className="col mb-4">
          <SimpleNumber data={dashboardData.orderComplete} title="Orders completed" />
        </div>
        <div className="col mb-4">
          <SimpleNumber data={dashboardData.orderComplete} title="average order size" />
        </div>
        <div className="col mb-4">
          <SimpleNumber data={dashboardData.orderComplete} title="Shipping cost collected" />
        </div>
      </div>




     

      <div className="row">
        {/* {!_.isEmpty(dashboardData.quantityByStatus) ? ( */}
        <div className="col-lg-6 mb-4">
          <BarChartHorizontalBlock data={dashboardData.quantityByStatus} title="Total Quantity by order status" settings={chartSettings} />
        </div>
        {/* ) : null} */}

        {/* {!_.isEmpty(staticData.topProducts) ? ( */}
        <div className="col-lg-6 mb-4 ">
          <BarChartBlock data={staticData.topProducts} name="Quantity" settings={chartSettings} title="Top Performing Products" />
        </div>
        {/* ) : null} */}
      </div>

      <div className="row">
        {/* { !_.isEmpty(dashboardData.salesByChannel) ? ( */}
        <div className="col-lg-6 mb-4">
          <PieChartBlock data={dashboardData.salesByChannel} title="Orders by CAtegories" />
        </div>
        {/* ) : null} */}
        {/* { !_.isEmpty(dashboardData.salesByChannel) ? ( */}
        <div className="col-lg-6 mb-4">
          <PieChartBlock data={dashboardData.salesByChannel} title="Total Sales" />
        </div>
        {/* ) : null} */}
      </div>

    </>
  )
}

