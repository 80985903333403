
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import apiClient from "../header";

var defaultState = {
  pageNumber: 1,
  limit: 15,
  sort: null,
  searchParams: undefined,
};


export function useIntegrationListQuery({defaultValue = {} } = {}) {
  defaultState = { ...defaultState, ...defaultValue };

  const [pageNumber, setPageNumber] = useState(defaultState.pageNumber);
  const [limit, setLimit] = useState(defaultState.limit);
  const [searchParams, setSearchParams] = useState(defaultState.searchParams);
  const [sort, setSort] = useState(defaultState.sort);

  function getList(signal) {
    const query = apiClient
      .get(`integrations/all`, {
        params: {
          limit: limit,
          page: pageNumber,
          search: searchParams,
          sort: sort,
        },
        signal,
      })
      .then((result) => result.data);
    return query;
  }

  function handleSuccess(data) {
    document.dispatchEvent(
      new CustomEvent("integration-fetched", { detail: data.data })
    );
  }

  const query = useQuery(
    ["integration-list", { searchParams, pageNumber, sort }],
    ({ signal }) => getList(signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
    }
  );

  return [
    query,
    query.data,
    {
      searchParams: searchParams,
      setSearchParams: setSearchParams,

      limit: limit,
      setLimit: setLimit,

      setPageNumber: setPageNumber,

      sort: sort,
      setSort: setSort,
    },
  ];
}

