
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import moment from "moment";

import apiClient from "../header";
import ErrorPage from "../../components/errors/ErrorPage";
import { objectToArray } from "../../../scripts/utils";
import { notify } from "../../../scripts/notify";


const initialState = {data: {
  customer_first_name: "",
  customer_last_name: "",
  telephone: "",
  payment_address: "",
  shipping_address: "",
  payment_city: "",
  shipping_city: "",
  payment_country: "",
  shipping_country: "",
  payment_postcode: "",
  payment_details: "",
  shipping_postcode: "",
  payment_territory: "",
  shipping_territory: "",
  payment_company: "",
  shipping_company: "",
  shipping_method: "",
  coupon_code: null,
  coupon_amount: null,
  payment_method: "",
  shipping_cost: null,
  shipping_id:"",
  status: "",
  comment: "",
  discount: null,
  discount_type:null,
  quotation_date: moment().format('yyyy-MM-DD'),
  inclusive_tax_discount: false,
  products: null,
}}

export function useQuotationViewQuery(quotationId) {
  const [viewError, setViewError] = useState()

  function getView(signal, id) {    
    const query =  apiClient.get(`quotations/get`, {
      params: {
        id: id, 
      },
      signal,
    })
    .catch((error) => {
      setViewError(<ErrorPage error={error.response.status}/>)
    })
    .then((result => {
      
      if (result.data.status === 0) {
        setViewError( <> <ErrorPage/> </> )
      }
      
      if (result?.data?.data?.warehouses) {
        result.data.data.warehouses = objectToArray(result.data.data.warehouses);
      }

      if (result?.data?.data?.total_summary) {
        result.data.data = {...result.data.data,  ...result.data.data.total_summary}
      }

      return result.data
    }));
  
    return query;
  }
  
  function handleSuccess(data) {
    if (data.hasOwnProperty("status") && !data.status) {
      notify(data.type, data.message)
    }
  }

  var query =  useQuery(['quotation-view', {quotationId}], 
  ({ signal }) => getView(signal, quotationId), 
  {
    retry: 0,
    keepPreviousData : true,
    refetchOnWindowFocus: false,
    onSuccess: handleSuccess,
    enabled: quotationId ? true : false,
    initialData: initialState
  });

  return [
    query,
    {
      'viewError' : viewError
    }
  ];
}

