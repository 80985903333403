import React, { useState, useEffect, useRef } from "react";
import { UilEnvelope } from '@iconscout/react-unicons';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import api from "../../../api";
import { notify } from '../../../scripts/notify';
import CustomSelect from "../../../refactoring/components/global/CustomSelect";


export default function UserCreationForm(props) {
  const [errors, setErrors] = useState({
    email: "",
    groups: "",
  });

  const [inputs, setInputs] = useState({
    user_id: (props.inputs && props.inputs.userID) || "",
    email: (props.inputs && props.inputs.email) || "",
    groups: "",
  });

  const [groups, setGroups] = useState([]);

  const [selectedData, setSelectData] = useState({
    group: null,
  });

  const validator = useRef(new SimpleReactValidator());

  useEffect(() => {
    api.groups.get()
      .then(response => {
        var data = [];
        response.data.map(group => (
          data.push({ value: group.id, label: group.group_name })
      ));
        setGroups(data);
      });


    if (props.isEdit === true) {
      var groupData = [];
      var groupId = null;
      props.inputs.groups.map(group => ( groupData.push({ value: group.id, label: group.group_name }), groupId = group.id ));

      setInputs(prevProps => ({
        ...prevProps,
        groups: groupId,
      }));

      setSelectData({
        group: groupData,
      });
    }
  }, []);


  const onValueChange = (e) => {
    setSelectData(prevProps => ({
      ...prevProps,
      group: e
    }));

    setInputs(prevProps => ({
      ...prevProps,
      groups: e.value
    }))
  }

  const handleInputChange = (event) => {
    var val = event.target.value

    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: val
    }));
  };

  const SubmitHandler = event => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.users.user
        .add({ inputs })
        .then(response => {
          notify(response.data.type, response.data.message);
          if (response.data.status = 1) {
            props.callback();
            props.closeModal();
            setInputs({
              email: "",
              groups: ""
            });

            setSelectData({
              group: null,
            });
          }

          if (response.data.status === 0) {
            if (typeof response.data.field_error != "undefined") {
              setErrors(response.data.field_error);
            }
          }
        })
        .catch(error => {
          console.log("Error::", error);
        });
    } else {
      setErrors(prevProps => ({
        ...prevProps,
        ...validator.current.errorMessages
      }));
      validator.current.showMessages();
    }
  }

  const handleEditUser = () => {
    if (validator.current.allValid()) {
      api.users.user
        .edit({ inputs })
        .then(response => {
          notify(response.data.type, response.data.message);
          if (typeof response.data.field_error != "undefined") {
            setErrors(response.data.field_error);
          }

          if (response.data.status === 1) {
            props.callback();
            props.closeModal();
          }
        })
        .catch(error => {
          notify("error");
          console.log("Error::", error);
        });
    } else {
      setErrors(prevProps => ({
        ...prevProps,
        ...validator.current.errorMessages
      }));
      validator.current.showMessages();
    }
  }

  return (
    <div>
      <div className="row mb-4">
        <div className="col-xxl-12">
          <div className="mb-3">
              <label className="fw-bold me-3 ">Email</label>
              <input
                type="text"
                className={
                  props.isEdit ? "form-control not-allowed" : "form-control"
                }
                name="email"
                onChange={handleInputChange}
                value={inputs.email}
                placeholder="Email"
                readOnly={props.isEdit ? true : false}
                ref={props.refName}
              />
            {validator.current.message("email", inputs.email, "required|email", {
              className: "small text-danger"
            })}
          </div>
        </div>
        <div className="col-xxl-12">
          <label className="fw-bold me-3 ">Add Groups</label>
          <div className="mb-3">
            {groups && groups ? (
              <CustomSelect
                placeholder="Groups"
                isMulti={false}
                closeMenuOnSelect={true}
                isSearchable={true}
                options={groups}
                value={selectedData.group}
                onChange={onValueChange}
              />
            ) : (
              "Form Group Loading"
            )}
            {validator.current.message("group", selectedData.group, "required", {
              className: "small text-danger"
            })}
          </div>
        </div>
        <div className="col-xxl-12">
          <div className="">
            <button
              onClick={props.isEdit ? handleEditUser : SubmitHandler}
              className="btn btn-secondary w-100"
            >
              {props.isEdit ? "Save changes" : "Send Invite"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}