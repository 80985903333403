import { Modal } from "react-bootstrap";
import _ from "lodash";

export default function ModalComponent({ show, onEntered, onHide, title, body, footer, size="modal-md" }) {
  return ( 
    <Modal show={show} onHide={onHide} onEntered={onEntered} centered dialogClassName={size}>
      <Modal.Header closeButton className="p-4">
        <Modal.Title className="h5">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {body}
      </Modal.Body>
      {footer ?
        <Modal.Footer >
          <div className="justify-content-end">
            {footer}
          </div>
        </Modal.Footer>
        : null
      }
    </Modal>
  );
}
