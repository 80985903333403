import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { objectToFormData } from "../../../scripts/utils";
import { Paths } from "../../../routes";


function createCategory(data) {
  const formData = new FormData
  objectToFormData(data, formData);
  
  return apiClient.post(`categories/add`, formData)
  .then(
    (result) => result.data,
  );
}


export function useCategoryCreateQuery() {
  const [errors, setErrors] = useState({});
	const navigate = useNavigate();
  const queryClient = useQueryClient();

  function handleSuccess(data) {
    setErrors({})
    if (data.status) {
      notify(data.type, data.message);
      queryClient.invalidateQueries(['category-list']);
      queryClient.invalidateQueries(['product-metadata']);
      setTimeout(() => {
        navigate(Paths.Category.pages.index.path);
      }, 2000)
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors(prevProps => ({
        ...prevProps,
          [error]: message[0]
        }));
      }
    }
  }
  
  const mutate =  useMutation((newCategory) => createCategory(newCategory), {
    retry: false,
    onSuccess: handleSuccess
  });

  return [
    mutate,
    {
      'createErrors': errors,
    },
  ]
}