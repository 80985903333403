import React, { Component } from 'react';

export default class PageInfo extends Component {
  render() {
    return (
      <div> 
        Showing {this.props.perPage * this.props.currentPage <= this.props.total ? this.props.perPage * this.props.currentPage : this.props.total} out of {this.props.total} entries
      </div>
    );
  }
}
