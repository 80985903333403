import React from "react";
import ProfileIcon from "./ProfileIcon";

import AmazonIcon from "../../../assets/img/pages/channel/list/amazon-icon.svg";
import OpencartIcon from "../../../assets/img/pages/channel/list/opencart-icon.svg";
import EbayIcon from "../../../assets/img/pages/channel/list/ebay-icon.svg";

export default function InfoIcon({ name, type, icon }) {
  return (
    <span>
      {icon ? (
        <img src={icon} height={32} className="me-2" alt="" />
      ) : type === 3 ? (
        <img src={OpencartIcon} height={32} className="me-2" alt="" />
      ) : type === 1 ? (
        <img src={AmazonIcon} height={32} className="me-2" alt="" />
      ) : type === 2 ? (
        <img src={EbayIcon} height={32} className="me-2" alt="" />
      ) : (
        <ProfileIcon text={name} />
      )}
      <span className="text-capitalize">{name}</span>
    </span>
  );
}
