import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { UilQuestionCircle } from "@iconscout/react-unicons";
import { UilCheck } from "@iconscout/react-unicons";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import template_1 from "../../../../assets/img/invoice_templates/product_template_invoice.png";
import template_2 from "../../../../assets/img/invoice_templates/service_template_invoice.png";
import template_3 from "../../../../assets/img/invoice_templates/agaetis_template.png";
import '../../../../sass/app.scss';

import { useValidator } from "../../../utils/validator";
import Loader from "../../../components/global/Loader";
import PageHeader from "../../../layouts/PageHeader";

import { useCustomizationQuery, useCustomizationUpdateQuery } from "../../../stores/setting/customization";


export default function Customization() {

  const [errors, setErrors] = useState([]);
  const [inputs, setInputs] = useState({});
  const [validator, { validate }] = useValidator(errors);
  const query = useCustomizationQuery();
  const [customizationUpdateQuery, { updateErrors }] = useCustomizationUpdateQuery();
  const [, forceUpdate] = useState();

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name == "order_format") {
      setInputs((prevProps) => ({
        ...prevProps,
        order_format: {
          type: "preference",
          option: "order_number_format",
          value: event.target.value,
        },
      }));
    } else if (event.target.name == "invoice_format") {
      setInputs((prevProps) => ({
        ...prevProps,
        invoice_format: {
          type: "preference",
          option: "invoice_number_format",
          value: event.target.value,
        },
      }));
    } else if (event.target.name == "quotation_format") {
      setInputs((prevProps) => ({
        ...prevProps,
        quotation_format: {
          type: "preference",
          option: "quotation_number_format",
          value: event.target.value,
        },
      }));
    } else if (event.target.name == "invoice_type") {
      setInputs((prevProps) => ({
        ...prevProps,
        invoice_type: {
          type: "preference",
          option: "invoice_type",
          value: event.target.value,
        },
      }));
    }
  };

  //Modal functions

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleInvoiceRadio = (event) => {
    event.persist();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      customizationUpdateQuery.mutate(inputs);
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };

  useEffect(() => {
    if (query.isFetched) {
      setInputs((prevProps) => ({
        ...prevProps,
        order_format: query.data.data.find(
          (element) => element.option === "order_number_format"
        ),
        quotation_format: query.data.data.find(
          (element) => element.option === "quotation_number_format"
        ),
        invoice_format: query.data.data.find(
          (element) => element.option === "invoice_number_format"
        ),
        invoice_type: query.data.data.find(
          (element) => element.option === "invoice_type"
        ),
      }));
    }
  }, [query.data]);

  useEffect(() => {
    console.log('cc', inputs);
  }, [inputs])

  const invoiceTemplates =[
    { id: "1", imgSrc: template_1, altText: "template_1"},
    { id: "2", imgSrc: template_2, altText: "template_2"},
    { id: "3", imgSrc: template_3, altText: "template_3"},
  ];

  return (
    <>
      {query.isLoading && _.isEmpty(inputs) ? (
        <Loader />
      ) : (
        <form>
          <section className="bg-white p-5 rounded mb-4 border">
            <PageHeader
              subtitle="Customization"
              subtext="Default customization for the system."
            />
            <div className="row">
              <div className="col-md-12 ">
                <div className="mb-3">
                  <label className="fw-bold">Order Number Format</label>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Customize how your order number gets generated
                        automatically when you create a new order.
                      </Tooltip>
                    }
                  >
                    <span>
                      { <UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" /> }
                    </span>
                  </OverlayTrigger>
                  <input
                    className="form-control"
                    name="order_format"
                    id="order_format"
                    placeholder="Enter Order Number Format"
                    onChange={handleInputChange}
                    value={ inputs.order_format ? inputs.order_format.value : "" }
                  />
                  {validate(inputs?.order_format?.value, "required", { title: "order format number", })}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="fw-bold">Invoice Number Format</label>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Customize how your Invoice Number gets generated
                        automatically when you create a new invoice.
                      </Tooltip>
                    }
                  >
                    <span>
                      { <UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" /> }
                    </span>
                  </OverlayTrigger>
                  <input
                    className="form-control"
                    id="invoice_format"
                    name="invoice_format"
                    placeholder="Enter Invoice Number Format"
                    onChange={handleInputChange}
                    value={ inputs.invoice_format ? inputs.invoice_format.value : ""  }
                  />
                  {validate(inputs?.invoice_format?.value, "required", { title: "invoice format number", })}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="fw-bold mb-2">Customize Invoice</label>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Customize format of Invoice, when you create a new invoice.
                      </Tooltip>
                    }
                  >
                    <span>
                      { <UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" /> }
                    </span>
                  </OverlayTrigger>
                  <div className="">
                    <Button variant="primary" onClick={handleShow}>
                      Choose Invoice Template
                    </Button>
                    <Modal show={show} onHide={handleClose} size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>Choose Invoice Template</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="d-flex flex-wrap">
                          {invoiceTemplates.map((template) => (
                            <div className="form-check me-3 mb-2" key={template.id}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id={`invoice_type_${template.id}`}
                                value={template.id}
                                name="invoice_type"
                                onChange={handleInputChange}
                                checked={inputs?.invoice_type?.value === template.id}
                              />
                              <label
                                className="form-check-label px-2"
                                htmlFor={`invoice_type_${template.id}`}
                              >
                                <img
                                  alt={template.altText}
                                  src={template.imgSrc}
                                  height={300}
                                  width={200}
                                />
                                {inputs?.invoice_type?.value === template.id && (
                                  <UilCheck size="16" className="ui-check position-absolute" />
                                )}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="fw-bold">Quotation Number Format</label>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Customize how your quotation number gets generated
                        automatically when you create a new quotation.
                      </Tooltip>
                    }
                  >
                    <span>
                      { <UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" /> }
                    </span>
                  </OverlayTrigger>
                  <input
                    className="form-control"
                    id="quotation_format"
                    name="quotation_format"
                    placeholder="Enter Quotation Number Format"
                    onChange={handleInputChange}
                    value={ inputs.quotation_format ? inputs.quotation_format.value : "" }
                  />
                  {validate(inputs?.quotation_format?.value, "required", { title: "quotation format number", })}
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="p-4 add-new-product border bg-white rounded">
                <button
                  className="btn btn-secondary text-white"
                  onClick={handleSubmit}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
