import { useRef, useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { UilEllipsisH, UilTrashAlt, UilPen, UilFilter, UilAngleDown, UilFilePlusAlt, UilPlusSquare, UilEye, UilEnvelopeAlt, UilPrint } from '@iconscout/react-unicons';
import _ from 'lodash';

import { Paths } from '../../../routes';
import configurations from '../../../configurations';
import { abbreviateNumber, changeTimezone, dateTimeFormat } from '../../../scripts/utils';

import { useQuotationListQuery } from '../../stores/quotations/list';
import { useDeleteQuotationQuery } from '../../stores/quotations/delete';

import PageHeader from '../../layouts/PageHeader'
import ActionBar from '../../components/global/ActionBar';
import ErrorPage from '../../components/errors/ErrorPage';
import DataTable from '../../components/common/DataTable';
import ModalComponent from '../../components/common/ModalComponent';
import UpdateStock from '../../components/product/UpdateStock';
import { MetaDataContext } from '../../../web/context/metaData';
import { useQuotationConversion } from '../../stores/quotations/convert';
import { usePaymentMethodListQuery } from '../../stores/setting/payment';
import SimpleReactValidator from 'simple-react-validator';
import { notify } from '../../../scripts/notify';
import { useQuotationUpdateQuery } from '../../stores/quotations/update';
import SelectPaymentModal from '../../components/modal/SelectPaymentModal';
import { useSendQuotationQuery } from '../../stores/quotations/send';
import Payment from '../../components/payment/Payment';


export default function Listing() {
 
  const navigate = useNavigate();
  
  // const [forceUpdate, setForceUpdate] = useState();

  const {data: preferences} = useContext(MetaDataContext);
  const [inputs, setInputs] = useState({})
  const [query, {searchParams, setSearchParams, setPageNumber, setSort, setFilters}] = useQuotationListQuery();
  const [paymentMethodQuery] = usePaymentMethodListQuery({ defaultValue: {limit: 'all'}})
  
  const validator = useRef(new SimpleReactValidator({}));
  
  const [quotationUpdateQuery, { updateErrors }] = useQuotationUpdateQuery();
  const [{convertToInvoice, convertToOrder}] = useQuotationConversion();
  const [sendQuotationQuery] = useSendQuotationQuery()

  const deleteQuotation = useDeleteQuotationQuery();
  const [openModal, setOpenModal] = useState(false);


  function handleClose() {
    setOpenModal(false);
  }  

  function handleFilters(value) {
    if (value && Array.isArray(value)) {
      var filterData = [];
      value.map(data => filterData[data.key] = value.map(item => item.value));
      setFilters((prevProps) => ({
        ...prevProps,
        ...filterData,
      }));  
    } else {
      setFilters((prevProps) => ({
        ...prevProps,
        [value.key]: value.value
      }));  
    }     
  }
  
  function createInvoice(id) {
    convertToInvoice.mutate(id)
  }
  
  function createOrder(id) {
    convertToOrder.mutate(id)
  }
  
  function handleEdit(id) {
    navigate(Paths.Quotations.pages.edit.path + id)
  } 

  function sendQuotation(id) {
    sendQuotationQuery.mutate(id)
  }
    
  function handleDelete(id) {
    deleteQuotation.mutate(id);
  } 

  function handleIncompleteData(id, dataFor) {
    setInputs({id: id})
    navigate(Paths.Invoice.pages.create.path + `?quotation_id=${id}`)
  }

  function handleInputChanges(label , value) {
    setInputs((prevProps) => ({
      ...prevProps,
      [label]: value
    }))
  }

  function handleSubmit() {
    if (validator.current.allValid()) {
      quotationUpdateQuery.mutate({...inputs}, {
        onSuccess: (data) => {
          if (data.status) {
            openModal.for == 'invoice' && createInvoice(inputs.id)
            openModal.for == 'order' && createOrder(inputs.id)
            setOpenModal({show: false})
            setInputs({})
          }
        },
      })
    } else {
      validator.current.showMessages();
      // setForceUpdate(true)
    }
  }


  const ActionDropDown = ({data}) => {
    return (
      <ul className="dropdown text-end mb-0">
        <button className="btn btn-link" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" >
          <span><UilEllipsisH size="16" className="text-secondary"/></span>
        </button>
        <ul className="dropdown-menu dropdown-menu-end py-0">
          <li className="fw-bold cursor dropdown-item py-2"  onClick={() => createInvoice(data.id)}>
              <span><UilFilePlusAlt size="14" className="text-secondary me-1 mb-1" />Create Invoice</span>
          </li>
          <li className="fw-bold cursor dropdown-item py-2"  onClick={() => createOrder(data.id)}>
              <span><UilPlusSquare size="14" className="text-secondary me-1 mb-1" />Create Order</span>
          </li>
          <li className="fw-bold cursor dropdown-item py-2" onClick={() => handleEdit(data.id, data?.composite_product)} >
            <span><UilEye size="14" className="text-secondary me-1 mb-1"/>View/Edit Quotation</span>
          </li>
          <li className="fw-bold cursor dropdown-item py-2" onClick={() => sendQuotation(data.id)} >
            <span><UilEnvelopeAlt size="14" className="text-secondary me-1 mb-1"/>Send Quotation</span>
          </li>
          <a target="_blank" href={data.view_url} className="text-decoration-none" > 
            <li className="fw-bold cursor dropdown-item py-2" >
                <UilPrint size="14" className="text-secondary me-1 mb-1"/>Print Quotation
            </li>
          </a>
          <li className="fw-bold cursor dropdown-item py-2" onClick={() => handleDelete(data.id)} >
              <span><UilTrashAlt size="14" className="text-danger me-1 mb-1"/>Delete Quotation </span>
          </li>
        </ul>
      </ul>
    );
  }

  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: 'q_no',
      name: 'Quotation Number',
      cell: row => (
        <Link to={Paths.Quotations.pages.edit.path + row.id} >
          {row.quotation_no}
        </Link>
      ),
      width: '500px',
    },
    {
      id: 'to',
      name: 'To',
      selector: row => row.customer_detail.customer_first_name + ' ' + row.customer_detail.customer_last_name + ", " + row.customer_detail.email,
    },
    {
      id: 'created_date',
      name: 'Created Date',
      selector: row => dateTimeFormat(row.created_at, 'long', preferences.timezone),
    },
    {
      id: 'total',
      name: 'Total',
      selector: row => abbreviateNumber(row.total, preferences, "standard"),
    },
    {
      id: 'status',
      name: 'Status',
      selector: row => _.toUpper(_.find(configurations.QuotationStatus, ['id',  row.status])?.status),
    },
    {
      name: '',
      id: 'action',
      cell: row => (<ActionDropDown data={row}/>),
    }, 
  ]
  
  return (
    <>
      {query.isError && <ErrorPage innerPage={false} error='500'/> }

      <PageHeader 
        title='quotations' 
        subtitle='All Quotation' 
        component={<Link to={Paths.Quotations.pages.create.path} className="btn btn-secondary">Create quotation</Link>} 
      />
      
      <ActionBar searchParams={searchParams} setSearchParams={handleSearch} placeholder={'Search for Quotation number'} sortOptions={configurations.Sort} setSort={setSort}/>
      <DataTable 
        data={query?.data?.data} 
        columns={columns} 
        isLoading={query.isFetching}
        pagination={true}
        paginationMeta={query?.data?.meta}
        onChangePage={page=> setPageNumber(page)}
      />
      
      <ModalComponent 
        show={openModal.show} 
        title={ 'Complete the quotation data' }
        body={
          <Payment 
            paymentMethod={paymentMethodQuery.data} 
            payment_method={inputs.payment_method} 
            payment_details={inputs.payment_details} 
            handleInputChanges={handleInputChanges} 
            model={true} 
            validator ={validator}
            // validateField={true}
          />
        } 
        footer={
          <div>
            <button className="btn btn-secondary me-2" name="submit" onClick={(e) => handleSubmit(e)}>Add payment method</button>
            <button className="btn btn-outline-primary" name="cancel" onClick={() => setOpenModal({show: false})}>Cancel</button>
          </div>
        }
        onHide={() => setOpenModal({show: false})} 
      />



    </>
  )  

}