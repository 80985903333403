import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UilEnvelopeAlt, UilPhoneAlt, UilAngleDown, } from "@iconscout/react-unicons";
import _ from "lodash";
import moment from "moment";

import { numberFormat, formatNumber, dateTimeFormat } from "../../../scripts/utils";
import PageHeader from "../../layouts/PageHeader";
import Loader from "../../components/global/Loader";
import lang from "../../../lang/en/Theme/Colors";
import config from "../../../configurations";

import { useCreateInvoiceQuery } from "../../stores/invoices/createInvoice";
import { useUpdateStatusQuery } from "../../stores/orders/updateStatus";
import { MetaDataContext } from "../../../web/context/metaData";
import { useOrderViewQuery } from "../../stores/orders/view";
import InfoIcon from "../../components/common/InfoIcon";

export default function OrderView() {
  const { id } = useParams();
  const {data: preferences} = useContext(MetaDataContext);

  const [currency, setCurrency] = useState( preferences.currency_symbol );

  const [orderViewQuery, orderViewQueryData, { viewError }] = useOrderViewQuery(id);
  const [statusCheck, setStatusCheck] = useState({ status: "", orders: [] });

  const [createInvoice] = useCreateInvoiceQuery();
  const [updateStatus] = useUpdateStatusQuery();

  const updateOrderStatus = (e, status) => {
    setStatusCheck({
      status: status,
      orders: [
        {
          order_id: orderViewQueryData.order.order_id,
          channel_id: orderViewQueryData.order.channel_id,
        },
      ],
    });
  };

  const generateInvoice = (id) => {
    createInvoice.mutate({ id: id, from: "order" });
  };

  useEffect(() => {
    if (!_.isEmpty(statusCheck.status)) {
      updateStatus.mutate(statusCheck);
    }
  }, [statusCheck.status]);

  return (
    <>
      {orderViewQuery.isLoading ? (
        <Loader />
      ) : viewError ? (
        viewError
      ) : (
        <React.Fragment>
          <div>
            <PageHeader
              title="All Orders"
              subtitle={"#" + orderViewQueryData.order.order_id}
            />
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <div>
                <span className=" me-3">                     
                  {orderViewQueryData.order.user ? `Created by ${orderViewQueryData.order.user.name ?? orderViewQueryData.order.user.email} on ` : "" }
                  {dateTimeFormat(orderViewQueryData.order.placed_at_tz, 'long', '', 'medium')} from
                </span>

                <span className="me-3">
                  <InfoIcon
                    type={orderViewQueryData.order.channel.channel_type}
                    name={orderViewQueryData.order.channel.channel_name}
                  />
                </span>
                <span
                  className={`badge rounded-pill ${config.OrderStatus[orderViewQueryData.order.order_status].class}`} >
                  {config.OrderStatus[orderViewQueryData.order.order_status].status} 
                </span>
              </div>
              <div>
                <div className="dropdown">
                  <button
                    className="btn px-3"
                    type="button"
                    id="batch-setting"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Order Status <UilAngleDown />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-start"
                    aria-labelledby="batch-setting"
                  >
                    {_.sortBy(config.OrderStatus, ["sort_order"]).map(
                      (status, index) =>
                        !_.isEmpty(status) &&
                        !_.isEmpty(status.status) && (
                          <li key={index}>
                            <a
                              className="dropdown-item "
                              href="#"
                              onClick={(e) =>
                                updateOrderStatus(e, status.status)
                              }
                            >
                              <span
                                className={`badge rounded-pill ${status.class}`}
                              >
                                {status.status}
                              </span>
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8">
                <div className="card py-4 mb-4">
                  <h3 className="px-4">
                    {
                      config.OrderStatus[orderViewQueryData.order.order_status]
                        .status
                    }
                  </h3>
                  <div className="px-4 border-bottom">
                    <div className="row py-2 site-heading fw-bold text-gray-500">
                      <div className="col-4 ">Product name</div>
                      <div className="col-2">Unit price</div>
                      <div className="col-2 pe-0">Discount</div>
                      <div className="col-2 ">
                        Price <br /> X Quantity
                      </div>
                      <div className=" col-2 text-center "> Total Price</div>
                    </div>
                  </div>
                  {orderViewQueryData.order_product.map(
                    (product, index, { length, last = length - 1 }) => (
                      <div
                        className={`px-4 py-3 ${
                          index === last ? "" : "border-bottom"
                        }`}
                        key={index}
                      >
                        <div className="row align-items-center">
                          <div className="col-4">
                            <div>{product.name}</div>
                            {product.value !== "" && (
                              <div className="mb-3">
                                &mdash; {product.value}
                              </div>
                            )}
                            {product.description !== "null" && (
                              <div className="mb-3 small text-gray-500">
                                {product.description}
                              </div>
                            )}

                            {!_.isEmpty(product.addons) &&
                              product.addons.map((addon, index) => (
                                <div>
                                  
                                  {addon.type ? (
                                    <div key={index + addon.id}>
                                      {addon.type}: {addon.name} {addon.amount !== "0.00" && (currency + addon.amount)}
                                    </div>
                                  ) : (
                                    Object.keys(addon).map((key) => (
                                      <div key={key}>
                                        <div>
                                          <span className="text-capitalize">
                                            {key !== "comment" ? key : null}
                                          </span>
                                           {key !== "comment" ? `:` + addon[key].name : null}
                                          {typeof addon[key].amount !=
                                            "undefined" && (  
                                            <span>
                                              {" "}
                                              {addon[key]?.type !== "comment" ? 
                                                (addon[key].amount !== "0.00" ? `(${(currency + numberFormat(addon[key].amount))})` : " ", 
                                                !_.isEmpty(addon[key].comment) ? ` Comment: ` + addon[key].comment : '') 
                                                : `Comment: ` + addon[key].amount
                                              }
                                            </span>
                                          )}
                                          {typeof addon[key].offcut !=
                                            "undefined" && (
                                            <div> {addon[key].offcut} </div>
                                          )}
                                        </div>
                                      </div>
                                    ))
                                  )}
                                </div>
                              ))}

                            {!_.isEmpty(product.api_products) &&
                              product.api_orderViewQueryData.order_product
                                .sku != "" && (
                                <div>
                                  SKU:{" "}
                                  {
                                    product.api_orderViewQueryData.order_product
                                      .sku
                                  }
                                </div>
                              )}

                            {!_.isEmpty(product.products) &&
                              product.orderViewQueryData.order_product.sku !=
                                "" && (
                                <div>
                                  SKU:{" "}
                                  {product.orderViewQueryData.order_product.sku}
                                </div>
                              )}
                          </div>
                          <div className="col-2">
                            {currency +
                              (product.unit_price ??
                                product.selling_price ??
                                "-")}
                          </div>
                          <div className="col-2 text-danger">
                            {product.discount && product.discount != 0 ? (
                              product.discount_type == "percent" ? (
                                product.discount + "%"
                              ) : (
                                currency + product.discount
                              )
                            ) : (
                              <span className="text-primary">-</span>
                            )}
                          </div>
                          <div className="col-2">
                            {currency + product.selling_price} <br />x{" "}
                            {product.quantity}
                          </div>
                          <div className="col-2 text-center">
                            {currency +
                              (
                                product.selling_price * product.quantity
                              ).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  {!_.isEmpty(orderViewQueryData.order.addon) &&
                    orderViewQueryData.order.addon.map(
                      (item, index, { length, last = length - 1 }) => (
                        <div className={`px-4 py-3 border-top`} key={index}>
                          <div className="row  align-items-center">
                            <div className="col-4">
                              <div>{item.product_name}</div>
                            </div>
                            <div className="col-2">
                              {currency + formatNumber(item.selling_price)}
                            </div>
                            <div className="col-2 text-danger">
                              {item.discount != 0
                                ? item.discount_type == "percent"
                                  ? formatNumber(item.discount) + "%"
                                  : currency + formatNumber(item.discount)
                                : "-"}
                            </div>
                            <div className="col-2">
                              {currency + formatNumber(item.price)}
                              <br /> x {item.quantity}
                            </div>
                            <div className="col-2 text-center">
                              {currency +
                                (item.selling_price * item.quantity).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>

                <div className="card py-4 mb-4">
                  <h3 className="px-4">Payment</h3>

                  {!_.isEmpty(orderViewQueryData.order.total_summary) && (
                    <div className="px-4 py-3 border-bottom">
                      <div className="row align-items-center">
                        <div className="col-auto">Sub Total</div>
                        <div className="col text-end">
                          {currency +
                            orderViewQueryData.order.total_summary.sub_total}
                        </div>
                      </div>
                    </div>
                  )}

                  {!_.isEmpty(orderViewQueryData.order.shipping_cost) && (
                    <div className="px-4 py-3 border-bottom text-success">
                      <div className="row  align-items-center">
                        <div className="col-auto">Shipping Cost</div>
                        <div className="col text-end">
                          {currency + orderViewQueryData.order.shipping_cost}
                        </div>
                      </div>
                    </div>
                  )}
                  {!_.isEmpty(orderViewQueryData.order.tax_amount) && (
                    <div className="px-4 py-3 border-bottom">
                      <div className="row  align-items-center">
                        <div className="col-auto">
                          Tax ({orderViewQueryData.order.discount_code})
                        </div>
                        <div className="col text-end">
                          {currency + orderViewQueryData.order.discount}
                        </div>
                      </div>
                    </div>
                  )}
                  {!_.isEmpty(orderViewQueryData.order.discount_code) && (
                    <div className="px-4 py-3 border-bottom text-danger">
                      <div className="row  align-items-center">
                        <div className="col-auto">
                          Coupon ({orderViewQueryData.order.discount_code})
                        </div>
                        <div className="col text-end">
                          {currency + orderViewQueryData.order.discount}
                        </div>
                      </div>
                    </div>
                  )}
                  {!_.isEmpty(
                    orderViewQueryData.order.total_summary.discount_percent
                  ) &&
                  orderViewQueryData.order.total_summary.discount_cost != 0 ? (
                    <div className="px-4 py-3 border-bottom text-danger">
                      <div className="row  align-items-center">
                        <div className="col-auto">
                          Discount (
                          {
                            orderViewQueryData.order.total_summary
                              .discount_percent
                          }{" "}
                          {orderViewQueryData.order.total_summary.discount_type}
                          )
                        </div>
                        <div className="col text-end">
                          {currency +
                            orderViewQueryData.order.total_summary
                              .discount_cost}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!_.isEmpty(orderViewQueryData.order.total_summary) && (
                    <div className="px-4 py-3 border-bottom">
                      <div className="row  align-items-center">
                        <div className="col-auto">Tax</div>
                        <div className="col text-end">
                          {currency +
                            orderViewQueryData.order.total_summary.total_tax}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="px-4 py-3">
                    <div className="row  align-items-center">
                      <div className="col-auto">Total</div>
                      <div className="col text-end">
                        {currency +
                          orderViewQueryData.order.total_summary.total}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card py-4 mb-4 px-3">
                  <h5>Quick Links</h5>

                  {orderViewQueryData.order.channel.channel_type === 1 ? (
                    <div>
                      <div>
                        <a
                          href={`https://sellercentral.amazon.co.uk/orders-v3/`}
                          target="_blank"
                        >
                          {" "}
                          Go to {
                            orderViewQueryData.order.channel.channel_name
                          }{" "}
                          Seller Central Manage Order
                        </a>
                      </div>
                      <div>
                        <a
                          href={`https://sellercentral.amazon.co.uk/orders-v3/order/${orderViewQueryData.order.order_id}`}
                          target="_blank"
                        >
                          {" "}
                          Go to {
                            orderViewQueryData.order.channel.channel_name
                          }{" "}
                          Order
                        </a>
                      </div>
                    </div>
                  ) : orderViewQueryData.order.channel.channel_type ===
                    2 ? null : orderViewQueryData.order.channel.channel_type ===
                    3 ? (
                    <div>
                      <div>
                        <a
                          href={
                            orderViewQueryData.order.channel.api_url +
                            `/ai7_m19?route=sale/order/info&order_id=${orderViewQueryData.order.order_id}`
                          }
                          target="_blank"
                        >
                          View order in{" "}
                          {orderViewQueryData.order.channel.channel_name}
                        </a>
                      </div>
                      <div>
                        <a
                          href={
                            orderViewQueryData.order.channel.api_url +
                            `/ai7_m19?route=sale/order/invoice&order_id=${orderViewQueryData.order.order_id}`
                          }
                          target="_blank"
                        >
                          Download Invoice
                        </a>
                      </div>
                    </div>
                  ) : orderViewQueryData.order.channel.channel_type === 4 ? (
                    !_.isNull(orderViewQueryData.order.invoice_no) ? (
                      <a
                        href={orderViewQueryData.order.invoice_url}
                        target="_blank"
                      >
                        View Invoice
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          generateInvoice(orderViewQueryData.order.id)
                        }
                        className="cursor"
                      >
                        Generate Invoice
                      </a>
                    )
                  ) : null}
                </div>
                <div className="card py-4 mb-4 px-3">
                  <h5>Notes from the customer</h5>
                  <div>
                    {_.isEmpty(orderViewQueryData.order.comment)
                      ? "-"
                      : orderViewQueryData.order.comment}
                  </div>
                </div>
                <div className="card py-4 mb-4 px-3">
                  <h5>Files attached</h5>
                  {_.isEmpty(orderViewQueryData.order.upload_files) ? (
                    <div> No Files attached </div>
                  ) : (
                    <div>
                      {orderViewQueryData.order.upload_files.map(
                        (file, index) => (
                          <ul className="list-group list-group-flush">
                            <li
                              key={file + index}
                              className="list-group-item p-0"
                            >
                              <a
                                className=""
                                href={file.filepath}
                                target="_blank"
                              >
                                {file.filename}
                              </a>
                            </li>
                          </ul>
                        )
                      )}
                    </div>
                  )}
                </div>
                <div className="card py-4 mb-5 px-3">
                  <h5 className="mb-3">Customer Details</h5>
                  {orderViewQueryData.customer && (
                    <div className="mb-4">
                      {!_.isEmpty(
                        orderViewQueryData.customer.customer_first_name
                      ) && (
                        <div>
                          {orderViewQueryData.customer.customer_first_name}{" "}
                          {orderViewQueryData.customer.customer_last_name}
                        </div>
                      )}
                      {!_.isEmpty(orderViewQueryData.customer.email) && (
                        <a
                          href={"mailto: " + orderViewQueryData.customer.email}
                          className="fw-bold mb-3 d-inline text-decoration-none text-info"
                        >
                          <div className="text-info">
                            {" "}
                            {
                              <UilEnvelopeAlt
                                size="14"
                                color={lang.colors.teal}
                              />
                            }{" "}
                            <span className="ms-2">
                              {" "}
                              {orderViewQueryData.customer.email}{" "}
                            </span>
                          </div>
                        </a>
                      )}
                      {!_.isEmpty(orderViewQueryData.customer.telephone) && (
                        <a
                          href={"tel: " + orderViewQueryData.customer.telephone}
                          className="f1w-bold mb-3 d-inline text-decoration-none text-success"
                        >
                          <div>
                            {
                              <UilPhoneAlt
                                size="14"
                                color={lang.colors.success}
                              />
                            }{" "}
                            <span className="ms-2">
                              {" "}
                              {orderViewQueryData.customer.telephone}
                            </span>
                          </div>
                        </a>
                      )}
                    </div>
                  )}

                  <div className="letter-spacing-1 tiny-font text-uppercase mb-2">
                    Shipping address
                  </div>
                  {orderViewQueryData.customer && (
                    <div className="mb-4">
                      {!_.isEmpty(
                        orderViewQueryData.customer.shipping_firstname
                      ) && (
                        <div>
                          {orderViewQueryData.customer.shipping_firstname}{" "}
                          {orderViewQueryData.customer.shipping_lastname}
                        </div>
                      )}
                      {!_.isEmpty(
                        orderViewQueryData.customer.shipping_company
                      ) && (
                        <div>
                          {orderViewQueryData.customer.shipping_company}
                        </div>
                      )}
                      {!_.isEmpty(
                        orderViewQueryData.customer.shipping_address
                      ) && (
                        <div>
                          {orderViewQueryData.customer.shipping_address}{" "}
                          {orderViewQueryData.customer.shipping_territory}{" "}
                        </div>
                      )}
                      {!_.isEmpty(
                        orderViewQueryData.customer.shipping_city
                      ) && (
                        <div>
                          {orderViewQueryData.customer.shipping_city}{" "}
                          {orderViewQueryData.customer.shipping_postcode}
                        </div>
                      )}
                      {!_.isEmpty(
                        orderViewQueryData.customer.shipping_country
                      ) && (
                        <div>
                          {orderViewQueryData.customer.shipping_country}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="letter-spacing-1 tiny-font text-uppercase mb-2">
                    Billing address
                  </div>
                  {orderViewQueryData.customer && (
                    <div>
                      {!_.isEmpty(
                        orderViewQueryData.customer.payment_firstname
                      ) && (
                        <div>
                          {orderViewQueryData.customer.payment_firstname}{" "}
                          {orderViewQueryData.customer.payment_lastname}
                        </div>
                      )}
                      {!_.isEmpty(
                        orderViewQueryData.customer.payment_company
                      ) && (
                        <div>{orderViewQueryData.customer.payment_company}</div>
                      )}
                      {!_.isEmpty(
                        orderViewQueryData.customer.payment_address
                      ) && (
                        <div>
                          {orderViewQueryData.customer.payment_address}{" "}
                          {orderViewQueryData.customer.payment_territory}{" "}
                        </div>
                      )}
                      {!_.isEmpty(orderViewQueryData.customer.payment_city) && (
                        <div>
                          {orderViewQueryData.customer.payment_city}{" "}
                          {orderViewQueryData.customer.payment_postcode}
                        </div>
                      )}
                      {!_.isEmpty(
                        orderViewQueryData.customer.payment_country
                      ) && (
                        <div>{orderViewQueryData.customer.payment_country}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
}
