import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';

import api from '../api';
import localstorage from '../localstorage';
import { Paths } from '../routes';

import Logo from "../assets/img/branding/innoventory-logo-full-white.svg";
import FullLogo from  "../assets/img/branding/innoventory-logo-color.svg";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => {

    val.length > 0 && (valid = false)

  });
  return valid;
};


function validate(email, password) {

  const errors = {};
  if (!validEmailRegex.test(email)) {
    errors.email = "The email format is invalid.";
  }
  if (email.length == 0) {
    errors.email = "The email field is required.";
  }
  if (password.length == 0) {
    errors.password = "The password field is required.";
  }

  return errors;
}


export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {
        email: '',
        password: '',
        login:""
      },
      logoutMessage: ""
    };

    this.onChange = this.onChange.bind(this);
    this.SubmitHandler = this.SubmitHandler.bind(this);
  }

  // componentDidMount(props){
  //   if(this.props.location.state && this.props.location.state.messge){
  //     console.log("property_id",this.props.location.state.message);
  //   }
  // }


  onChange(event) {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  SubmitHandler(event) {
    event.preventDefault();
    let validErrors = validate(this.state.email, this.state.password);

    if (JSON.stringify(validErrors).length <= 2) {
      const { email, password } = this.state;
      // const { history } = this.props;
      // console.log(process.env.MIX_APP_UR,)
      api.auth.login({ email, password })
      .then((result) => {
				if (result.data.access_token) {
					localstorage.token.set(result.data);

					const { history } = this.props;
					window.location.href = '/';
				} else {
        	this.setState({ errors:{login: "These credentials do not match our records."} });
				} 
      })
    } else {
      this.setState({ errors: validErrors })
    }

  }

  render() {
    const logoutMessage = this.state.logoutMessage;

    if (localstorage.token.get()) {
      return <Navigate to={Paths.Root.path} />
    }

    return (

      <div className="login-page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="row align-items-center justify-content-center h-100">
                <div className="col-md-10 col-xl-6">
                  <div className="text-center my-4 ">
                    <img src={FullLogo} width={250} alt=""/>
                  </div>
                  <div className="d-flex flex-column bg-white p-5 mb-4">
                    <h5 className="mb-4">Login to your account</h5>
                    <form onSubmit={this.SubmitHandler}>
                      <div className="mb-4">
                        <p className="mb-2">Email</p>
                        <input
                          type='text'
                          className="form-control"
                          name='email'
                          onChange={this.onChange}
                          value={this.state.email}
                          placeholder="Email"
                        />
                        <small className='text-danger'>{this.state.errors.email}</small>
                      </div>
                      <div className="mb-4">
                        <p className="mb-2">Password</p>
                        <input
                          type='password'
                          className="form-control"
                          name="password"
                          onChange={this.onChange}
                          placeholder="Password"
                        />
                        <div className='text-danger small mb-3'>{this.state.errors.password}</div>
                        <div><Link to={Paths.ResetPassword.path}>Reset Password?</Link></div>
                      </div>
                      <div className="mb-3">
                        <button type='submit' className="btn btn-secondary w-100" > Sign in </button>
                        <div className="mt-2 text-danger"> {this.state.errors.login}</div>
                        {
                          logoutMessage ? (
                            <div className="alert alert-success>">
                              logoutMessage
                            </div>
                          ) : ""
                        }
                      </div>
                    </form>
                  </div>
                  <div className="text-center">
                    <p><small>By clicking on 'Sign in' you agree to our <a href="`">Terms</a> and have read and acknowledged our <a href={process.env.REACT_APP_WEB_URL + 'privacy-policy'}>Privacy Statement</a></small></p>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-5 login-sidebar-container h-100 p-0">
              <div className=" login-textblock-wrapper">
                <div className="text-block p-3 px-lg-5">
                  <h3 className="text-white mb-4">Experience hassle-free management.</h3>
                  <p className="text-muted mb-5">More channels, more buyers and more profit. Connect your inventory with multiple store and sell your products on channels like Amazon, Ebay, Opencart, Magento etc.</p>
                  <h5 className="text-white">This is unbelievable. After using this dashboard my business skyrocketed!</h5>
                  <p className="text-muted">&mdash; Rick E.</p>
                </div>
                <div className="copyright-block px-3 px-lg-5">
                  <img className="mb-2" src={Logo} height={30} alt="" />
                  <p className="text-muted"><small>Copyrights Reserved by Agaetis Technologies Pvt. Ltd.</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
