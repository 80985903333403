import React from 'react';
import { Link } from 'react-router-dom';
import { UilCheckCircle } from '@iconscout/react-unicons'

import lang from "../../../lang/en/Theme/Colors";

export default function Success({name, link, option }) {
    return(
      <div>
        <div className="row">
          <div className="col-lg-6">
            <section className="my-4 bg-white p-5 text-center">
              <div className="mb-4 text-center">
                <UilCheckCircle size="32" color={lang.colors.success} />
              </div>
              <div>
                <h4 className="mb-3">{name} has been <br /> successfully integrated</h4>
                <Link to={link}>
                  <small>View {option}</small>
                </Link>
              </div>
            </section> 
          </div>
        </div>
      </div>
    )
  }


