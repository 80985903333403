
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ErrorPage from "../../components/errors/ErrorPage";
import { objectToArray } from "../../../scripts/utils";
import apiClient from "../header";


export function useProductViewQuery(id) {
  const [viewError, setViewError] = useState()

  function getView(signal, id) {    
    const query =  apiClient.get(`inventory/get`, {
      params:{id: id}, 
      signal,
    })
    .catch((error) => {
      setViewError(<ErrorPage error={error.response.status}/>)
    })
    .then((result => {
      if (result.data.status === 0) {
        setViewError(
        <>
          <ErrorPage />
        </>
        )
      }
      if (result?.data?.data?.warehouses) {
        result.data.data.warehouses = objectToArray(result.data.data.warehouses);
      }

      return result.data
    }));
  
    return query;
  }
  

  var query =  useQuery(['product-view', {id}], 
  ({ signal }) => getView(signal, id), 
  {
    retry: 0,
    keepPreviousData : true,
    refetchOnWindowFocus: false,
    enabled: id ? true : false,
  });

  return [
    query,
    {
      'viewError' : viewError
    }
  ];
}

