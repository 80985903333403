
import React from 'react';
import { UilDatabase } from "@iconscout/react-unicons";

export default function NoDataFound() {

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className='text-center'>
        <div>
          <UilDatabase size="50" className="me-2 text-secondary" />
        </div>

        <div>
          <h6 className="fw-bold">No data found!</h6>
        </div>
      </div>
    </div>

  )

}

