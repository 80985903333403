import React from 'react';
import { useLocation } from 'react-router';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';

import { Paths } from '../../routes';

import PageHeader from '../../refactoring/layouts/PageHeader';
import TabsComponent from '../../refactoring/layouts/TabsLayout';


export default function Settings() {
  const { pathname } = useLocation();

  return (
    <>
      <PageHeader title="Settings" subtitle="All Settings" />
      <section>
        <div className='container-fluid px-0'>
          <div className="row">
            <div className="col-2">
              <div className='nav flex-column position-sticky' style={ {top: '20px'} } id='settings'>
                <TabsComponent
                  tabs={[
                    Paths.Settings.pages.AccountSettings,
                    Paths.Settings.pages.BusinessInfo,
                    Paths.Settings.pages.Customization,
                    Paths.Settings.pages.Preferences,
                    Paths.Settings.pages.UserManagement,
                    Paths.Settings.pages.GroupManagement,
                    Paths.Settings.pages.WarehouseManagement,
                    Paths.Settings.pages.ManufacturerManagement,
                    Paths.Settings.pages.Tax,
                    Paths.Settings.pages.UnitsManagement,
                    Paths.Settings.pages.ShippingManagement,
                    Paths.Settings.pages.PaymentManagement,
                    Paths.Settings.pages.CouponManagement,
                    Paths.Settings.pages.MailManagement,
                  ]}
                  activeRoute={pathname}
                />
              </div>
            </div>
            <div className="col-10">
              <div>
                <Outlet />
                {/* <Navigate from={Paths.Settings.path} to={Paths.Settings.pages.AccountSettings.path} /> */}
              </div>
            </div>
            
          </div>
        </div>
      </section>

    </>
  )
}
