import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import apiClient from "../header";
import ErrorPage from "../../components/errors/ErrorPage";
import { objectToFormData } from "../../../scripts/utils";

export function useBusinessViewQuery() {
  const [viewError, setViewError] = useState();

  function getView(signal) {
    const query = apiClient
      .get(`businesses/get`, { signal })
      .catch((error) => {
        setViewError(<ErrorPage error={error.response.status} />);
      })
      .then((result) => {
        if (result.data.status === 0) {
          setViewError(
            <>
              <ErrorPage />
            </>
          );
        }
        return result.data;
      });
    return query;
  }

  var query = useQuery(["business-view", {}], ({ signal }) => getView(signal), {
    retry: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return [
    query,
    {
      viewError: viewError,
    },
  ];
}

function update(data) {
  const formData = new FormData();
  objectToFormData(data, formData);

  return apiClient
    .post(`businesses/logo/edit`, formData)
    .then((result) => result.data);
}

export function useLogoUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["business-view"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }
  const mutate = useMutation((logo) => update(logo), {
    retry: false,
    onMutate: handleProcessing,

    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}

function updateInfo(data) {
  const formData = new FormData();
  objectToFormData(data, formData);

  return apiClient
    .post(`businesses/edit`, formData)
    .then((result) => result.data);
}

export function useBusinessInfoUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["business-view"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }
  const mutate = useMutation((data) => updateInfo(data), {
    retry: false,
    onMutate: handleProcessing,

    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}

export function getCountryList(queryKey, signal) {
  const [ query_key, { searchParams }, ] = queryKey; 
  const query = apiClient .get(`businesses/metadata`, { params: { search: searchParams, }, signal,
    })
    .then((result) => result.data);
  return query;
}

