import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { objectToFormData } from "../../../scripts/utils";
import { Paths } from "../../../routes";
import { toast } from "react-toastify";


export function useDeleteTrigger() {
  const queryClient = useQueryClient();
  function deleteTrigger(key) {
    const query = apiClient
      .post(`configs/reset`, {
        key: key,
      })
      .then((result) => result.data);
    return query;
  }

  function handleSuccess(data) {
    if (data.status) {
      notify(data.type, data.message);
      queryClient.invalidateQueries(["triggers-list"]);
    }
  }

  return useMutation((key) => deleteTrigger(key), {
    retry: false,
    onSuccess: handleSuccess,
  });
}


export function useTriggerCreateQuery() {
  
  function createTrigger(data) {  
    return apiClient
      .post(`configs/add-order-notification`, data)
      .then((result) => result.data);
  }
  const queryClient = useQueryClient();

  function handleSuccess(data) {
    notify(data.type, data.message);
    if (data.status) {
      queryClient.invalidateQueries(["triggers-list"]);
  }}

  return useMutation((newTrigger) => createTrigger(newTrigger), {
    retry: false,
    onSuccess: handleSuccess,
  });

}

export function useTriggerEditQuery() {
  
  function editTrigger(data) {  
    return apiClient
      .post(`configs/edit-order-notification`, data)
      .then((result) => result.data);
  }
  const queryClient = useQueryClient();

  function handleSuccess(data) {
    notify(data.type, data.message);
    if (data.status) {
      queryClient.invalidateQueries(["triggers-list"]);
  }}

  return useMutation((updateTrigger) => editTrigger(updateTrigger), {
    retry: false,
    onSuccess: handleSuccess,
  });

}

export function useTriggerStatusQuery() {
  function editStatus(data) {  
    return apiClient.post(`configs/toggle`, data).then((result) => result.data);
  }

  function handleSuccess(data) {
    if (data.status) {
      notify(data.type, data.message);
  }}

  return useMutation((updateTrigger) => editStatus(updateTrigger), {
    retry: false,
    onSuccess: handleSuccess,
  });

}

export function useSendNotificationQuery() {
  
  const queryClient = useQueryClient();
  function sendNotification(data) {  
    return apiClient
      .post(`notifications/order`, data)
      .then((result) => result.data);
  }

  function handleSuccess(data) {
    if (data.status) {
      notify(data.type, data.message);
      queryClient.invalidateQueries(["notification-order-list"]);
  }}

  return useMutation((notification) => sendNotification(notification), {
    retry: false,
    onSuccess: handleSuccess,
  });

}
