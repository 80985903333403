import React, { useState, useEffect } from "react";

import { useValidator } from "../../../utils/validator";
import { useWarehouserCreateQuery, useWarehouserUpdateQuery, } from "../../../stores/setting/warehouse";

export default function Form(props) {

  const [inputs, setInputs] = useState({
    warehouse_name: (props.inputs && props.inputs.warehouseName) || "",
    warehouse_location: (props.inputs && props.inputs.warehouseLocation) || "",
    warehouse_id: (props.inputs && props.inputs.warehouseID) || null,
  });

  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);
  const [validator, { validate }] = useValidator(errors);

  const [warehouseCreateQuery, { createErrors }] = useWarehouserCreateQuery();
  const [warehouseUpdateQuery, { updateErrors }] = useWarehouserUpdateQuery();

  const onChange = (event) => {
    event.preventDefault();
    setErrors({});
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if (inputs.warehouse_id) {
        warehouseUpdateQuery.mutate(inputs, {
          onSuccess: (data) => {
            if (data.status) {
              setInputs({});
              props.closeModal();
            }
          },
        });
      } else {
        warehouseCreateQuery.mutate(inputs, {
          onSuccess: (data) => {
            if (data.status) {
              setInputs({});
              props.closeModal();
              if (props.callback) props.callback();
            }
          },
        });
      }
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);

  return (
    <section>
      <div className="row mb-4">
        <div className={`col-12 mb-3`}>
          <label className="fw-bold me-3 ">Warehouse Name</label>
          <input
            type="text"
            className="form-control "
            name="warehouse_name"
            onChange={(event) => onChange(event)}
            value={inputs.warehouse_name || ""}
            placeholder="Warehouse Name"
            ref={props.refName}
          />
          {validate(inputs.warehouse_name, "required|min:2|max:25", {
            title: "warehouse name",
            onServer: errors["warehouse_name"],
          })}
        </div>
        <div className={`col-12 mb-3`}>
          <label className="fw-bold me-3 ">Warehouse Location</label>
          <input
            type="text"
            className="form-control "
            name="warehouse_location"
            onChange={onChange}
            value={inputs.warehouse_location || ""}
            placeholder="Warehouse Location"
          />
          {validate(inputs.warehouse_location, "required|max:120", {
            title: "warehouse location",
          })}
        </div>
        <div className={`col-12 mb-3`}>
          <button
            onClick={handleSubmit}
            className="btn btn-secondary w-100 "
          >
            {props.isEdit ? "Save changes" : "Add Warehouse"}
          </button>
        </div>
      </div>
    </section>
  );
}
