import React, { useState, useEffect }  from 'react'
import Pagination from "react-bootstrap/Pagination";

export default function Paginator ({onChangePage, currentPage, totalPages }) 
{
  var items = [];
  const setThePage = (pageNum) => {
    onChangePage(pageNum)
  };
 
  const generatePagination = () => {
    items = [];
    
    var startPage = (currentPage - 2 > 0) ? currentPage - 2 : 1;
    var lastPage = (currentPage + 2 <= totalPages) ? currentPage + 2 : totalPages;
   
    if(currentPage-2 > 1) {
      items.push(
        <Pagination.Ellipsis key={"firstPageEllipsis"}  onClick={()=>setThePage(currentPage - 1)}/>
      );
    }
    
    for (let i = startPage; i <= lastPage; i++) {
      items.push(
        <Pagination.Item
          onClick={()=>setThePage(i)}
          key={i}
          active={i === currentPage}
        >
          {i}
        </Pagination.Item>
      );
    }

    if(currentPage < totalPages - 2){
      items.push(
        <Pagination.Ellipsis key={"lastPageEllipsis"} onClick={()=>setThePage(currentPage+1) }/>
      );
    }
  };

  return (
    <>
      {totalPages > 0 ? generatePagination() : null}
      <Pagination className="d-flex justify-content-end m-0">
        {(totalPages != 1)?(
          <>
          <Pagination.First onClick={()=>setThePage(1)}/>
          <Pagination.Prev onClick={()=>setThePage(currentPage-1)} disabled={currentPage == 1}/>
          </>
          ):null }
        {items}
        {(totalPages != 1)?(
          <>
          <Pagination.Next onClick={()=>setThePage(currentPage+1)} disabled={currentPage == totalPages}/>
          <Pagination.Last onClick={()=>setThePage(totalPages) } />
          </>
          ):null }
      </Pagination>
    </>
  )
}


