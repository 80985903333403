import _ from "lodash";
import Select from "react-select";
import React, { useState, useEffect, useContext } from "react";

import { useValidator } from "../../../utils/validator";
import { preventNonNumeric } from "../../../../scripts/utils";
import { MetaDataContext } from "../../../../web/context/metaData";

import { useShippingMethodCreateQuery, useShippingMethodUpdateQuery, } from "../../../stores/setting/shipping";
import { useTaxListQuery } from "../../../stores/setting/tax";


export default function Form(props) {

  const [inputs, setInputs] = useState(props.inputs ?? {});
  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);
  const [taxes, setTaxes] = useState([]);

  const [validator, { validate }] = useValidator(errors);
  const {data:preferences} = useContext(MetaDataContext);

  const [shippingMethodCreateQuery, { createErrors }] = useShippingMethodCreateQuery();
  const [shippingMethodUpdateQuery, { updateErrors }] = useShippingMethodUpdateQuery();
  const [tax] = useTaxListQuery({ defaultValue: { limit: "all" } });

  const handleInputChange = (event) => {
    event.preventDefault();
    setErrors({});
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  function handleSelectChange(label, value) {
    setInputs((prevProps) => ({
      ...prevProps,
      [label]: value,
    }));
  }

  useEffect(() => {
    if (tax.isFetched) {
      let data = [{ value: 0, label: "Tax is not applicable" }];
      tax.data.map((tax) => {
        data.push({ value: tax.id, label: tax.tax_name });
      });
      setTaxes(data);
    }
  }, [tax?.data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if(inputs.id){
        shippingMethodUpdateQuery.mutate( { ...inputs }, {
        onSuccess: (data) => {
          if (data.status) {
            setInputs({});
            props.closeModal();
          }
        },
      });
      } else {
        shippingMethodCreateQuery.mutate( { ...inputs }, {
          onSuccess: (data) => {
            if (data.status) {
              setInputs({});
              props.closeModal();
              if (props.callback) props.callback();
            }
          },
        });
    }
  }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);

  return (
    <>
      <section className="">
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-lg-12 mb-3">
              <label className="fw-bold me-3 ">Shipping Method</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter Shipping Method"
                autoComplete="off"
                value={inputs.name || ""}
                onChange={(event) => handleInputChange(event)}
                ref={props.refName}
              />
              {validate(inputs.name, "required|min:3|max:10", { title: "name", onServer: errors['name']})}
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold me-3 ">Tax Name</label>
              {taxes && taxes ? (
                <Select
                  id="tax_classes_id"
                  closeMenuOnSelect={true}
                  options={taxes}
                  isMulti={false}
                  isSearchable={true}
                  placeholder="Select Tax Class"
                  value={_.filter(taxes, function (obj) { return obj.value == inputs.tax_classes_id; })}
                  components={{ IndicatorSeparator: () => null }}
                  onChange={(e) => handleSelectChange("tax_classes_id", e.value)
                  }
                />
              ) : (
                "Form Taxes Loading"
              )}
              {validate(inputs.tax_classes_id, "required", { title: "tax_classes_id", })}
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold me-3 ">Amount</label>
              <input
                placeholder={ preferences.currency_symbol }
                type="number"
                name="amount"
                className="form-control"
                min="0"
                autoComplete="off"
                onKeyPress={(e) => preventNonNumeric(e)}
                value={inputs.amount || ""}
                onChange={(event) => handleInputChange(event)}
              />
              {validate(inputs.amount, "required|min:1|max:15", { title: "amount", })}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 ">
              <button type="submit" className="btn btn-secondary w-100">
                {props.isEdit ? "Save changes" : "Add shipping method"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
