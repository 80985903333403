import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import apiClient from "../header";


export function useCategoryListQuery({
  defaultValue = {
    'pageNumber': 1,
    'limit': 15,
    'sort': null,
    'searchParams': undefined,
    'filters': [],
    'linked': undefined,
  },
} = {}) {
  // const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(defaultValue.pageNumber);
  const [searchParams, setSearchParams] = useState(defaultValue.searchParams);
  const [limit, setLimit] = useState(defaultValue.limit);
  const [sort, setSort] = useState(defaultValue.sort);

  function getList(signal) {
    const query = apiClient
      .get(`categories`, {
        params: { limit: limit, page: pageNumber, search: searchParams, sort: sort },
        signal,
      })
      .then((result) => result.data);
    return query;
  }


    function handleSuccess(data) {
      document.dispatchEvent(
        new CustomEvent("category-fetched", { detail: data.data })
      );
    } 

  const query = useQuery(
    ["category-list", { searchParams, pageNumber, sort }],
    ({ signal }) => getList(signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
    }
  );

  return [
    query,
    {
      'searchParams': searchParams,
      'setSearchParams': setSearchParams,

      'limit': limit,
      'setLimit': setLimit,

      'setPageNumber': setPageNumber,

      'sort': sort,
      'setSort': setSort,
    },
  ];
}

