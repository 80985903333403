import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../header";

export function useUserListQuery({
  defaultValue = {
    pageNumber: 1,
    limit: 15,
  },
} = {}) {
  const [pageNumber, setPageNumber] = useState(defaultValue.pageNumber);
  const [limit, setLimit] = useState(defaultValue.limit);

  function getList(signal) {
    const query = apiClient
      .get(`/users/get`, {
        params: {
          page: pageNumber,
          limit: limit,
        },
        signal,
      })
      .then((result) => result.data);
    return query;
  }

  function handleSuccess(data) {
    document.dispatchEvent( new CustomEvent("user-list-fetched", { detail: data.data }) );
  }

  const query = useQuery(
    ["user-list", { pageNumber }],
    ({ signal }) => getList(signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
    }
  );

  return [
    query,
    {
      setPageNumber: setPageNumber,
    },
  ];
}

export function useUserCreateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function create(data) {
    return apiClient.post(`users/add`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(["user-list"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((data) => create(data), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}

export function useUserUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function update(data) {
    return apiClient.post(`users/edit`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(["user-list"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((data) => update(data), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      updateErrors: errors,
    },
  ];
}

export function useUserDeleteQuery() {
  const queryClient = useQueryClient();

  var toastify;
  function remove(id) {
    return apiClient.post(`users/delete`, { user_id : id }).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(["user-list"]);
    }
  }

  const mutate = useMutation((id) => remove(id), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return mutate;
}
