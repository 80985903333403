import { indexOf } from "lodash";
import moment from 'moment'
import localstorage from "../localstorage";

export let formatNumber = (number) => {
  number = parseInt(number).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return number;
}

export let numberFormat = (number) => {
  if (typeof number != 'string'){
    number = number + '';
  }
  var precision = number.indexOf('.') + 3;
  number = number.slice(0, precision)
  return number;
}



export let abbreviateNumber = (value, currency = false, notationType = "compact") => {
	var currencyValue = parseFloat(parseFloat(value).toFixed(2));
  if (currency) {
    return new Intl.NumberFormat(currency.currency_locale, { notation: notationType, style: 'currency', currency: currency.currency_code }).format(currencyValue)
  } else {
    return currencyValue.toLocaleString();
  }
}

export let dateTimeFormat = (value, dateStyle = "short", timezone = "", timeStyle = null) => {
  value = typeof value == 'string' && new Date(value);
  return new Intl.DateTimeFormat('en-US', {dateStyle: dateStyle, ...(timeStyle) && {timeStyle: timeStyle}, ...(timezone) && {timeZone: timezone}}).format(value)
}

export let changeTimezone = (value, timezone) => {
  let date = new Date(value);
  let changedDate = date.toLocaleString('en-US', { timeZone: timezone });
    return new Date(changedDate);
}

export let preventNonNumeric = (e) => {
  e.persist();
  var invalidChars = ["-", "e", "+", "E", "."];
  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
}

export let checkFloat = (e) => {
  e.persist();
  var invalidChars = ["-", "e", "+", "E"];
  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
}

export let objectToArray = (object) => {
  var array = [];
  Object.keys(object).map((key, index) => {
    array[key] = object[key]
  });
  return array;
}

export let onlyPercentage = (e) => {
  e.persist();
  var invalidChars = ["-", "e", "+", "E","."];
  var pattern = /^(\d{0,2})\.*(\.\d{0,2})?$/
  if (invalidChars.includes(e.key) || !pattern.test(e.target.value)) {
    e.preventDefault();
  }
}

export function objectToFormData(obj, formData){
  const createFormData = (obj, subKeyStr = '') => {
    for(let i in obj){
        let value = obj[i];
        let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;
        if(typeof(value) === 'string' || typeof(value) === 'number' || value instanceof File){
            formData.append(subKeyStrTrans, value);
        } else if(typeof(value) === 'object'){
            createFormData(value, subKeyStrTrans);
        }
    }
  }
  createFormData(obj);
  return formData;
}

export function isAuthorized(routePermission) {
	if (localstorage.userPermissions.get()) {
		const userPermission = localstorage.userPermissions.get().split(",");
		return routePermission.some((permission) => userPermission.includes(permission)) || routePermission.includes('*')
	}
}

