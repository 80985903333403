import React from "react";

import Loader from "../../../components/global/Loader";

import Logo from "./Logo";
import Form from "./Form";
import { useBusinessViewQuery } from "../../../stores/setting/businessInformation";


export default function BusinessInfo() {
  const [queryData] = useBusinessViewQuery();
  return (
    <>
      {queryData.isLoading ? (
        <Loader />
      ) : (
        <>
          <Logo data={queryData} />
          <Form data={queryData} />
        </>
      )}
    </>
  );
}
