
import { useState, useEffect } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import apiClient from "../header";
import _ from "lodash";
import { useRef } from "react";

var defaultState = {
  'key': 'all',
  'startData': new Date(new Date()),
  'endData': new Date(),
}

export function useDashboardQuery({ enabled = true, defaultValue = {}} = {}) {
  defaultState = {...defaultState, ...defaultValue};
  const dashboardRef = useRef({
    sales_orders: null,
    order_pending: null,
    order_processing: null,
    order_completed: null,
    totalSales: null,
    total_order: null,
    top_products: null,
    product_details: null,
    order_status: null,
    inventory_summary: null,
    business_summary: null,
    order_customization: null,
    average_sales: null,
    sales_by_channel: null,
    plank: null,
    average_sales: null,
    total_cubic_meter_category: null,
    order_customization: null,
    avg_delivery_charge: null,
    total_cubic_meter_category: null,
  })

  function getData(queryKey, signal) {
    const [query_key,  { queryParams }] = queryKey
    const query =  apiClient.get(`general/dashboard/overview`, {
      params: { 'key': queryParams.queryFor, 'startDate': queryParams.startDate, 'endData': queryParams.endDate }, 
      signal,
    }).then(
      (result) => {
        dashboardRef.current = {...dashboardRef.current, ...result.data}
        return dashboardRef.current;
      }
    );
    return query;
  }
  
  const [queryParams, setQueryParams] = useState({
    queryFor: defaultState.key,
    startDate: defaultState.startDate,
    endData: defaultState.endData
  })

  const query = useQuery(['dashboard', { queryParams }], 
  ({queryKey, signal}) => getData(queryKey, signal), 
  {
    retry: 0,
    enabled: enabled,
    cacheTime: false,
    keepPreviousData: true,
  })    

  return [
    query,
    {
      queryParams,
      setQueryParams
    }
  ]
}

