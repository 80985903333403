import React, { useState, useRef, useEffect, useContext } from "react";
import Select from "react-select";
import _ from "lodash";
import SimpleReactValidator from "simple-react-validator";
import { UilAngleDown } from "@iconscout/react-unicons";

import api from "../../../api";
import config from "../../../configurations";
import { notify } from "../../../scripts/notify";

import PageHeader from "../../../refactoring/layouts/PageHeader";
import Loader from "../../../refactoring/components/global/Loader";
import { MetaDataContext } from "../../../web/context/metaData";


export default function Preferences() {
  const [timezone, setTimezone] = useState({});
  const [currency, setCurrency] = useState({});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState({});

  const validator = useRef(new SimpleReactValidator());
  const metaData = useContext(MetaDataContext);

  const fetchData = async () => {
    api.preferences.get().then((response) => {
      let preferenceData = response.data.data;
      setInputs((prevProps) => ({
        ...prevProps,
          timezone: preferenceData.find((element) => element.option === "timezone"),
          currency_symbol: {
            type: "preference",
            option: "currency_symbol",
            value:  preferenceData.find((element) => element.option === "currency_symbol").value,
          },
          currency_code: {
            type: "preference",
            option: "currency_code",
            value:  preferenceData.find((element) => element.option === "currency_code").value,
          },
          currency_locale: {
            type: "preference",
            option: "currency_locale",
            value: preferenceData.find((element) => element.option === "currency_locale").value,
          },
      }));
         
      setCurrency((prevprops) => ({
        ...prevprops,
        currency_code: preferenceData.find((element) => element.option === "currency_code").value,
        currency_symbol: preferenceData.find((element) => element.option === "currency_symbol").value,
        currency_locale: preferenceData.find((element) => element.option === "currency_locale").value,
      }));
      
      setTimezone((prevprops) => ({
        ...prevprops,
        value: preferenceData.find((element) => element.option === "timezone").value,
        name: _.find(config.Timezones, {
          value: preferenceData.find((element) => element.option === "timezone").value,
        }).name,
      }));

      setLoading(false);
    });
  };
  
  const handleInputChange = (event, label) => {
    if (label == "timezone") {
      setInputs((prevProps) => ({
        ...prevProps,
        timezone: {
          type: "preference",
          option: "timezone",
          value: event.value,
        },
      }));
      setTimezone(event);
    } else if (label == "currency") {
      setInputs((prevProps) => ({
        ...prevProps,
          currency_symbol: {
            type: "preference",
            option: "currency_symbol",
            value: event.currency_symbol,
          },
          currency_code: {
            type: "preference",
            option: "currency_code",
            value: event.currency_code,
          },
          currency_locale: {
            type: "preference",
            option: "currency_locale",
            value: event.currency_locale,
          },
      }));
      setCurrency(event);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.preferences.add({...inputs})
        .then((response) => {
          notify(response.data.type, response.data.message);
          // fetchData();
          metaData.updateMetaDataContext();
        })
        .catch((error) => {
          notify("error");
          console.log("error", error);
        });
    } else {
      notify("error", "Form error");
      setErrors((prevProps) => ({
        ...prevProps,
        ...validator.current.errorMessages,
      }));
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      { loading ? (
        <Loader />
      ) : (
        <form>
          <section className="bg-white p-5 rounded mb-4 border">
            <PageHeader
              subtitle="Preferences"
              subtext="Default preferences for the system."
            />
            <div className="row">
              <div className="col-md-6">
                <label className="fw-bold">Time Zone</label>
                <Select
                  id="select_timezone"
                  placeholder={
                    <span className="text-muted">Select time zone</span>
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => {
                      return (
                        <div className="px-2">
                          <UilAngleDown width="16" />
                        </div>
                      );
                    },
                  }}
                  value={timezone}
                  isMulti={false}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  options={config.Timezones}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(event) => {
                    handleInputChange(event, "timezone");
                  }}
                />
                { validator.current.message("Timezone", inputs.timezone, "required", { className: "small text-danger" })}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <label className="fw-bold">Currency</label>
                <Select
                  id="select_currency"
                  placeholder={
                    <span className="text-muted">Select Currency</span>
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => {
                      return (
                        <div className="px-2">
                          <UilAngleDown width="16" />
                        </div>
                      );
                    },
                  }}
                  isMulti={false}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  options={config.Currencies}
                  getOptionLabel={(option) => option.currency_code}
                  getOptionValue={(option) => option.currency_symbol}
                  // formatOptionLabel={formatOptionLabel}
                  value={currency}
                  onChange={(event) => {
                    handleInputChange(event, "currency");
                  }}
                  />
                  
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="p-4 add-new-product border bg-white rounded">
                <button
                  className="btn btn-secondary text-white"
                  onClick={handleSubmit}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
