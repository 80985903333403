import _ from "lodash";
import { useState } from "react";

import CustomSelect from "../global/CustomSelect";
import { useValidator } from "../../utils/validator";
import ModalComponent from "../common/ModalComponent";
import { preventNonNumeric } from "../../../scripts/utils";


export default function SelectShippingModal(props) {
  const initialState = {
    'shipping_method': null,
    'shipping_cost': null,
    'shipping_id': null,
  };
  
  const {
    show = false,
    defaultValue = initialState,
    onChange = () => {},
    onHide = () => {},
    data = {}
   } = props;


  const [inputs, setInputs] = useState(defaultValue);
  const [validator, {validate}] = useValidator()
  const [, forceUpdate] = useState();

  function handleInputChange(data) {
    setInputs(prevProps => ({
      ...prevProps,
      [data.name]: data.value,
    }));
  }

  function handleSelectChange(option) {
    setInputs({
      'shipping_id': option.id,
      'shipping_method': option.name,
      'shipping_cost': option.amount,
    });
  }

  function handleSubmit() {
    if (validator.current.allValid()) {
      onChange(inputs)
      onHide()
    } else {
      validator.current.showMessages();
      forceUpdate(true)
    }
  }


  return (
    <>
      <ModalComponent
        show={show}
        onHide={onHide}
        title="Add shipping method"
        body={
          <div className="row">
            <div className="col-6">
              <label className="fw-bold me-3">Shipping method</label>
              <CustomSelect
                isSearchable={true}
                options={data}
                closeMenuOnSelect={true}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                placeholder="Choose a method"
                value={_.find(data, ["name", inputs.shipping_method]) || ""}
                onChange={(option) => handleSelectChange(option)}
              />
              {validate(inputs.shipping_method, "required", {
                title: "shipping_method",
              })}
            </div>
            <div className="col-6">
              <label className="fw-bold me-3 ">Shipping cost</label>
              <input
                type="number"
                className="form-control"
                placeholder="Amount"
                min="0"
                name="shipping_cost"
                value={inputs.shipping_cost || ""}
                onKeyPress={(e) => preventNonNumeric(e)}
                onChange={({ target }) => handleInputChange(target)}
              />
              {validate(inputs.shipping_cost, "required", {
                title: "shipping_cost",
              })}
            </div>
          </div>
        }
        footer={
          <div className="px-4 text-end">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={handleSubmit}
            >
              Add shipping method
            </button>
          </div>
        }
      />
    </>
  );
};