import React, { useState, useEffect } from "react";
import { NavLink, Link } from 'react-router-dom';
import { UilApps, UilShoppingBag, UilChannel, UilChartLine, UilStore, UilReceiptAlt, UilUsdCircle, UilSwatchbook,UilChart, UilBell } from "@iconscout/react-unicons";

import api from '../../api';
import localstorage from '../../localstorage';
import { Paths } from '../../routes';

import Logo from "../../assets/img/branding/innoventory-128x128.svg";

import _ from 'lodash';

export default function Sidebar() {

  const [info, setInfo] = useState({
    businessName: "",
    userId: "",
    userName: "",
    groupName: "",
    permissionId: [],
  });
  const [notifications, setNotifications] = useState([]);

  const logout = (event) => {
    event.preventDefault();
    api.auth.logout().then((response) => {
      if (response.data.status) {
        localstorage.token.logout();
        window.location.href = Paths.Login.path;
      }
    });
  }
   
    
  useEffect(() => {
    // api.business.get()
    //   .then(response => {
    //     let buisnessData = response.data.business_data;
    //     let userData = response.data.user_data;
    //     let groupData = response.data.group_data;
    //     let permissionData = response.data.permission_data.map(permission => {
    //       return permission.id
    //     });

    //     localStorage.setItem('permissions', permissionData);

    //     setInfo({
    //       businessName: buisnessData.business_name,
    //       userId: userData.id,
    //       userName: userData.name,
    //       groupName: groupData.group_name,
    //       permissionId: permissionData
    //     })
    //   });
  }, []);

  // * this is the listener for the events
  // ? might have to move somewhere it initializes as user login
  // useEffect(() => {
  //   window.Echo.private(`App.Models.User.${info.userId}`).notification((notification) => {
  //     // console.log(notification);
  //     if (!notifications.includes(notification.id)) {
  //       setNotifications(prevprops => ([
  //         ...prevprops,
  //         notification
  //       ]));
  //     }
  //   });
  // }, [info]);

  // const { businessName, userName, groupName, permissionId } = this.state;

  return (
    <div className='sidebar'>
      <div className="sidebar-inner h-100 pt-3 d-flex flex-column justify-content-between">

        <div>
          <div className="logo mb-5 py-3 d-flex justify-content-center">
            <Link to={Paths.Root.path}>
              <img src={Logo} height={60} alt=""/>
            </Link>
          </div>

          {/* <div className="px-4 pt-1 border-top">
            <div className="my-2">
              <ul className="nav">
                <li>
                  <NavLink to={Paths.Notifications.pages.SyncStatus.path} className="nav-link">
                    <span><UilSync size="16" className="me-2 mb-1" />Sync Status</span> <NotificationCount count={notifications.length} />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="px-4 mb-4 ">
            <ul className="accordion nav flex-column" id="sidebar">
							{/* {Object.values(Paths).map((route, key) => (
								route.isVisible &&   

                <li className="mb-2" key={key} >
                  <NavLink to={route.path} className= {`nav-link ${route.pages ? 'has-child' : ''}  `} 
                      data-bs-toggle="collapse" data-bs-target={`#${route.name}`} aria-expanded="true" aria-controls={`#${route.name}`}>
										<span>{route.icon}{route.name}</span>
									</NavLink>
                    <div className="collapse" id={route.name} data-bs-parent="#sidebar">
                      {route.pages &&
                        <ul className="btn-toggle-nav ">
                          {Object.values(route.pages).map((page, index) => (
                            (page.isVisible &&
                              <li key={index}>
                                <NavLink to={page.path} >{page.name}</NavLink>
                              </li>)
                          ))}
                        </ul>
                      } 
                  </div>
								</li>
							))} */}

							{Object.values(Paths).map((route, key) => (
								route.isVisible &&              
								<li className="mb-2" key={key} >
									<NavLink to={route.path} className={`nav-link ${route.pages ? 'has-child' : ''}`}>
										<span >{route.icon}{route.name}</span>
									</NavLink>
									{route.pages &&
										<ul className="">
										{Object.values(route.pages).map((page, index) => (
											(page.isVisible &&
											<li key={index}>
												<NavLink to={page.path} >{page.name}</NavLink>
											</li>)
										))}
										</ul>
									} 
								</li>
							))}
						</ul>
					</div>    
         

        </div>

    
      </div>
    </div>
  );
}
