import React, { useState } from "react";

import Add from "./Add";
import GetOrders from "../GetOrders";
import GetProducts from "../GetProducts";
import { Paths } from "../../../../routes";
import Success from "../../../components/common/Success";
import PageHeader from "../../../layouts/PageHeader";
import StepperComponent from "../../../components/global/Stepper";

export default function Stepper() {
  const [step, setStep] = useState(1);
  const [id, setId] = useState(null);

  function showStep(step) {
    switch (step) {
      case 1:
        return <Add step={step} setStep={setStep} setId={setId} />
      case 2:
        return <GetProducts step={step} setStep={setStep} id={id} name="Opencart" type={3} />;
      case 3:
        return <GetOrders step={step} setStep={setStep} id={id} name="Opencart" type={3} />;
      case 4:
        return <Success name="Opencart" link={Paths.Order.pages.index.path} option="orders" />;
    }
  }
  

  return (
    <div>
      <PageHeader title="Channels" subtitle="OpenCart" />
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-4">
            <StepperComponent
              steps={[
                {
                  title: "Add a Channel",
                  onClick: (e) => {
                    e.preventDefault();
                    setStep(1);
                  },
                },
                {
                  title: "Fetch OpenCart Products",
                  onClick: (e) => {
                    e.preventDefault();
                    setStep(2);
                  },
                },
                {
                  title: "Fetch OpenCart Orders",
                  onClick: (e) => {
                    e.preventDefault();
                    setStep(3);
                  },
                },
              ]}
              activeStep={step - 1}
            />
          </div>
        </div>
      </div>
      {showStep(step)}
    </div>
  );
}
