
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { Paths } from "../../../routes";
import { objectToFormData } from "../../../scripts/utils";

function updateInvoice(data) {
  const formData = new FormData();
  objectToFormData(data, formData);
  return apiClient
    .post(`invoices/edit`, formData)
    .then((result) => result.data);
}


export function useInvoiceUpdateQuery() {
	const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});
  var toastify;

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(['invoice-list']);
      setTimeout(() => {
        navigate(Paths.Invoice.pages.index.path);
      }, 2000)
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors(prevProps => ({
        ...prevProps,
          [error]: message[0]
        }));
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }
  
  function handleError(data) {
    toast.update(toastify, {
      render: 'Something went wrong',
      type: 'error',
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
  }

  const mutate = useMutation((editInvoice) => updateInvoice(editInvoice), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return [
    mutate,
    {
      'updateErrors': errors,
    },
  ]
}