import React, { useState, useRef, useContext } from "react";
import { UilPen, UilTrashAlt } from "@iconscout/react-unicons";
import _ from "lodash";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import DataTable from "../../../components/common/DataTable";
import PageHeader from "../../../layouts/PageHeader";
import { MetaDataContext } from "../../../../web/context/metaData";
import ModalComponent from "../../../components/common/ModalComponent";

import Form from "./Form";
import { useShippingMethodDeleteQuery, useShippingMethodListQuery, } from "../../../stores/setting/shipping";

export default function Listing() {

  const {data: preferences} = useContext(MetaDataContext);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({});
  const refName = useRef();

  const [query, { setPageNumber }] = useShippingMethodListQuery();
  const deleteShippingMethod = useShippingMethodDeleteQuery();

  const add = () => {
    setOpen(true);
    setInputs({});
  };

  const edit = (id, name, amount, tax_classes_id) => {
    setOpen(true);
    setInputs({
      id: id,
      name: name,
      amount: amount,
      tax_classes_id: tax_classes_id,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleDelete = (id) => {
    deleteShippingMethod.mutate(id);
  };

  const columns = [
    {
      id: "name",
      name: "Shipping Methods",
      selector: (row) => row.name,
    },
    {
      id: "tax",
      name: "Tax Name",
      cell: (row) =>
        _.isNull(row.shipping_tax) && row.tax_classes_id == 0 ? (
          <td>Tax is not applicable</td>
        ) : (
          <td>{row.shipping_tax.tax_name}</td>
        ),
    },
    {
      id: "amount",
      name: "Amount",
      cell: (row) => preferences.currency_symbol + row.amount,
    },
    {
      id: "action",
      name: "",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit shipping method</Tooltip>} >
            <span 
              className="me-3 active-icon" 
              onClick={() => { setIsEdit(true); edit(row.id, row.name, row.amount, row.tax_classes_id); }}
              id="edit"
            >
              {<UilPen size="14" className="text-secondary" />}
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Delete shipping method</Tooltip>}
          >
            <span className="cursor active-icon" id="delete">
              { <UilTrashAlt size="14" onClick={() => handleDelete(row.id)} className="text-danger" /> }
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Shipping Methods" subtext="Manage shipping methods" />

          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()}>
              Add Shipping Method
            </button>
          </div>
        </div>

        <div className="border rounded-3 overflow-hidden">
          <DataTable
            data={query?.data?.data}
            columns={columns}
            isLoading={query.isLoading}
            pagination={true}
            paginationMeta={query?.data}
            onChangePage={(page) => setPageNumber(page)}
          />
        </div>
      </div> 

      {open ? (
        <ModalComponent
          show={open}
          onHide={closeModal}
          onEntered={() => refName.current.focus()}
          title={inputs.id ? "Edit shipping method" : "Add Shipping Method"}
          body={
            <Form
              inputs={inputs}
              isEdit={isEdit}
              closeModal={closeModal}
              refName={refName}
            />
          }
        />
      ) : null}
    </React.Fragment>
  );
}
