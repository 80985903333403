import { toast } from 'react-toastify';

export const notify = ( type, message = "Oops something went wrong!" ) => {
  if(type === 'warning'){
    toast.warning(message)
  }else if(type === 'success'){
    toast.success(message)
  }else if(type === 'error'){
    toast.error(message)
  }else{
    toast.error(message)
  }
}