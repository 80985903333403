import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import apiClient from "../header";

export function useUnitListQuery({
  enabled = true,
  defaultValue = {
    pageNumber: 1,
    limit: 15,
  },
} = {}) {
  const [pageNumber, setPageNumber] = useState(defaultValue.pageNumber);
  const [limit, setLimit] = useState(defaultValue.limit);

  function getList(signal) {
    const query = apiClient
      .get(`units`, {
        params: { limit: limit, page: pageNumber },
        signal,
      })
      .then((result) => result.data);
    return query;
  }

  function handleSuccess(data) {
    document.dispatchEvent(
      new CustomEvent("unit-fetched", { detail: data.data })
    );
  }

  const query = useQuery(
    ["unit-list", { pageNumber }],
    ({ signal }) => getList(signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
      enabled: enabled,
    }
  );

  return [
    query,
    {
      setPageNumber: setPageNumber,
    },
  ];
}

export function useUnitCreateQuery() {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  var toastify;

  function create(data) {
    return apiClient.post(`units/add`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["unit-list"]);
      queryClient.invalidateQueries(["product-metadata"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((newUnit) => create(newUnit), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}

export function useUnitUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function update(data) {
    return apiClient.post(`units/edit`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["unit-list"]);
      queryClient.invalidateQueries(["product-metadata"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((updateUnit) => update(updateUnit), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      updateErrors: errors,
    },
  ];
}

export function useUnitDeleteQuery() {
  const queryClient = useQueryClient();

  var toastify;
  function remove(id) {
    return apiClient
      .post(`units/delete`, { id: id })
      .then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["unit-list"]);
      queryClient.invalidateQueries(["product-metadata"]);
    }
  }

  const mutate = useMutation((id) => remove(id), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return mutate;
}
