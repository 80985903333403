import React, { useState, useEffect } from "react";

import CustomSelect from "../../../components/global/CustomSelect";
import { useValidator } from "../../../utils/validator";
import { useUserCreateQuery, useUserUpdateQuery, } from "../../../stores/setting/user";
import { useGroupListQuery } from "../../../stores/setting/group";


export default function Form(props) {

  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);
  const [validator, { validate }] = useValidator(errors);

  const [userCreateQuery, { createErrors }] = useUserCreateQuery();
  const [userUpdateQuery, { updateErrors }] = useUserUpdateQuery();

  const [inputs, setInputs] = useState({
    user_id: (props.inputs && props.inputs.userID) || null,
    email: (props.inputs && props.inputs.email) || "",
    groups: "",
  });

  const [groups, setGroups] = useState([]);

  const [selectedData, setSelectData] = useState({
    group: null,
  });

  const [query] = useGroupListQuery({ defaultValue: { limit: "all" } });

  const onValueChange = (e) => {
    setSelectData((prevProps) => ({
      ...prevProps,
      group: e,
    }));

    setInputs((prevProps) => ({
      ...prevProps,
      groups: e.value,
    }));
  };

  const handleInputChange = (event) => {
    event.persist();
    setErrors({})
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if (inputs.user_id) {
        userUpdateQuery.mutate({ inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                setSelectData({});
                props.closeModal();
              }
            },
          }
        );
      } else {
        userCreateQuery.mutate({ inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                setSelectData({});
                props.closeModal();
              }
            },
          }
        );
      }
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (query.isFetched) {
      var data = [];
      query?.data.map((group) =>
        data.push({ value: group.id, label: group.group_name })
      );
      setGroups(data);
      if (props.isEdit === true) {
        var groupData = [];
        var groupId = null;
        props.inputs.groups.map(
          (group) => (
            groupData.push({ value: group.id, label: group.group_name }),
            (groupId = group.id)
          )
        );
        setInputs((prevProps) => ({
          ...prevProps,
          groups: groupId,
        }));
        setSelectData({
          group: groupData,
        });
      }
    }
  }, [query.data]);

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);

  return (
    <div>
      <div className="row mb-4">
        <div className="col-xxl-12">
          <div className="mb-3">
            <label className="fw-bold me-3 ">Email</label>
            <input
              type="text"
              className={ props.isEdit ? "form-control not-allowed" : "form-control" }
              name="email"
              onChange={handleInputChange}
              value={inputs.email}
              placeholder="Email"
              readOnly={props.isEdit ? true : false}
              ref={props.refName}
            />
            {validate(inputs.email, "required|email", { title: "email", onServer: errors['email'] })}
          </div>
        </div>
        <div className="col-xxl-12">
          <label className="fw-bold me-3 ">Add Groups</label>
          <div className="mb-3">
            {groups && groups ? (
              <CustomSelect
                placeholder="Groups"
                isMulti={false}
                closeMenuOnSelect={true}
                isSearchable={true}
                options={groups}
                value={selectedData.group}
                onChange={onValueChange}
              />
            ) : (
              "Form Group Loading"
            )}
            {validate(selectedData.group, "required", { title: "group" })}
          </div>
        </div>
        <div className="col-xxl-12">
          <div className="">
            <button onClick={handleSubmit} className="btn btn-secondary w-100">
              {props.isEdit ? "Save changes" : "Send Invite"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
