
import React, { useContext, useEffect } from "react";
import _ from "lodash";
import { UilPen, UilTimesCircle } from "@iconscout/react-unicons";
import lang from "../../../lang/en/Theme/Colors";
import config from "../../../configurations";
import { MetaDataContext } from "../../../web/context/metaData";
import { abbreviateNumber } from "../../../scripts/utils";

export default function Total(props) {
  const {data:preferences} = useContext(MetaDataContext);

  function totalCheck(num) {
    if (num[0] == "-") {
      var temp = num.replace("-", "");
      return "-" + abbreviateNumber(temp, preferences, "standard")
    }
    return abbreviateNumber(num, preferences, "standard")
  }

  return (
    <table className="table mb-0">
      <tbody>
        <tr>
          <td className="text-end fw-bold">Sub Total</td>
          <td className="text-end text-primary">{preferences.currency_symbol}{props.sub_total}</td>
          <td>&nbsp;</td>
        </tr>
        {!_.isNull(props.shipping_cost) ? (
          <tr>
            <td className="text-end fw-bold ">Shipping <br />
              {!_.isNull(props.shipping_tax_amount) ? (
                <span className="text-light" >(Shipping tax)</span>
              ) : (null)}
            </td>
            <td className="text-end text-primary"> + {preferences.currency_symbol}{props.shipping_cost} <br />
              {!_.isNull(props.shipping_tax_amount) ? (
                <span className="text-light">{preferences.currency_symbol}{props.shipping_tax_amount} </span>
              ) : (null)}
            </td>
            {props.pathname !== 'view' ?
              <td className="text-end text-primary align-items-start ">
                <button type="button" className="btn btn-link cursor active-icon" onClick={() => props.openModel('shipping')} >
                  <span className="text-secondary fw-bold d-inline">{<UilPen size="14" color={lang.colors.secondary} />}</span>
                </button>
                <button type="button" className="btn btn-link cursor active-icon ps-1 invisible" onClick={() => props.removeModelData('coupon')} >
                  <span className="text-secondary fw-bold d-inline">{<UilTimesCircle size="14" color={lang.colors.danger} />}</span>
                </button>
              </td>
              : null}
          </tr>
        ) : (null)}
        {!_.isNull(props.coupon_code) ? (
          <tr>
            <td className="text-end fw-bold">Coupon/Voucher <br />({props.coupon_code})</td>
            <td className="text-end text-primary "> - {preferences.currency_symbol}{props.coupon_amount}</td>
            {props.pathname !== 'view' ?
              <td className="text-end text-primary ">
                <button type="button" className="btn btn-link cursor active-icon" onClick={() => props.openModel('coupon')} >
                  <span className="text-secondary fw-bold d-inline">{<UilPen size="14" color={lang.colors.secondary} />}</span>
                </button>
                <button type="button" className="btn btn-link cursor active-icon ps-1" onClick={() => props.removeModelData('coupon')} >
                  <span className="text-secondary fw-bold d-inline">{<UilTimesCircle size="14" color={lang.colors.danger} />}</span>
                </button>
              </td>
              : null}
          </tr>
        ) : (null)}
        {!_.isNull(props.discount_type) ? (
          <tr>
            <td className="text-end fw-bold ">Discount <br />({props.discount} {(props.discount_type)})</td>
            <td className="text-end text-primary "> - {preferences.currency_symbol}{props.discount_cost}</td>
            {props.pathname !== 'view' ?
              <td className="text-end text-primary">
                <button  type="button" className="btn btn-link cursor active-icon" onClick={() => props.openModel('discount')} >
                  <span className="text-secondary fw-bold d-inline">{<UilPen size="14" color={lang.colors.secondary} />}</span>
                </button>
                <button type="button" className="btn btn-link cursor active-icon ps-1" onClick={() => props.removeModelData('discount')} >
                  <span className="text-secondary fw-bold d-inline">{<UilTimesCircle size="14" color={lang.colors.danger} />}</span>
                </button>
              </td>
              : null}
          </tr>
        ) : (null)}
        <tr>
          <td className="text-end fw-bold">Taxes</td>
          <td className="text-end text-primary"> {props.isCheck ? '+' : null} {abbreviateNumber(props.total_tax, preferences, "standard")}</td>
          <td>&nbsp;</td>
        </tr>

        <tr className="border-top">
          <td className="text-end fw-bold">Total</td>
          <td className="text-end text-primary"> {totalCheck(props.total)} </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
};
