import React, { useRef, useState, useEffect, useCallback} from "react";
// import Swal from 'sweetalert2';
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';

import api from "../../../api";
import { notify } from "../../../scripts/notify";
import { objectToFormData } from "./../../../scripts/utils";

import ProfileIcon from "../../../refactoring/components/common/ProfileIcon";
import PageHeader from "../../../refactoring/layouts/PageHeader";
import Loader from "../../../refactoring/components/global/Loader";

// function handleDelete(event) {
//   event.preventDefault();
//   Swal.fire({
//     icon: 'warning',
//     title: 'Are you sure you want to delete this business account?',
//     text: 'By clicking yes, you will delete this business',
//     showCancelButton: true,
//     confirmButtonText: `Yes, I am sure`,
//   })
//     .then((result) => {
//       if (result.isConfirmed) {
//         Swal.fire('Account Deleted!', '', 'warning')
//       }
//     }
//     )
//   }

export default function BusinessInfo(props) {
  const { businessName, ownerId, userId } = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileError, setFileError] = useState([]);
  const [uploadFile, setUploadFiles] = useState([]);
  const [country, setCountry] = useState([]);
  const [errors, setErrors] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [inputs, setInputs] = useState({
    id: null,
    logo: '',
    business_name: "",
    business_description: "",
    payment_details:"",
    support_email: "",
    telephone: "",
    website_url: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    tax_code: "",
    tax_code: ""
  })

  const validator = useRef(new SimpleReactValidator({
    validators: {
      website: {
        message: 'The website URL is invalid',
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/)
        }
      }
    }
  }));

  const fetchData = async () => {
    api.business.get().then(response => {
      let buisnessData = response.data.business_data;
      setInputs(prevProps => ({
        ...prevProps,
        business_name: buisnessData.business_name,
        support_email: (buisnessData.support_email) ? buisnessData.support_email : "",
        logo: buisnessData.logo,
        payment_details: buisnessData.payment_details,
        business_description: buisnessData.business_description,
        telephone: buisnessData.telephone,
        website_url: buisnessData.website_url,
        address_1: buisnessData.address_1,
        address_2: buisnessData.address_2,
        city: buisnessData.city,
        state: buisnessData.state,
        zipcode: buisnessData.zipcode,
        country: !_.isNull(buisnessData.country) ? (buisnessData.country.id):(''),
        tax_id: buisnessData.tax_id,
        tax_code: buisnessData.tax_code
      })
      )
      setCountry(buisnessData.country);
      setLoading(false);
    });
  }

  const fetchCountry =(keyword) => {
    return api.business.metadata({params: { search: keyword }}).then(response => {
      return response.data; 
   });
  }

  const debouncedChangeHandler = useCallback(
    _.debounce((keyword, callback) => {
      fetchCountry(keyword).then((options) => callback(options));    
    }, 3000)
  , []);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);

  const onChange = (event) => {
    setButtonDisabled(false)
    event.persist();
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  }

  const handleSelectChange = (option) => {
    setInputs(prevProps => ({
      ...prevProps,
      'country' : option.id
    }));
    setButtonDisabled(false);
    setCountry(option);
  }

  const onFileChange = (event) => {
    event.persist();
    if ((!_.isNull(event.target))) {
      var file = event.target.files[0];
      const allowedExtensions = ['jpg', 'png', 'jpeg', 'svg'];
      const fileExtension = (file.name).substring((file.name).lastIndexOf('.') + 1);
      setUploadFiles(event.target.files[0]);
      if (!allowedExtensions.includes(fileExtension)) {
        var err = 'File format not supported';
        setFileError(err);
      } else {
        setFileError(false);
      }
    }
  }


  const onSubmit = event => {
    event.preventDefault();
    if (validator.current.allValid()) {
      const formData = new FormData
      objectToFormData(inputs, formData);
      api.business.update(formData)
        .then((response) => {
          notify(response.data.type, response.data.message);
          fetchData();
          setButtonDisabled(true)
        })
        .catch(error => {
          notify();
          // console.log("ERRR:", error.response);
        });
    } else {
      notify('error', 'Form error');
      setErrors(prevProps => ({
        ...prevProps,
        ...validator.current.errorMessages
      }));
      validator.current.showMessages();
    }
  }

  const onSubmitLogo = event => {
    event.preventDefault();
    const logo = uploadFile;
    const formData = new FormData();
    formData.append('logo', logo);

    api.business.logo.update(formData)
      .then((response) => {
        notify(response.data.type, response.data.message);
        fetchData();
        if (response.data.status !== 1) {
          setFileError(response.data.field_error.logo.join());
        }
      })
      .catch(error => {
        notify(error);
      });
    setFileError(false);
  }

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div>
      <div className="" >
        {loading ? (<Loader />) : (
          <div>
            <section className="bg-white p-5 rounded mb-4 border">
              <PageHeader subtitle="Business Information" subtext="Information about your company that will be displayed on invoices, estimates and other documents created by Innoventory" />
              <form onSubmit={onSubmitLogo} encType="multipart/form-data">
                <div className="row">
                  <div className="col-md-6">
                    <label>Current logo</label>
                    {inputs.logo ? (
                      <div className="">
                        <img className="img-fluid" src={inputs.logo} alt="" />
                      </div>
                    ) : (
                      <div>
                        <ProfileIcon text={businessName ? businessName : ""} />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div>
                        <label htmlFor="logo">Upload logo</label>
                        <span className="text-light">
                          <small>
                            {" "}
                            (PNG or JPG no bigger than 1000px wide and tall.){" "}
                          </small>
                        </span>
                        <input
                          type="file"
                          className="form-control"
                          id="logo"
                          name="logo"
                          onChange={e => onFileChange(e)}
                        />
                      </div>
                      <span className="small text-danger">{fileError}</span>
                    </div>
                    <div className="col-md-6 pt-4">
                      <button className="btn btn-secondary px-5">
                        Change logo
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </section>

            <form onSubmit={onSubmit} encType="multipart/form-data">
              <section className="bg-white p-5 rounded mb-4 border">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <label htmlFor="business_name">Business Name</label>
                    <span className="text-danger">*</span>
                    {/* <div className="input-group">
                      <span className="input-group-text">
                        <UilBriefcaseAlt size="14" className="text-secondary" />
                      </span> */}
                      <input
                        type="text"
                        className="form-control"
                        id="business_name"
                        name="business_name"
                        placeholder="Enter Business Name"
                        value={inputs.business_name || ""}
                        onChange={onChange}
                      />
                    {/* </div> */}
                    {validator.current.message("business_name", inputs.business_name, "required|min:2|max:20", { className: "small text-danger" })}
                  </div>
                  <div className="col-md-12 mb-4 ">
                    <label htmlFor="address">Business Description</label>
                    <input
                      type="text"
                      className="form-control"
                      name="business_description"
                      placeholder="Enter Business Description"
                      value={inputs.business_description || ""}
                      onChange={onChange}
                    />
                    {validator.current.message("business_description", inputs.business_description, "max:150", { className: "small text-danger" })}
                  </div>
                  <div className="col-md-12 mb-4">
                    <label htmlFor="address">Business Payment Details</label>
                    <textarea
                      type="text-area"
                      className="form-control"
                      name="payment_details"
                      placeholder="Enter Business Payment Details"
                      value={inputs.payment_details || ""}
                      onChange={onChange}
                    />
                    {validator.current.message("payment_details", inputs.payment_details, "max:500", { className: "small text-danger" })}
                  </div>
                    <div className="col-md-6">
                      <label htmlFor="business_name">Tax Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tax_code"
                        name="tax_code"
                        placeholder="Enter Tax Code"
                        value={inputs.tax_code || ""}
                        onChange={onChange}
                      />
                    </div>
                    {validator.current.message("tax_code", inputs.tax_code, "max:120", { className: "small text-danger" })}
                    <div className="col-md-6 mb-4 ">
                      <label htmlFor="business_name">Tax ID</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tax_id"
                        name="tax_id"
                        placeholder="Enter Tax ID"
                        value={inputs.tax_id || ""}
                        onChange={onChange}
                      />
                      {validator.current.message("tax_id", inputs.tax_id, "max:120", { className: "small text-danger" })}
                    </div>
                 
                </div>
              </section>
            </form>

            <form onSubmit={onSubmit} encType="multipart/form-data">

              <section className="bg-white p-5 rounded mb-4 border">
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <label htmlFor="business_name">Support Email</label>
                    <span className="text-danger">*</span>
                   
                      {/* <span className="input-group-text">
                        <UilEnvelope size="14" className="text-secondary" />
                      </span> */}
                      <input
                        type="text"
                        className="form-control"
                        id="support_email"
                        name="support_email"
                        placeholder="Enter Support Email"
                        value={inputs.support_email || ""}
                        onChange={onChange}
                      />
                   
                    {validator.current.message("support_email", inputs.support_email, "required|email", { className: "small text-danger" })}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="support_email">Telephone</label>
                    {/* <div className="input-group"> */}
                      {/* <span className="input-group-text">
                        <UilPhone size="14" className="text-secondary" />
                      </span> */}
                      <input
                        type="text"
                        className="form-control"
                        id="telephone"
                        name="telephone"
                        placeholder="Enter Telephone"
                        value={inputs.telephone || ""}
                        onChange={onChange}
                      />
                    {/* </div> */}
                    {validator.current.message("telephone", inputs.telephone, "phone", { className: "small text-danger" })}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="business_name">Website URL</label>
                    {/* <div className="input-group"> */}
                      {/* <span className="input-group-text">
                        <UilGlobe size="14" className="text-secondary" />
                      </span> */}
                      <input
                        type="text"
                        className="form-control"
                        id="website_url"
                        name="website_url"
                        placeholder="Enter Website URL"
                        value={inputs.website_url || ""}
                        onChange={onChange}
                      />
                    {/* </div> */}
                    {validator.current.message("website_url", inputs.website_url, "website", { className: "small text-danger" })}
                  </div>
                </div>
              </section>

              <section className="bg-white p-5 rounded mb-4 border">
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <label htmlFor="business_name">Address 1</label>
                    {/* <div className="input-group"> */}
                      {/* <span className="input-group-text">
                        <UilLocationPoint size="14" className="text-secondary" />
                      </span> */}
                      <input
                        type="text"
                        className="form-control"
                        id="address_1"
                        name="address_1"
                        placeholder="Enter Address 1"
                        value={inputs.address_1 || ""}
                        onChange={onChange}
                      />
                    {/* </div> */}
                    {validator.current.message("address_1", inputs.address_1, "max:120", { className: "small text-danger" })}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="business_name">Address 2</label>
                    {/* <div className="input-group">
                      <span className="input-group-text">
                        <UilLocationPoint size="14" className="text-secondary" />
                      </span> */}
                      <input
                        type="text"
                        className="form-control"
                        id="address_2"
                        name="address_2"
                        placeholder="Enter Address 2"
                        value={inputs.address_2 || ""}
                        onChange={onChange}
                      />
                    {/* </div> */}
                    {validator.current.message("address_2", inputs.address_2, "max:120", { className: "small text-danger" })}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="business_name">City</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      placeholder="Enter City"
                      value={inputs.city || ""}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="business_name">State</label>
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      name="state"
                      placeholder="Enter State"
                      value={inputs.state || ""}
                      onChange={onChange}
                    />
                    {validator.current.message("state", inputs.state, "max:50", { className: "small text-danger" })}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="business_name">Zip Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="zipcode"
                      name="zipcode"
                      placeholder="Enter Zip Code"
                      value={inputs.zipcode || ""}
                      onChange={onChange}
                    />
                    {validator.current.message("zipcode", inputs.zipcode, "max:15", { className: "small text-danger" })}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="business_name">Country</label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={debouncedChangeHandler}
                      value={'' || country}
                      isSearchable={true}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.name}
                      placeholder='Enter Country'
                      onChange={handleSelectChange}
                    />
                  </div>
                </div>
              </section>


              <div className="row ">
                <div className="col-md-4 text-center">
                  <div className="p-4 add-new-product border bg-white rounded">
                    <button className="btn btn-secondary text-white" disabled={buttonDisabled}>Save changes</button>
                  </div>
                </div>
              </div>

            </form>

          </div>
        )
        }
      </div >
    </div >
  );
}

