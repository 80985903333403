import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UilUser, UilEnvelope, UilLock } from '@iconscout/react-unicons'

import api from "../../../api";
import lang from '../../../lang/en/User/User';
import { notify } from '../../../scripts/notify'
import { Paths } from "../../../routes"

import PageHeader from '../../../refactoring/layouts/PageHeader';
import Loader from "../../../refactoring/components/global/Loader";
// import PasswordModal from '../../components/PasswordModal';

export default function User() {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(undefined);
  const [inputs, setInputs] = useState({})
  const [errors, setErrors] = useState({})
  const navigate = useNavigate();

  async function fetchData() {
    await  api.user.get()
    .then(response => {
      setUserData(response.data);
      setInputs({ name: response.data.name });
    });
    await setLoading(false);

    if (localStorage.getItem('flag') === 1) {
      notify('error', 'Please change your password');
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event) => {
    event.persist();
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateUserName()) {
      const name = inputs.name;
      api.users.edit({ name })
        .then((response) => {
          notify(response.data.type, response.data.message);
          if (response.data.status !== 1) {
            if (typeof response.data.field_error != 'undefined') {
              setErrors(response.data.field_error);
            }
          }
        })
        .catch((error) => {
          notify('error')
          console.log('ERRR:::', error.response.data.message);
        })
    } else {
      notify('error', 'Form error')
    }
  }

  const handleNewPasswordEdit = (event) => {
    event.preventDefault();
    if (validatePassword()) {
      const { old_password, password, password_confirmation } = inputs;
      api.users.update({ old_password, password, password_confirmation })
        .then((response) => {
          notify(response.data.type, response.data.message);

          if (response.data.status === 1) {
            setInputs({});
            if (localStorage.getItem('flag') === 1) {
              localStorage.setItem('flag', 0);
              setTimeout(() => {
                navigate.push( Paths.Products.pages.ProductsManagement.path );
              }, 5000)
            }
          } else {
            if (typeof response.data.field_error != 'undefined') {
              setErrors(response.data.field_error);
            }
          }
        })

        .catch(error => {
          notify('error')
          console.log('ERRR:::', error);
        })
    } else {
      notify('error', 'Form error')
    }

  }

  const validateUserName = () => {
    let input = inputs.name;
    let isValid = true;
    let errors = {};

    if (!input) {
      isValid = false;
      errors.name = lang.inputErrorMessage.user_name;
    }

    setErrors(errors);

    return isValid;
  }

  const validatePassword = () => {
    let input = inputs;
    let errors = {};
    let isValid = true;

    if (!input.old_password) {
      isValid = false;
      errors.old_password = lang.inputErrorMessage.old_password;
    }

    if (!input.password) {
      isValid = false;
      errors.password = [lang.inputErrorMessage.password];
    }

    if (!input.password_confirmation) {
      isValid = false;
      errors.password_confirmation = lang.inputErrorMessage.password_confirmation;
    }

    if (typeof input.password !== "undefined" && typeof input.password_confirmation !== "undefined") {
      if (input.password !== input.password_confirmation) {
        isValid = false;
        errors.password_confirmation = lang.inputErrorMessage.unmatched_password;
      }
    }

    setErrors(errors);

    return isValid;
  }

  return (
    
    <React.Fragment>
      <div>
        <div className="">
          
            <>
              <section className="bg-white p-5 rounded mb-4 border">
                <PageHeader subtitle="Account Settings" subtext="You can update your name, email & password using the form below." />

                {loading ? ( <Loader /> ) : (
                <form className="" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="name">Name</label>
                      {/* <div className="input-group"> */}
                       
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={inputs.name}
                          className="form-control "
                          onChange={handleChange}
                        />
                      {/* </div> */}
                      <div className="small text-danger mb-3">{errors.name}</div>
                    </div>
      
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      {/* <div className="mb-3 input-group">
                        <span className="input-group-text">
                          <UilEnvelope size="16" className="text-secondary" />
                        </span> */}
                        <input
                          type="text"
                          disabled
                          id="email"
                          defaultValue={userData.email}
                          className="form-control not-allowed bg-white"
                        />
                      {/* </div> */}
                    </div>
                  </div>
                  <input type="submit" value="Save changes" className="btn btn-secondary" />
                </form>
                )}
              </section>
    
              <section className="bg-white p-5 rounded mb-4 border">
                <form className="" onSubmit={handleNewPasswordEdit}>
                  <div className="row">
                    <div className="col-md-6">
                      <h6>Change Password</h6>
                      <div className="small text-gray-500">
                        <p>You can change your password here</p>
                          Password should contain
                        <ul>
                          <li> Alphabet </li>
                          <li> Number </li>
                          <li> Special charater </li>
                          <li> Should be minimum 6 in length </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <div className="input-group"> */}
                       
                        <div className="form-floating">
                          <input
                            type="password"
                            className="form-control "
                            id="old_password"
                            name="old_password"
                            placeholder="Old password"
                            value={inputs.old_password || ''}
                            onChange={handleChange}
                          />
                          <label htmlFor="old_password">Old password</label>
                        </div>
                      {/* </div> */}
                      <div className="small text-danger mb-3">{errors.old_password}</div>
      
                    <div className="mb-3">
                      {/* <div className="input-group"> */}
                        {/* <span className="input-group-text">
                          <UilLock size="16" className="text-secondary" />
                        </span> */}
                        <div className="form-floating">
                          <input
                            type="password"
                            className="form-control "
                            id="password"
                            name="password"
                            placeholder="New password"
                            value={inputs.password || ''}
                            onChange={handleChange}
                          />
                          <label htmlFor="password">New password</label>
                        </div>
                      {/* </div> */}
                      {errors.password &&
                      <span className="small text-danger" dangerouslySetInnerHTML={{ __html: errors.password.join('<br>') }}></span>}
                    </div>
      
                      {/* <div className="input-group"> */}
                        {/* <span className="input-group-text">
                          <UilLock size="16" className="text-secondary" />
                        </span> */}
                        <div className="form-floating">
                          <input
                            type="password"
                            className="form-control"
                            id="password_confirmation"
                            name="password_confirmation"
                            placeholder="Confirm password"
                            value={inputs.password_confirmation || ''}
                            onChange={handleChange}
                          />
                          <label htmlFor="password_confirmation">Confirm password</label>
                        </div>
                      {/* </div> */}
                      <div className="small text-danger mb-3">{errors.password_confirmation}</div>
      
                      <div className="mb-3 text-end">
                        <button type="submit" className="btn btn-secondary px-4">
                          Change account password
                          </button>
                      </div>

                    </div>
                  </div>
                </form>
              </section>
    
              <section className="p-4 border-bottom d-none">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h6>Enable email notifications</h6>
                    <p className="small text-gray-500 m-0">
                      Never miss an important update! Get notifications on your registered email when you’re not around.
                      </p>
                  </div>
                  <div className="col-md-auto">
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked />
                      {/* <label className="form-check-label" for="flexSwitchCheckChecked"></label> */}
                    </div>
                  </div>
                </div>
              </section>
    
            <section className="bg-white p-5 rounded mb-4 border">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h6>Delete your account</h6>
                    <p className="small text-gray-500 m-0">
                      Please contact the admin to delete your account from this business.
                      </p>
                  </div>
                  <div className="col-md-auto not-allowed">
                    <button className="btn btn-outline-danger disabled">
                      Delete account
                      </button>
                  </div>
                </div>
              </section>
            </>
        </div>
      </div>
  </React.Fragment>
  )
}
