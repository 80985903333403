import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import ErrorPage from "../../components/errors/ErrorPage";
import apiClient from "../header";
import { objectToArray } from "../../../scripts/utils";

export function useInvoiceViewQuery(invoiceId) {

  const initialState = {
    data: {
      customer_first_name: "",
      customer_last_name: "",
      telephone: "",
      payment_address: "",
      shipping_address: "",
      payment_city: "",
      shipping_city: "",
      payment_country: "",
      shipping_country: "",
      payment_postcode: "",
      shipping_postcode: "",
      payment_territory: "",
      shipping_territory: "",
      payment_company: "",
      shipping_company: "",
      shipping_method: "",
      shipping_id: "",
      coupon_code: null,
      coupon_amount: null,
      payment_method: "",
      shipping_cost: null,
      invoice_status: null,
      comment: "",
      date: "",
      inclusive_tax_discount: false,
      discount: null,
      discount_type: null,
      payment_details: "",
      invoice_date: moment().format("yyyy-MM-DD"),
      invoice_due_date: moment().add(15, "d").format("yyyy-MM-DD"),
      products: [],
      attachments: [],
    },
  };

  const [viewError, setViewError] = useState();

  function getView(signal, id) {
    const query = apiClient
      .get(`invoices/get`, { params: { id: id }, signal })
      .catch((error) => {
        setViewError(<ErrorPage error={error.response.status} />);
      })
      .then((result) => {
        if (result.data.status === 0) {
          setViewError(
            <>
              <ErrorPage />
            </>
          );
        }
        if (result?.data?.data?.warehouses) {
          result.data.data.warehouses = objectToArray(
            result.data.data.warehouses
          );
        }
        if (result.data.data.total_summary) {
          result.data.data = {
            ...result.data.data,
            ...result.data.data.total_summary,
          };
        }
        return result.data;
      });

    return query;
  }

  var query = useQuery( ["invoice-view", { invoiceId }], ({ signal }) => getView(signal, invoiceId),
    {
      retry: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: invoiceId ? true : false,
      initialData: initialState,
    }
  );

  return [
    query,
    {
      viewError: viewError,
    },
  ];
}
