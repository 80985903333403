
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";

var defaultState = {
  pageNumber: 1,
  limit: 15,
  sort: null,
  searchParams: undefined,
  filters: [],
  linked: undefined,
};

export function useProductMappingListQuery({ id, enabled = true, defaultValue = {} } = {}) {
  defaultState = { ...defaultState, ...defaultValue };  

  const [pageNumber, setPageNumber] = useState(defaultState.pageNumber);
  const [limit, setLimit] = useState(defaultState.limit);
  const [searchParams, setSearchParams] = useState(defaultState.searchParams);

  function getList(signal, id) { 
    const query = apiClient
      .get(`inventory/product/get`, { params: { limit: limit, page: pageNumber, search: searchParams, channel_id: id},
        signal,
      })
      .then((result) => result.data);
    return query;
  }

  function handleSuccess(data) {
    document.dispatchEvent(
      new CustomEvent("channel-product-fetched", { detail: data.data })
    );
  }

  const query = useQuery(
    ["channel-product-list", { id, searchParams, pageNumber, limit }],
    ({ signal }) => getList(signal, id),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
      enabled: id ? true : false,
    }
  );

  return [
    query,
    query.data,
    {
      searchParams: searchParams,
      setSearchParams: setSearchParams,

      limit: limit,
      setLimit: setLimit,

      setPageNumber: setPageNumber,
    },
  ];
}

export function useLinkProductQuery() {
  
  const queryClient = useQueryClient();
  const [success, setSuccess] = useState(false);

  function linkProducts(data) {
    return apiClient
      .post(`channels/product/map`, data )
      .then((result) => result.data);
  }

  function handleSuccess(data) {
    notify(data.type,data.message);
    if (data.status) {
      queryClient.invalidateQueries(["channel-product-list"]);
      setSuccess(true);
    } 
  }

  const mutate = useMutation((data) => linkProducts(data), {
    retry: false,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      'linkProduct': success,
    },
  ];
}





