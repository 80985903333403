import _ from "lodash";
import { useParams, Link } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';

import { Paths } from '../../../routes';
import { useValidator } from "../../utils/validator";
import Loader from '../../components/global/Loader';
import TinyMCE from '../../components/global/TinyMCE';
import { preventNonNumeric } from "../../../scripts/utils";
import PageHeader from "../../layouts/PageHeader";

import { useCategoryViewQuery } from "../../stores/categories/view";
import { useCategoryListQuery } from '../../stores/categories/list';
import { useCategoryCreateQuery } from "../../stores/categories/create";
import { useCategoryUpdateQuery } from "../../stores/categories/update";
import CustomSelectComponent from '../../components/global/CustomSelectComponent';

export default function CategoryCreationForm() {
  const { id } = useParams();
  const initial = {
    name: "",
    stock: "",
  };

  const [inputs, setInputs] = useState(initial)
  const [errors, setErrors] = useState({})
  const [ categoryQuery ] = useCategoryListQuery({ defaultValue: { limit: 'all' } });
  const [categoryCreateQuery, { createErrors }] = useCategoryCreateQuery();
  const [categoryUpdateQuery, { updateErrors }] = useCategoryUpdateQuery();
  const [categoryViewQuery, { viewError }] = useCategoryViewQuery(id);

  const [, forceUpdate] = useState();
  
  const description = useRef(null);
  
  const [validator, { validate }] = useValidator(inputs, errors)

  const handleInputChange = (event) => {
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
    if (errors[event.target.name]) {
      setErrors(prevProps => ({
        ...prevProps,
        [event.target.name]: ''
      }));
    }
  };

  function handleSelectChange(label, value) {
    setInputs(prevProps => ({
       ...prevProps,
      [label]: value
    }));
  }

  function handleEditorChange(event) {
    setInputs(prevProps => ({
       ...prevProps,
       description: event
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()){
      if (!id) {
        categoryCreateQuery.mutate(inputs)
      } else {
        categoryUpdateQuery.mutate(inputs)
      }
    }
    validator.current.showMessages();
    forceUpdate(true)
  }

  useEffect(() => {    
    if (createErrors) {
      setErrors(createErrors)
    }
  }, [createErrors])
  
  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors)
    }
  }, [updateErrors])
  
  useEffect(() => {
    if (categoryViewQuery.isFetched) {
      setInputs(categoryViewQuery.data.data)
    }
  }, [categoryViewQuery.isFetching])

  useEffect(() => {
    setInputs(initial)
  }, [id])


  return (
    <React.Fragment>
      <PageHeader title="Categories" subtitle={!id ? "Add Category" : "Edit Category"} />

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div>
          {categoryQuery.isLoading || categoryViewQuery.isFetching ? (
            <Loader />
          ) : viewError ? ( viewError ) : (
            <>
              <div className="row product-creation-form">
                <div className="col-lg-12 align-self-center">
                  <div className="bg-white p-4 mb-4 border">
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-4">
                          <label className="fw-bold">
                            <span className="text-danger">*</span>Category Name
                          </label>
                          <input
                            placeholder="Enter category name"
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            value={inputs.name}
                            onChange={(e) => handleInputChange(e)}
                            autoFocus
                          />
                          {validate( inputs.name, "required|min:2|max:50,string", { title: "name", onServer: errors.name } )}
                        </div>
                        <div className="mb-4">
                          <label className="fw-bold me-2">
                            Parent Category
                          </label>
                          <small className="text-gray-600">
                            Leave this field blank if this is a parent category
                          </small>
                          <CustomSelectComponent
                            type="1"
                            placeholder="Choose a parent category"
                            isClearable={true}
                            isLoading={categoryQuery.isLoading}
                            closeMenuOnSelect={true}
                            options={(categoryQuery?.data?.data).filter( (data) => data.id !== inputs.id )}
                            value={categoryQuery?.data?.data.filter( (element) => element.id == inputs.parent_id )}
                            onChange={(e) => handleSelectChange("parent_id", e ? e.id : 0) }
                          />
                        </div>
                        <div className="mb-4">
                          <label className="fw-bold">
                            <span className="text-danger">*</span>Stock
                          </label>
                          <div>
                            <input
                              placeholder="Enter category stock"
                              type="number"
                              id="stock"
                              min="0"
                              name="stock"
                              className="form-control"
                              value={inputs.stock}
                              onChange={(e) => handleInputChange(e)}
                              onKeyPress={(e) => preventNonNumeric(e)}
                            />
                            {validate(inputs.stock, "required|min:0,num", { title: "stock", })}
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <label className="fw-bold me-2 mb-1">Description</label>
                        <small className="text-gray-600">
                          (500 characters only)
                        </small>
                        <TinyMCE
                          editorRef={description}
                          handleInputChange={handleEditorChange}
                          value={inputs.description}
                        />
                        {validate(inputs.description, "max:500,string", { title: "description", })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="p-4 add-new-product bg-white text-center border">
                    <button type="submit" className="btn btn-secondary me-3">
                      {!id ? "Add category" : "Save changes"}
                    </button>
                    <Link to={Paths.Category.pages.index.path} className="btn btn-outline-primary text-decoration-none" >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}


