import React, { useState, useEffect } from "react";

import PageHeader from "../../../layouts/PageHeader";
import Loader from "../../../components/global/Loader";
import { useValidator } from "../../../utils/validator";

import {
  useUserUpdateQuery,
  useUserViewQuery,
} from "../../../stores/setting/accountSetting";

export default function Form() {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);
  const [validator, { validate }] = useValidator(errors);
  const [queryData] = useUserViewQuery();
  const [userUpdate, { createErrors }] = useUserUpdateQuery();

  const handleChange = (event) => {
    event.persist();
    setErrors({});
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      const name = inputs.name;
      userUpdate.mutate({ name });
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (queryData.isFetched) {
      setInputs(queryData.data);
    }
  }, [queryData.data]);

  return (
    <React.Fragment>
      <>
        <section className="bg-white p-5 rounded mb-4 border">
          <PageHeader
            subtitle="Account Settings"
            subtext="You can update your name, email & password using the form below."
          />

          {queryData.isFetching ? (
            <Loader />
          ) : (
            <form className="" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="name">Name</label>

                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={inputs.name || ""}
                    className="form-control "
                    onChange={handleChange}
                  />
                  {validate(inputs.name, "required", {
                    title: "name",
                    onServer: errors["name"],
                  })}
                </div>

                <div className="col-md-6">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    disabled
                    id="email"
                    value={inputs.email || ""}
                    className="form-control not-allowed bg-white"
                  />
                </div>
              </div>
              <input
                type="submit"
                value="Save changes"
                className="btn btn-secondary"
              />
            </form>
          )}
        </section>
      </>
    </React.Fragment>
  );
}
