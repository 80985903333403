import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { objectToFormData } from "../../../scripts/utils";
import { Paths } from "../../../routes";
import { toast } from "react-toastify";


  var toastify;


function updateNexmo(data) {
  return apiClient.post(`configs/nexmo`, data).then((result) => result.data);
}

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

export function useUpdateVonageQuery() {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  function handleSuccess(data) {
    if (data.status) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });      
    queryClient.invalidateQueries(["notificationSetting-view"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((data) => updateNexmo(data), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}
