
import React from "react";
import _ from 'lodash';
import Skeleton from "react-loading-skeleton";

import { abbreviateNumber } from "../../../scripts/utils";
import CardComponent from "../../layouts/CardLayout";

export default function TotalSales(props) {

  return (
    <>
      <CardComponent filter_key={props.filter_key} title={props.title} footer={props.footer}>
        <div className='d-flex justify-content-between'>
          <p className=' '>Quantity </p>
          {(props.isLoading) ? (
            <>
              <Skeleton height={20} width={50} />
            </>
          ) : (
            <div className=' '>
              <span className="h4 fw-bold">{props.data ? (<>{(props.data)}</>) : '-'}
              </span>
            </div>
          )}
        </div>
        <div className='d-flex justify-content-between'>
          <p className=''>Sales in amount </p>
          {(props.isLoading) ? (
            <> <Skeleton height={20} width={50} /> </>
          ) : (
            <div>
              <span className="h4 fw-bold"> {props.value ? (<>{abbreviateNumber(props.value, props.withCurrency)}</>) : '-'}</span>
            </div>
          )}
        </div>
      </CardComponent>
    </>
  );
}
