const tokenName = 'token'
const tokenExpiryName = 'tokenExpiry'
const timeNow = 'timeNow'
const userTokenName = 'userData'
const business_id = 'business_id'
const permissions = 'permissions'
const setting = 'settings'
const sortPages = 'sort'

const token = {
  get: param => localStorage.getItem(tokenName, param),
  set: (param) => {
		// console.log('param', param)
    localStorage.setItem(tokenName, param.access_token)
    localStorage.setItem(tokenExpiryName, param.expires_in)
    localStorage.setItem(timeNow, Date.now())
    localStorage.setItem(business_id, param.business_id)
		localStorage.setItem(userTokenName, JSON.stringify(param.user))
		localStorage.setItem(permissions, param.user.permissions)
  },
  logout: () => {
    localStorage.removeItem(tokenName)
    localStorage.removeItem(setting)
    localStorage.removeItem(sortPages)
    localStorage.removeItem(tokenExpiryName)
    localStorage.removeItem(timeNow)
    localStorage.removeItem(business_id)
    localStorage.removeItem(userTokenName)
    localStorage.removeItem(permissions)
  }
}

const userPermissions = {
  get: param => localStorage.getItem(permissions),
}

const business = {
  get: param => localStorage.getItem(business_id, param),
}

const userData = {
  get: param => localStorage.getItem(userTokenName),
  set: (param) => localStorage.setItem(userTokenName, param),
}

const settings = {
  get: param => localStorage.getItem('settings'),
  set: (params) => {
    localStorage.setItem('settings', JSON.stringify(params))
  },
}

const sort = {
  get: param => JSON.parse(localStorage.getItem('sort'))?? '',
  set: (params) => {
    localStorage.setItem('sort', JSON.stringify(params))
  },
}

const staticDashboardData = {
  get: param => localStorage.getItem('staticDashboardData'),
  set: (param) => {
    localStorage.setItem('staticDashboardData', param)
  },
  clear: (param) => {
    localStorage.removeItem('staticDashboardData')
    localStorage.removeItem('expiryTime')
  }
}

const filteredDashboardData = {
  get: param => localStorage.getItem('filteredDashboardData'),
  set: (param) => {
    localStorage.setItem('filteredDashboardData', param)
  },
  clear: (param) => {
    localStorage.removeItem('filteredDashboardData')
    localStorage.removeItem('expiryTime')
  }
}

export default {
  token,
  userData,
  settings,
  sort,
  staticDashboardData,
  filteredDashboardData,
	business,
	userPermissions
}
