import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { UilPaperclip, UilQuestionCircle } from "@iconscout/react-unicons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";

import lang from "../../../../lang/en/Theme/Colors";
import { useValidator } from "../../../utils/validator";
import { checkFloat } from "../../../../scripts/utils";
import { Paths } from "../../../../routes";

import { MetaDataContext } from "../../../../web/context/metaData";
import { useMetaData } from "../../../stores/products/metaData";
import { useProductCreateQuery } from "../../../stores/products/create";
import { useProductUpdateQuery } from "../../../stores/products/update";
import { useProductViewQuery } from "../../../stores/products/view";
import SelectProducts from "../../../components/product/SelectProducts";

import PageHeader from "../../../layouts/PageHeader";
import CustomSelectComponent from "../../../components/global/CustomSelectComponent";
import ImageUpload from "../../../components/global/ImageUpload";


export default function CompositeProduct() {
  
  const { id } = useParams();
  const {data: preferences} = useContext(MetaDataContext);

  const initial = {
    product_name: '',
    composite_products: [{}],
    sku: '',
    mpn: '',
    upc: '',
    isbn: '',
    ean: '',
    selling_price: '',
    cost_price: '',
    tax_class: null,
    type: 2,
    product_image: null,
    existing_image: null
  };

  const metaData = useMetaData()

  const [productCreateQuery, { createErrors }] = useProductCreateQuery();
  const [productUpdateQuery, { updateErrors }] = useProductUpdateQuery();
  const [productViewQuery, { viewError }] = useProductViewQuery(id);
  

  const [forceUpdate, setForceUpdate] = useState();

  const [inputs, setInputs] = useState(initial)
  const [errors, setErrors] = useState({})

  const [compositeProduct, setCompositeProduct] = useState([{}])

  const [validator, { validate }] = useValidator(errors)
  const selectProductValidator = useValidator(errors)
  const fileValidator = useValidator(errors)

  const handleInputChange = (label, value) => {
    setInputs(prevProps => ({
      ...prevProps,
      [label]: value
    }));
    if (errors[label]) {
      setErrors(prevProps => ({
        ...prevProps,
        [label]: ''
      }));
    }
  };

  const handleFile = (file) => {
    if (file instanceof File) {
      setInputs((prevProps) => ({
        ...prevProps,
        product_image: file,
      }));
    } else if (_.isNull(file)) {
      setInputs((prevProps) => ({
        ...prevProps,
        existing_image: null,
        product_image: null,
      }));
    }
  };

  
  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors)
    }
  }, [createErrors])
  
  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors)
    }
  }, [updateErrors])


  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid() && selectProductValidator[0].current.allValid() && fileValidator[0].current.allValid()) {
      if (!id) {
        productCreateQuery.mutate(inputs)
      } else {
        productUpdateQuery.mutate(inputs)
      }
    } else {
      validator.current.showMessages();
      selectProductValidator[0].current.showMessages();
      fileValidator[0].current.showMessages();
      setForceUpdate(true);
    }
  }

  function onSelectProduct(data) {
    setInputs(prevProps => ({
      ...prevProps,
      'composite_products': data.map((value, index) => (!_.isEmpty(value) && {'product_id': value.product_id, 'quantity': value.quantity}))
    }));
  }  
  
  useEffect(() => {
    if (productViewQuery.isFetched) {
      productViewQuery.data.data.type = 2;
      setInputs(productViewQuery.data.data)
      setCompositeProduct([...productViewQuery.data.data.composite_products, {}])
    }
  }, [productViewQuery.data])
  

  return (
    <div>

      <div className="d-flex justify-content-between">
        <PageHeader title="products" subtitle= {`${!id ? 'Add' : 'Edit'} Composite Product `}/>
        <div className="mb-4 d-flex justify-content-end align-items-center">
          <Link to={Paths.Product.pages.create.path} className="btn btn-secondary"> Add product </Link>
        </div>
      </div>

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="row">
          <div className="col-lg-7">
            <div className="bg-white p-4 mb-4 border">
              <div className='row'>
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div className="fw-bold mb-2">Product Details</div>
                    <label className="fw-bold mb-2">(<span className="text-danger">*</span>Fields marked with are mandatory)</label>
                  </div>
                </div>
                <div className='col-12'>
                  <div className="mb-3">
                    <label className="fw-bold">
                      <span className="text-danger">*</span>Product Name
                    </label>
                    <input
                      autoFocus
                      type="text"
                      id="product_name"
                      name="product_name"
                      className="form-control "
                      value={inputs?.product_name || ''}
                      placeholder="Enter product name"
                      onChange={({ target }) => handleInputChange(target.name, target.value)}
                      maxLength="200"
                    />
                    {validate(inputs.product_name, 'required|min:2', {title: 'product_name'})}
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                  <SelectProducts key={compositeProduct} defaultValue={compositeProduct} onChange={onSelectProduct} validator={selectProductValidator} forceUpdate={forceUpdate}/>
                    {validate(inputs.composite_products, 'nested', {title: 'product'})}
                  </div>
                </div>
                <div className='col-12'>
                  <div className="mb-3">
                    <label className="fw-bold">
                      <span className="text-danger">*</span>SKU
                    </label>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Stock Keeping Unit</Tooltip>}>
                      <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                    </OverlayTrigger>
                    <input
                      type="text"
                      className="form-control"
                      id="sku"
                      name="sku"
                      value={inputs?.sku || ''}
                      placeholder="  Enter Product SKU"
                      onChange={({ target }) => handleInputChange(target.name, target.value)}
                    />
                    {validate(inputs.sku, 'required|min:2', {title: 'sku', onServer: errors['sku']})}
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label className="fw-bold">MPN</label>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Manufacturing Part Number unambiguously identifies a part design</Tooltip>}>
                      <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor" />}</span>
                    </OverlayTrigger>
                    <input
                      type="text"
                      id="mpn"
                      name="mpn"
                      className="form-control"
                      value={inputs?.mpn || ''}
                      onChange={({ target }) => handleInputChange(target.name, target.value)}
                    />
                    {validate(inputs.mpn, 'min:1|max:70', {title: 'mpn'})}
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label className="fw-bold">UPC</label>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Twelve digit unique number associated with the barcode (Universal Product Code)</Tooltip>}>
                      <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor" />}</span>
                    </OverlayTrigger>
                    <input
                      type="text"
                      id="upc"
                      name="upc"
                      className="form-control"
                      value={inputs?.upc || ''}
                      onChange={({ target }) => handleInputChange(target.name, target.value)}
                    />
                    {validate(inputs.upc, 'size:12', {title: 'upc'})}
                  </div>
                </div>
                <div className="col-6">
                  <div className="">
                    <label className="fw-bold">ISBN</label>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Thirteen digit unique commercial book identifier (International Standard Book Number)</Tooltip>}>
                      <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor" />}</span>
                    </OverlayTrigger>
                    <input
                      type="text"
                      id="isbn"
                      name="isbn"
                      className="form-control"
                      value={inputs?.isbn || ''}
                      onChange={({ target }) => handleInputChange(target.name, target.value)}
                    />
                    {validate(inputs.isbn, 'size:13', {title: 'isbn'})}
                  </div>
                </div>
                <div className="col-6">
                  <div className="">
                    <label className="fw-bold">EAN</label>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Thirteen digit unique number (International Article Number)</Tooltip>}>
                      <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor" />}</span>
                    </OverlayTrigger>
                    <input
                      type="text"
                      id="ean"
                      name="ean"
                      className="form-control"
                      value={inputs?.ean || ''}
                      onChange={({ target }) => handleInputChange(target.name, target.value)}
                    />                    
                    {validate(inputs.ean, 'size:13', {title: 'ean'})}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 mb-4 border">
              <div className="row">
                <div className="col-md-6">
                  <div className="fw-bold mb-2">Sales Information</div>
                  <div className="mb-3">
                    <label className="fw-bold">
                      <span className="text-danger">*</span>Selling Price</label>
                    <input
                      type="number"
                      id="selling_price"
                      name="selling_price"
                      className="form-control"
                      placeholder={preferences.currency_symbol}
                      min="0"
                      step="any"
                      value={inputs?.selling_price || ''}
                      onChange={({ target }) => handleInputChange(target.name, target.value)}
                      onKeyPress={e => checkFloat(e)}
                    />
                    {validate(inputs.selling_price, 'required|numeric|min:0,num', {title: 'selling_price'})}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="fw-bold mb-2">Purchase Information</div>
                  <div className="mb-3">
                    <label className="fw-bold">
                      <span className="text-danger">*</span>Cost Price</label>
                    <input
                      type="number"
                      id="cost_price"
                      name="cost_price"
                      className="form-control"
                      placeholder={preferences.currency_symbol}
                      min="0"
                      step="any"
                      value={inputs?.cost_price || ''}
                      onChange={({ target }) => handleInputChange(target.name, target.value)}
                      onKeyPress={e => checkFloat(e)}
                    />
                    {validate(inputs.cost_price, 'required|numeric|min:0,num', {title: 'cost_price'})}
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="fw-bold">
                      <span className="text-danger">*</span>Product Tax
                    </label>
                    <div>
                      <CustomSelectComponent
                        type='2'
                        id="tax_class"
                        placeholder="Select tax type"
                        closeMenuOnSelect={true}
                        options={metaData?.data?.taxes}
                        getOptionLabel={option => option.tax_name}
                        value={metaData?.data?.taxes.find(tax => tax.id == inputs.tax_class)}
                        onChange={({ id }) => handleInputChange('tax_class', id)}
                      />
                      {validate(inputs.tax_class, 'required', {title: 'tax_class'})}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-lg-5">
            <div className='p-4 mb-4 bg-white border'>
              <div className="row">
                <div className="col-lg-12">
                  <div className="fw-bold mt-2 d-flex align-items-center mb-1"> {<UilPaperclip size="14" color={lang.colors.secondary} className="me-1" />} Add a product image</div>
                </div>
              </div>
            <ImageUpload value={inputs.existing_image} onChange={(file) => handleFile(file)} validator={fileValidator}/>
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className="col-md-6">
            <div className='bg-white p-4 text-center border'>
              <div className="row">
                <div className='col-md'>
                  <button type="submit" className="btn btn-secondary">{`${!id ? 'Add' : 'Edit' } composite product`}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>
  )
}