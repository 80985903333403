 import React, { useState, useEffect,useRef, useContext } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link, useParams} from "react-router-dom";
import { UilPaperclip, UilQuestionCircle } from "@iconscout/react-unicons";
import _ from "lodash";
import CreatableSelect from 'react-select/creatable';

import CustomSelectComponent from "../../components/global/CustomSelectComponent";

import api from "../../../api";
import lang from "../../../lang/en/Theme/Colors";
import { Paths } from "../../../routes";
import { preventNonNumeric, checkFloat } from "../../../scripts/utils";

import PageHeader from "../../layouts/PageHeader";
import TinyMCE from "../../components/global/TinyMCE";
import CustomSelect from "../../components/global/CustomSelect";
import Loader from "../../components/global/Loader";
import { MetaDataContext } from "../../../web/context/metaData";
import ManufactureCreationForm from "../settings/manufacturer/Form";
import WarehouseCreationForm from "../settings/warehouse/Form";

import { useMetaData } from "../../stores/products/metaData";
import { useProductCreateQuery } from "../../stores/products/create";
import { useQueryClient } from "@tanstack/react-query";
import { useValidator } from "../../utils/validator";
import { useProductViewQuery } from "../../stores/products/view";
import { useProductUpdateQuery } from "../../stores/products/update";
import ModalComponent from "../../components/common/ModalComponent";
import ImageUpload from "../../components/global/ImageUpload";


export default function ProductCreationForm() {

  const { id } = useParams();

  const initial = {
    product_name: "",
    product_model: "",
    sku: "",
    tax_class: null,
    cost_price: "",
    selling_price: "",
    mpn: "",
    upc: "",
    isbn: "",
    ean: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    categories: "",
    manufacturer: "",
    warehouses: [],
    description: "",
    product_image: null,
    existing_image: null
  };

  const [productCreateQuery, { createErrors }] = useProductCreateQuery();
  const [productUpdateQuery, { updateErrors }] = useProductUpdateQuery();
  const [productViewQuery, { viewError }] = useProductViewQuery(id);

  const refName = useRef();
  const description = useRef(null);
  const {data: preferences} = useContext(MetaDataContext);

  const [, forceUpdate] = useState();

  const [modelOpen, setModelOpen] = useState({
    manufacturer: false,
    warehouse: false
  });

  const [inputs, setInputs] = useState(initial)
  const [errors, setErrors] = useState({})

  const queryClient = useQueryClient()
  const metaData = useMetaData()

  const [validator, { validate }] = useValidator(errors)
  const fileValidator = useValidator(errors)


  const handleFile = (file) => {
    if (file instanceof File) {
      setInputs((prevProps) => ({
        ...prevProps,
        product_image: file,
      }));
    } else if (_.isNull(file)) {
      setInputs((prevProps) => ({
        ...prevProps,
        existing_image: null,
        product_image: null,
      }));
    }
  };


  const handleEditorChange = (event, id = false) => {
    setInputs(prevProps => ({
      ...prevProps,
      description: event
    }));
  }

  const handleInputChange = (event) => {
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
    if (errors[event.target.name]) {
      setErrors(prevProps => ({
        ...prevProps,
        [event.target.name]: ''
      }));
    }
  };

  function handleWarehouseInput(event, id) {
    var inputValue = inputs.warehouses;
    var index = _.findIndex(inputValue, {'id': id})
    index = index === -1 ? inputValue.length : index; 
    var beforeValue = 0;
    if (event.target.name === 'stock') {
      beforeValue = inputValue[index] ? inputValue[index]['warning_threshold'] : '';
      inputValue[index] = { 'id': id, 'stock': event.target.value, 'warning_threshold': beforeValue };
    }
    if (event.target.name === 'warning_threshold') {
      beforeValue = inputValue[index] ? inputValue[index]['stock'] : '';
      inputValue[index] = { 'id': id, 'stock': beforeValue, 'warning_threshold': event.target.value };
    }
    if (beforeValue === "" && event.target.value === "") {
      inputValue.splice(index, 1);
    }

    setInputs(prevProps => ({
      ...prevProps,
      'warehouses': inputValue
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid() && fileValidator[0].current.allValid()){
      if (!id) {
        productCreateQuery.mutate(inputs)
      } else {
        productUpdateQuery.mutate(inputs)
      }
    }
    validator.current.showMessages();
    fileValidator[0].current.showMessages();
    forceUpdate(true)
  }

  function handleSelectChange(label, value) {
    setInputs(prevProps => ({
      ...prevProps,
      [label]: value
    }));
  }

  function handleMultiSelectChange(label, data) {
    var ids = data.map(value => {
      return value.id
    });
    setInputs(prevProps => ({
      ...prevProps,
      [label]: ids
    }));
  }

  function handleSelect(value, action) {
    if (action === "select-option") {
      setInputs(prevProps => ({
        ...prevProps,
        'manufacturer': value.id
      }));
    }
    if (action === "create-option") {
      setInputs(prevProps => ({
        ...prevProps,
        id: "",
        name: value.value,
      }))
      openModel('manufacturer')
    }
  }

  function fetchData() {
    queryClient.invalidateQueries(['product-metadata']);
  }

  function openModel(modal) {
    setModelOpen(prevProps => ({
      ...prevProps,
      [modal]: true
    }));
  }

  function closeModel() {
    setModelOpen({
      manufacturers: false,
      warehouses: false
    });
  }

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors)
    }
  }, [createErrors])

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors)
    }
  }, [updateErrors])

  useEffect(() => {
    if (productViewQuery.isFetched) {
      setInputs(productViewQuery.data.data)
    }
  }, [productViewQuery.isFetching])

  useEffect(() => {
    setInputs(initial)
  }, [id])


  return (

    <>

      <PageHeader title="products" subtitle={!id ? 'Add product' : 'Edit product'} />

      {/* <div className="text-end mb-4">
        <Link
          to={Paths.Product.pages.CompositeCreation.path}
          className="text-decoration-none"
        >
        </Link>
      </div> */}

      <form onSubmit={handleSubmit} encType="multipart/form-data" >
        <div>
          {metaData.isLoading || productViewQuery.isFetching ? (
            (<Loader />)
          ) : (viewError) ? viewError : (
              <>
                <div className="row product-creation-form" key={id}>
                  <div className="col-lg-7 align-self-center">
                    <div className='bg-white p-4 mb-4 border'>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div className="fw-bold mb-2">Product Details</div>
                            <label className="fw-bold mb-2">(<span className="text-danger">*</span>Fields marked with are mandatory)</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="fw-bold">
                              <span className="text-danger">*</span>Product Name</label>
                            <input
                              type="text"
                              id="product_name"
                              name="product_name"
                              className="form-control "
                              placeholder=" Enter product name"
                              value={inputs?.product_name}
                              autoFocus
                              maxLength="200"
                              onChange={handleInputChange}
                            />
                            {validate(inputs.product_name, 'required', {title: 'product_name'})}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="fw-bold">Description</label>
                            <TinyMCE editorRef={description} handleInputChange={handleEditorChange} value={inputs.description} />
                            {validate(inputs.description, 'max:500', {title: 'description'})}
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="mb-3">
                            <label className="fw-bold">Manufacturer</label>
                            <CustomSelectComponent
                              type='2'
                              options={metaData?.data?.manufacturer}
                              placeholder='Add manufacturer'
                              value={metaData?.data?.manufacturer.filter(element => element.id === inputs.manufacturer)}
                              onChange={(option, { action }) => { handleSelect(option, action) }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="fw-bold"><span className="text-danger">*</span>SKU</label>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Stock Keeping Unit</Tooltip>}>
                              <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                            </OverlayTrigger>
                            <input
                              placeholder="Enter product SKU"
                              type="text"
                              id="sku"
                              name="sku"
                              className="form-control"
                              value={inputs?.sku}
                              onChange={handleInputChange}
                              maxLength="30"
                            />
                            {validate(inputs.sku, 'required|min:2', {title: 'sku', onServer: errors['sku']})}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="fw-bold">Model</label>
                            <input
                              placeholder="Enter product model"
                              type="text"
                              id="product_model"
                              name="product_model"
                              className="form-control"
                              value={inputs?.product_model}
                              onChange={handleInputChange}
                              maxLength="20"
                            />
                            {validate(inputs.product_model, 'min:2|max:20', {title: 'product model'})}
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <label className="fw-bold"><span className="text-danger">*</span>Units</label>
                          <OverlayTrigger placement="right" overlay={(<Tooltip id="tooltip-top"> The item will be measured in terms of this units (eg: kg,dozen)</Tooltip>)}>
                            <span><UilQuestionCircle size="16" className="text-primary ms-1 mb-1" /></span>
                          </OverlayTrigger>
                          <CustomSelectComponent
                            id="units"
                            options={metaData?.data?.units}
                            type='1'
                            closeMenuOnSelect={true}
                            isLoading={metaData.isLoading}
                            placeholder="Select units"
                            value={metaData?.data?.units.filter(element => element.id === inputs.unit_id)}
                            onChange={(e) => handleSelectChange('unit_id', e.id)}
                          />
                          {validate(inputs.unit_id, 'required', {title: 'unit'})}
                        </div>
                        <div className="col-md-6 ">
                          <div className="mb-3">
                            <label className="fw-bold">MPN</label>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Manufacturing Part Number unambiguously identifies a part design</Tooltip>}>
                              <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                            </OverlayTrigger>
                            <input
                              type="text"
                              id="mpn"
                              name="mpn"
                              className="form-control"
                              value={inputs?.mpn}
                              onChange={handleInputChange}
                            />
                            {validate(inputs.mpn, 'min:1|max:70', {title: 'mpn'})}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="fw-bold">UPC</label>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Twelve digit unique number associated with the barcode (Universal Product Code)</Tooltip>}>
                              <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                            </OverlayTrigger>
                            <input
                              type="text"
                              id="upc"
                              name="upc"
                              className="form-control"
                              value={inputs.upc || ""}
                              onChange={handleInputChange}
                            />
                            {validate(inputs.upc, 'size:12', {title: 'upc'})}
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="mb-3">
                            <label className="fw-bold">ISBN</label>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Thirteen digit unique commercial book identifier (International Standard Book Number)</Tooltip>}>
                              <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                            </OverlayTrigger>
                            <input
                              type="text"
                              id="isbn"
                              name="isbn"
                              className="form-control"
                              value={inputs.isbn || ""}
                              onChange={handleInputChange}
                            />
                            {validate(inputs.isbn, 'size:13', {title: 'isbn'})}
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="mb-3">
                            <label className="fw-bold">EAN</label>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top"> Thirteen digit unique number (International Article Number)</Tooltip>}>
                              <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                            </OverlayTrigger>
                            <input
                              type="text"
                              id="ean"
                              name="ean"
                              className="form-control"
                              value={inputs.ean || ""}
                              onChange={handleInputChange}
                            />
                            {validate(inputs.ean, 'size:13', {title: 'ean'})}
                          </div>
                        </div>
                        <hr className="mb-3" />
                        <div className="col-md-6">
                          <div className="">
                            <label className="fw-bold">Dimensions</label>
                            <span className="text-light">
                              <small> (Length X Width X Height) </small>
                            </span>
                            <div className="input-group">
                              <input
                                type="number"
                                name="length"
                                min="0"
                                className="form-control border-end-0 "
                                value={inputs.length || ""}
                                onKeyPress={e => checkFloat(e)}
                                onChange={handleInputChange}
                              />
                              <span className="input-group-text border-end-0 border-start-0 text-gray-400">
                                X
                              </span>
                              <input
                                type="number"
                                name="width"
                                min="0"
                                value={inputs.width || ""}
                                className="form-control border-end-0 border-start-0"
                                onChange={handleInputChange}
                                onKeyPress={e => checkFloat(e)}
                              />
                              <span className="input-group-text border-end-0 border-start-0 text-gray-400">
                                X
                              </span>
                              <input
                                type="number"
                                name="height"
                                min="0"
                                value={inputs.height || ""}
                                className="form-control border-start-0"
                                onKeyPress={e => checkFloat(e)}
                                onChange={handleInputChange}
                              />
                            </div>
                            {validate(inputs.length, 'numeric|min:0,num', {title: 'length'})} 
                            {validate(inputs.width, 'numeric|min:0,num', {title: 'width'})} 
                            {validate(inputs.height, 'numeric|min:0,num', {title: 'height'})}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="weight-section">
                            <label className="fw-bold">Weight</label>
                            <span className="text-light">
                              <small> (Used to calculate shipping rates) </small>
                            </span>
                            <div className="">
                              <input
                                type="number"
                                id="weight"
                                name="weight"
                                className="form-control"
                                min="0"
                                placeholder="Enter weight"
                                value={inputs.weight || ""}
                                onChange={handleInputChange}
                                onKeyPress={e => checkFloat(e)}
                              />
                              {validate(inputs.weight, 'numeric|min:0,num', {title: 'weight'})}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-4 mb-4 border">
                      <div className="row">
                        <div className="col-md-12">
                          <span className="fw-bold">Inventory</span>
                          <div className="row my-3">
                            <div className="col-md-6">
                              <label className="fw-bold">Warehouse Name</label>
                            </div>
                            <div className="col-md-3">
                              <label className="fw-bold"><span className="text-danger">*</span>Stock</label>
                            </div>
                            <div className="col-md-3">
                              <label className="fw-bold"><span className="text-danger">*</span>Stock Warning</label>
                            </div>
                          </div>
                          <div className="warehouses">
                            {metaData?.data?.warehouses.map(warehouse => (
                              <div className="row mb-3 warehouse" key={warehouse.id}>
                                <div className="col-md-6">
                                  <div className="mt-1">
                                    {warehouse.warehouse_name}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="number"
                                    id="stock"
                                    name="stock"
                                    min="0"
                                    className="form-control"
                                    value={_.find(inputs.warehouses, ['id', warehouse.id])?.stock || ''}
                                    onKeyPress={e => preventNonNumeric(e)}
                                    onChange={event => handleWarehouseInput(event, warehouse.id)}
                                  />
                                  {(_.findIndex(inputs.warehouses, ['id', warehouse.id]) != -1) && validate(_.find(inputs.warehouses, ['id', warehouse.id])?.stock, 'required', {title: 'stock'}) }
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="number"
                                    id={warehouse.id}
                                    name="warning_threshold"
                                    min="0"
                                    max={_.find(inputs.warehouses, ['id', warehouse.id])?.stock ?? 0}
                                    className="form-control"
                                    value={_.find(inputs.warehouses, ['id', warehouse.id])?.warning_threshold || ''}
                                    onKeyPress={e => preventNonNumeric(e)}
                                    onChange={event => handleWarehouseInput(event, warehouse.id)}
                                  />
                                  {(_.findIndex(inputs.warehouses, ['id', warehouse.id]) != -1) && validate(_.find(inputs.warehouses, ['id', warehouse.id])?.warning_threshold, 'required', {title: 'warning_threshold'}) }
                                </div>
                              </div>
                            ))}
                          </div>
                          {validate(inputs.warehouses.filter(e => e), 'required|min:1,array', {title: 'warehouses'})}
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <a onClick={() => openModel('warehouse')} className="text-secondary fw-bold cursor" >
                                Add a new warehouse
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className='p-4 mb-4 bg-white border'>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="fw-bold mt-2 d-flex align-items-center mb-1"> {<UilPaperclip size="14" color={lang.colors.secondary} className="me-1" />} Add a product image</div>
                        </div>
                      </div>
                      <ImageUpload value={inputs.existing_image} onChange={(file) => handleFile(file)} validator={fileValidator}/>
                    </div>
                    <div className='p-4 mb-4 bg-white border'>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="fw-bold mb-2">Sales Information</div>
                          <div className="mb-3">
                            <label className="fw-bold"><span className="text-danger">*</span>Selling Price</label>
                            <input
                              type="number"
                              id="selling_price"
                              name="selling_price"
                              className="form-control"
                              placeholder={preferences.currency_symbol}
                              min="0"
                              step="any"
                              value={inputs.selling_price || ""}
                              onChange={handleInputChange}
                              onKeyPress={e => checkFloat(e)}
                            />
                            {validate(inputs.selling_price, 'required|numeric|min:0,num', {title: 'selling_price'})}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="fw-bold mb-2">Purchase Information</div>
                          <div className="">
                            <label className="fw-bold"><span className="text-danger">*</span>Cost Price</label>
                            <input
                              type="number"
                              id="cost_price"
                              name="cost_price"
                              className="form-control "
                              placeholder={preferences.currency_symbol}
                              min="0"
                              step="any"
                              max={inputs.selling_price}
                              value={inputs.cost_price || ""}
                              onChange={handleInputChange}
                              onKeyPress={e => checkFloat(e)}
                            />
                            {validate(inputs.cost_price, 'required|numeric|min:0,num', {title: 'cost_price'})}
                          </div>
                        </div>
                        <div className="col-12">
                          <label className="fw-bold">
                            <span className="text-danger">*</span>Product Tax</label>
                          <div>

                            <CustomSelectComponent
                              id="tax_class"
                              type='2'
                              placeholder="Select Tax Class"
                              options={metaData?.data?.taxes}
                              getOptionLabel={option => option.tax_name}
                              value={metaData?.data?.taxes.find(tax => tax.id == inputs.tax_class) || ''}
                              onChange={(e) => handleSelectChange('tax_class', e.id)}
                              />
                            {validate(inputs.tax_class, 'required', {title: 'tax'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-4 mb-4 border">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-between mb-2">
                          <label className="fw-bold">
                            <span className="text-danger">*</span>Product Category
                          </label>
                          <span>
                            <Link to={Paths.Category.pages.create.path} className=" text-secondary fw-bold text-decoration" >
                              Create a category
                            </Link>
                          </span>
                        </div>
                        <div className="col">
                          <div>
                            <CustomSelectComponent
                              type='1'
                              id="categories"
                              placeholder="Select a product categories"
                              options={metaData?.data?.categories}
                              isMulti={true}
                              value={metaData?.data?.categories.filter(element => _.includes(inputs.categories, element.id))}
                              onChange={(e) => handleMultiSelectChange('categories', e)}
                            />
                            {validate(inputs.categories, 'required', {title: 'category'})}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row mx-0 ">
                      <div className="col-4 bg-white p-0 border">
                        <div className="p-4 add-new-product text-center">
                          <button type="submit" className="btn btn-secondary me-3" disabled={productCreateQuery.isLoading || productUpdateQuery.isLoading} onClick={handleSubmit} >
                            {_.isEmpty(id) ? 'Add ' : 'Update '}product
                          </button>
                          <Link to={Paths.Product.pages.index.path} className="btn btn-outline-primary text-decoration-none" >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          )}
        </div>
      </form>
      <ModalComponent
        show={modelOpen.warehouse}
        onHide={closeModel}
        title='Add Warehouse'
        body={<WarehouseCreationForm callback={fetchData} closeModal={closeModel} />}
      />

      <ModalComponent
        show={modelOpen.manufacturer}
        onEntered={() => refName.current.focus()}
        onHide={() => closeModel('manufacturer')}
        title='Create a new manufacturer'
        body={<ManufactureCreationForm callback={fetchData} closeModal={closeModel} inputs={inputs} refName={refName} />}
      />
    </>
    
  );
}
