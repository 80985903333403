import React, { useState, useEffect } from "react";
import Select from "react-select";
import _ from "lodash";

import { useValidator } from "../../../utils/validator";
import { useGroupCreateQuery, useGroupUpdateQuery, usePermissionListQuery, } from "../../../stores/setting/group";

export default function Form(props) {

  const [permissions, setPermissions] = useState([]);
  const [errors, setErrors] = useState({});

  const [, forceUpdate] = useState(false);
  const [validator, { validate }] = useValidator(errors);

  const [groupCreateQuery, { createErrors }] = useGroupCreateQuery();
  const [groupUpdateQuery, { updateErrors }] = useGroupUpdateQuery();
  const permissionList = usePermissionListQuery();

  const [selectedData, setSelectData] = useState({
    permissions: null,
  });

  const [inputs, setInputs] = useState({
    group_id: (props.inputs && props.inputs.groupID) || null,
    group_name: (props.inputs && props.inputs.groupName) || "",
    permissions: "",
  });

  const handleInputChange = (event) => {
    event.persist();
    setErrors({})
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const onSelectChange = (e) => {
    setSelectData((prevProps) => ({
      ...prevProps,
      permissions: e,
    }));
    var dropDownPermission = [];
    if (Array.isArray(e)) {
      e.map((x) => dropDownPermission.push(x.value));
    }
    dropDownPermission = dropDownPermission.join(",");
    setInputs((prevProps) => ({
      ...prevProps,
      permissions: dropDownPermission,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if (inputs.group_id) {
        groupUpdateQuery.mutate({ inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                setSelectData({});
                props.closeModal();
              }
            },
          }
        );
      } else {
        groupCreateQuery.mutate({ inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                setSelectData({});
                props.closeModal();
              }
            },
          }
        );
      }
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);

  
  useEffect(() => {
    if (permissionList.isFetched) {
      var data = [];
      permissionList?.data.map((permission) =>
        data.push({ value: permission.id, label: permission.permission_name })
      );
      setPermissions(data);

      if (props.isEdit === true) {
        var permissionIds = [];
        var permissionData = [];
        props.inputs.permissions.map(
          (permission) => (
            permissionData.push({
              value: permission.id,
              label: permission.permission_name,
            }),
            permissionIds.push(permission.id)
          )
        );

        setInputs((prevProps) => ({
          ...prevProps,
          permissions: permissionIds.join(","),
        }));

        setSelectData({
          permissions: permissionData,
        });
      }
    }
  }, [permissionList.data]);

  return (
    <div>
      <section className="mb-3">
        <div className="row mb-4">
          <div className="col-xxl-12">
            <label className="fw-bold me-3 ">Group Name</label>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                name="group_name"
                onChange={handleInputChange}
                value={inputs.group_name}
                placeholder="Group Name"
                ref={props.refName}
              />
              {validate(inputs.group_name, "required|min:2|max:25", {
                title: "group name",
                onServer: errors["group_name"],
              })}
            </div>
          </div>
          <div className="col-xxl-12">
            <label className="fw-bold me-3 ">Permissions</label>
            <div className="mb-3">
              {permissions && permissions ? (
                <Select
                  isMulti={true}
                  isSearchable={true}
                  onChange={onSelectChange}
                  closeMenuOnSelect={false}
                  options={permissions}
                  value={selectedData.permissions}
                  placeholder="Permissions"
                  className="bg-white"
                />
              ) : (
                "Form Group Loading"
              )}
              {validate(selectedData.permissions, "required", {
                title: "Permissions",
              })}
            </div>
          </div>
          <div className="col-xxl-12">
            <button
              onClick={handleSubmit}
              className="btn btn-secondary w-100"
            >
              {props.isEdit ? "Save changes" : "Add group"}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
