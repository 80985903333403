import React, { useState, useRef, useEffect, useContext } from "react";

import api from "../../../api";
import config from "../../../configurations";
import { notify } from "../../../scripts/notify"
import { preventNonNumeric } from "../../../scripts/utils"
import SimpleReactValidator from 'simple-react-validator';
import CustomSelect from "../../../refactoring/components/global/CustomSelect";
import _ from "lodash";
import { MetaDataContext } from "../../../web/context/metaData";


export default function ShippingCreationForm(props) {
  const [inputs, setInputs] = useState({
    id: (props.inputs && props.inputs.id) || "",
    name: (props.inputs && props.inputs.name) || "",
    amount: (props.inputs && props.inputs.amount) || "",
    tax_classes_id: (props.inputs && props.inputs.tax_classes_id) || "",
  });

  const [errors, setErrors] = useState({});
  const [, formError]=useState(false);
  const [taxes, setTaxes] = useState([]);

  const validator = useRef(new SimpleReactValidator());
  const metaData = useContext(MetaDataContext);
  useEffect(() => {
  }, [metaData]);

  const handleInputChange = event => {
    event.preventDefault();
    setErrors({});
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
  };

  function handleSelectChange(label, value) {
    setInputs(prevProps => ({
       ...prevProps,
      [label]: value
    }));
  }

  const fetchData = async () => {
    await api.tax.get()
      .then(response => {
        let data = [{ value: 0, label: 'Tax is not applicable' }];
        response.data.map(tax => {
          data.push({ value: tax.id, label: tax.tax_name })
        });
        setTaxes(data);
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.shippingMethod
        .add(inputs)
        .then((response) => {
          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInputs({
              id: "",
              name: "",
              amount: "",
              tax_classes_id:""
            });
            props.callback();
            props.closeModal();
          } else {
            for (let [field, message] of Object.entries(response.data.errors)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          notify();
        });
    } else {
      // setErrors((prevProps) => ({
      //   ...prevProps,
      //   ...validator.current.errorMessages,
      // }));
      formError(true);
      validator.current.showMessages();
    }
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.shippingMethod
        .edit(inputs)
        .then((response) => {
          console.log(response.data);

          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInputs({
              id: "",
              name: "",
              amount: "",
              tax_classes_id:""
            });
            props.callback();
            props.closeModal();
          } else {
            for (let [field, message] of Object.entries(response.data.errors)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          notify();
        });
    } else {
      // setErrors((prevProps) => ({
      //   ...prevProps,
      //   ...validator.current.errorMessages,
      // }));
      formError(true);
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <section className="">
        <form onSubmit={props.isEdit ? (handleEditSubmit) : (handleSubmit) }>
          <div className="row mb-4">
            <div className="col-lg-12 mb-3">
              <label className="fw-bold me-3 ">Shipping Method</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter Shipping Method"
                autoComplete='off'
                value={inputs.name}
                onChange={event => handleInputChange(event)}
                ref={props.refName}
              />
              {validator.current.message("name", inputs.name, "required|min:3|max:100", { className: "small text-danger" })}
              <div className="small text-danger">{errors.name}</div>
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold me-3 ">Tax Name</label>
                {taxes && taxes ? (
                  <CustomSelect
                    id="tax_classes_id"
                    closeMenuOnSelect={true}
                    isMulti={false}
                    isSearchable={true}
                    onChange={(e) => handleSelectChange('tax_classes_id', e.value)}
                    options={taxes}
                    value={taxes.filter(tax => tax.value == inputs.tax_classes_id)}
                    placeholder="Select Tax Class"
                  />
                ) : (
                  "Form Taxes Loading"
                )}
                {validator.current.message("tax_classes_id", inputs.tax_classes_id, "required", { className: "small text-danger" })}
            </div>
            <div className="col-lg-12 mb-3">
              <label className="fw-bold me-3 ">Amount</label>
              <input
                placeholder={metaData.metaData.preference.currency.currency_symbol}
                type="number"
                name="amount"
                className="form-control"
                min="0"
                autoComplete='off'
                onKeyPress={(e) => preventNonNumeric(e)}
                value={inputs.amount}
                onChange={event => handleInputChange(event)}
              />
              {validator.current.message("amount", inputs.amount, "required|min:1|max:15", { className: "small text-danger" })}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 ">
              <button type="submit" className="btn btn-secondary w-100" >
                {props.isEdit ? "Save changes" : "Add shipping method"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
