import React, { Component } from 'react';
import _ from "lodash";
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Tooltip, BarChart, Bar } from 'recharts';

import colors from "../../../lang/en/Theme/Colors";
import { abbreviateNumber } from "../../../scripts/utils";

import CardComponent from "../../layouts/CardLayout";
import NoDataFound from '../global/NoDataFound';


export default function BarChartBlock(props) {
	const	tickFormatter = (value) => {
		const limit = 20; // put your maximum character
		if (value.length < limit) return value;
		return value.substring(0, limit) + '...';
	}

	const CustomTooltip = ({ active, payload, label }) => {

  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-light p-2">
        <p className="label text-break small">{`${label}`}</p>
        <p className="text-secondary">{`${props.name} : ${abbreviateNumber(payload[0].value, props.withCurrency)}`}</p>
      </div>
    );
  }
		return null;
	};

  return (
    <>
      <CardComponent filter_key={props.filter_key} title={props.title} footer={props.footer}  >
        
          {
            <>
              {
                !_.isEmpty(props.data) ? (
                  <ResponsiveContainer width="100%" height={220} className="bar-chart-block">
                    <BarChart data={props.data} >
                      <CartesianGrid stroke={colors.colors.gray400} strokeDasharray="3 3" />
                      <XAxis dataKey="product_name"  tickFormatter={tickFormatter} width={30} dy={10} dx={10} interval={0} tick={{ fontSize: 10 }} allowDecimals={false} />
                      <YAxis width={40}  tick={props.settings.font}  type="number" datakey="total_quantity" allowDecimals={false} />
                      <Tooltip content={CustomTooltip} wrapperStyle={{width:'30%'}}/>
                      <Bar name={props.name} dataKey="total_quantity" fill={colors.colors.chartColors[0]} barSize={80} />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                      <NoDataFound />
                )
              }
            </>
          }
        
      </CardComponent>

    </>


  );
}
