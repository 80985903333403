
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import apiClient from "../header";
import _ from "lodash";


export function useTotalQuery() {
  const [totalData, setTotalData] = useState();

  function getCalculation(signal) {    
    const query =  apiClient.post(`order/total`, totalData, {
      signal: signal
    }).then(
      (result) => result.data,
    );
    return query;
  } 

  function handleSuccess(data) {
    document.dispatchEvent(new CustomEvent('calculation-fetched', {'detail': data.data}))
  }  

  const query = useQuery(['order-total', {totalData}], 
  ({ signal }) => getCalculation(signal), 
  {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: handleSuccess,
    enabled: !_.isEmpty(totalData)
  })  

  return [
    query, 
    query.data,
    {
      'setTotalData': setTotalData,
    }
  ]
}

