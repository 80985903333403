import _ from "lodash";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UilUpload, UilFile, UilTrashAlt } from "@iconscout/react-unicons";


export default function ImageUpload({ value, onChange, validator, isDisabled = false, }) {

  const [inputs, setInputs] = useState(null);
  const [fileValidate, { validate }] = validator;

  function add(event) {
    event.persist();
    if (!_.isNull(event.target)) {
      if (!inputs) {
        setInputs(event.target.files[0]);
      }
    }
  }

  function remove() {
    setInputs(null);
  }

  useEffect(() => {
    setInputs(value);
  }, [value]);

  useEffect(() => {
    fileValidate.current.purgeFields();
    onChange(inputs);
  }, [inputs]);

  function FileDrop() {
    return (
      <>
        <div className="small text-gray-500">
          Upload a JPG, JPEG, PNG file of not more than 2 MB
        </div>
        <label htmlFor="product_image" className="py-3 w-100">
          <div className="bg-lighter border border-2 rounded text-center p-5 cursor">
            {<UilUpload size="30" className="me-1 text-secondary" />}
            <div className="fw-bold mt-2 text-decoration-underline">
              Click here to add a image
            </div>
          </div>
        </label>
        <input
          className="form-control d-none"
          type="file"
          id="product_image"
          accept="image/*"
          onChange={(e) => add(e)}
        />
      </>
    );
  }

  function File({ data }) {
    return (
      <div className="col-lg-12 mb-2">
        <div className="border border-gray-200 rounded p-2 d-flex justify-content-between">
          <div>
            <span>
              <UilFile size="14" className="me-1 mb-1 text-primary" />
            </span>
            <span className="text-primary ms-2">{data.name}</span>
          </div>
          <div className="text-end">
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete file</Tooltip>} >
              <button className="btn text-secondary fw-bold d-inline p-0" onClick={(e) => remove(e)} >
                {<UilTrashAlt size="16" className="me-1 text-danger" />}
              </button>
            </OverlayTrigger>
          </div>
        </div>
        {!("path" in data) && validate(data, "file_type:image/jpeg,image/png|file_size:2", { title: "product_image" })}
      </div>
    );
  }

  function UploadedFiles() {
    if (!_.isNull(inputs)) {
      return (
        <>
          <File data={inputs} />
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      {_.isNull(inputs) ? <FileDrop /> : <></>}
      <UploadedFiles />
    </>
  );
}
