import React, { useState, useEffect, useContext } from "react";
import _, { valuesIn } from "lodash";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Card } from "react-bootstrap";

import config from "../../../configurations";
import colors from "../../../lang/en/Theme/Colors";
import { useTitle } from "../../../scripts/htmlOps";
import { abbreviateNumber } from "../../../scripts/utils";
import { MetaDataContext } from "../../../web/context/metaData";

import { useDashboardQuery } from "../../../refactoring/stores/dashboard/fetch";

import PageHeader from "../../layouts/PageHeader";
import LineChartBlock from "../../components/charts/LineChartBlock"
import BarChartBlock from "../../components/charts/BarChartBlock";
import BarChartHorizontalBlock from "../../components/charts/BarChartHorizontalBlock";
import SalesByChannels from "../../components/report/SalesByChannels";
import ProductDetailsBlock from "../../components/report/ProductDetailBlock";
import InnoventorySummary from "../../components/report/InnoventorySummary";
import BusinessSummary from "../../components/report/BusinessSummary";
import NoDataFound from "../../components/global/NoDataFound";
import DateFilter from "../../components/global/DateFilter";


export default function Dashboard() {
  useTitle('Innoventory');
  const now = new Date();
  const chartSettings = {
    stroke: [3],
    font: {
      fontSize: 12,
      fill: colors.colors.gray500
    },

    legend: {
      labels: {
        padding: 50,
      },
    }
  };

  const {data: preferences} = useContext(MetaDataContext);

  const [data, {queryParams, setQueryParams}] = useDashboardQuery()
  
  function handleFilterChange(key, startDate, endDate){
    setQueryParams({
      queryFor: key,
      startDate:startDate,
      endDate:endDate,
    })
  }
  
  
  return (
    <>
      <PageHeader title="Dashboard" subtitle="Overview" />

      {data.isLoading ? (
        <>
          <div className="row mb-4">
            <div className="col">
              <Skeleton height={140} />
            </div>
            <div className="col">
              <Skeleton height={140} />
            </div>
            <div className="col">
              <Skeleton height={140} />
            </div>
            <div className="col">
              <Skeleton height={140} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Skeleton height={300} />
            </div>
            <div className="col">
              <Skeleton height={300} />
            </div>
            <div className="col">
              <Skeleton height={300} />
            </div>
            <div className="col">
              <Skeleton height={300} />
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="bg-white mb-4 border">
            <div className="row">
              <div className="col-sm-12 ">
                <div className="row p-4 overview">
                  {!_.isEmpty(data?.data?.order_customization?.data) ? (
                      data.data.order_customization.data.map((value) => (
                        value.key !="edging" &&
                        <div className="col-sm-3 overview-card-cols">
                          <Card className={`${config.OrderCustomization[value.key].class} text-white pt-1`}>
                            <Card.Body>
                              <Card.Title as="h2" className="mb-0">
                                <>
                                  {abbreviateNumber(value.value, (!['cubic_meter', 'oiling_percentage'].includes(value.key) ? preferences : false))}
                                  {value.key == 'cubic_meter'  && <span className='fw-bold ms-1 h6'>{config.Units.text}</span>}
                                  {value.key == 'delivery_charge'  && <span className='fw-bold ms-1 h6'>/order</span>}
                                  {value.key == 'oiling_percentage'  && <span className='fw-bold ms-1 h6'>%</span>}
                                </>
                              </Card.Title>
                              <Card.Text>
                                {value.title}
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer className={`pb-3 border-0 ${config.OrderCustomization[value.key].class}`}>
                              <span className='me-1 h6 fw-bold'> 
                              {value.key != 'oiling_percentage' ?
                                value.sub_title && `${preferences.currency_symbol} ${value.sub_title}/order`
                                : `Out of ${value.order_count} orders`
                              }
                              </span>
                            </Card.Footer>
                          </Card>
                        </div>
                      ))
                  ) : <NoDataFound />}
                </div>
              </div>
              <div className="col">
                <div className="card-footer bg-white mx-0">
                  <DateFilter filterKey="order_customization" onChange={handleFilterChange}/>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col mb-4">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Sales Order
                </Card.Header>
                <Card.Body>
                  {data.isLoading && (['sales_orders','all'].includes(queryParams.queryFor)) ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) :(
                    <Card.Title as="h2" className="mb-0 py-3">{abbreviateNumber(data?.data?.sales_orders?.data, preferences)}</Card.Title>
                  )}
                </Card.Body>
                <Card.Footer className="bg-white">
                  <DateFilter filterKey="sales_orders" onChange={handleFilterChange}/>
                </Card.Footer>
              </Card>
            </div>

            <div className="col mb-4">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Orders pending
                </Card.Header>
                <Card.Body>
                  {data.isLoading && (['order_pending','all'].includes(queryParams.queryFor)) ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) :(
                    <Card.Title as="h2" className="mb-0 py-3">{abbreviateNumber(data?.data?.order_pending?.data?.order_count ?? 0)}</Card.Title>
                  )}
                </Card.Body>
                <Card.Footer className="bg-white">
                  <DateFilter filterKey="order_pending" onChange={handleFilterChange}/>
                </Card.Footer>
              </Card>
            </div>

            <div className="col mb-4">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Orders processing
                </Card.Header>
                <Card.Body>
                  {data.isLoading && (['order_processing','all'].includes(queryParams.queryFor)) ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) :(
                    <Card.Title as="h2" className="mb-0 py-3">{abbreviateNumber(data?.data?.order_processing?.data?.order_count ?? 0)}</Card.Title>
                  )}
                </Card.Body>
                <Card.Footer className="bg-white">
                  <DateFilter filterKey="order_processing" onChange={handleFilterChange}/>
                </Card.Footer>
              </Card>
            </div>

            <div className="col mb-4">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Orders completed
                </Card.Header>
                <Card.Body>
                  {data.isLoading && (['order_completed','all'].includes(queryParams.queryFor)) ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) :(
                    <Card.Title as="h2" className="mb-0 py-3">{abbreviateNumber(data?.data?.order_completed?.data?.order_count ?? 0)}</Card.Title>
                  )}
                </Card.Body>
                <Card.Footer className="bg-white">
                  <DateFilter filterKey="order_completed" onChange={handleFilterChange}/>
                </Card.Footer>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-6 mb-4">
              <div>
                <LineChartBlock
                  data={data.data?.average_sales?.data}
                  XAxisKey={'date'}
                  YAxisKey={'order_sales'}
                  name={`Sales in ${preferences.currency_symbol}`}
                  footer={<DateFilter filterKey="average_sales" onChange={handleFilterChange}/>}
                  title="Total Average Sales"
                  settings={chartSettings}
                  withCurrency={preferences}
                  filter_key="average_sales"
                  isLoading={data.isLoading}
                />
              </div>
            </div>

            <div className="col-6 mb-4">
              <div>
                <LineChartBlock
                  data={data.data?.total_order?.data ?? null}
                  XAxisKey={'date'}
                  YAxisKey={'order_count'}
                  name="Orders"
                  title="Total Orders"
                  settings={chartSettings}
                  footer={<DateFilter filterKey="total_order" onChange={handleFilterChange}/>}
                  filter_key="total_order"
                  isLoading={data.isLoading}
                  withCurrency={false}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-4">
              <SalesByChannels
                value={data.data?.sales_by_channel?.data ?? null}
                data={data.data?.sales_by_channel?.data ?? null}
                title="Total Sales by channels"
                footer={<DateFilter filterKey="sales_by_channel" onChange={handleFilterChange}/>}
                filter_key="sales_by_channel"
                withCurrency={preferences}
                isLoading={data.isLoading}
              />
            </div>
          </div>

          <div className="row">
            {!_.isEmpty(data?.data?.inventory_summary.data) ? (
              <div className="col mb-4">
                <InnoventorySummary
                  value={data?.data?.inventory_summary.data}
                  data={data?.data?.inventory_summary.data}
                  title="Inventory summary by top categories in last 7 days"
                  withCurrency={false}
                />
              </div>
            ) : null}
          </div>

          <div className="row">
            <div className="col-md-5 mb-4">
              <BarChartHorizontalBlock
                data={data?.data?.order_status?.data ?? null}
                title="Total Orders by Status"
                name="Order Count"
                settings={chartSettings}
                footer={<DateFilter filterKey="order_status" onChange={handleFilterChange}/>}
                filter_key="order_status"
                width={50}
                withCurrency={false}
                isLoading={data.isLoading}
              />
            </div>

            <div className="col-lg-7 mb-4 ">
              <BarChartBlock
                data={data?.data?.top_products.data ?? null}
                name="Quantity"
                settings={chartSettings} 
                title="Top Performing Products"
                footer={<DateFilter filterKey="top_products" onChange={handleFilterChange}/>}
                filter_key="top_products"
                withCurrency={false}
                isLoading={data.isLoading}
                />
            </div>
          </div>

          <div className="row">
            {!_.isEmpty(data?.data?.product_details.data) ? (
              <div className="col-sm-6 mb-4">
                <ProductDetailsBlock
                  value={data?.data?.product_details.data}
                  title="Product Details"
                  data={data?.data?.product_details.data}
                  withCurrency={false}
                  />
              </div>
            ) : null}

            <div className="col-sm-6 mb-4">
              <BusinessSummary
                value={data?.data?.business_summary.data}
                title="Business Summary"
                data={data?.data?.business_summary.data}
                footer={<DateFilter filterKey="business_summary" onChange={handleFilterChange}/>}
                filter_key="business_summary"
                withCurrency={preferences}
                isLoading={data?.data?.business_summary.isLoading}
              />
            </div>
          </div>

        </div>
      )}

    </>
  );
}
