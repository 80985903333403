import React from "react";

import _ from "lodash";

import CardComponent from "../../layouts/CardLayout";
import { abbreviateNumber } from "../../../scripts/utils";
import Skeleton from "react-loading-skeleton";

export default function BusinessSummary(props) {

  return (
    <>
      <CardComponent filter_key={props.filter_key} title={props.title} footer={props.footer} >
        <>
          <div className="d-flex justify-content-between">
            <p className=" ">Gross Profit </p>
            {props.isLoading ? (
              <>
                <Skeleton height={20} width={50} />
              </>
            ) : (
              <div className=" ">
                <span className="h4 fw-bold">
                  {props.data ? (
                    <>{abbreviateNumber(props.data, props.withCurrency)}</>
                  ) : (
                    "-"
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <p className="">Net Profit </p>
            <div className="text-secondary">Coming Soon!</div>
          </div>
        </>
      </CardComponent>
    </>
  );
}
