import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Paths } from "../../../routes";
import { isAuthorized } from "../../../scripts/utils";
import localstorage from "../../../localstorage";

// components
import ErrorPage from "../errors/ErrorPage";

const Middleware = ({component, permissions}) => {

  const isAuthenticated = localstorage.token.get();

  if (isAuthenticated) {
		try {
			return (
				<>	
						{isAuthorized(permissions) ? component : <ErrorPage innerPage={true} error='403'/>}
				</>
			)
			} catch (error) {
				return ( <ErrorPage innerPage={true} error='500'/> )
			}
  }

  return <Navigate to={Paths.Login.path} />;
};

export default Middleware;
