import React, { useState, useEffect, useRef } from 'react'
import { UilUsersAlt } from '@iconscout/react-unicons'
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import api from "../../../api";
import { notify } from '../../../scripts/notify';


export default function GroupCreationForm(props) {
  
  const [permissions, setPermissions] = useState([]);
  const [errors, setErrors] = useState({});
  const [, formError] = useState(false);

  const [selectedData, setSelectData] = useState({
    permissions: null
  });

  const [inputs, setInputs] = useState({
    group_id: (props.inputs && props.inputs.groupID) || "",
    group_name: (props.inputs && props.inputs.groupName) || "",
    permissions: ""
  });

  const validator = useRef(new SimpleReactValidator());

  useEffect(() => {
    api.permissions.get().then(response => {
      var data = [];
      response.data.data.map(permission => (
        data.push({ value: permission.id, label: permission.permission_name })
      ));
      setPermissions(data);
    });

    if (props.isEdit === true) {
      var permissionIds = [];
      var permissionData = [];
      props.inputs.permissions.map(permission => ( permissionData.push({ value: permission.id, label: permission.permission_name }), permissionIds.push(permission.id) ));

      setInputs(prevProps => ({
        ...prevProps,
        permissions: permissionIds.join(",")
      }));

      setSelectData({
        permissions: permissionData
      });
    }
  }, []);

  const onChange = (event) => {
    setErrors({});
    var val = event.target.value
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: val
    }));
  }

  const onSelectChange = (e) => {
    setSelectData(prevProps => ({
      ...prevProps,
      permissions: e
    }));

    var dropDownPermission = [];

    if (Array.isArray(e)) {
      e.map(x => dropDownPermission.push(x.value))
    }

    dropDownPermission = dropDownPermission.join(',')
    setInputs(prevProps => ({
      ...prevProps,
      permissions: dropDownPermission
    }));
  }

  const SubmitHandler = event => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.groups
        .add({ inputs })
        .then(response => {
          notify(response.data.type, response.data.message);
          if (response.data.status === 1) {
            setInputs({
              group_name: "",
              permissions: []
            });
            props.callback();
            props.closeModal();

            setSelectData({
              permissions: null
            });
          } else {
            for (let [field, message] of Object.entries(response.data.field_error)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          // notify();
        });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };

  const handleEditGroup = () => {
    if (validator.current.allValid()) {
      api.groups
        .edit({ inputs })
        .then(response => {
          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            props.callback();
            props.closeModal();
          } else {
          for (let [field, message] of Object.entries(response.data.field_error)) {
            console.log(response.data);
            setErrors((prevProps) => ({
              ...prevProps,
              [field]: message[0]
            }));
          }
        }
      })
        .catch(function () {
          notify();
        });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };


  return (
    <div>
      <section className="mb-3">
        <div className="row mb-4">
          <div className="col-xxl-12">
            <label className="fw-bold me-3 ">Group Name</label>
            <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="group_name"
                  onChange={onChange}
                  value={inputs.group_name}
                  placeholder="Group Name"
                  ref={props.refName}
                />
              {validator.current.message("group_name", inputs.group_name, "required|min:2|max:25", {className: "small text-danger"})}
              <div className="small text-danger">{errors.group_name}</div>
            </div>
          </div>
          <div className="col-xxl-12">
            <label className="fw-bold me-3 ">Permissions</label>
            <div className="mb-3">
              {permissions && permissions ? (
                <Select
                  isMulti={true}
                  isSearchable={true}
                  onChange={onSelectChange}
                  closeMenuOnSelect={false}
                  options={permissions}
                  value={selectedData.permissions}
                  placeholder="Permissions"
                  className="bg-white"
                />
              ) : (
                "Form Group Loading"
              )}
              {validator.current.message("Permissions", selectedData.permissions, "required", {className: "small text-danger"})}
            </div>
          </div>
          <div className="col-xxl-12">
            <button onClick={props.isEdit ? handleEditGroup : SubmitHandler} className="btn btn-secondary w-100" >
              {props.isEdit ? "Save changes" : "Add group"}
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}
