
import React, { useContext } from "react";
import _ from 'lodash';

import CardComponent from '../../layouts/CardLayout';
import NoDataFound from "../global/NoDataFound";
import { abbreviateNumber } from "../../../scripts/utils";
import { MetaDataContext } from "../../../web/context/metaData";
import configurations from "../../../configurations";


export default function InnoventorySummary(props) {
  const {data:preferences} = useContext(MetaDataContext);

  return (
    <>
      <CardComponent title={props.title} footer={props.footer}>

        <div className="row">
          {!_.isEmpty(props.data) ?
            props.data.map((key, index) => (
              <div className="col-md-4 border-end border-right-line pe-4" key={key + index}>
                <h6 className="fw-bold">{key.name}</h6>
                <div className='d-flex justify-content-between'>
                  <p className=' '> Quantity in Hand </p>
                  <div className=' '>
                    <span className="h5 fw-bold">{abbreviateNumber(key.stock_in_hand,props.withCurrency)}</span>
                    {key.unit == "cubic meter" ? (
                      <span className="p fw-bold" dangerouslySetInnerHTML={{ __html: configurations.Units.symbol }}
                      />
                    ) : ( <span className="p fw-bold">{key.unit}</span> )
                    }
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <p className=''> Quantity Sold </p>
                  <div className=' '>
                    <span className="h5 fw-bold">{abbreviateNumber(key.stock_sold, props.withCurrency)}</span>
                    <span className="p fw-bold">
                    {key.unit == "cubic meter" ? (
                      <span className="p fw-bold" dangerouslySetInnerHTML={{ __html: configurations.Units.symbol }}
                      />
                    ) : ( <span className="p fw-bold">{key.unit}</span> )
                    }
                    </span>
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <p className=''>Quantity Sold Value </p>
                  <div className=' '>
                    <span className="h5 fw-bold">{preferences.currency_symbol}</span>
                    <span className="h5 fw-bold">{abbreviateNumber(key.sold_value, props.withCurrency)}</span>
                  </div>
                </div>
              </div>
            ))
            : <div className="d-flex justify-content-center align-items-center h-100">
              <NoDataFound />
            </div>
          }
        </div>
      </CardComponent>
    </>
  );
}
