import React from 'react';
import { UilEnvelopeAlt, UilPhoneAlt } from "@iconscout/react-unicons"
import _ from 'lodash'


export default function CustomerDetailsView({data, isEditEnabled = false, isDeleteEnabled = false}) {
  return (
    <>
      <div className="col-md-4 mb-2">
        <div className="text-primary mb-1 text-truncate">
          { !_.isEmpty(data.customer_first_name) && <span className="text-primary"> {data.customer_first_name} {data.customer_last_name} </span> }
        </div>
        <div className="mb-2">
          <span>
            {
              !_.isEmpty(data.email) &&
              <a href={"mailto: " + data.email} className="fw-bold mb-3 d-inline text-decoration-none text-info">
                <div className="text-info">  {<UilEnvelopeAlt size="14" className="text-info" />} <span className="ms-2"> {data.email}</span></div>
              </a>
            }
          </span>
          <div className="fw-bold mb-3 d-inline text-success text-truncate">
            {
              !_.isEmpty(data.telephone) &&
              <a href={"tel: " + data.telephone} className="f1w-bold mb-3 d-inline text-decoration-none text-success">
                <div>{<UilPhoneAlt size="14" className="text-success" />} <span className="ms-2">  {data.telephone}</span></div>
              </a>
            }
          </div>
        </div>
      </div>

      <div className="col-md-4">
        <div className="d-flex justify-content-between">
          <div className="letter-spacing-1 tiny-font text-uppercase mb-2">
            Shipping Address
          </div>
        </div>
        {
          data &&
          <div className="text-start text-primary mb-3 text-break">
            { !_.isEmpty(data.shipping_firstname) && <div> {data.shipping_firstname} {data.shipping_lastname} </div> }
            { !_.isEmpty(data.shipping_company) && <div> {data.shipping_company} </div> }
            { !_.isEmpty(data.shipping_address) && <div> {data.shipping_address} {data.shipping_territory} </div> }
            { !_.isEmpty(data.shipping_city) && <div> {data.shipping_city} {data.shipping_postcode} </div> }
            { !_.isEmpty(data.shipping_country) && <div> {data.shipping_country} </div> }
          </div>
        }
      </div>

      <div className="col-md-4">
        <div className="d-flex justify-content-between">
          <div className="letter-spacing-1 tiny-font text-uppercase mb-2">
            Billing/Payment Address
          </div>
        </div>
        {
          data &&
          <div className="text-start text-primary mb-3 text-break ">
            { !_.isEmpty(data.payment_firstname) && <div> {data.payment_firstname} {data.payment_lastname} </div> }
            { !_.isEmpty(data.payment_company) && <div> {data.payment_company} </div> }
            { !_.isEmpty(data.payment_address) && <div> {data.payment_address} {data.payment_territory} </div> }
            { !_.isEmpty(data.payment_city) && <div>{data.payment_city} {data.payment_postcode} </div> }
            { !_.isEmpty(data.payment_country) && <div> {data.payment_country} </div> }
          </div>
        }
      </div>
    </>
  );
}