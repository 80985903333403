import React, { useState, useEffect, useRef } from "react";
import { UilPen, UilTrashAlt, UilTransaction } from '@iconscout/react-unicons';
import { Modal, Table } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Skeleton from 'react-loading-skeleton';

import { notify } from '../../../scripts/notify'
import api from "../../../api";

import PageHeader from "../../../refactoring/layouts/PageHeader";
import PageInfo from "../../../components/pagination/PageInfo";
import PaymentCreationForm from"./PaymentCreationForm";
import ReactPagination from "../../../components/pagination/ReactPagination";


export default function PaymentManagement() {
  const [loading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState({
    currentPage: pageNumber,
    per_page: 0,
    total_count: 0,
    last_page: 0
  });
  const [isOpen, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({
    id: "",
    name: "",
  });
  const refName = useRef();

  const pageChange = pageNo => {
    setPageNumber(pageNo);
  };

  async function fetchData() {
    await api.paymentMethod.all({
      params: {
        paginate: 15,
        page: pageNumber
      }
    })
      .then(response => {
        setPaymentMethod(response.data.data);
        setPages({
          currentPage: response.data.current_page,
          per_page: response.data.per_page,
          total_count: response.data.total,
          lastPage: response.data.last_page
        });

        if (pageNumber >= response.data.last_page) {
          setPageNumber(response.data.last_page);
        }
        // console.log(response);
      });
    await setLoading(false);

  }

  const add = () => {
    setOpen(true);
    setInputs({
      id: "",
      name: "",
    })
  }

  const edit = (id, name) => {
    setOpen(true);
    setInputs({
      id: id,
      name: name,
    });
    // console.log(id, name, inputs);
  }

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  }

  const handleDeletePaymentMethodClass = (Id) => {
    api.paymentMethod.delete({ id: Id })
      .then(response => {
        notify(response.data.type, response.data.message);
        if (response.data.status === 1) {
          fetchData();
        }
      })
      .catch(error => {
        notify('error');
        // console.log('Error::', error);
      })
  }

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <React.Fragment>
      <div className="bg-white p-5 border rounded">

        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Payment Methods" subtext="Manage Payment Methods" />

          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()} > Add Payment Method</button>
          </div>
        </div>


        <div className="border rounded-3 overflow-hidden">
          <Table className="table table-hover">
            <thead>
              <tr>
                <th>Payment Method</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='100%' className='text-center'>
                    <Skeleton count={5} height={30} className="mb-3"/>
                  </td>
                </tr>
              ) : paymentMethod.length ? (
                paymentMethod.map(paymentMethod => (
                  <tr key={paymentMethod.id}>
                    <td>{paymentMethod.name}</td>
                    <td>&nbsp;</td>
                    <td className="d-flex justify-content-end">
                      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit Payment Method</Tooltip>}>
                        <span className="me-3 cursor active-icon" onClick={() => { setIsEdit(true); edit(paymentMethod.id, paymentMethod.name) }} id="edit">
                          {<UilPen size="14" className="text-secondary" />}
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete Payment Method</Tooltip>}>
                        <span className="cursor active-icon" id="delete">
                          {<UilTrashAlt size="14" onClick={() => handleDeletePaymentMethodClass(paymentMethod.id)} className="text-danger" />}
                        </span>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))
              ) : (

                <tr>
                  <td colSpan="100%">
                    <div className="text-center bg-white py-5">
                      {<UilTransaction size="38" className="mb-3 text-secondary" />}
                      <p className="h5 text-gray-700">No Payment Method is available</p>
                      <p className="small text-gray-600">Enter a Payment Method.</p>
                    </div>
                  </td>
                </tr>

              )}
            </tbody>
            <tfoot>
              <tr className="border-top">
                <td colSpan="100%">
                  <div className="d-flex justify-content-between align-items-center">
                    <PageInfo perPage={pages.per_page} currentPage={pages.currentPage} total={pages.total_count} />
                    {pages ? ( <ReactPagination pageNumber={pageChange} pages={pages} /> ) : null}
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>

      </div>

      {/* TODO: fetch from component, below section */}
      {isOpen ? (
        <Modal show={isOpen} onEntered={() => refName.current.focus()} onHide={closeModal} centered dialogClassName="modal-md">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>{inputs.tax_id ? "Edit Payment Method" : "Add Payment Method"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <PaymentCreationForm callback={fetchData} inputs={inputs} isEdit={isEdit} closeModal={closeModal} refName={refName} setInputs={setInputs}/>
          </Modal.Body>
        </Modal>
      ) : null
      }
    </React.Fragment>
  );
}
