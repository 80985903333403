import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from "lodash";

import { useValidator } from "../../utils/validator";
import Loader from "../../components/global/Loader";
import PageHeader from "../../layouts/PageHeader";
import { useChannelViewQuery } from "../../stores/channel/view";
import { useChannelUpdateQuery, useSyncStatusQuery } from "../../stores/channel/update";
import { useGetOrdersQuery } from "../../stores/channel/getOrders";
import { useGetProductsQuery } from "../../stores/channel/getProducts";
import IntervalSetter from "../../components/channel/IntervalSetter";


export default function Edit() {

  const { id } = useParams();
  const initial ={
    id: "",
    channel_type: "",
    channel_name: "",
    channel_username: "",
    api_key: "",
    api_url: "",
    interval_products: "",
    interval_orders: "",
    channel_type_name: ""
  };

  const [, forceUpdate] = useState();
  const [errors, setErrors] = useState({});
  const [getOrdersQuery] = useGetOrdersQuery();
  const [inputs, setInputs] = useState(initial);
  const [syncStatusQuery] = useSyncStatusQuery();
  const [getProductsQuery] = useGetProductsQuery();
  const [channelUpdateQuery] = useChannelUpdateQuery();
  const [validator, { validate, validateIf }] = useValidator(errors);
  const [channelViewQuery, channelViewQueryData, { viewError }] = useChannelViewQuery(id);

  const handleInputChange = (event) => {
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
    if (errors[event.target.name]) {
      setErrors(prevProps => ({
        ...prevProps,
        [event.target.name]: ''
      }));
    }
  };

  const handleStatusChange = (e) => {
    var status = e.target.checked ? 1 : 0;
    setInputs((prevProps) => ({
      ...prevProps,
      status: status,
    }));
    syncStatusQuery.mutate({id:id, status: status});
  };

  useEffect(() => {
    if (channelViewQuery.isFetched) {
      setInputs(channelViewQueryData.data);
    }
  }, [channelViewQuery.data]);

  const fetchOrders = async () => {
    getOrdersQuery.mutate({ id: id, type: inputs.channel_type });
  };  
  
  const fetchProducts = async () => {
    getProductsQuery.mutate({ id: id, type: inputs.channel_type });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setErrors({});
    validator.current.purgeFields();
    if (validator.current.allValid()) {
      channelUpdateQuery.mutate(inputs);
    }
    validator.current.showMessages();
    forceUpdate(true)
  }


  return (
    <div>
      {channelViewQuery.isLoading ? (
        <Loader />
      ) : viewError ? (
        viewError
      ) : (
        <div>
          <div className="text-capitalize">
            <PageHeader title="channels" subtitle={inputs.channel_name} />
          </div>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-lg-10">
                <section className="p-5 border bg-white">
                  <h4 className="mb-4">Channel Details</h4>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <label className="mb-2" htmlFor="channel_name">
                        Channel Name
                      </label>
                      <input
                        type="text"
                        className="form-control not-allowed"
                        id="channel_name"
                        name="channel_name"
                        value={inputs?.channel_name || ""}
                        placeholder="Channel Name"
                        readOnly={true}
                      />
                    </div>
                    {inputs.channel_type != 2 && (
                      <div className="col-md-6">
                        <label className="mb-2" htmlFor="channel_username">
                          Username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="channel_username"
                          name="channel_username"
                          value={inputs?.channel_username || ""}
                          placeholder="Username"
                          onChange={handleInputChange}
                        />
                        {validateIf(inputs.channel_username, "required", inputs.channel_type != 2, "channel_username")}
                      </div>
                    )}
                  </div>
                  {inputs.channel_type != 2 && (
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label className="mb-2" htmlFor="api_key">
                          API Key
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="api_key"
                          name="api_key"
                          value={inputs.api_key}
                          placeholder="API Key"
                          onChange={handleInputChange}
                        />
                        {validateIf(inputs.api_key, "required", inputs.channel_type != 2, "api_key")}
                      </div>
                      <div className="col-md-6">
                        {inputs.channel_type == 3 && (
                          <div>
                            <label className="mb-2" htmlFor="api_url">
                              Channel URL
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="api_url"
                              name="api_url"
                              value={inputs.api_url}
                              placeholder="Channel URL"
                              onChange={handleInputChange}
                            />
                          </div>
                        )}
                        {inputs.channel_type == 1 && (
                          <div className="dropdown">
                            <label className="mb-2" htmlFor="api_url">
                              Region
                            </label>
                            <select
                              value={inputs.api_url}
                              name="api_url"
                              onChange={handleInputChange}
                              className="form-control"
                            >
                              <option>Select Region</option>
                              <option value="https://sellercentral.amazon.com/">
                                us-east-1
                              </option>
                              <option value="https://sellercentral.amazon.co.jp/">
                                us-west-2
                              </option>
                              <option value="https://sellercentral-europe.amazon.com">
                                eu-west-1
                              </option>
                            </select>
                          </div>
                        )}
                        {validateIf(inputs.api_url, "required", inputs.channel_type != 2, "api_url")}
                      </div>
                    </div>
                  )}

                  {inputs.interval_orders ? (
                    <>
                      <IntervalSetter
                        callback={setInputs}
                        previousData={inputs}
                        intervalName="interval_orders"
                        label="Interval For Orders"
                      />

                      <IntervalSetter
                        erroKey="2"
                        callback={setInputs}
                        previousData={inputs}
                        intervalName="interval_products"
                        label="Interval For Products"
                      />
                    </>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-grid">
                        <button className="btn btn-secondary px-4">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-lg-10">
              <section className="py-5 border-bottom">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h6>Enable fetching orders and products</h6>
                    <p className="small text-gray-500 m-0">
                      Fetch all latest order and products from the channel to
                      innoventory.
                    </p>
                  </div>
                  <div className="col-md-auto">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="status"
                        checked={inputs.status}
                        onClick={handleStatusChange}
                      />
                      <label className="form-check-label"></label>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-5 border-bottom">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h6>Fetch {inputs.channel_type_name} Products</h6>
                    <p className="small text-gray-500 m-0">
                      Get all products assigned to {inputs.channel_type_name}{" "}
                      channel{" "}
                    </p>
                  </div>
                  <div className="col-md-auto">
                    {inputs.status == 1 ? (
                      <button
                        className="btn btn-secondary px-4"
                        onClick={fetchProducts}
                        disabled={inputs.channel_type == 2}
                      >
                        Get Products
                      </button>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        key="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {"Channel is disable, enable it to fetch products"}
                          </Tooltip>
                        }
                      >
                        <div>
                          <button
                            className="btn btn-outline-light px-4"
                            disabled
                          >
                            Get Products
                          </button>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </section>
              <section className="py-5 border-bottom">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <div className="col-md-6">
                      <h6>Fetch {inputs.channel_type_name} Orders</h6>
                      <p className="small text-gray-500 m-0">
                        Get all orders assigned to {inputs.channel_type_name}{" "}
                        channel{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-auto">
                    {inputs.status == 1 ? (
                      <button
                        className="btn btn-secondary px-4"
                        onClick={fetchOrders}
                      >
                        Get Orders
                      </button>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        key="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {"Channel is disable, enable it to fetch orders"}
                          </Tooltip>
                        }
                      >
                        <div>
                          <button
                            className="btn btn-outline-light px-4"
                            disabled
                          >
                            Get Orders
                          </button>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}