const colors = {
  
  gray100: '#F8F9FA',
  gray200: '#E9ECEF',
  gray300: '#DEE2E6',
  gray400: '#CED4DA',
  gray500: '#ADB5BD',
  gray600: '#6C757D',
  gray700: '#495057',
  gray800: '#343A40',
  gray900: '#212529',
  white:  '#fff',
  
  primary: '#000036',
  secondary: '#5e72eb',
  info: '#4dc0b5',
  success: '#8dd350',
  danger: '#ea4949',
  warning: '#e8b34b',
  light: '#ADB5BD',
  dark: '#495057',

  chartColors: [
    "#6574cd",
    "#4dc0b5",
    "#8dd350",
    "#e8b34b",
    "#ea4949",
    "#000036",
  ]
}

export default{
  colors
}