
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { Paths } from "../../../routes";

function createOrder(data) {
  return apiClient.post(`order/add`, data)
  .then(
    (result) => result.data,
  );
}


export function useOrderCreateQuery() {
  const [errors, setErrors] = useState({});
	const navigate = useNavigate();
  const queryClient = useQueryClient();
  var toastify;

  function handleSuccess(data) {
    if (data.status) {
      notify(data.type, data.message);
      queryClient.invalidateQueries(['order-list']);
      queryClient.invalidateQueries(["quotations-list"]);
      setTimeout(() => {
        navigate(Paths.Order.pages.index.path);
      }, 2000)
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors(prevProps => ({
        ...prevProps,
          [error]: message[0]
        }));
      }
    }
  }

  function handleError(data) {
    toast.update(toastify, { render: "Something went wrong", type: "error", isLoading: false, autoClose: true, closeButton: true, }); 
  }
  
  const mutate = useMutation((newOrder) => createOrder(newOrder), {
    retry: false,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return [
    mutate,
    {
      'createErrors': errors,
    },
  ]
}