
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notify } from "../../../scripts/notify";

import apiClient from "../header";

function deleteProduct(id) {    
  const query =  apiClient.post(`inventory/delete`, {
    id: id,
  }).then(
    (result) => result.data,
  );
  return query;
}

export function useDeleteProductQuery() {
  const queryClient = useQueryClient()
  
  function handleSuccess(data) {
    notify(data.type, data.message);
    queryClient.invalidateQueries(['product-list']);
  }
  
  function handleError(data) {
    notify(data.type);
  }

  return useMutation((productId) => deleteProduct(productId), {
    retry: false,
    onSuccess: handleSuccess,
    onError: handleError
  });
}

