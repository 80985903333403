import React, { useState, useEffect }  from 'react'
import Pagination from "react-bootstrap/Pagination";

export default function ReactPagination  (props) {

  const pageNumber = props.pageNumber;
  const pages = props.pages;

  var items = [];

  const setThePage = (pageNum) => {
    pages.currentPage=pageNum
    props.pageNumber(pageNum)
  }
 
  const generatePagination = () => {
    items = [];
    
    var startPage = (pages.currentPage - 2 > 0)?pages.currentPage - 2:1;
    var lastPage = (pages.currentPage + 2 <= pages.lastPage)?pages.currentPage  + 2:pages.lastPage;
   
    if( pages.currentPage-2 > 1 ) {
      // items.push(
      //   <Pagination.Item
      //     onClick={()=>setThePage(1)}
      //     key={1}
      //   >
      //   {1}         
      //   </Pagination.Item>
      // );
      items.push(
        <Pagination.Ellipsis key={"elipsis1"}  onClick={   ()=>setThePage(pages.currentPage-1)  }/>
      );
    }

    
    for (let i = startPage; i <= lastPage; i++) {
      items.push(
        <Pagination.Item
          onClick={()=>setThePage(i)}
          key={i}
          active={i === pages.currentPage}
        >
          {i}
        </Pagination.Item>
      );
    }

    if(pages.currentPage < pages.lastPage - 2){
      items.push(
        <Pagination.Ellipsis key={"elipsis2"} onClick={()=>setThePage(pages.currentPage+1) }/>
      );
    //   items.push(
    //     <Pagination.Item
    //       onClick={()=>setThePage(pages.lastPage) }
    //       key={pages.lastPage}
          
    //     >
    //       {pages.lastPage}
    //     </Pagination.Item>
    //   );
    }
  };





  return (
    <div>
      {pages.lastPage > 0 ? generatePagination() : null}
      <Pagination className="m-0">
        {(pages.lastPage != 1)?(
        <Pagination.First onClick={()=>setThePage(1)}/>
        ):null }
        {items}
        {(pages.lastPage != 1)?(
        <Pagination.Last onClick={()=>setThePage(pages.lastPage) } />
        ):null }
      </Pagination>
    </div>
  )
}


