import React  from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { UilEllipsisH, UilTrashAlt, UilPen } from '@iconscout/react-unicons';

import { Paths } from '../../../routes';
import configurations from '../../../configurations';

import InfoIcon from '../../components/common/InfoIcon';
import DataTable from '../../components/common/DataTable';
import ActionBar from '../../components/global/ActionBar';
import PageHeader from '../../layouts/PageHeader'
import ErrorPage from '../../components/errors/ErrorPage';
import { confirmDelete } from '../../utils/scripts';
import { useIntegrationListQuery } from '../../stores/integrations/list';
import { useDeleteIntegrationQuery } from '../../stores/integrations/delete';
import AmazonIcon from "../../../assets/img/pages/channel/list/amazon-icon.svg";
import NoDataFound from '../../components/global/NoDataFound';



export default function Listing() {
 
  const navigate = useNavigate();
  const [integrationQuery, integrationQueryData, {searchParams, setSearchParams , setPageNumber, setSort}] = useIntegrationListQuery();
  const deleteIntegration = useDeleteIntegrationQuery();
  
  function handleDelete(id) {
    confirmDelete( 'integration' , () => deleteIntegration.mutate(id))
  } 

  function handleEdit(id) {
    navigate(Paths.Integration.pages.EditTrackpod.path + id)
  }
   
  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }


  const columns = [
    {
      id: 'integration_name',
      name: 'Integration Name',
      width: '500px',
      cell: row => (
        <InfoIcon name={row.integration_name} />
      ),
    },
    {
      id: 'user_name',
      name: 'username',
      selector: row => row.integration_username,
    },
    {
      id: 'date_added',
      name: 'Date Added',
      selector: row => row.date,
    },
    {
      name: '',
      id: 'action',
      cell: row => (
        <ul className="dropdown text-end mb-0">
          <button className="btn btn-link" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" >
            <span><UilEllipsisH size="16" className="text-secondary"/></span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end py-0">
            <li className="fw-bold cursor dropdown-item py-2" onClick={() => handleEdit(row.id)} >
              <span><UilPen size="14" className="text-secondary me-1 mb-1"/>Edit integration</span>
            </li>
            <li className="fw-bold cursor dropdown-item py-2" onClick={() => handleDelete(row.id)} >
                <span><UilTrashAlt size="14" className="text-danger me-1 mb-1"/>Delete integration </span>
            </li>
          </ul>
        </ul>
      ),
    }, 
  ]
  
  return (
    <>
      {integrationQuery.isError && <ErrorPage innerPage={false} error='500'/> }

      <PageHeader title='Integrations' subtitle='All Integrations'  component={ <Link to={Paths.Integration.pages.select.path} className="btn btn-secondary" > Add an Integration </Link> } />
      
      <ActionBar searchParams={searchParams} setSearchParams={handleSearch} sortOptions={configurations.IntegrationSort} setSort={setSort} placeholder="Search by Integration name"/>
      <DataTable 
        data={integrationQueryData?.data} 
        columns={columns} 
        isLoading={integrationQuery.isFetching}
        pagination={true}
        paginationMeta={integrationQueryData?.meta}
        onChangePage={page=> setPageNumber(page)}
      />
    </>
  )  

}