import React, { useState, useEffect, useRef } from "react";
import { UilPen, UilTrashAlt, UilMoneyBill } from '@iconscout/react-unicons';
import { Modal } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Skeleton from 'react-loading-skeleton';

import { notify } from '../../../scripts/notify'
import api from "../../../api";

import Loader from '../../../refactoring/components/global/Loader';
import PageHeader from "../../../refactoring/layouts/PageHeader";
import PageInfo from "../../../components/pagination/PageInfo";
import TaxCreationForm from './TaxCreationForm';
import ReactPagination from "../../../components/pagination/ReactPagination";


export default function TaxManagement() {
  const [loading, setLoading] = useState(true);
  const [taxes, setTaxes] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState({
    currentPage: pageNumber,
    per_page: 0,
    total_count: 0,
    last_page: 0
  });
  const [isOpen, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({
    tax_id: "",
    tax_name: "",
    tax_amount: "",
    tax_type: "",
    tax_application: "",
  });
  const refName = useRef();

  const pageChange = pageNo => {
    setPageNumber(pageNo);
  };

  async function fetchData() {
    await api.tax.get({
      params: {
        paginate: true,
        page: pageNumber
      }
    })
      .then(response => {
        setTaxes(response.data.data);
        setPages({
          currentPage: response.data.current_page,
          per_page: response.data.per_page,
          total_count: response.data.total,
          lastPage: response.data.last_page
        });

        if (pageNumber >= response.data.last_page) {
          setPageNumber(response.data.last_page);
        }

      });
    await setLoading(false);
  }

  const add = () => {
    setOpen(true);
    setInputs({
      tax_id: "",
      tax_name: "",
      tax_amount: "",
      tax_type: "",
      tax_application: "",
    })
  }

  const edit = (id, tax_name, tax_amount,tax_type,tax_application) => {
    setOpen(true);
    setInputs({
    tax_id : id,
    tax_name : tax_name,
    tax_amount : tax_amount,
    tax_type : { label: tax_type, value: tax_type, name: 'tax_type' },
    tax_application : { label: tax_application, value: tax_application, name: 'tax_application' },
  });
  }

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  }

  const handleDeleteTaxClass = (taxId) => {
    api.tax.delete({ tax_id: taxId })
      .then(response => {
        notify(response.data.type, response.data.message);
        if (response.data.status === 1) {
          fetchData();
        }
      })
      .catch(error => {
        notify('error');
        console.log('Error::', error);
      })
  }

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <React.Fragment>
      <div className="bg-white p-5 border rounded">

        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Tax Types" subtext="Manage tax types" />
          
          <div className="mb-4 text-end d-flex align-items-center">
            <button  className="btn btn-secondary"  onClick={() => add()} > Add Tax Type </button>
          </div>
        </div>

   
        <div className="border rounded-3 overflow-hidden">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Tax Name</th>
                <th>Tax Percentage</th>
                <th>Tax Type</th>
                <th>Tax Application</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='100%' className='text-center'>
                    <Skeleton count={5} height={30} className="mb-3"/>
                  </td>
                </tr> 
              ) : taxes.length ? (
              taxes.map(tax => (
                <tr key={tax.id}>
                  <td>{tax.tax_name}</td>
                  <td>{tax.tax_amount}</td>
                  <td>{tax.tax_type}</td>
                  <td>{tax.tax_application}</td>
                  <td className="d-flex justify-content-end">
                    <OverlayTrigger placement="top"  overlay={<Tooltip id="tooltip-top">Edit tax</Tooltip>}>
                      <span className="me-3 active-icon" onClick={() =>{setIsEdit(true); edit(tax.id, tax.tax_name, tax.tax_amount,tax.tax_type,tax.tax_application)}} id="edit">
                        {<UilPen size="14" className="text-secondary" />}
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete tax</Tooltip>}>
                      <span className="cursor active-icon" id="delete">
                        {<UilTrashAlt size="14" onClick={() => handleDeleteTaxClass(tax.id)} className="text-danger" />}
                      </span>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))
                  ) : (

                  <tr>
                    <td colSpan="100%">
                      <div className="text-center bg-white py-5">
                        {<UilMoneyBill size="38" className="mb-3 text-secondary" />}
                        <p className="h5 text-gray-700">No tax type available</p>
                        <p className="small text-gray-600">Enter a tax name, percentage, type and application to create a tax type. All the added tax types will be reflected here</p>
                      </div>
                    </td>
                  </tr>
   
      )}
            </tbody>
            <tfoot>
              <tr className="border-top">
                <td colSpan="2">
                  <PageInfo
                    perPage={pages.per_page}
                    currentPage={pages.currentPage}
                    total={pages.total_count}
                  />
                </td>
                {pages ? (
                  <td colSpan="6">
                    <ReactPagination pageNumber={pageChange} pages={pages} />
                  </td>
                ) : null}
              </tr>
            </tfoot>
          </table>
        </div>
    
      </div>

      {/* TODO: fetch from component, below section */}
      {isOpen ? (
        <Modal show={isOpen} onEntered={() => refName.current.focus()} onHide={closeModal} centered dialogClassName="modal-md">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>{ inputs.tax_id ? "Edit Tax type" : "Add Tax type" }</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <TaxCreationForm callback={fetchData} inputs={inputs} isEdit={isEdit} closeModal={closeModal} refName={refName}/>
          </Modal.Body>
        </Modal>
      ) : null
      }
    </React.Fragment>
  );
}
