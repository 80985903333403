import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { useValidator } from "../../../utils/validator";
import { preventNonNumeric } from "../../../../scripts/utils";
import { MetaDataContext } from "../../../../web/context/metaData";
import { useCouponCreateQuery, useCouponUpdateQuery, } from "../../../stores/setting/coupon";

export default function Form(props) {
  const [inputs, setInputs] = useState({
    id: props?.inputs?.id || null,
    name: props?.inputs?.name || "",
    amount: props?.inputs?.amount || "",
  });

  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);

  const [couponCreateQuery , {createErrors}] = useCouponCreateQuery();
  const [couponUpdateQuery , {updateErrors}] = useCouponUpdateQuery();
  const {data: preferences} = useContext(MetaDataContext);

  const [validator, { validate }] = useValidator(inputs, errors);

  const handleInputChange = (event) => {
    event.preventDefault();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if(_.isNull(inputs.id)) {
        couponCreateQuery.mutate({ ...inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                props.closeModal();
                if (props.callback) props.callback();
              }
            },
          }
        );
      } else {
        couponUpdateQuery.mutate({ ...inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                props.closeModal();
              }
            },
          }
        );
      }
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);

  return (
    <div>
      <section className="">
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-lg-6  ">
              <label className="fw-bold me-3 ">Coupon/Vouchers code</label>
              <input
                placeholder="Coupon/Vouchers name"
                type="text"
                name="name"
                autoComplete="off"
                className="form-control"
                value={inputs.name}
                onChange={(event) => handleInputChange(event)}
                ref={props.refName}
              />
              {validate(inputs.name, "required|min:1|max:50", { title: "coupon name", onServer: errors['name']})}
            </div>
            <div className="col-lg-6  ">
              <label className="fw-bold me-3 ">Amount</label>
              <input
                placeholder={preferences.currency_symbol}
                type="number"
                name="amount"
                className="form-control"
                min="0"
                autoComplete="off"
                onKeyPress={(e) => preventNonNumeric(e)}
                value={inputs.amount}
                onChange={(event) => handleInputChange(event)}
              />
              {validate(inputs.amount, "required|min:1", { title: "amount" })}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-secondary w-100">
                {props.isEdit ? "Save changes" : "Add "}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
