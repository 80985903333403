import React, { useEffect, useRef, useState } from "react";
import { UilEllipsisH, UilPen, UilTrashAlt } from "@iconscout/react-unicons";

import lang from "../../../../lang/en/Theme/Colors";
import DataTable from "../../../components/common/DataTable";
import ModalComponent from "../../../components/common/ModalComponent";
import PageHeader from "../../../layouts/PageHeader";
import ProfileIcon from "../../../components/common/ProfileIcon";

import { confirmDelete } from "../../../utils/scripts";

import { useUserDeleteQuery, useUserListQuery, } from "../../../stores/setting/user";
import Form from "./Form";

export default function Listing() {

  const refName = useRef(); 
  const [inputs, setInputs] = useState({
    userID: null,
    email: "",
    group: "",
  });
  
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [query, { setPageNumber }] = useUserListQuery();
  const deleteGroup = useUserDeleteQuery();

  const add = () => {
    setOpen(true);
    setInputs({
      userID: "",
      email: "",
      groups: "",
    });
  };

  const edit = (userID, email, group) => {
    setOpen(true);
    setInputs({
      userID: userID,
      email: email,
      groups: group,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleRemoveUser = (id) => {
    confirmDelete("User", () => deleteGroup.mutate(id));
  };

  const columns = [
    {
      id: "member",
      name: "Member",
      cell: (row) => (
        <>
          <ProfileIcon text={row.name ? row.name : ""} />
          <span> {row.name} </span>
        </>
      ),
    },
    {
      id: "email",
      name: "Email",
      cell: (row) =>
        row.id === 1 ? (
          <>
            {row.email}
            <span className="badge rounded-pill bg-white text-success ms-3 px-3 py-1 border">
              Owner
            </span>
          </>
        ) : (
          row.email
        ),
    },
    {
      id: "role",
      name: "Role",
      cell: (row) =>
        row.groups.map((group) => (
          <div key={group.id} id={group.id} className="small text-gray-600">
            {group.group_name}
          </div>
        )),
    },
    {
      id: "action",
      name: "",
      cell: (row) =>
        row.id === 1 ? (
          <></>
        ) : (
          <div className="overflow-visible text-end">
            <div className="dropdown">
              <button
                className="btn btn-link"
                id="dropdownMenu"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  {<UilEllipsisH size="16" color={lang.colors.secondary} />}
                </span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu">
                <li
                  className=" fw-bold cursor dropdown-item"
                  onClick={() => {
                    setIsEdit(true);
                    edit(row.id, row.email, row.groups);
                  }}
                >
                  {<UilPen size="14" className="text-secondary me-1 mb-1" />}{" "}
                  Edit User
                </li>
                <li
                  className=" text-danger fw-bold cursor dropdown-item"
                  onClick={() => handleRemoveUser(row.id)}
                >
                  {<UilTrashAlt size="14" className="text-danger me-1 mb-1" />}{" "}
                  Delete User
                </li>
              </ul>
            </div>
          </div>
        ),
    },
  ];

  return (
    <div>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader
            subtitle="Users"
            subtext="Manage users to your dashboard"
          />
          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()}>
              Add User
            </button>
          </div>
        </div>

        <div className="border rounded-3 overflow-hidden">
          <DataTable
            data={query?.data?.data}
            columns={columns}
            isLoading={query.isLoading}
            pagination={true}
            paginationMeta={query?.data}
            onChangePage={(page) => setPageNumber(page)}
          />
        </div>

        {open ? (
          <ModalComponent
            show={open}
            onHide={closeModal}
            onEntered={() => refName.current.focus()}
            title={inputs.userID ? "Edit User" : "Add User"}
            body={
              <Form
                inputs={inputs}
                isEdit={isEdit}
                closeModal={closeModal}
                refName={refName}
              />
            }
          />
        ) : null}
      </div>
    </div>
  );
}
