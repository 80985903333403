import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Paths } from "../../../../routes";

import { useValidator } from "../../../utils/validator";
import {
  useResetPasswordQuery,
  useUserViewQuery,
} from "../../../stores/setting/accountSetting";
// import PasswordModal from '../../components/PasswordModal';

export default function Password() {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(undefined);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [, forceUpdate] = useState(false);
  const [validator, { validate, validateIf }] = useValidator(errors);

  const [queryData] = useUserViewQuery();
  const [resetPassword, { createErrors }] = useResetPasswordQuery();

  useEffect(() => {
    if (queryData.isFetched) {
      setInputs(queryData.data);
    }
  }, [queryData.data]);

  const handleChange = (event) => {
    event.persist();
    setErrors({});
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      const { old_password, password, password_confirmation } = inputs;
      resetPassword.mutate(
        { old_password, password, password_confirmation },
        {
          onSuccess: (data) => {
            if (data.status === 1) {
              // setInputs({});
              if (localStorage.getItem("flag") === 1) {
                localStorage.setItem("flag", 0);
                setTimeout(() => {
                  navigate.push(Paths.Product.pages.index.path);
                }, 5000);
              }
            }
          },
        }
      );
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  return (
    <>
      <section className="bg-white p-5 rounded mb-4 border">
        <form className="" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <h6>Change Password</h6>
              <div className="small text-gray-500">
                <p>You can change your password here</p>
                Password should contain
                <ul>
                  <li> Alphabet </li>
                  <li> Number </li>
                  <li> Special character </li>
                  <li> Should be minimum 6 in length </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control "
                    id="old_password"
                    name="old_password"
                    placeholder="Old password"
                    value={inputs.old_password || ""}
                    onChange={handleChange}
                  />
                  <label htmlFor="old_password">Old password</label>
                </div>
                {validate(inputs.old_password, "required", {
                  title: "old password",
                  onServer: errors["old_password"],
                })}
              </div>

              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control "
                    id="password"
                    name="password"
                    placeholder="New password"
                    value={inputs.password || ""}
                    onChange={handleChange}
                  />
                  <label htmlFor="password">New password</label>
                </div>
                {validate(inputs.password, "required", {
                  title: "password",
                  onServer: errors["password"],
                })}
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control"
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Confirm password"
                    value={inputs.password_confirmation || ""}
                    onChange={handleChange}
                  />
                  <label htmlFor="password_confirmation">
                    Confirm password
                  </label>
                </div>
                {validate(inputs.password_confirmation, "required", {
                  title: "password confirmation",
                  onServer: errors["password_confirmation"],
                })}
              </div>

              <div className="mb-3 text-end">
                <button type="submit" className="btn btn-secondary px-4">
                  Change account password
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>

      <section className="p-4 border-bottom d-none">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <h6>Enable email notifications</h6>
            <p className="small text-gray-500 m-0">
              Never miss an important update! Get notifications on your
              registered email when you’re not around.
            </p>
          </div>
          <div className="col-md-auto">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                defaultChecked
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
