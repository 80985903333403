import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import apiClient from "../header";


function sendInvoice(id) {
  return apiClient
    .post(`invoices/send-invoice`, { id: id })
    .then((result) => result.data);
}

export function useSendInvoiceQuery() {
  
  var toastify;

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
  }

  function handleError(data) {
    toast.update(toastify, { render: "Oops something went wrong!", type: "error", isLoading: false, autoClose: true, closeButton: true, });
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  const mutate = useMutation((id) => sendInvoice(id), {
    retry: false,
    onMutate: handleProcessing,
    onError: handleError,
    onSuccess: handleSuccess,
  });

  return [mutate];
}
