import React, { useEffect, useState } from "react";

import { useValidator } from "../../../utils/validator";

import { useManufacturerCreateQuery, useManufacturerUpdateQuery, } from "../../../stores/setting/manufacturer";


export default function Form(props) {

  const [inputs, setInputs] = useState({
    id: (props.inputs && props.inputs.id) || null,
    name: (props.inputs && props.inputs.name) || "",
  });

  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);

  const [validator, { validate }] = useValidator(errors);
  const [manufacturerCreateQuery, { createErrors }] = useManufacturerCreateQuery();
  const [manufacturerUpdateQuery, { updateErrors }] = useManufacturerUpdateQuery();

  const handleInputChange = (event) => {
    event.preventDefault();
    setErrors({});
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if (inputs.id) {
        manufacturerUpdateQuery.mutate(inputs, {
          onSuccess: (data) => {
            if (data.status) {
              props.closeModal();
            }
          },
        });
      } else {
        manufacturerCreateQuery.mutate(inputs, {
          onSuccess: (data) => {
            if (data.status) {
              if (props.callback) props.callback();
              props.closeModal();
            }
          },
        });
      }
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

    useEffect(() => {
      if (createErrors) {
        setErrors(createErrors);
      }
    }, [createErrors]);

    useEffect(() => {
      if (updateErrors) {
        setErrors(updateErrors);
      }
    }, [updateErrors]);

  return (
    <div>
      <section className="">
        <form onSubmit={ handleSubmit}>
          <div className="row mb-4">
            <div className="col-lg-12">
              <label className="fw-bold me-3 ">Manufacturer Name</label>
              <div className="">
                <input
                  type="text"
                  name="name"
                  placeholder="Manufacturer Name"
                  onChange={(event) => handleInputChange(event)}
                  className="form-control "
                  value={inputs.name || ""}
                  ref={props.refName}
                />
                {validate(inputs.name, "required|min:3|max:100", {
                  title: "name",
                  onServer: errors["name"],
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <button type="submit" className="btn btn-secondary w-100">
                {props.isEdit ? "Save changes" : "Add manufacturer"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
