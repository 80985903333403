import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { notify } from "../../../scripts/notify";

import apiClient from "../header";

export function useDeleteChannelQuery() {
  const queryClient = useQueryClient();

  function deleteChannel(id) {
    const query = apiClient
      .post(`channels/delete`, { id: id, })
       .then((result) => {
        notify(result.data.type, result.data.message);
        queryClient.invalidateQueries(["channel-list"]);
      })
      .catch((err) => {
        Swal.fire({
          title: "Please type 'confirm' to delete",
          input: "text",
          inputPlaceholder: "Type confirm",
          showCancelButton: true,
        }).then((result) => {
          if (result.value && result.value.toLowerCase() == "confirm") {
            apiClient .post(`channels/delete`, { id: id, flag: result.value.toLowerCase(), })
              .then((result) => {
                queryClient.invalidateQueries(["channel-list"]);
                notify(result.data.type, result.data.message);
              })
              .catch((err) => {
                notify("error", "Try again.");
              });
          } else {
            notify("error", "Try again.");
          }
        });
      });

    return query;
  }

  function handleSuccess() {
    queryClient.invalidateQueries(["channel-list"]);
  }

  return useMutation((id) => deleteChannel(id), {
    retry: false,
    // onSuccess: handleSuccess,
  });
}
