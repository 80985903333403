import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from 'lodash';
import { UilInfoCircle, UilSync, UilCodeBranch } from '@iconscout/react-unicons';

import PageHeader from "../../../layouts/PageHeader";
import Mapping from "./Mapping";
import InfoIcon from "../../../components/common/InfoIcon";
import { useChannelViewQuery } from "../../../stores/channel/view";
import { useProductMappingListQuery } from "../../../stores/channel/productMapping";
import ActionBar from "../../../components/global/ActionBar";
import ErrorPage from "../../../components/errors/ErrorPage";
import DataTable from "../../../components/common/DataTable";
import ModalComponent from "../../../components/common/ModalComponent";
import { QueryClient } from "@tanstack/react-query";


export default function ProductMapping() {
  const { id } = useParams();
  const [modelOpen, setModelOpen] = useState(false);
  
  const [channel, setChannel] = useState('');
  const [channelProducts, setChannelProducts] = useState('');
  
  const [productMap, setProductMap] = useState([]);
  const [bulkProductMap, setBulkProductMap] = useState([]);
  const [isBulkProduct, setIsBulkProduct] = useState(false);
  const [ productQuery, productQueryData, { searchParams, setSearchParams, setPageNumber, setSort, setFilters }, ] = useProductMappingListQuery({ id, defaultValue: { limit: 15 }, });

  const [channelViewQuery, channelViewQueryData, { viewError }] = useChannelViewQuery(id);

  const openModal = (s_id, c_id, c_name, s_name, isBulk = false) => {
    setModelOpen(true);
    if (!isBulk) {
      setProductMap({
        system_product_id: s_id,
        channel_product_id: c_id,
        product_name: c_name,
        system_product_name: s_name
      });
    } else {
      setIsBulkProduct(true);
    }
  }

  const closeModal = () => {
    setBulkProductMap([]);
    setIsBulkProduct(false);
    setModelOpen(false);
  }

  useEffect(() => {
    if (channelViewQuery.isFetched) {
      setChannel(channelViewQueryData.data);
    }
  }, [channelViewQuery.data]);

  useEffect(() => {
    if (productQuery.isFetched) {
      setChannelProducts(productQueryData.data);
    }
  }, [productQuery]);

  function fetchData() {
    QueryClient.invalidateQueries(['channel-product-list']);
  }
  
  const handleInputChange = (event, channel_product_id, channel_product_name) => {
    let checked = event.target.checked;

    channelProducts.map(product => {
      if (product.product_id === channel_product_id) {
        product.checked = checked;
      }
      return product;
    });

    if (checked) {
      setBulkProductMap(prevProps => ([
        ...prevProps,
        {
          product_id: channel_product_id,
          product_name: channel_product_name
        }
      ]));
    } else {
      setBulkProductMap(_.remove(bulkProductMap, (product) => { return product.product_id != channel_product_id }));
    }
  }

  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: "",
      name: "",
      body: {width: '100px'},
      cell: (row) => (
        <input
          className="form-check-input"
          type="checkbox"
          name={row.id}
          checked={row.checked}
          onChange={(event) =>
            handleInputChange(event, row.product_id, row.product_name)
          }
        />
      ),
    },
    {
      id: "product_name",
      name: "Product Name",
      selector: (row) => row.product_name,
    },
    ,
    {
      id: "sku",
      name: "sku",
      body: {width: '400px'},

      selector: (row) => row.sku,
    },
    {
      name: "Linked with",
      id: "action",
      body: {width: '400px'},
      cell: (row) => (
        <>
          {row.sys_product_name ? (
            <a
              href="#"
              className="link-info"
              onClick={() =>
                openModal( row.linked_with, row.product_id, row.product_name, row.sys_product_name )
              }
            >
              <UilCodeBranch width="16" /> {row.sys_product_name}
            </a>
          ) : (
            <a
              href="#"
              className="text-muted"
              onClick={() =>
                openModal(row.linked_with, row.product_id, row.product_name)
              }
            >
              <UilCodeBranch width="16" /> Link product
            </a>
          )}
        </>
      ),
    },
  ];



  return (
    <>
      {productQuery.isError && <ErrorPage innerPage={false} error="500" />}

      <PageHeader title="Channel list" subtitle="Product Mapping" />

      <div>
        <div className="d-flex justify-content-between mb-3">
          <InfoIcon type={channel.channel_type} name={channel.channel_name} />
        </div>

        <div className="h6 mb-3">
          <p className="small text-dark mb-1">
            Select a channel product to map with multiple system products.
          </p>
          <p className="small text-dark mb-0">
            To map more than one channel products, and click on bulk mapping
          </p>
        </div>

        <div></div>
      </div>

      <div className="row mb-3 justify-content-between">
        <div className="col">Channel products</div>
        <div className="col-auto">
          <span className="text-muted">
            Learn how to map products <UilInfoCircle width="16" />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col mb-3 mb-md-0">
          <ActionBar
            searchParams={searchParams}
            setSearchParams={handleSearch}
            placeholder="Search by Product name"
            component={
              <div className="">
                <button
                  className="btn bg-lighter px-3"
                  type="button"
                  aria-expanded="false"
                  onClick={() => openModal("", "", "", "", true)}
                  disabled={_.isEmpty(productQueryData?.data)}
                >
                  Bulk Mapping{" "}
                  <UilCodeBranch width="16" className="text-secondary" />
                </button>
              </div>
            }
          />
        </div>
      </div>

      <DataTable
        data={productQueryData?.data}
        columns={columns}
        isLoading={productQuery.isFetching}
        pagination={true}
        paginationMeta={productQueryData?.meta}
        onChangePage={(page) => setPageNumber(page)}
      />

      <ModalComponent
        show={modelOpen}
        size="modal-lg"
        onHide={() => closeModal()}
        title={
          isBulkProduct ? "Bulk Mapping" : "Map " + productMap.product_name
        }
        body={
          <Mapping
            callback={fetchData}
            productMap={productMap}
            isBulk={isBulkProduct}
            bulkProducts={bulkProductMap}
            closeModal={closeModal}
          />
        }
      />
    </>
  );


}