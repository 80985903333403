 
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../header";

// default listing state
var defaultState = {
  'searchParams': undefined,
  'pageNumber': 1,
  'filters': [],
  'sort': null,
  'limit': 15,
}

function getList(signal, queryKey) {  
  const [_key, {searchParams, pageNumber, sort, limit, isPaginated}] = queryKey;
  return apiClient.get(`/paymentMethod`, {
    params: { page: pageNumber, search: searchParams, sorts:sort, limit: limit, paginated: isPaginated}, 
    signal,
  }).then(
    (result) => result.data,
  );
}

export function usePaymentMethodListQuery({ enabled = true, defaultValue = {} } = {}) {
  //override defaultState
  defaultState = {...defaultState, ...defaultValue};  
  
  const [pageNumber, setPageNumber] = useState(defaultState.pageNumber)
  const [searchParams, setSearchParams] = useState(defaultState.searchParams)
  const [sort, setSort] = useState(defaultState.sort)
  const [limit, setLimit] = useState(defaultState.limit)
  const [isPaginated, setPaginated] = useState(defaultState.isPaginated)

  const query = useQuery(['payment-methods-list',{ searchParams, pageNumber, sort, limit, isPaginated}], 
  ({ signal, queryKey }) => getList(signal, queryKey), 
  {
    retry: 0,
    staleTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: enabled
  })

  return [
    query, 
    {
      'sort': sort,
      'setSort': setSort,
      'limit': limit,
      'setLimit': setLimit,
      'searchParams': searchParams,
      'setSearchParams': setSearchParams, 
      'setPageNumber': setPageNumber, 
    },
  ]
}

export function usePaymentMethodCreateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function create(data) {
    return apiClient
      .post(`paymentMethod/add`, data)
      .then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["payment-methods-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((newPayment) => create(newPayment), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}

export function usePaymentMethodUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function update(data) {
    return apiClient
      .post(`paymentMethod/edit`, data)
      .then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["payment-methods-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((updatePayment) => update(updatePayment), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      updateErrors: errors,
    },
  ];
}

export function usePaymentMethodDeleteQuery() {
  const queryClient = useQueryClient();

  var toastify;
  function remove(id) {
    return apiClient
      .post(`paymentMethod/delete`, { id: id })
      .then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["payment-methods-list"]);
    }
  }

  const mutate = useMutation((id) => remove(id), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return mutate;
}
