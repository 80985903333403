import React, { useContext, useEffect, useState, useRef } from "react";

import config from "../../../configurations";
import { UilExport, UilAngleDown } from "@iconscout/react-unicons";
import colors from "../../../lang/en/Theme/Colors";
import ReactToPrint from "react-to-print";
import _ from "lodash";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { MetaDataContext } from "../../../web/context/metaData";
import { subDays } from "date-fns";
import { Button } from "react-bootstrap";

import PageHeader from "../../layouts/PageHeader";
import FilterComponent from "../../components/common/FilterComponent";
import { useReportForRecurringCustomerQuery } from "../../stores/reports/recurringcustomer";
import SalesAnalysisComponent from "./SaleAnalysisComponent";
import moment from "moment";
import RecurringCustomerComponent from "./RecurringCustomerComponent";
import localstorage from "../../../localstorage";

export default function RecurringCustomerAnalysis() {

  const settings = JSON.parse( localstorage.settings.get() );
  let userSetting = settings || {};

  const {data: preferences} = useContext(MetaDataContext);
  const [sales, setSales] = useState(true);
  const [filtersData, setFiltersData] = useState(settings ? (settings?.recurringCustomerFilter) : []);
  const [checkFilter, setCheckFilter] = useState(false);
  const componentToPrint = useRef(null);

  const [ reportQuery, { setFilters, setKey, pdf, setPdf, exportData, setExportData, selectionRange, setSelectionRange, setPageNumber }, ] = useReportForRecurringCustomerQuery();

  const initial = {
    startDate: new Date(selectionRange.startDate),
    endDate: new Date(selectionRange.endDate),
    key: "selection",
  };

  const [reportData, setReportData] = useState({});
  const [date, setDate] = useState(initial);

  const chartSettings = {
    stroke: [3],
    font: {
      fontSize: 12,
      fill: colors.colors.gray500,
    },
  };

  const fetchFilteredData = async (key) => {
    setKey(key.key)
    setPageNumber(key.pageNumber);
  };

  const exportExcel = () => {
    setExportData({
      export: "xlsx",
      response_type: "blob",
    });
  };

  const findFilter = () => {
    setFilters([...Object.values(filtersData)]);
    setSelectionRange(date);
    userSetting = {
      ...userSetting,
      recurringCustomerFilter: filtersData,
    };
    localstorage.settings.set(userSetting);

  };

  const handleSelect = (ranges) => {
    setDate({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
  };

  useEffect(() => {
    if ( reportQuery.data instanceof Blob) {
      const url = URL.createObjectURL(reportQuery.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute( "download", `report ${moment(selectionRange.startDate).format( "D-M-Y" )} to ${moment(selectionRange.endDate).format("D-M-Y")}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setExportData(null);
    }
  }, [reportQuery.data]);

  useEffect(() => {
    if (_.isEmpty(filtersData) && checkFilter) {
      setFilters([]);
      setSelectionRange(initial);
    } else if (!_.find(filtersData, { name: "Date Range" })) {
      setDate(initial);
    }
  }, [filtersData]);

  useEffect(() => {
    if (pdf?.paginate == false && pdf?.resolve != null) {
      pdf.resolve();
      setPdf({ paginate: true, resolve: null });
    }
  }, [reportQuery.data]);

  return (
    <div>
      <PageHeader title="All Reports" subtitle="Recurring Customer Analysis" />
      <div>
        <div className="d-flex justify-content-end mb-4">
          <FilterComponent
            runFilter={findFilter}
            insertData={setFiltersData}
            activeFilters={filtersData}
            filters={config.RecurringCustomerAnalysis}
            sales={sales}
            selectionRange={date}
            setSelectionRange={setDate}
            handleSelect={handleSelect}
            initial={initial}
            setCheckFilter={setCheckFilter}
            checkFilter={checkFilter}
          />
          <div className="dropdown">
            <button
              className="btn btn-secondary"
              id="dropdownMenu"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <UilExport width="16" className="me-2" />
              Export As
              <UilAngleDown width="16" />
            </button>
            <ul className="dropdown-menu " aria-labelledby="dropdownMenu">
              <ReactToPrint
                trigger={() => (
                  <div className="text-decoration-none">
                    <li className="dropdown-item fw-bold cursor p-0 ">
                      <Button id={"print"} variant="contained" color="primary">
                        {"Pdf"}
                      </Button>
                    </li>
                  </div>
                )}
                content={() => componentToPrint.current}
                onBeforeGetContent={() => {
                  return new Promise((resolve) => {
                    setPdf({ paginate: false, resolve: resolve });
                  });
                }}
                onAfterPrint={() => {
                  setPdf({ paginate: true, resolve: null });
                }}
              />
              <a onClick={exportExcel} className="text-decoration-none">
                <li className="dropdown-item fw-bold cursor ">Excel</li>
              </a>
            </ul>
          </div>
        </div>
        <RecurringCustomerComponent
          refs={componentToPrint}
          reportData={reportQuery}
          preferences={preferences}
          chartSettings={chartSettings}
          setReportData={setReportData}
          fetchFilteredData={fetchFilteredData}
          pdf={pdf}
        />
      </div>
    </div>
  );
}
