import { isNull, snakeCase } from "lodash"
import { UilAngleDown, UilSearch } from '@iconscout/react-unicons';
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';


export default function CustomSelectModal({
  handleMultiselect,
  isSearchable,
  isMulti,
  type,
  getOptionValue,
  getOptionLabel,
  formatOption,
  onChange,
  ...rest
}) {

  const DropdownIndicator = () => {
    return (
      <>
        <div className="px-2">
          <UilAngleDown width="16" />
        </div>
      </>
    )
  };

  const SearchIcon = () => {
    return (
      <>
        <div className="px-2">
          <UilSearch size="14" />
        </div>
      </>
    )
  };

  const formatOptionLabel = (option, { context }) => {
    return context === "menu" && option.__isNew__ ? (
      <span className="text-dark">
        Add {option.value}
      </span>
    ) : (
      <div>
        <span>{option.name}</span>
      </div>
    );
  };


  const selectType = (selectType) => {
    switch (selectType) {
      case '1':
        return (
          <Select
            {...rest}
            isMulti={isMulti}
            getOptionValue={getOptionValue ? (getOptionValue) : (option => option.id)}
            getOptionLabel={getOptionLabel ? (getOptionLabel) : (option => option.name)}
            // styles={{ ...baseStyles, ...customStyles }}
            formatOptionLabel={formatOption ? formatOptionLabel : null}
            onChange={(event) => onChange(event)}
          />
        );
      case '2':
        return(
          <CreatableSelect
            {...rest}
            cacheOptions
            defaultOptions
            isSearchable={true}
            getOptionValue={getOptionValue ? (getOptionValue) : (option => option.id)}
            getOptionLabel={getOptionLabel ? (getOptionLabel) : (option => option.name)}
            getNewOptionData={(inputValue, optionLabel) => ({
              id: optionLabel,
              value: inputValue,
              __isNew__: true
            })}
            // styles={{ ...baseStyles}}
            onChange={onChange} 
            noOptionsMessage={() => null}
            formatOptionLabel={formatOption ? formatOptionLabel : null}
          />
        );
      default:
        break;
    }
  }


  return (
    <>
      {selectType(type)}
    </>
   
  )
}