import React, { useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { UilQuestionCircle } from "@iconscout/react-unicons";

import api from "../../../api";
import { notify } from "../../../scripts/notify";

import PageHeader from "../../../refactoring/layouts/PageHeader";
import Loader from "../../../refactoring/components/global/Loader";

export default function Customization() {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [inputs, setInputs] = useState({});

  const validator = useRef(new SimpleReactValidator());

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name == "order_format") {
      setInputs((prevProps) => ({
        ...prevProps,
        order_format: {
          type: "preference",
          option: "order_number_format",
          value: event.target.value,
        },
      }));
    } else if (event.target.name == "invoice_format") {
      setInputs((prevProps) => ({
        ...prevProps,
        invoice_format: {
          type: "preference",
          option: "invoice_number_format",
          value: event.target.value,
        },
      }));
    } else if (event.target.name == "quotation_format") {
      setInputs((prevProps) => ({
        ...prevProps,
        quotation_format: {
          type: "preference",
          option: "quotation_number_format",
          value: event.target.value,
        },
      }));
    }
  };

  const fetchData = async () => {
    api.customization.get().then((response) => {
      let customizationData = response.data.data;
      setInputs((prevProps) => ({
        ...prevProps,
        order_format: customizationData.find((element) => element.option === "order_number_format"),
        quotation_format: customizationData.find((element) => element.option === "quotation_number_format"),
        invoice_format: customizationData.find((element) => element.option === "invoice_number_format"),
      }));
      setLoading(false);
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    console.log('inputs', inputs)
    if (validator.current.allValid()) {
      api.customization.add({ ...inputs })
        .then((response) => {
          notify(response.data.type, response.data.message);
          fetchData();
        })
        .catch((error) => {
          notify("error");
        });
    } else {
      notify("error", "Form error");
      setErrors((prevProps) => ({
        ...prevProps,
        ...validator.current.errorMessages,
      }));
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <form>
          <section className="bg-white p-5 rounded mb-4 border">
            <PageHeader
              subtitle="Customization"
              subtext="Default customization for the system."
            />
            <div className="row">
              <div className="col-md-12 ">
                <div className="mb-3">
                  <label className="fw-bold">Order Number Format</label>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Customize how your order number gets generated automatically when you create a new order.
                      </Tooltip>
                    }
                  >
                    <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                  </OverlayTrigger>
                  <input
                    className="form-control"
                    name="order_format"
                    id="order_format"
                    placeholder="Enter Order Number Format"
                    onChange={handleInputChange}
                    value={ inputs.order_format ? inputs.order_format.value : null }
                  />
                  {validator.current.message( "order format number", inputs.order_format.value, "required", { className: "small text-danger" } )}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="fw-bold">Invoice Number Format</label>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Customize how your Invoice Number gets generated automatically when you create a new invoice.
                      </Tooltip>
                    }
                  >
                    <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                  </OverlayTrigger>
                  <input
                    className="form-control"
                    id="invoice_format"
                    name="invoice_format"
                    placeholder="Enter Invoice Number Format"
                    onChange={handleInputChange}
                    value={ inputs.invoice_format ? inputs.invoice_format.value : null }
                  />
                  {validator.current.message( "invoice format number", inputs.invoice_format.value, "required", { className: "small text-danger" } )}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="fw-bold">Quotation Number Format</label>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Customize how your quotation number gets generated automatically when you create a new quotation.
                      </Tooltip>
                    }
                  >
                    <span>{<UilQuestionCircle size="16" className="text-primary ms-1 mb-1 cursor-pointer" />}</span>
                  </OverlayTrigger>
                  <input
                    className="form-control"
                    id="quotation_format"
                    name="quotation_format"
                    placeholder="Enter Quotation Number Format"
                    onChange={handleInputChange}
                    value={ inputs.quotation_format ? inputs.quotation_format.value : null }
                  />
                  {validator.current.message( "quotation format number", inputs.quotation_format.value, "required", { className: "small text-danger" } )}
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="p-4 add-new-product border bg-white rounded">
                <button className="btn btn-secondary text-white" onClick={onSubmit}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
