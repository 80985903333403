import _ from "lodash";
import { useState, useContext } from "react";

import CustomSelect from "../global/CustomSelect";
import { preventNonNumeric } from "../../../scripts/utils";
import { MetaDataContext } from "../../../web/context/metaData";
import ModalComponent from "../common/ModalComponent";
import { useValidator } from "../../utils/validator";

export default function SelectDiscountModal(props) {
  
  const [inputs, setInputs] = useState({
    discount: props.total.discount_percent || 0,
    discount_type: props.total.discount_type || null,
    inclusive_tax_discount: props.total.tax_discount || 0,
  });
  const { data: preferences } = useContext(MetaDataContext);
  const [validator, { validate }] = useValidator();
  const [, forceUpdate] = useState();

  const types = [
    { label: "Fixed", value: "fixed", name: "discount_type" },
    { label: "Percent", value: "percent", name: "discount_type" },
  ];

  function handleInputChange(label, e) {
    if (label === "discount") {
      var check = e.target.value;
      if (_.isEmpty(e.target.value)) {
        e.target.value = 0;
      } else if (check.charAt(0) == 0) {
        e.target.value = check.substring(1);
      } else {
        e.target.value = check;
      }
      setInputs((prevProps) => ({
        ...prevProps,
        discount: e.target.value,
      }));
    } else if (label === "discount_type") {
      setInputs((prevProps) => ({
        ...prevProps,
        discount_type: e.value,
      }));
    } else if (label === "checkDiscountTax") {
      if (e.target.checked == true) {
        var check = 1;
      } else {
        var check = 0;
      }
      setInputs((prevProps) => ({
        ...prevProps,
        inclusive_tax_discount: check,
      }));
    }
  }

  function handleSubmit() {
    if (validator.current.allValid()) {
      props.setInputs((prevProps) => ({
        ...prevProps,
        discount: inputs.discount,
        discount_type: inputs.discount_type,
        inclusive_tax_discount: inputs.inclusive_tax_discount,
      }));
      props.setTotal((prevProps) => ({
        ...prevProps,
        discount: inputs.discount,
        discount_type: inputs.discount_type,
        inclusive_tax_discount: inputs.inclusive_tax_discount,
      }));
      props.onHide("discount");
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  }

  return (
    <>
      <ModalComponent
        show={props.show}
        onHide={props.onHide}
        title="Discount"
        body={
          <div className="row align-items-start">
            <div className="col-6 mb-2 dropdown d-grid">
              <label className="fw-bold me-3">Discount Type</label>
              <CustomSelect
                placeholder="Discount type"
                className="mb-1"
                closeMenuOnSelect={true}
                isMulti={false}
                name="discount_type"
                options={types}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                value={_.find(types, function (obj) {
                  return obj.value === inputs.discount_type;
                })}
                onChange={(e) => handleInputChange("discount_type", e)}
              />
              {validate(inputs.discount_type, "required", {
                title: "discount_type",
              })}
            </div>
            <div className="col-6 mb-2">
              <label className="fw-bold me-3">Discount amount</label>
              <input
                placeholder={preferences.currency_symbol}
                type="number"
                name="discount"
                min="1"
                className="form-control mb-1"
                onKeyPress={(e) => preventNonNumeric(e)}
                value={inputs.discount}
                onChange={(e) => handleInputChange("discount", e)}
              />
              {inputs.discount_type == "percent" ||
              _.isNull(inputs.discount_type)
                ? validate(inputs.discount, "required|between:1,100,num", {
                    title: "discount",
                  })
                : validate(inputs.discount, "required|min:1,num", {
                    title: "discount",
                  })}
            </div>
            <div className="col-12">
              <label className="fw-bold">
                <input
                  type="checkbox"
                  name="inclusive_tax_discount"
                  className="form-check-input me-2"
                  value={inputs.inclusive_tax_discount}
                  checked={parseInt(inputs.inclusive_tax_discount)}
                  onChange={(e) => {
                    handleInputChange("checkDiscountTax", e);
                  }}
                />
                Discount without tax.
              </label>
            </div>
          </div>
        }
        footer={
          <div className="px-4 text-end">
            <button
              type="submit"
              className="btn btn-secondary"
              name="shipping"
              onClick={handleSubmit}
            >
              Add Discount
            </button>
          </div>
        }
      />
    </>
  );
}
