import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import { useValidator } from '../../../utils/validator';
import { useTrackpodCreateQuery } from "../../../stores/integrations/trackpod/create";


export default function Add({ step, setStep, setId }) {

  const initial = {
    integration_name: "",
    integration_username: "",
    api_key: "",
    api_url: ""
  };

  const [trackpodCreateQuery, { createErrors, id }] = useTrackpodCreateQuery();

  const [, forceUpdate] = useState();
  const [inputs, setInputs] = useState(initial);
  const [errors, setErrors] = useState({});
  const [validator, { validate }] = useValidator(errors)

  const handleInputChange = (event) => {
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
    if (errors[event.target.name]) {
      setErrors(prevProps => ({
        ...prevProps,
        [event.target.name]: ''
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      trackpodCreateQuery.mutate(inputs)
    }
    validator.current.showMessages();
    forceUpdate(true)
  }

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors)
    }
  }, [createErrors])

  useEffect(() => {
    setInputs(initial)
  }, [id])

  useEffect(() => {
    if (!_.isNull(id)) {
      setId(id);
      setStep(step + 1);
    }
  }, [id]);


  return (
    <>
      <div className="row">
        <div className="col-lg-6">

          <form onSubmit={handleSubmit}>
            <section className="bg-white p-5">
              <h4 className="mb-4">Add an Integration</h4>

              <div className="mb-3">
                <label className="" htmlFor="integration_name">Integration Name</label>
                <input
                  placeholder="Enter Integration Name"
                  type="text"
                  name="integration_name"
                  className="form-control"
                  value={inputs?.integration_name || ''}
                  onChange={handleInputChange}
                />
                {validate(inputs.integration_name, 'required', { title: 'integration_name', onServer: errors["integration_name"] })}
              </div>

              <div className="mb-3">
                <label className="" htmlFor="integration_username">TrackPod Username</label>
                <input
                  placeholder="Enter TrackPod Username"
                  type="text"
                  name="integration_username"
                  className="form-control"
                  value={inputs?.integration_username || ''}
                  onChange={handleInputChange}
                />
                {validate(inputs.integration_username, 'required', { title: 'integration_username' })}
              </div>

              <div className="mb-3">
                <label htmlFor="api_key">TrackPod API Key</label>
                <input
                  type="text"
                  name="api_key"
                  className="form-control"
                  placeholder="Enter TrackPod API Key"
                  value={inputs?.api_key || ''}
                  onChange={handleInputChange}
                />
                {validate(inputs.api_key, 'required', { title: 'api_key', onServer: errors["api_key"] })}
              </div>

              <div className="d-grid">
                <button className="btn btn-secondary">Add Integration</button>
              </div>
            </section>
          </form>

        </div>
      </div>
    </>
  );
};


