import React, { createContext, useState, useEffect, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

import apiClient from "../../refactoring/stores/header";

const MetaDataContext = createContext();

const MetaDataContextProvider = ({ children }) => {
  function getPreferences(queryKey, signal) { 
    const query =  apiClient.get(`preferences/get`, { signal}).then(
      (result) => { 
        return result.data.data.reduce((data, value) => ({ ...data, [value.option]: value.value}), {}) ; 
    });
    return query;
  }
  
  const query = useQuery(['preferences'], ({queryKey, signal}) => getPreferences(queryKey, signal), 
  {
    retry: 0,
    staleTime: Infinity,
    keepPreviousData : true,
    refetchOnWindowFocus: false,
  }) 

  return (
    <MetaDataContext.Provider value={{ ...query }}>
      {!query.isLoading && children }
    </MetaDataContext.Provider>
  );
};

export { MetaDataContext, MetaDataContextProvider };