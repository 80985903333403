import React from "react";
import { Link } from "react-router-dom";
import { UilArrowRight } from "@iconscout/react-unicons";

export default function List({name, link, icon, option}) {

  return (
    <div className="col-4">
      <Link to={link}>
        <div className="card p-4 text-center">
          <img src={icon} height={30} className="mb-3" alt="" />
          <div className="mb-3 fw-bold">{name}</div>
          <small>
            Start {option} <UilArrowRight size="12" className="ms-2" />
          </small>
        </div>
      </Link>
    </div>
  );
  
}
