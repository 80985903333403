
import React from 'react';
import { UilDatabase } from "@iconscout/react-unicons";
import CardComponent from '../../layouts/CardLayout';
import { Table } from 'react-bootstrap';
import { isEmpty } from 'lodash';

export default function NoDataTable({ title = '', columnNames }) {

  return (
    <CardComponent title={title}>
      <Table className="table border mb-4">
        <thead>
          {!isEmpty(columnNames) ? (
            <tr className="fw-bold">
              {columnNames.map(name => (
                  <th>{name}</th>
              ))}
            </tr>  
          ) : null}
        </thead>
        <tbody>
          <tr>
            <td colSpan="12">
              <div className="text-center bg-white py-5">
                <UilDatabase size="50" className="mb-3 text-secondary" />
                <h6 className="fw-bold">No data found!</h6>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </CardComponent>
  )

}
