import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UilPen, UilTrashAlt } from "@iconscout/react-unicons";

import configurations from "../../../configurations";
import DataTable from "../../components/common/DataTable";

import { useValidator } from "../../utils/validator";
import ModalComponent from "../../components/common/ModalComponent";
import CustomSelectComponent from "../../components/global/CustomSelectComponent";

import { useNotificationOrderQuery, } from "../../stores/notifications/view";
import { useDeleteTrigger, useTriggerCreateQuery, useTriggerEditQuery, useTriggerStatusQuery, } from "../../stores/notifications/notificationsTrigger";

export default function NotificationTriggers() {

  const [errors, setErrors] = useState(false);
  const [validator, { validate, validateIf }] = useValidator(errors);
  const [, forceUpdate] = useState();
  const [triggers, setTriggers] = useState([]);
  const [showModal, setShowModal] = useState({ isActive: false });
  const [trigger, setTrigger] = useState([]);
  const [triggerList, setTriggerList] = useState([]);
  const [selectedTriggerType, setSelectedTriggerType] = useState();

  const [orderData] = useNotificationOrderQuery();
  const updateStatus = useTriggerStatusQuery();
  const createTrigger = useTriggerCreateQuery();
  const editTrigger = useTriggerEditQuery();
  const deleteTrigger = useDeleteTrigger();

  function edit(data) {
    setTrigger(data);
    setSelectedTriggerType({
      label: _.startCase(data.key),
      value: data.key,
      name: data.key,
    });
    setTriggerList([
      { label: _.startCase(data.key), value: data.key, name: data.key },
    ]);
    setErrors(false);
    openModal("edit");
  }

  function add() {
    let array = configurations.OrderTriggers;
    triggers.map((type) => {
      array = _.reject(array, ["value", type.key]);
    });
    setTrigger({
      enabled: 1,
      key: "",
      email: { enabled: false, message: "", },
      sms: { enabled: false, message: "", },
      value: null,
    });
    setErrors(false);
    setTriggerList(array);
    openModal("add");
  }

  const deleteTriggers = (key) => {
    deleteTrigger.mutate(key);
  };

  const openModal = (type) => {
    setShowModal((prevProps) => ({
      ...prevProps,
      isActive: true,
      mode: type,
    }));
  };

  const closeModal = () => {
    validator.current.hideMessages();
    forceUpdate(true);
    setSelectedTriggerType();
    setShowModal((prevProps) => ({
      ...prevProps,
      isActive: false,
    }));
    setErrors(false);
  };

  const handleNotification = (event, name) => {
    setErrors(false);
    setTrigger((prevProps) => ({
      ...prevProps,
      [name]: { ...prevProps[name], [event.target.name]: event.target.name !== "enabled" ? event.target.value : event.target.checked, },
    }));
  };

  const handleTriggerType = (event) => {
    setErrors(false);
    setTrigger((prevProps) => ({
      ...prevProps,
      key: event.name,
    }));
    setSelectedTriggerType(event);
  };

  const handleTriggerStatus = (event) => {
    var status = event.target.checked ? 1 : 0;
    setTriggers((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.key === event.target.name) {
          return { ...obj, enabled: status };
        }
        return obj;
      });
      return newState;
    });
    updateStatus.mutate({ key: _.find(triggers, ["key", event.target.name]).key, value: status, });
  };

  const submitForm = (event) => {
    event.preventDefault();
    var inputs = {
      trigger: trigger.key,
      sms: trigger.sms,
      email: trigger.email,
      enabled: trigger.enabled,
    };

    if (validator.current.allValid()) {
      if (showModal.mode == "edit") {
        editTrigger.mutate(inputs, {
          onSuccess: (data) => {
            if (data.status) {
              closeModal(true);
            }
          },
        });
      } else {
        createTrigger.mutate(inputs, {
          onSuccess: (data) => {
            if (data.status) {
              closeModal(true);
            }
          },
        });
      }
    } else {
      validator.current.showMessages();
      setErrors(true);
      forceUpdate(true);
    }
  };

  const columns = [
    {
      id: "trigger_events",
      name: "Trigger Events",
      cell: (row) => _.startCase(row.key),
    },
    {
      id: "type",
      name: "Notification type",
      cell: (row) => (
        <>
          <span>
            {row.sms.enabled ? _.find(configurations.NotificationType, ["value", "sms"]) .label : ""}
          </span>
          <span>{row.sms.enabled && row.email.enabled ? "," : ""}</span>
          <span>
            {row.email.enabled ? _.find(configurations.NotificationType, ["value", "email"]) .label : ""}
          </span>
        </>
      ),
    },
    {
      id: "status",
      name: "Status",
      cell: (row) => (
        <div className="form-check form-switch">
          <input
            className="form-check-input cursor"
            type="checkbox"
            name={row.key}
            defaultChecked={row.enabled}
            onClick={handleTriggerStatus}
          />
          <label className="form-check-label"></label>
        </div>
      ),
    },
    {
      name: "",
      id: "action",
      cell: (row) => (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit trigger</Tooltip>} >
            <button type="button" className="btn p-0 me-3" id="edit_trigger" onClick={() => edit(row)} >
              <UilPen size="14" className="text-secondary" />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete trigger</Tooltip>} >
            <button type="button" className="btn p-0" id={trigger.key} onClick={() => deleteTriggers(row.key)} >
              <UilTrashAlt size="14" className="text-danger" />
            </button>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  const ModalContent = () => {
    return (
      <>
        <form onSubmit={submitForm} id="trigger" encType="multipart/form-data">
          <section className="mb-4">
            <h6 className="fw-bold">
              <span className="text-danger">*</span>Trigger type
            </h6>
            <div className="row">
              <div>
                <CustomSelectComponent
                  type="1"
                  id="order_trigger_type"
                  name="order_trigger_type"
                  placeholder="Order trigger type"
                  options={triggerList}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.label}
                  closeMenuOnSelect={true}
                  value={selectedTriggerType}
                  onChange={(e) => handleTriggerType(e)}
                />
                <div>
                  {validate( selectedTriggerType, "required", { title: "order_trigger_type" } )}
                </div>
              </div>
            </div>
          </section>
          <section className="mb-4 border-bottom">
            <div className="row align-items-center justify-content-between mb-2">
              <div className="col-md-6">
                {" "}
                <h6 className="fw-bold">Enable SMS Notification</h6>{" "}
              </div>
              <div className="col-md-auto">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input cursor"
                    type="checkbox"
                    name="enabled"
                    defaultChecked={trigger?.sms?.enabled}
                    onChange={(e) => handleNotification(e, "sms")}
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
            </div>
            <Collapse in={trigger?.sms?.enabled}>
              <div className={`mb-4`}>
                <label className="fw-bold">
                  <span className="text-danger">*</span>Message
                  <span className="text-light fw-light">
                    <small>(150 characters only)</small>
                  </span>
                </label>
                <div>
                  <textarea
                    rows="4"
                    cols="50"
                    placeholder="Enter a message"
                    name="message"
                    className="form-control"
                    value={trigger?.sms?.message || ""}
                    onChange={(e) => handleNotification(e, "sms")}
                  />
                </div>
                <div>
                  {validateIf( trigger?.sms?.message, "required|max:150", trigger?.sms?.enabled, "sms message" )}
                </div>
              </div>
            </Collapse>
          </section>

          <section className="mb-4 border-bottom">
            <div className="row align-items-center justify-content-between mb-2">
              <div className="col-md-6">
                {" "}
                <h6 className="fw-bold">Enable Email Notification</h6>{" "}
              </div>
              <div className="col-md-auto">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="enabled"
                    checked={trigger?.email?.enabled}
                    onChange={(e) => handleNotification(e, "email")}
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
            </div>
            <Collapse in={trigger?.email?.enabled}>
              <div className="">
                <div className="mb-4">
                  <label className="fw-bold">
                    <span className="text-danger">*</span>Subject{" "}
                    <span className="text-light fw-light">
                      <small>(50 characters only)</small>
                    </span>
                  </label>
                  <div>
                    <input
                      placeholder="Enter a Subject"
                      name="subject"
                      className="form-control"
                      value={trigger?.email?.subject || ""}
                      onChange={(e) => handleNotification(e, "email")}
                    />
                  </div>
                  <div>
                    {validateIf( trigger?.email?.subject, "required|max:50", trigger?.email?.enabled, "email subject" )}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="fw-bold">
                    <span className="text-danger">*</span>Message{" "}
                    <span className="text-light fw-light">
                      <small>(150 characters only)</small>
                    </span>
                  </label>
                  <textarea
                    rows="4"
                    cols="50"
                    placeholder="Enter a message"
                    name="message"
                    className="form-control"
                    value={trigger?.email?.message || ""}
                    onChange={(e) => handleNotification(e, "email")}
                  />
                  <div>
                    {validateIf( trigger?.email?.message, "required|max:150", trigger?.email?.enabled, "email message" )}

                  </div>
                </div>
              </div>
            </Collapse>
          </section>
          <div>
            {validate( trigger?.sms?.enabled || trigger?.email?.enabled, "enabled", { title: "trigger_notification" } )}
          </div>
        </form>
      </>
    );
  };

  useEffect(() => {
    if (orderData.isFetched) {
      setTriggers(orderData.data.data);
    }
  }, [orderData.isFetching]);

  return (
    <>
      <section className="border bg-white rounded p-4 mb-4">
        <h6 className="fw-bold">Order Notifications</h6>
        <p>Configure all notifications sent from Innoventory.</p>
        <button className="btn btn-secondary mb-4" disabled={orderData.isFetching || triggers.length == 4 } onClick={() => add()} >
          Add a Trigger
        </button>
        <div className="border">
          <DataTable
            data={triggers}
            columns={columns}
            isLoading={orderData.isLoading}
          />
        </div>
      </section>

      <ModalComponent
        show={showModal.isActive}
        onHide={closeModal}
        size={"modal-lg"}
        title="Trigger event"
        body={ModalContent()}
        footer={
          <div className="">
            <button type="submit" form="trigger" className="btn btn-secondary"> 
            {(showModal.mode == "add") ? ("Add Trigger") : ("Save Changes")} </button>
            <button type="button" className="btn btn-link text-decoration-underline text-muted" onClick={closeModal} >
              Cancel
            </button>
          </div>
        }
      />
    </>
  );
}
