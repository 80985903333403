import React, { useCallback, useEffect } from "react";
import { UilSearch } from "@iconscout/react-unicons";
import _ from "lodash";

export default function Search({callback, ...rest}) {
  const debouncedChangeHandler = useCallback(
    _.debounce((keyword) => {
      callback(keyword);
    }, 800),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  return (
    <div className="input-group">
      <input
        type="search"
        name="query"
        autoComplete="off"
        placeholder="Search"
        className="form-control border bg-white"
        onChange={({ target: { value } }) => debouncedChangeHandler(value)}
        {...rest}
      />
      <span className="input-group-text border bg-lighter">
        {<UilSearch size="14" className="text-primary" />}
      </span>
    </div>
  );
}
