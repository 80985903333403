import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import ErrorPage from "../../components/errors/ErrorPage";
import apiClient from "../header";

export function useCustomerOrderHistoryQuery({    
  id,
  showSamples = "false",
  enabled = true,
  defaultValue = {
    'pageNumber': 1,
    'limit': 15,
    'sort': null,
    'searchParams': undefined,
    'filters': [],
  }
} = {}) {

  const [pageNumber, setPageNumber] = useState(defaultValue.pageNumber)
  const [limit, setLimit] = useState(defaultValue.limit)
  const [searchParams, setSearchParams] = useState(defaultValue.searchParams)
  const [sort, setSort] = useState(defaultValue.sort)
  const [filters, setFilters] = useState(defaultValue.filters)
  const [viewError, setViewError] = useState();

  function getList(signal) {    
    console.log('id', id)
    const query =  apiClient.get(`channels/customer/order-history`, {
      params: { id: id, showSamples: showSamples, limit: limit, page: pageNumber, search: searchParams, sort:sort, filters: filters }, 
      signal,
    }).catch((error) => {
      setViewError(<ErrorPage error={error.response.status} />);
    })
    .then((result) => {
      if (result.data.status === 0) {
        setViewError(
          <>
            <ErrorPage />
          </>
        );
      }
      return result.data;
    });
    return query;
  }

  function handleSuccess(data) {
    document.dispatchEvent(new CustomEvent('order-fetched', {'detail': data.data}))
  }

  const query = useQuery(['order-history', {searchParams, pageNumber, sort, filters, limit}], 
  ({ signal }) => getList(signal), 
  {
    retry: 0,
    staleTime: 0,
    keepPreviousData : false,
    refetchOnWindowFocus: false,
    onSuccess: handleSuccess,
    enabled: enabled
  })  

  return [
    query, 
    query.data,
    {
      'searchParams': searchParams,
      'setSearchParams': setSearchParams, 

      'limit': limit,
      'setLimit': setLimit,

      'setPageNumber': setPageNumber, 

      'sort': sort,
      'setSort': setSort,
      
      'filters': filters,
      'setFilters': setFilters,

      viewError: viewError,
    }
  ]
}