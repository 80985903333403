import React, { useState, useEffect, useRef } from 'react';
import { UilTrashAlt, UilUsersAlt, UilPen } from '@iconscout/react-unicons';
import { Modal } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Skeleton from 'react-loading-skeleton';

import { notify } from '../../../scripts/notify';
import api from '../../../api';
import lang from '../../../lang/en/Theme/Colors';

import Loader from '../../../refactoring/components/global/Loader';
import PageHeader from "../../../refactoring/layouts/PageHeader";
import ReactPagination from "../../../components/pagination/ReactPagination";
import GroupCreationForm from './GroupCreationForm';
import PageInfo from "../../../components/pagination/PageInfo";


export default function GroupsManagement() {

  const [isLoading, setIsLoading] = useState(true);
  const [groupData, setGroupData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState(false);
  const refName = useRef();

  const [group, setGroup] = useState({
    groupID: null,
    group_name: "",
    permissions: []
  });

  useEffect(() => {
    getGroupsAndPermissions()
  }, [pageNumber]);

  const getGroupsAndPermissions = () => {
    api.groups.get({
      params: {
        paginate: true,
        page: pageNumber
      }
    }).then(response => {
      setIsLoading(false);
      var res = response.data;
      setGroupData(res.data);
      setPages({
        currentPage: res.current_page,
        per_page: res.per_page,
        total_count: res.total,
        lastPage: res.last_page
      });

      if (pageNumber >= response.data.last_page) {
        setPageNumber(response.data.last_page);
      }
    });
  }

  const pageChange = pageNo => {
    setPageNumber(pageNo);
  };

  const add = () => {
    setModalIsOpen(true);
    setGroup({
      groupID: "",
      groupName: "",
      permissions: "",
    });
  }

  const edit = (groupID, group_name, permissions) => {
    setModalIsOpen(true);
    setGroup({
      groupID: groupID,
      groupName: group_name,
      permissions: permissions,
    });
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setIsEdit(false);
  }

  const handleDelete = (group_id) => {
    api.groups.delete({ group_id })
      .then(response => {
        notify(response.data.type, response.data.message);
        getGroupsAndPermissions();
      })
      .catch(err => {
        notify('error');
        console.log('Error::', err);
      })
  }

  return (
    <div>
      <div className="bg-white p-5 border rounded" >

        <div className="d-flex justify-content-between">
          <PageHeader subtitle="User Groups" subtext="Manage user groups" />

          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()} >
              Add Group
            </button>
          </div>
        </div>

      <div className="row">
        <div className="col">
          <table className="table table-hover bg-white border">
            <thead>
              <tr>
                <th>Group Name</th>
                <th>Permission</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>

              {isLoading ? (
                <tr>
                  <td colSpan='100%' className='text-center'>
                    <Skeleton count={5} height={30} className="mb-3"/>
                  </td>
                </tr>
              ) : groupData ? (
                groupData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.group_name}</td>
                    <td className="permissions-data">
                      {
                        data.permissions.map((permission, index) => (
                          <span key={index}>{permission.permission_name}</span>
                        ))
                      }
                    </td>
                    <td>
                    {
                      data.id === 1 ? (<td></td>) : (
                        <div className="d-flex justify-content-end"> 
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit group</Tooltip>}>
                            <span className="me-3 cursor active-icon" id="edit" onClick={() => { setIsEdit(true); edit(data.id, data.group_name, data.permissions) }}>
                              {<UilPen size="14" className="text-secondary" />}
                            </span>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete group</Tooltip>}>
                            <span className="cursor active-icon" id="delete" onClick={() => handleDelete(data.id)}>{<UilTrashAlt size="16" color={lang.colors.danger} />}</span>
                          </OverlayTrigger>
                          </div>
                      )
                    }
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    <div className="text-center bg-white py-5">
                      {<UilUsersAlt size="38" className="mb-3 text-secondary" />}
                      <p className="h5 text-gray-700">No user groups created</p>
                      <p className="small text-gray-600">Enter a group name and assign permission levels</p>
                    </div>
                  </td>
                </tr>
              )
              }
            </tbody>
            <tfoot>
              <tr className="border-top">
                <td colSpan="100%">
                  <div className="d-flex justify-content-between align-items-center">
                    <PageInfo
                      perPage={pages.per_page}
                      currentPage={pages.currentPage}
                      total={pages.total_count}
                    />
                    {pages ? (
                      <ReactPagination pageNumber={pageChange} pages={pages} />
                    ) : null}
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      
      {modalIsOpen &&
        <Modal show={modalIsOpen} onEntered={() => refName.current.focus()} onHide={closeModal} centered dialogClassName="modal-md">
          <Modal.Header closeButton className="p-4" >
            <Modal.Title>{ group.groupID ? "Edit Role" : "Add Role" }</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4" >
            <GroupCreationForm callback={getGroupsAndPermissions} inputs={group} isEdit={isEdit} closeModal={closeModal} refName={refName} />
          </Modal.Body>
        </Modal>
      }
    </div>
    </div>

  )
}