
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import apiClient from "../header";
import ErrorPage from "../../components/errors/ErrorPage";


export function useChannelViewQuery(id) {
  const [viewError, setViewError] = useState()

  function getView(signal, id) {
    const query =  apiClient.get(`channels/get`, { params: {id: id}, signal })
    .catch((error) => {
      setViewError(<ErrorPage error={error.response.status}/>)
    })
    .then((result => {
      if (result.data.status === 0) {
        setViewError(
          <>
              <ErrorPage />
          </>
          )
        }
        return result.data
    }));
  
    return query;
  }
  

  var query =  useQuery(['channel-view', {id}], 
  ({ signal }) => getView(signal, id), 
  {
    retry: 0,
    keepPreviousData : true,
    refetchOnWindowFocus: false,
    enabled: id ? true : false,
  });

  return [
    query,
    query.data,
    {
      'viewError' : viewError
    }
  ];
}

