import React, { useEffect, useCallback } from 'react'
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { UilSearch, UilTruck, UilCreditCard } from "@iconscout/react-unicons";
import _ from "lodash";

import api from "../../../api";
import lang from "../../../lang/en/Theme/Colors";
import { getList } from "../../stores/customer/list";
import { useQueryClient } from "@tanstack/react-query";

export default function CustomerSearch(props) {
	const min_input_length = 3;
	const queryClient = useQueryClient()
	const Control = ({ children, ...props }) => {
		return (
			<components.Control {...props}>
				<span className="input-group-text border-0">
					{<UilSearch size="14" color={lang.colors.secondary} />}
				</span>
				{children}
			</components.Control>
		);
	};

	const formatOptionLabel = (option, { context }) => {
		return context === "menu" && option.__isNew__ ? (
			<span>
				Create "{option.customer_first_name} {option.customer_last_name}"
			</span>
		) : (
			<div>
				<div>
					<span className="text-secondary">{option.customer_first_name} {option.customer_last_name}</span>
					<span className="text-gray-500 ms-2">{option.email}</span>
				</div>
				{option.shipping_address ? (
					<div className="row">
						<div className="col text-truncate">
							<span><UilTruck size="14" color={lang.colors.secondary} /> {option.shipping_address}, {option.shipping_city}, {option.shipping_country}</span><br />
							<span><UilCreditCard size="14" color={lang.colors.secondary} /> {option.payment_address}, {option.payment_city}, {option.payment_country}</span>
						</div>
					</div>
				) : (null)}
			</div>
		);
	};

	const loadingMessage = (input) => (input.inputValue).length >= min_input_length ? "Loading ..." : "Please enter at least 3 or more characters";

	const noOptionsMessage = (input) => (input.inputValue).length >= min_input_length ? "No Options" : "Please enter at least 3 or more characters";

	const loadOptions = _.debounce((searchParams, callback) => {
		(async () => {
			let query = await queryClient.fetchQuery(['customer-list', { searchParams }], ({ queryKey, signal }) => getList(queryKey, signal), { staleTime: Infinity })
			callback(query)
		})()
	}, 500)

	return (
		<>
			<AsyncCreatableSelect
				cacheOptions
				isDisabled={props.isDisabled}
				loadOptions={loadOptions}
				placeholder='Search by First name or Last name or Customer email or Company name'
				getOptionLabel={option => `${option.customer_first_name ?? ''} ${option.customer_last_name ?? ''}`}
				value={props.value}
				getOptionValue={option => option.id}
				getNewOptionData={(inputValue, optionLabel) => ({
					id: optionLabel,
					customer_first_name: inputValue.split(' ')[0] ?? '',
					customer_last_name: inputValue.split(' ')[1] ?? '',
					__isNew__: true
				})}
				onChange={(option, { action }) => {
					props.handleSelect(option, action);
				}}
				noOptionsMessage={noOptionsMessage}
				loadingMessage={loadingMessage}
				formatOptionLabel={formatOptionLabel}
				components={
					{
						Control,
						DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
						LoadingIndicator: () => null
					}
				}
			/>
		</>

	);
}