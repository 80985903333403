
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notify } from "../../../scripts/notify";

import apiClient from "../header";

function deleteIntegration(id) {    
  const query =  apiClient.post(`integrations/delete`, {
    id: id,
  }).then(
    (result) => result.data,
  );
  return query;
}

export function useDeleteIntegrationQuery() {
  const queryClient = useQueryClient()
  
  function handleSuccess(data) {
    notify(data.type, data.message);
    queryClient.invalidateQueries(['integration-list']);
  }

  return useMutation((id) => deleteIntegration(id), 
  {
    retry: false,
    onSuccess: handleSuccess
  });
}

