import React, { Component } from 'react';
import moment from 'moment';

export default class Footer extends Component {
  render() {
    return (
      <footer className='footer content-section p-4'>
        <div>
          <small>&copy; Innoventory v1.0 {moment().format('y')} <a href="https://agaetis.tech">Agaetis Technologies.</a></small>
        </div>
      </footer>
    );
  }
}