import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';


import { MetaDataContext } from "../../../web/context/metaData";
import { abbreviateNumber } from '../../../scripts/utils';
import localstorage from '../../../localstorage';
import configurations from '../../../configurations';

import CardComponent from '../../layouts/CardLayout';
import DataTable from '../common/DataTable';
import { Link } from 'react-router-dom';
import { Paths } from '../../../routes';


export default function RecurringCustomersbyOrderCount(props) {

  const settings = JSON.parse(localstorage.settings.get());
  let userSetting = settings || {};
  const [pageNumber, setPageNumber] = useState(settings ? settings.salesPageNo : 1);
  const metaData = useContext(MetaDataContext);
  const [filter, setFilter] = useState(null);

  const pageChange =(pageNo) => {
    setFilter(
      prevProps => ({
        ...prevProps,
        key: props.filter_key,
        pageNumber:pageNo
    }) );
    userSetting = {
      ...userSetting,
      salesPageNo: pageNo
    };
    localstorage.settings.set(userSetting);
    setPageNumber(pageNo);
  };

  const columns = [
    {
      id: "customer_first_name",
      name: "Customer First Name",
      cell: (row) => row.customer_first_name,
    },
    {
      id: "customer_last_name",
      name: "Customer Last Name",
      cell: (row) => row.customer_last_name,
    },
    {
      id: "customer_email",
      name: "Customer Email",
      cell: (row) => (
        <Link to={Paths.Customer.pages.view.path + row.id}> {row.email}</Link>
      ),
    },
    {
      id: "order_numbers",
      name: "No. of Orders",
      cell: (row) => row.order_count,
    }
  ];

  
  useEffect(() => {
    if (filter) {
      props.fetchFilteredData(filter);
    }
  }, [filter]);

  useEffect(() => {
  }, [metaData]);
  
  useEffect(() => {
  }, [props?.data]);

  return (
    <>
      <CardComponent title={props.title} pagination={false}>
        <div className="table border mb-4 ">
        <DataTable
          data={props?.data?.data}
          columns={columns}
          isLoading={props.isLoading}
          onChangePage={(page) => pageChange(page)}
          pagination={true}
          paginationMeta={props?.data}
        />
        </div>
      </CardComponent>
    </>
  );

}
