import React, { Component } from 'react';
import TrackpodIcon from '../../assets/img/pages/integration/list/trackpod-icon.svg';
import ProfileIcon from '../../refactoring/components/common/ProfileIcon';

export default class IntegrationInfo extends Component {
  render() {
    return (
      <span>
        {/* { this.props.integrationType === 1 ? 
        ( <img src={TrackpodIcon} height={32} className="me-2"/> )
        : 
        ( <ProfileIcon text={this.props.integrationName} /> ) }                                                                       */}
        <ProfileIcon text={this.props.integrationName} />
        <span className="text-capitalize">{this.props.integrationName}</span> 
      </span>
    );
  }
}