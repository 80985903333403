import lang from "../../../lang/en/Theme/Colors";
import Stepper from "react-stepper-horizontal";

export default function StepperComponent({ steps, activeStep }) {
  return (
    <div>
      <Stepper
        steps={steps}
        activeStep={activeStep}
        barStyle={"dashed"}
        completeBarColor={lang.colors.success}
        titleFontSize={12}
        defaultTitleColor={lang.colors.gray500}
        completeTitleColor={lang.colors.success}
        defaultColor={lang.colors.gray300}
        circleFontSize={12}
        circleFontColor={lang.colors.white}
        activeColor={lang.colors.secondary}
        completeColor={lang.colors.success}
        completeBorderColor={lang.colors.success}
        lineMarginOffset={2}
      />
    </div>
  );
}
