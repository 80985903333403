import React, { } from "react";
import { Routes, Route } from 'react-router-dom';

import { Paths } from "../routes";

import Middleware from "../refactoring/components/global/Middleware";
import ErrorPage from "../refactoring/components/errors/ErrorPage";
import ProtectedRoute from "../refactoring/components/global/ProtectedRoute";

import Login from "./Login";
import ResetPassword from "./ResetPassword";


export default () => (
	// <PrivateRoute component={
	// route.component
	// } permissions={route.Permissions} />

	<Routes>
		<Route path={Paths.Login.path} element={<Login />} />
		<Route path={Paths.ResetPassword.path} element={<ResetPassword />} />
		<Route element={<ProtectedRoute/>}>
			{Object.values(Paths).map((route, key) => (
					route.isTabs ? (
						<Route key={key} path={route.path} element={<Middleware component={route.component} permissions={route.permissions}/>}>
							{route.pages &&
								Object.values(route.pages).map((page, id) => (
									(page.component &&
										<>	
											{id == 0 && <Route index element={page.component}  key={id} /> }
											<Route key={id} path={`${page.path}${page.isParam?page.isParam:''}`} element={<Middleware component={page.component} permissions={page.permissions}/>} />
										</>
									)
								))
							}
						</Route>)
					: (route.component && 
					<>
						<Route key={key} path={route.path} element={<Middleware component={route.component} permissions={route.permissions}/>}/>
							{route.pages &&
								Object.values(route.pages).map((page, index) => (
									(page.component &&
										<>
										<Route key={key+'_pages_'+index} path={`${page.path}${page.isParam?page.isParam:''}`} element={<Middleware component={page.component} permissions={page.permissions}/>} />
										</>
									)
								))
							}
					</>)
			))}
		</Route>
		
		<Route path='*' element={<ErrorPage />} />
	</Routes>
	// <Routes>
	//   <Route path={Paths.Login.path} element={<Login/>} />
	//   <Route path={Paths.ResetPassword.path} element={<ResetPassword/>} />
	//     <Route path={Paths.Root.path} element={<PrivateRoute component={<Dashboard/>} permissions={Paths.Dashboard.Permissions} />} /> 
	//     <Route path={Paths.Dashboard.path} element={<PrivateRoute component={<Dashboard/>} permissions={Paths.Dashboard.Permissions} />} />
	//     <Route path={Paths.OrderAnalysis.path} element={< OrderAnalysis />} />

	//     <Route path={Paths.Report.path} element={<Report/>} />
	//     <Route path={Paths.Report.SalesAnalysis.path} element={< SalesAnalysis />} />

	//     <Route path={Paths.Products.path} element={<ProductsManagement/>} />
	//     <Route path={Paths.Products.ProductsManagement.path} permissions={Paths.Products.ProductsManagement.Permissions} element={< ProductsManagement/>} />
	//     <Route path={Paths.Products.pages.ProductsCreation.path} permissions={Paths.Products.ProductsCreation.Permissions} element={<ProductsCreationForm/>} />
	//     <Route path={Paths.Products.CompositeCreation.path} permissions={Paths.Products.CompositeCreation.Permissions}  element={<CompositeProduct/>} />
	//     <Route path={Paths.Products.ProductsEditForm.path + ":id"} key="product-edit" permissions={Paths.Products.ProductsEditForm.Permissions} element={<ProductsCreationForm/>} />
	//     <Route path={Paths.Products.ProductsCompositeEditForm.path + ":id"}  key="composite-edit" permissions={Paths.Products.ProductsCompositeEditForm.Permissions} element={<CompositeProduct/>} />  

	//     <Route path={Paths.Orders.path} element={<OrdersManagement/>}  />
	//     <Route path={Paths.Orders.OrdersManagement.path} permissions={Paths.Orders.OrdersManagement.Permissions} element={<OrdersManagement/>} />
	//     <Route path={Paths.Orders.OrdersCreation.path} permissions={Paths.Orders.OrdersCreation.Permissions} element={<OrdersCreationForm/>} />
	//     <Route path={Paths.Orders.OrderView.path + ":id"} permissions={Paths.Orders.OrderView.Permissions} element={<OrderView/>} />

	//     <Route path={Paths.Category.path} element={<CategoryManagement/>} />
	//     <Route path={Paths.Category.CategoryManagement.path} permissions={Paths.Category.CategoryManagement.Permissions} element={<CategoryManagement/>} />
	//     <Route path={Paths.Category.CategoryCreation.path} permissions={Paths.Category.CategoryCreation.Permissions} element={<CategoryCreation/>} />
	//     <Route path={Paths.Category.CategoryEdit.path + ":id"} key="category-edit" permissions={Paths.Category.CategoryEdit.Permissions} element={<CategoryCreation/>} />

	//     <Route path={Paths.Channels.path} element={<ChannelManagement/>}  />
	//     <Route path={Paths.Channels.ChannelManagement.path} permissions={Paths.Channels.ChannelManagement.Permissions} element={<ChannelManagement/>} />
	//     {/* Opencart */}
	//     <Route path={Paths.Channels.ChannelEditForm.path + ":id"} element={<ChannelEditForm/>} />  
	//     <Route path={Paths.Channels.AddChannel.path} element={<AddChannel/>} />
	//     <Route path={Paths.Channels.OpenCartMain.path} element={<OpenCartMain/>} />
	//     <Route path={Paths.Channels.AmazonMain.path} element={<AmazonMain/>} />

	//     <Route path={Paths.Channels.EbayMain.path} element={<EbayMain/>} />
	//     <Route path={Paths.Ebay.path} element={<EbayPopup/>} />

	//     {/* <Route path={Paths.Channels.View.path+":id"} element={<ViewChannel/*/}
	//     <Route path={Paths.Channels.ProductMap.path+":id"} element={<ProductMapping/>} />
	//     <Route path={Paths.Channels.PopUp.path} element={<PopUp/>} />
	//     {/* <Route path={Paths.Orders.OrdersCreation.path} element={<OrdersCreationForm/*/}

	//     <Route path={Paths.Invoices.path} element={<InvoiceManagement/>}  />
	//     <Route path={Paths.Invoices.InvoicesManagement.path} element={<InvoiceManagement/>} />
	//     <Route path={Paths.Invoices.InvoicesCreation.path}  element={<InvoiceCreation/>} />
	//     <Route path={Paths.Invoices.InvoicesEdit.path + ":id" } key="invoice-edit" element={<InvoiceCreation/>} />
	//     <Route path={Paths.Invoices.InvoicesView.path + ":id" } key="invoice-edit" element={<InvoiceCreation/>} />

	//     <Route path={Paths.Quotation.path} element={<QuotationManagement/>}  />
	//     <Route path={Paths.Quotation.QuotationManagement.path} element={<QuotationManagement/>} />
	//     <Route path={Paths.Quotation.QuotationCreation.path}  element={<QuotationCreation/>} />
	//     <Route path={Paths.Quotation.QuotationEdit.path + ":id" } key="quotation-edit" element={<QuotationCreation/>} />
	//     <Route path={Paths.Quotation.QuotationView.path + ":id" } key="quotation-view" element={<QuotationCreation/>} />

	//     {/* <Navigate from={Paths.Integrations.path} to={Paths.Integrations.IntegrationManagement.path}  /> */}
	//     <Route path={Paths.Integrations.path} element={<IntegrationManagement/>}  />
	//     <Route path={Paths.Integrations.IntegrationManagement.path} element={<IntegrationManagement/>} />

	//     <Route path={Paths.Notifications.path} element={<Notifications/>}>
	//       <Route index element={<NotificationSettings/>}/>
	//       <Route path={Paths.Notifications.Orders.path} element={<NotificationOrders/>}/>
	//       <Route path={Paths.Notifications.Settings.path} element={<NotificationSettings/>}/>
	//     </Route>

	//     {/* TrackPod */}
	//     <Route path={Paths.Integrations.IntegrationEditForm.path + ":id"} element={<IntegrationEditForm/>} />     
	//     <Route path={Paths.Integrations.AddIntegration.path} element={<AddIntegration/>} />
	//     <Route path={Paths.Integrations.TrackPodMain.path} element={<TrackPodMain/>} />


	//     <Route path={Paths.Settings.path}  element={<Settings />} >
	//       <Route index element={<AccountSettings />} />
	//       <Route path={Paths.Settings.AccountSettings.path} element={<AccountSettings />} />
	//       <Route path={Paths.Settings.BusinessInfo.path} element={<BusinessInfo />} />
	//       <Route path={Paths.Settings.Preferences.path} element={<Preferences/>} />
	//       <Route path={Paths.Settings.Customization.path} element={<Customization/>} />
	//       <Route path={Paths.Settings.UserManagement.path} element={<UserManagement />} />
	//       <Route path={Paths.Settings.GroupManagement.path} element={<GroupManagement />} />
	//       <Route path={Paths.Settings.WarehouseManagement.path} element={<WarehouseManagement />} />
	//       <Route path={Paths.Settings.Tax.path} element={<TaxManagement />} />
	//       <Route path={Paths.Settings.ManufacturerManagement.path} element={<ManufacturerManagement/>} />
	//       <Route path={Paths.Settings.UnitsManagement.path} element={<UnitsManagement/>} />
	//       <Route path={Paths.Settings.ShippingManagement.path} element={<ShippingManagement/>} />
	//       <Route path={Paths.Settings.CouponManagement.path} element={<CouponManagement/>} />
	//       <Route path={Paths.Settings.PaymentManagement.path} element={<PaymentManagement/>} />
	//       <Route path={Paths.Settings.MailManagement.path} element={<MailManagement/>} />
	//     </Route>

	//     {/* <Route path={Paths.Notifications.path} element={<SyncStatus/>} /> */}
	//     {/* <Route path={Paths.Products.OptionsManagement.path} element={<OptionsManagement/>} /> */}

	//   </Route>

	// </Routes>
)
