import React, {  useEffect, useRef, useState } from 'react';
import { Modal } from "react-bootstrap";
import { UilEllipsisH, UilUsersAlt, UilPen, UilTrashAlt, } from '@iconscout/react-unicons';

import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';

import api from '../../../api';
import lang from '../../../lang/en/Theme/Colors';
import { notify } from '../../../scripts/notify'

import Loader from '../../../refactoring/components/global/Loader';
import PageHeader from "../../../refactoring/layouts/PageHeader";
import UserCreationForm from './UserCreationForm';
import ProfileIcon from "../../../refactoring/components/common/ProfileIcon";
import ReactPagination from "../../../components/pagination/ReactPagination";
import PageInfo from "../../../components/pagination/PageInfo";

export default function UserManagment() {

  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [ownerId, setOwerId] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState(false);
  const refName = useRef();

  const [user, setUser] = useState({
    userID: null,
    email: "",
    group: ""
  });

  useEffect(() => {
    api.business.get().then(response => {
      let buisnessData = response.data.business_data;
      setOwerId(buisnessData.owner_id);
    });
    fetchUsers();
  }, [pageNumber]);
  
  const add = () => {
    setModalIsOpen(true);
    setUser({
      userID: "",
      email: "",
      groups: ""
    });
  }

  const edit = (userID, email, group) => {
    setModalIsOpen(true);
    setUser({
      userID: userID,
      email: email,
      groups: group
    });
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setIsEdit(false);
  }

  const fetchUsers = () => {
    api.users.get({
      params: {
        paginate:true,
        page: pageNumber
      }
    }).then(response => {
      setIsLoading(false);
      var res = response.data;
      setUserData(res.data);
      setPages({
        currentPage: res.current_page,
        per_page: res.per_page,
        total_count: res.total,
        lastPage: res.last_page
      });

      if(pageNumber >= response.data.last_page){
        setPageNumber(response.data.last_page);
      }
    });
  }

  const pageChange = pageNo => {
    setPageNumber(pageNo);
  };

  const handleRemoveUser = (user_id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to delete this user?',
      text: 'By clicking yes, you will remove this user from your business.',
      showCancelButton: true,
      confirmButtonText: `Yes, I am sure`,
    })
      .then(response => {
        if (response.isConfirmed) {
          api.users.remove({ user_id })
            .then(response => {
              notify(response.data.type, response.data.message);
              fetchUsers();
            })
            .catch((err) => {
              // notify('error', 'Cannot delete user since user is the owner of the business');
              console.log('Error::', err);
            })
        }
      })
      .catch(err => {
        console.log(err);
      })
  }


  return (
    <div>
      <div className="bg-white p-5 border rounded">

        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Users" subtext="Manage users to your dashboard" />
          <div className='mb-4 text-end d-flex align-items-center'>
            <button className="btn btn-secondary" onClick={() => add()}>
              Add User
            </button>
          </div>
        </div>
      

        <div className="border rounded-3 overflow-hidden">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Member</th>
                <th>Email</th>
                <th>Role</th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan='100%' className='text-center'>
                    <Skeleton count={5} height={30} className="mb-3"/>
                  </td>
                </tr> 
              ) : userData ? (
                  userData.map(user => (
                    <tr key={user.id}>
                      <td className="">
                        <ProfileIcon text={user.name ? user.name : ''} />
                        {user.name}
                      </td>

                      {user.id === 1 ? (
                        <td>
                          {user.email}
                          <span className="badge rounded-pill bg-white text-success ms-3 px-3 py-1 border">
                            Owner
                          </span>
                        </td>
                      ) : (
                        <td>{user.email}</td>
                      )}

                      {user.groups.map(group => (
                        <td
                          key={group.id}
                          id={group.id}
                          className="small text-gray-600"
                        >
                          {group.group_name}
                        </td>
                      ))}
                      {user.id === 1 ? (
                        <td></td>
                      ) : (
                        <td className="overflow-visible text-end">
                          <div className="dropdown">
                            <button
                              className="btn btn-link"
                              id="dropdownMenu"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>{<UilEllipsisH size="16" color={lang.colors.secondary} />}</span>
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenu"
                            >
                              <li
                                className=" fw-bold cursor dropdown-item"
                                onClick={() => {
                                  setIsEdit(true);
                                  edit(
                                    user.id,
                                    user.email,
                                    user.groups
                                  );
                                }}
                              >
                                  {<UilPen size="14" className="text-secondary me-1 mb-1" />} Edit User
                              </li>
                              <li
                                className=" text-danger fw-bold cursor dropdown-item"
                                onClick={() => handleRemoveUser(user.id)}
                              >
                                  {<UilTrashAlt size="14" className="text-danger me-1 mb-1" />} Delete User
                              </li>
                            </ul>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                // </tbody>
              ) : (
                // <tbody>
                <tr>
                  <td colSpan="100%" className="text-center">
                    <div className="text-center bg-white py-5">
                      {<UilUsersAlt size="38" className="mb-3 text-secondary" />}
                      <p className="h5 text-gray-700">No users added</p>
                      <p className="small text-gray-600">
                        Enter email id and assign a user to group to send an
                        invite.
                        <br /> All the added users will be reflected here
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr className="border-top">
                <td colSpan="100%">
                  <div className="d-flex justify-content-between align-items-center">
                    <PageInfo
                      perPage={pages.per_page}
                      currentPage={pages.currentPage}
                      total={pages.total_count}
                    />

                    {pages ? (
                      <ReactPagination pageNumber={pageChange} pages={pages} />
                    ) : null}
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
     
      {modalIsOpen && (
        <Modal show={modalIsOpen} onEntered={() => refName.current.focus()} onHide={closeModal} centered dialogClassName="modal-md">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>{user.userID ? "Edit User" : "Add User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <UserCreationForm
              callback={fetchUsers}
              inputs={user}
              isEdit={isEdit}
              closeModal={closeModal}
              refName={refName}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
    </div>
  );
}