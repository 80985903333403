import _ from "lodash";
import { useState } from "react";

import CustomSelect from "../global/CustomSelect";
import ModalComponent from "../common/ModalComponent";
import { useValidator } from "../../utils/validator";

export default function SelectCouponModal(props) {
  
  const [inputs, setInputs] = useState({
    coupon_code: props.inputs.coupon_code || null,
    coupon_amount: props.inputs.coupon_amount || 0,
  });
  const [validator, {validate}] = useValidator()
  const [, forceUpdate] = useState();

  function handleInputChange(e) {
    setInputs((prevProps) => ({
      ...prevProps,
      coupon_code: e.name,
      coupon_amount: e.amount,
    }));
  }

  function handleSubmit() {
    if (validator.current.allValid()) {
      props.setInputs((prevProps) => ({
        ...prevProps,
        coupon_code: inputs.coupon_code,
        coupon_amount: inputs.coupon_amount,
      }));
      props.onHide("coupon");
    } else {
      validator.current.showMessages();
      forceUpdate(true)
    }
  }

  return (
    <>
      <ModalComponent
        show={props.show}
        onHide={props.onHide}
        title="Coupon/Vouchers"
        body={
          <div className="row align-items-start">
            <div className="col">
              <label className="fw-bold me-3 ">Coupon/Vouchers code</label>
              <CustomSelect
                isSearchable={true}
                options={props.coupon}
                closeMenuOnSelect={true}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                placeholder="Choose Coupon/Vouchers code"
                value={_.find(props.coupon, function (obj) {
                  return obj.name === inputs.coupon_code;
                })}
                onChange={(e) => handleInputChange(e)}
              />
              {validate(inputs.coupon_code, "required", {
                title: "coupon_code",
              })}
            </div>
          </div>
        }
        footer={
          <div className="px-4 text-end">
            <button
              type="submit"
              className="btn btn-secondary"
              name="shipping"
              onClick={handleSubmit}
            >
              Add Coupon/Voucher
            </button>
          </div>
        }
      />
    </>
  );
}
