import React, { useCallback, useEffect } from "react";

import Search from "./Search";
import Filters from "./Filters";
import Sort from "./Sort";



export default function ActionBar({searchParams, setSearchParams, sortOptions, setSort, filterOptions, setFilters, placeholder, component}) {
  return (
    <div className="bg-white border-bottom">
      <div className="row px-2 py-3">
        <div className="col-md-6">
          <Search defaultValue={searchParams} callback={setSearchParams} placeholder={placeholder}/>
        </div>
        <div className="col-md-6">
          <div className="row justify-content-end">
            {component && ( component )}
            {filterOptions && (
              <div className="col-3">
                <Filters options={filterOptions} control={setFilters} isMulti={false} isClearable/>
              </div>
            )}
            {sortOptions && (           
              <div className="col-3">
                <Sort options={sortOptions} control={setSort}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
