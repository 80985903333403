import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import apiClient from "../header";

export function useCouponListQuery({
  enabled = true,
  defaultValue = {
    pageNumber: 1,
    limit: 15,
  },
} = {}) {
  const [pageNumber, setPageNumber] = useState(defaultValue.pageNumber);
  const [limit, setLimit] = useState(defaultValue.limit);

  function getList(signal) {
    const query = apiClient
      .get(`coupons`, {
        params: { limit: limit, page: pageNumber,},
        signal,
      })
      .then((result) => result.data);
    return query;
  }

  function handleSuccess(data) {
    document.dispatchEvent( new CustomEvent("coupon-fetched", { detail: data.data })
    );
  }

  const query = useQuery( ["coupon-list", { pageNumber }], ({ signal }) => getList(signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
      enabled: enabled,
    }
  );

  return [
    query,
    {
      setPageNumber: setPageNumber,
    },
  ];
}

export function useCouponCreateQuery() {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  var toastify;

  function create(data) {
    return apiClient.post(`coupons/add`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) { 
      queryClient.invalidateQueries(["coupon-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((newCoupon) => create(newCoupon), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];;
}

export function useCouponUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function update(data) {
    return apiClient.post(`coupons/edit`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(["coupon-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((updateCoupon) => update(updateCoupon), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [ 
  mutate, 
  {
    'updateErrors': errors
  }
  ];
}

export function useCouponDeleteQuery() {
  const queryClient = useQueryClient();
  
  var toastify;
  function remove(id) {
    return apiClient.post(`coupons/delete`, { id:id }).then((result) => result.data);
  }
  
  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(["coupon-list"]);
    }
  }

  const mutate = useMutation((id) => remove(id), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return mutate;
}
