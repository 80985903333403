import React, { useEffect, useRef } from "react";
import _ from "lodash";


import BusinessSummary from "../../components/report/BusinessSummary";
import StackedBarChartBlock from "../../components/charts/StackedBarChartBlock";
import LineChartBlock from "../../components/charts/LineChartBlock";
import CardComponent from "../../layouts/CardLayout";
import NoDataFound from "../../components/global/NoDataFound";
import SalesbyCategory from "../../components/report/SalesbyCategory";
import SalesbyCustomers from "../../components/report/SalesbyCustomers";
import PieChartBlock from "../../components/charts/PieChartBlock";
import NoDataTable from "../../components/global/NoDataTable";
import SimpleNumber from "../../components/report/SimpleNumber";
import TotalSales from "../../components/report/TotalSales";
import { useReportForRecurringCustomerQuery } from "../../stores/reports/recurringcustomer";
import RecurringCustomersbyOrderCount from "../../components/report/RecurringCustomersbyOrderCount";
import CustomerWithSampleOrders from "../../components/report/CustomerWithSampleOrders";
import CustomerWithProductOrders from "../../components/report/CustomerWithProductOrders";
import RecurringCustomersOrderFrequency from "../../components/report/RecurringCustomersOrderFrequency";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import DateFilter from "../../components/global/DateFilter";
import { abbreviateNumber } from "../../../scripts/utils";
import Customers from "../../components/report/Customers";
import RecurringCustomersOrderFrequencyByShippingPostcode from "../../components/report/RecurringCustomersOrderFrequencyByShippingPostcode";


export default function SampleOrderComponent({
  refs,
  reportData,
  preferences,
  chartSettings,
  setReportData,
  fetchFilteredData,
}) {

  return (
    <div ref={refs}>
      {!_.isEmpty(
        reportData,
        preferences,
        chartSettings,
        fetchFilteredData
      ) ? (
        <>
          <div className="row mb-4">
            <div className="col-6">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Sample Order Conversions
                </Card.Header>
                <Card.Body>
                  {reportData.isLoading ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) : (
                    <Card.Title as="h4" className="mb-0 py-3">{abbreviateNumber(reportData?.data?.sample_conversions?.data)?? 0}%</Card.Title>
                  )}
                </Card.Body>
              </Card>
            </div>
            <div className="col-6">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Average Order Value
                </Card.Header>
                <Card.Body>
                  {reportData.isLoading ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) : (
                    <Card.Title as="h4" className="mb-0 py-3">{`${abbreviateNumber(reportData?.data?.total_average_order_value?.data, preferences) ?? 0}`}</Card.Title>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row mb-4">
            {!_.isEmpty(reportData?.data?.customer_with_product_orders?.data) ? (
              <div className="col">
                <CustomerWithProductOrders
                  data={
                    reportData?.data?.customer_with_product_orders
                      ? reportData?.data?.customer_with_product_orders?.data
                      : null
                  }
                  withCurrency={preferences}
                  title="customers with sample order conversions"
                  setState={setReportData}
                  fetchFilteredData={fetchFilteredData}
                  filter_key="customer_product_orders"
                  isLoading={reportData.isLoading}
                />
              </div>
            ) : (
              <>
                <div className="col">
                  <NoDataTable 
                    title="customers with sample order conversions" 
                    columnNames={['Customer First Name', 'Customer Last Name', 'Customer Email', 'No. of Orders']} 
                  />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="">
          <NoDataFound />
        </div>
      )}
    </div>
  );
}
