import React, { useState, useEffect, useContext, useRef } from "react";
import _ from "lodash";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import SimpleReactValidator from 'simple-react-validator';
import { UilAngleDown, UilPlusCircle, UilLabel, UilTimes, UilPen } from "@iconscout/react-unicons";
import Select from "react-select";
import config from "../../../configurations";
import lang from "../../../lang/en/Theme/Colors";
import {objectToFormData} from "../../../scripts/utils";

import { useValidator } from "../../utils/validator";
import PageHeader from "../../layouts/PageHeader";
import ModalComponent from "../../components/common/ModalComponent";

import Total from "../../components/common/Total";
import { useUserListQuery } from "../../stores/setting/user";
import { UsersContext } from "../../../web/context/users";
import SelectCouponModal from "../../components/modal/SelectCouponModal";
import Payment from "../../components/payment/Payment";
import SelectDiscountModal from "../../components/modal/SelectDiscountModal";
import CustomerCreation from "../../components/customer/CustomerCreation";
import CustomerDetailsForm from "../../components/customer/CustomerDetailsForm";
import CreatableSelectProducts from "../../components/product/CreatableSelectProducts";
import FileAttachments from "../../components/global/FileAttachments";
import CouponCreationForm from "../settings/coupon/Form";
import PaymentCreationForm from "../settings/paymentMethod/Form";
import ShippingCreationForm from "../settings/shippingMethod/Form";

import { useTotalQuery } from "../../stores/orders/total";
import { useMetaData } from "../../stores/orders/metadata";
import { useOrderCreateQuery } from "../../stores/orders/create";
import SelectShippingModal from "../../components/modal/SelectShippingModal";
import { useQuotationViewQuery } from "../../stores/quotations/view";
import CustomerSearch from "../../components/customer/CustomerSearch";
import CustomerDetailsView from "../../components/customer/CustomerDetailsView";

  
  const initialState = {
    customer: {},
    shipping_method: "",
    user_id: null,
    created_by: "",
    shipping_id: "",
    coupon_code: null,
    coupon_amount: null,
    payment_method: "",
    shipping_cost: null,
    order_status: "",
    comment: "",
    date: "",
    inclusive_tax_discount: false,
    discount: null,
    discount_type: null,
    payment_details: "",
    products: [],
    attachments: [],
  };

export default function OrdersCreationForm() {

  const location = useLocation();
  var pathname = location.pathname; 
  const { users } = useContext(UsersContext);
  const [userListQuery] = useUserListQuery({ defaultValue: { limit: "all" } });
  const user_data = users?.data; 
  const { id } = useParams();

  const [ queryParams ]= useSearchParams();
  const quotation_id = queryParams.get('quotation_id') ?? false;

  const [tempData, setTempData] = useState({});
  const [customer, setCustomer] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [errors, setErrors] = useState({
    customer_flag: false
  });
  const [total, setTotal] = useState({
    sub_total: 0,
    total: 0,
    total_tax: 0,
    shipping_tax_amount: 0,
    shipping_gross_amount:0,
    discount_type: null,
    discount_cost: 0,
    discount_percent: 0
  });

  const [modelOpen, setModelOpen] = useState({
    customer: false,
    coupon: false,
    shipping: false,
    discount: false,
    newShipping: false,
    newCoupon: false,
    newPayment: false,
  });

  const [inputs, setInputs] = useState(initialState);

  const queryClient = useQueryClient();
  const [forceUpdate, setForceUpdate] = useState();
  const selectProductValidatorInstance = useValidator(errors);
  const fileValidator = useValidator(errors)
  const metaData = useMetaData();
  const [orderCreateQuery, { createErrors }] = useOrderCreateQuery();
  const [validator, { validate }] = useValidator(errors);
  const [totalQuery, totalQueryData, { totalData, setTotalData }] = useTotalQuery();

  const [quotationDataQuery] = useQuotationViewQuery(quotation_id);

  const customerDetailsFormValidator = useRef(new SimpleReactValidator());

  function fetchMetaData() {
    queryClient.invalidateQueries(['order-metadata']);
  }

  function getTotal() {
    let data = _.filter((inputs.products), function (obj) { return !_.isEmpty(obj); });
    if ((!_.isEmpty(data) && selectProductValidatorInstance[0].current.allValid()) || inputs.shipping_cost) {
      setTotalData({
        orderProducts: data,
        discount: parseFloat(inputs.discount),
        coupon_amount: parseFloat(inputs.coupon_amount),
        shipping_cost: parseFloat(inputs.shipping_cost),
        inclusive_tax_discount: inputs.inclusive_tax_discount,
        shipping_id: inputs.shipping_id,
        discount_type: inputs.discount_type,
      });
    } 
  }

  function handleSelectNewPayment(value, action) {
    if (action === "select-option") {
      setInputs(prevProps => ({
        ...prevProps,
        'payment_method':value.name
      }));
    }
    if (action === "create-option") {
      setInputs(prevProps => ({
        ...prevProps,
        id: "",
        name: value.value,
      }))
      openModel('newPayment')
    }
  }

  const openModel = (model) => {
    setModelOpen(prevProps => ({
      ...prevProps,
      [model]: true
    }));
  }

  const closeModel = (model) => {
    setModelOpen(prevProps => ({
      ...prevProps,
      [model]: false
    }));
    setTempData({})
  }

  const removeModelData = (event) => {
    if (event === "coupon") {
      setInputs(prevProps => ({
        ...prevProps,
        'coupon_code': null,
        'coupon_amount': null,
      }));
    }
    if (event === "discount") {
      setInputs(prevProps => ({
        ...prevProps,
        'discount_type': null,
        'discount': null,
        'inclusive_tax_discount' : 0
      }));
      setTotal(prevProps => ({
        ...prevProps,
        'discount_type': null,
        'discount': null,
        'inclusive_tax_discount' : 0
      }));
    }
  };

  function handleCustomerChangeSelect(value, action) {
    if (action === "select-option") {
      setCustomer(value);
      setTempData({
        'customer_first_name': value.customer_first_name,
        'customer_last_name': value.customer_last_name,
      })
    }

    if (action === "create-option") {
      setCustomer({});
      setTempData({
        'customer_first_name': value.customer_first_name,
        'customer_last_name': value.customer_last_name,
      })
      openModel('customer')
    }
  }

  const updateOrderStatus = (e, status) => {
    setInputs(prevProps => ({
      ...prevProps,
      order_status: status
    }));
  };

  const handleInputChanges = (event) => {
    event.persist();
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  }

  const onChangeModal = (data) => {
    setInputs((prevProps) => ({
      ...prevProps,
      ...data,
    }));
  };

  function handleSelectChange(label, value) {
    setInputs(prevProps => ({
       ...prevProps,
      [label]: value
    }));
  }

  function handleSelectChangeUser(value) {
    setInputs((prevProps) => ({
      ...prevProps,
      user_id: value.id,
      created_by: value.name ?? value.email,
    }));
  }

  const handleFiles = (file) => {
    if (!_.isNull(file)) {
      setInputs((prevProps) => ({
        ...prevProps,
        'upload_files': file,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid() && customerDetailsFormValidator.current.allValid() && selectProductValidatorInstance[0].current.allValid()  && fileValidator[0].current.allValid()) {
      const formData = new FormData();
      objectToFormData(inputs, formData)
      orderCreateQuery.mutate(formData)
    } else {
      validator.current.showMessages();
      selectProductValidatorInstance[0].current.showMessages();
      fileValidator[0].current.showMessages();
      setForceUpdate(true);
    }
  }

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors)
    }
  }, [createErrors])

  // useEffect(() => {
  //   if (customer) {
  //     delete customer.order_id
  //     setInputs(Object.assign(inputs, customer))
  //   }
  // }, [customer]);


  useEffect(() => {
  if (pathname.includes('create')) {
    getTotal();
    setTotalData({
      ...(selectProductValidatorInstance[0].current.allValid()) && {orderProducts: inputs.products},
      inclusive_tax_discount: inputs.inclusive_tax_discount,
      ...(inputs.discount) && {discount: parseFloat(inputs.discount)},
      ...(inputs.discount_type) && {discount_type: inputs.discount_type},
      ...(inputs.shipping_method) && {shipping_method: inputs.shipping_method},
      ...(inputs.shipping_cost) && {shipping_cost: parseFloat(inputs.shipping_cost)},
      ...(inputs.coupon_amount) && {coupon_amount: parseFloat(inputs.coupon_amount)},
      });
  }}, [inputs.products, inputs.shipping_cost, inputs.coupon_amount, inputs.discount, inputs.inclusive_tax_discount, inputs.shipping_id, inputs.discount_type]);

  useEffect(() => {
    if (totalQueryData) {
      setTotal(totalQueryData);
      setIsCheck(true);
    }
  }, [totalQuery]);
  
  function handleProductChange(data) {
    setInputs((prevProps) => ({
      ...prevProps,
      'products': data
    }))
  }

  const formatOptionLabelCreatedBy = (option, { context }) => {
    return (
      <div>
        <div>
          <span>{option.created_by ?? option.name} {option.email ?? ''}</span>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (queryParams.get('quotation_id')) {
      if (quotationDataQuery.isFetched && !quotationDataQuery.data.hasOwnProperty("status")) {
        setInputs((prevProps) => ({
          ...prevProps,
          ...quotationDataQuery.data.data,
          'quotation_id': quotation_id
        }))
        setCustomer((prevProps) => ({
          ...prevProps,
          ...quotationDataQuery.data.data.customer_detail ?? {},
        }))
      }
    }
  }, [quotationDataQuery.data])

  useEffect(() => {
    if (customer) {
      delete customer.order_id;
      setInputs((prevProps) => ({
        ...prevProps,
        customer_detail: customer,
      }));
    }
  }, [customer]);

  useEffect(() => {
    if (!id) {
      setInputs((prevProps) => ({
        ...prevProps,
        user_id: user_data.id,
        created_by: user_data.name,
      }));
    }
  }, [id]);

  return (
    <div className="">
      <PageHeader title="orders" subtitle="Create Order" />
      {queryParams.get("quotation_id") ? (
        <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert" >
          The provided data for creating an order is incomplete.
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" ></button>
        </div>
      ) : (
        <></>
      )}
      <React.Fragment>
        <form encType="multipart/form-data">
          <div className="row product-creation-form">
            <div className="col-lg-12">
              <div className="bg-white p-4 border-bottom-0 border">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <div className="fw-bold mb-1 d-inline">Find or <span className="text-secondary btn btn-link fw-bold p-0" onClick={() => openModel('customer')}>Create Customer</span></div>
                  </div>
                  <CustomerSearch
                    handleSelect={handleCustomerChangeSelect}
                    value={customer.customer_first_name ? _.pick(customer, ['customer_first_name', 'customer_last_name']) : null}
                    isDisabled={pathname == "view"}
                    />
                </div>

                {Object.keys(customer).length != 0 && 
                  <>
                    <div className="d-flex justify-content-between pe-0">
                      <div className="fw-bold my-3 d-inline ">Customer</div>
                      {pathname !== 'view' &&
                        <div className="d-flex justify-content-between">
                          <button type="button" className="btn btn-link px-2" onClick={() => openModel('customer')} >
                            <span className="text-secondary fw-bold d-inline">{<UilPen size="14" className="text-secondary" />}</span>
                          </button>
                          <button type="button" className="btn btn-link px-2" onClick={() => setCustomer(false)} >
                            <span className="text-secondary fw-bold d-inline">{<UilTimes size="14" className="text-danger" />}</span>
                          </button>
                        </div>}
                    </div>
                    <div className="row">
                      <div className="d-flex justify-content-between pe-0">
                        <CustomerDetailsView data={customer} />
                      </div>
                        {validate(customer, 'schema:shipping_firstname,shipping_lastname,shipping_address,shipping_city,shipping_country,payment_firstname,payment_lastname,payment_address,payment_city,payment_country', {title: 'customer details'})}
                    </div>
                  </>
                }
                {validate(customer, 'exist', {title: 'customer'})}
              </div>
              {modelOpen.customer &&
                <ModalComponent
                  size="modal-lg"
                  show={modelOpen.customer}
                  onHide={() => closeModel('customer')}
                  title='Create a new customer'
                  body={<CustomerDetailsForm customer={customer} setCustomer={setCustomer} tempData={tempData} onHide={closeModel} /> }
                  footer={
                      <div className="d-flex justify-content-end p-0" >
                        <button type="button" className="btn btn-outline-primary me-2" data-bs-dismiss="modal" onClick={()=>closeModel('customer')}>
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-secondary px-5" form="customer-details-form">
                          {Object.keys(customer).length ? 'Edit' : 'Create'} Customer
                        </button>
                      </div>
                    }
                />
              }
            </div>

            <div className="col-lg-12 align-self-center px-4">
              <div className="row bg-white p-4 mb-4 border-top-0 border">
                <div className="col-md-12 p-0">
                  <CreatableSelectProducts selectedProducts={inputs.products} validator={selectProductValidatorInstance} forceUpdate={forceUpdate} onChange={handleProductChange}/>
                </div> 

                <div className="col-md-4 p-0">
                  <div className="d-flex justify-content-start">
                    {validate(inputs.products, "required", { title: "products" })}
                  </div>
                </div>
                <div className="col-md-8 mb-2">
                  <div className="d-flex justify-content-end">
                    <div className="">
                      {_.isNull(inputs.shipping_cost) ? (
                        <button 
                          type="button" 
                          className="btn btn-link p-0 text-decoration-none text-primary me-5" 
                          onClick={() => { _.isEmpty(metaData?.data?.ShippingMethod) ? (openModel("newShipping")) : (openModel("shipping"))}}
                        >
                          <UilPlusCircle size="14" color={lang.colors.primary} /> Add Shipping Method
                        </button>
                      ) : (null)}

                      {modelOpen.shipping &&
                        <SelectShippingModal
                          show={modelOpen.shipping} 
                          defaultValue = {{
                            'shipping_method': inputs.shipping_method,
                            'shipping_cost': inputs.shipping_cost,
                          }}
                          data={metaData?.data?.ShippingMethod}
                          onHide={() => closeModel("shipping")}
                          onChange={onChangeModal}
                        /> 
                      }

                      { modelOpen.newShipping &&
                        <ModalComponent
                          show={modelOpen.newShipping}
                          onHide={() => closeModel('newShipping')}
                          title='Add new shipping method'
                          body={<ShippingCreationForm callback={fetchMetaData} inputs={inputs} closeModal={() => closeModel('newShipping')}/>}
                        />
                       }
                       {validate(inputs.shipping_method, 'required', {title: 'Shipping Method'})}
                    </div>
                    <div className="">
                      {_.isNull(inputs.coupon_code) ? (
                        <button 
                        type="button" 
                        className=" btn btn-link text-decoration-none text-primary p-0 me-5"
                        onClick={() => { _.isEmpty(metaData?.data?.Coupon) ? (openModel("newCoupon")) : (openModel("coupon"))}}
                        >
                          <UilPlusCircle size="14" color={lang.colors.primary} /> Add Coupon/Voucher
                        </button>
                      ) : (null)}
                      {modelOpen.coupon &&
                        <SelectCouponModal show={modelOpen.coupon} onHide={() => closeModel('coupon')} coupon={metaData?.data?.Coupon} setInputs={setInputs} inputs= {inputs} />
                      }
                      { modelOpen.newCoupon &&    
                        <ModalComponent
                          show={modelOpen.newCoupon}
                          onHide={() => closeModel('newCoupon')}
                          title='Add new coupon/Voucher'
                          body={<CouponCreationForm callback={fetchMetaData} inputs={inputs} closeModal={() => closeModel('newCoupon')}/>}
                        />
                       }
                    </div>
                    <div className="">
                      {_.isNull(inputs.discount_type) ? (
                        <button type="button" className="btn btn-link text-decoration-none text-primary p-0" onClick={() => openModel('discount')}>
                          <UilPlusCircle size="14" color={lang.colors.primary} /> Add Discount
                        </button>
                      ) : (null)}
                      {modelOpen.discount &&
                        <SelectDiscountModal show={modelOpen.discount} onHide={() => closeModel("discount")} setInputs={setInputs} setTotal={setTotal} inputs= {inputs} total={total} />
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-3 ps-0">
                  <div className="">
                    <textarea
                      placeholder="Add notes from customer"
                      rows="7"
                      cols="180"
                      name="comment"
                      className="form-control"
                      value={inputs.comment}
                      onChange={event => handleInputChanges(event)}
                    />
                    {validate(inputs.comment, 'max:500', {title: 'comment'})}

                  </div>
                </div>
                <div className="col-md-4 offset-1">
                  <Total
                    shipping_cost={inputs.shipping_cost}
                    coupon_code={inputs.coupon_code}
                    coupon_amount={inputs.coupon_amount}
                    total= {total.total}
                    total_tax= {total.total_tax}
                    shipping_tax_amount= {total.shipping_tax_amount}
                    shipping_gross_amount= {total.shipping_gross_amount}
                    discount={total.discount_percent}
                    discount_type= {total.discount_type}
                    discount_cost= {total.discount_cost}
                    sub_total= {total.sub_total}
                    pathname = {pathname}
                    isCheck={isCheck}
                    openModel={openModel}
                    removeModelData={removeModelData}
                    /> 
                </div>
             
              </div>
              
              <div className="row mb-4">
                <Payment paymentMethod={metaData?.data?.PaymentMethod} payment_method={inputs.payment_method} payment_details={inputs.payment_details} handleInputChanges={handleSelectChange} isDisabled={pathname == "view"} modal={false} validator = {validator} handleSelectNewPayment={handleSelectNewPayment}/>
                { modelOpen.newPayment &&
                  <ModalComponent
                    show={modelOpen.newPayment}
                    onHide={() => closeModel('newPayment')}
                    title='Add new Payment Method'
                    body={<PaymentCreationForm callback={fetchMetaData} inputs={inputs} setInputs={setInputs} closeModal={() => closeModel('newPayment')}/>}
                  />
                  }
                <div className="col-6">
                  <div className="px-4 py-3 bg-white border">
                    <div className="row">
                      <div className="col-lg-12 mb-4">
                        <div className="dropdown">
                          <button type="button" className="btn p-0 d-flex align-items-center" id="batch-setting" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {<UilLabel size="14" color={lang.colors.secondary} className="me-1" />} Order Status <UilAngleDown />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="batch-setting">
                            {
                              _.sortBy(config.OrderStatus, ['sort_order']).map((status, index) => (
                                !_.isEmpty(status) && !_.isEmpty(status.status) &&
                                <li key={index}>
                                  <a className="dropdown-item small" onClick={e => updateOrderStatus(e, status.id)}>
                                    <span className={`badge rounded-pill ${status.class}`}>{status.status}</span>
                                  </a>
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                        {validate(inputs.order_status, 'required', {title: 'order_status'})}


                        {inputs.order_status != "" ? (
                          <div className="col-6">
                            <span className={`badge rounded-pill ${config.OrderStatus[inputs.order_status].class}`}>{config.OrderStatus[inputs.order_status].status}</span>
                          </div>
                        ) : (null)}
                      </div>
                      <div className="col-lg-12">
                        <div className="fw-bold mb-1">
                          Created by
                        </div>
                        <Select
                          options={userListQuery.data}
                          placeholder="Select Created by"
                          closeMenuOnSelect={true}
                          value={_.find(userListQuery?.data, function (obj) { return obj.id === inputs.user_id; }) ?? { id: inputs.user_id, name: inputs.created_by}}
                          components={{ IndicatorSeparator: () => null }}
                          onChange={(value) =>
                            handleSelectChangeUser(value)
                          }
                          formatOptionLabel={formatOptionLabelCreatedBy}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                        />
                        {validate(inputs.user_id, "required", {
                          title: "Created by",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4 mb-4 bg-white border">
                <div className="row">
                  <div className="col-lg-12">
                    <FileAttachments
                      value={inputs.attachments}
                      onChange={(file) => handleFiles(file)}
                      validator={fileValidator}
                      isDisabled={pathname.includes("view")}
                    />
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-4 bg-white text-center border">
                  <div className="p-4 add-new-product">
                    <button className="btn btn-secondary" type="button" onClick={(e) => handleSubmit(e)}>Create a new order</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    </div>
  );
}
