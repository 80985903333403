import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notify } from "../../../scripts/notify";

import apiClient from "../header";


export function useUpdateStatusQuery() {
  const queryClient = useQueryClient();

  function updateStatus(data) {
    return apiClient
      .post(`channels/opencart/update/order`, data)
      .then((result) => result.data);
  }

  function handleSuccess(data) {
    notify(data.type, data.message);
    if (data.status) {
      queryClient.invalidateQueries(["order-list"]);
      queryClient.invalidateQueries(["order-view"]);
    }
  }

  const mutate = useMutation((data) => updateStatus(data), {
    retry: false,
    onSuccess: handleSuccess,
  });

  return [ mutate ];
}