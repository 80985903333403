import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import ErrorPage from "../../components/errors/ErrorPage";
import apiClient from "../header";

export function useVonageQuery() {

  const [viewError, setViewError] = useState();

  function getVonage(signal, key) {
    const query = apiClient
      .get(`configs/get`, { params: {key: key }, signal })
      .catch((error) => {
        setViewError(<ErrorPage error={error.response.status} />);
      })
      .then((result) => {
        if ((result.data.data).length == 0) {
          setViewError(
            <>
              <ErrorPage />
            </>
          );
        }
        return result.data;
      });


    return query;
  }

  var query = useQuery(
    ["vonage-view"],
    ({ signal }) => getVonage(signal, "nexmo"),
    {
      retry: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return [
    query,
    {
      'viewError' : viewError
    },
  ];
}


export function useNotificationOrderQuery() {

  const [viewError, setViewError] = useState();

  function getTriggers(signal, key) {
    const query = apiClient
      .get(`configs/get`, { params: {key: key }, signal })
      .catch((error) => {
        setViewError(<ErrorPage error={error.response.status} />);
      })
      .then((result) => {
        if (result.data.status === 0) {
          setViewError(
            <>
              <ErrorPage />
            </>
          );
        }
        return result.data;
      });


    return query;
  }

  var query = useQuery(
    ["triggers-list"],
    ({ signal }) => getTriggers(signal, "order"),
    {
      retry: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return [
    query,
    {
      viewOrderError: viewError,
    },
  ];
}
