import React, { Component } from 'react';
import { UilCheckCircle } from '@iconscout/react-unicons'
import lang from "../../lang/en/Theme/Colors";
import { Link } from 'react-router-dom';
import { Paths } from "../../routes"



export default class IntegrationSuccess extends Component {
  render(){
    return(
      <div>
        <div className="row">
          <div className="col-lg-6">
            <section className="my-4 bg-white p-5 text-center">
              <div className="mb-4 text-center">
                <UilCheckCircle size="32" color={lang.colors.success} />
              </div>
              <div>
                <h4 className="mb-3">{this.props.integrationName} has been <br /> successfully integrated</h4>
                <Link to={ Paths.Integrations.pages.IntegrationEditForm.path + this.props.integrationId}>
                  <small>View Integration</small>
                </Link>
              </div>
            </section> 
          </div>
        </div>
      </div>
    )
  }
}

