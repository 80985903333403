import React, { useRef, useState, useEffect, useCallback } from "react";
import _ from "lodash";
import AsyncSelect from "react-select/async";

import { useValidator } from "../../../utils/validator";
import { getCountryList, useBusinessInfoUpdateQuery, } from "../../../stores/setting/businessInformation";
import { useQueryClient } from "@tanstack/react-query";

export default function Form(props) {
  const [country, setCountry] = useState([]);
  const [errors, setErrors] = useState([]);
  const [validator, { validate }] = useValidator(errors);
  const [, forceUpdate] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [inputs, setInputs] = useState({
    id: null,
    logo: "",
    business_name: "",
    business_description: "",
    payment_details: "",
    support_email: "",
    telephone: "",
    website_url: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    tax_code: "",
    tax_code: "",
  });

  const [businessInfoUpdate, { createErrors }] = useBusinessInfoUpdateQuery();
  const queryClient = useQueryClient();

  const debouncedChangeHandler = _.debounce((searchParams, callback) => {
    (async () => {
      let query = await queryClient.fetchQuery(
        ["country-list", { searchParams }],
        ({ queryKey, signal }) => getCountryList(queryKey, signal)
      );
      callback(query);
    })();
  }, 200);

  const onChange = (event) => {
    setButtonDisabled(false);
    event.persist();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSelectChange = (option) => {
    setInputs((prevProps) => ({
      ...prevProps,
      country: option.id,
    }));
    setButtonDisabled(false);
    setCountry(option);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      businessInfoUpdate.mutate(inputs, {
        onSuccess: (data) => {
          if (data.status) {
            setButtonDisabled(true);
          }
        },
      });
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (props?.data?.isFetched) {
      setInputs((prevProps) => ({
        ...prevProps,
        ...props?.data?.data?.business_data,
        country: country?.id 
      }));
      setCountry(props?.data?.data?.business_data?.country);
    }
  }, [props?.data?.data]);

  return (
    <div>
      <form onSubmit={onSubmit} encType="multipart/form-data">
        <section className="bg-white p-5 rounded mb-4 border">
          <div className="row">
            <div className="col-md-12 mb-4">
              <label htmlFor="business_name">Business Name</label>
              <span className="text-danger">*</span>
              <input
                type="text"
                className="form-control"
                id="business_name"
                name="business_name"
                placeholder="Enter Business Name"
                value={inputs.business_name || ""}
                onChange={onChange}
              />
              {validate(inputs.business_name, "required|min:2|max:20", { title: "business_name", })}
            </div>
            <div className="col-md-12 mb-4 ">
              <label htmlFor="address">Business Description</label>
              <input
                type="text"
                className="form-control"
                name="business_description"
                placeholder="Enter Business Description"
                value={inputs.business_description || ""}
                onChange={onChange}
              />
              {validate(inputs.business_description, "max:150", { title: "business_description", })}
            </div>
            <div className="col-md-12 mb-4">
              <label htmlFor="address">Business Payment Details</label>
              <textarea
                type="text-area"
                className="form-control"
                name="payment_details"
                placeholder="Enter Business Payment Details"
                value={inputs.payment_details || ""}
                onChange={onChange}
              />
              {validate(inputs.payment_details, "max:500", { title: "payment_details", })}
            </div>
            <div className="col-md-6">
              <label htmlFor="business_name">Tax Code</label>
              <input
                type="text"
                className="form-control"
                id="tax_code"
                name="tax_code"
                placeholder="Enter Tax Code"
                value={inputs.tax_code || ""}
                onChange={onChange}
              />
            </div>
            {validate(inputs.tax_code, "max:120", { title: "tax_code", })}
            <div className="col-md-6 mb-4 ">
              <label htmlFor="business_name">Tax ID</label>
              <input
                type="text"
                className="form-control"
                id="tax_id"
                name="tax_id"
                placeholder="Enter Tax ID"
                value={inputs.tax_id || ""}
                onChange={onChange}
              />
              {validate(inputs.tax_id, "max:120", { title: "tax_code", })}
            </div>
          </div>
        </section>
      </form>

      <form onSubmit={onSubmit} encType="multipart/form-data">
        <section className="bg-white p-5 rounded mb-4 border">
          <div className="row">
            <div className="col-md-6 mb-4">
              <label htmlFor="business_name">Support Email</label>
              <span className="text-danger">*</span>
              <input
                type="text"
                className="form-control"
                id="support_email"
                name="support_email"
                placeholder="Enter Support Email"
                value={inputs.support_email || ""}
                onChange={onChange}
              />
              {validate(inputs.support_email, "required|email", { title: "support_email", })}
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="support_email">Telephone</label>
              <input
                type="text"
                className="form-control"
                id="telephone"
                name="telephone"
                placeholder="Enter Telephone"
                value={inputs.telephone || ""}
                onChange={onChange}
              />
              {validate(inputs.telephone, "telephone", { title: "telephone", })}
            </div>
            <div className="col-md-6">
              <label htmlFor="business_name">Website URL</label>
              <input
                type="text"
                className="form-control"
                id="website_url"
                name="website_url"
                placeholder="Enter Website URL"
                value={inputs.website_url || ""}
                onChange={onChange}
              />
              {validate(inputs.website_url, "web", { title: "website_url", })}
            </div>
          </div>
        </section>

        <section className="bg-white p-5 rounded mb-4 border">
          <div className="row">
            <div className="col-md-6 mb-4">
              <label htmlFor="business_name">Address 1</label>
              <input
                type="text"
                className="form-control"
                id="address_1"
                name="address_1"
                placeholder="Enter Address 1"
                value={inputs.address_1 || ""}
                onChange={onChange}
              />
              {validate(inputs.address_1, "max:120", { title: "address_1", })}
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="business_name">Address 2</label>
              <input
                type="text"
                className="form-control"
                id="address_2"
                name="address_2"
                placeholder="Enter Address 2"
                value={inputs.address_2 || ""}
                onChange={onChange}
              />
              {validate(inputs.address_2, "max:120", { title: "address_2", })}
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="business_name">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                placeholder="Enter City"
                value={inputs.city || ""}
                onChange={onChange}
              />
              {validate(inputs.city, "max:120", { title: "city", })}
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="business_name">State</label>
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
                placeholder="Enter State"
                value={inputs.state || ""}
                onChange={onChange}
              />
              {validate(inputs.state, "max:50", { title: "state", })}
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="business_name">Zip Code</label>
              <input
                type="text"
                className="form-control"
                id="zipcode"
                name="zipcode"
                placeholder="Enter Zip Code"
                value={inputs.zipcode || ""}
                onChange={onChange}
              />
              {validate(inputs.zipcode, "max:15", { title: "zipcode", })}
            </div>
            <div className="col-md-6 mb-4">
              <label htmlFor="business_name">Country</label>
              <AsyncSelect
                cacheOptions
                loadOptions={debouncedChangeHandler}
                value={country || ""}
                isSearchable={true}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                placeholder="Enter Country"
                noOptionsMessage={() => null}
                onChange={handleSelectChange}
              />
            </div>
          </div>
        </section>

        <div className="row ">
          <div className="col-md-4 text-center">
            <div className="p-4 add-new-product border bg-white rounded">
              <button
                className="btn btn-secondary text-white"
                disabled={buttonDisabled}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
