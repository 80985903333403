import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'


import { Paths } from "../../../routes";
import localstorage from "../../../localstorage";

// components
import Sidebar from "../../layouts/SidebarLayout.js";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import { MetaDataContextProvider } from "../../../web/context/metaData";
import { UsersContextProvider } from "../../../web/context/users";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { usePreferencesQuery, useQueryContext } from "../../stores/context";


const ProtectedRoute = () => {
  const queryClient = new QueryClient()
  if (localstorage.token.get()) {
    return (
      <>
				<Sidebar />
        <QueryClientProvider client={queryClient}>
				<MetaDataContextProvider>
				<UsersContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
					<main className="content d-flex flex-column justify-content-between gx-4">
						<div>
							<Navbar />
								<div className="container-fluid content-height">
								<Outlet context={['context']}/>
							</div>
						</div>
						<div>
							<Footer  />
						</div>
					</main>
				</UsersContextProvider>
				</MetaDataContextProvider>
        </QueryClientProvider>
			</>
		)
	}

	return <Navigate to={Paths.Login.path} />;
};

export default ProtectedRoute;
