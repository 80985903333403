import React, { useContext, useState } from "react";
import _ from "lodash";
import { Paths } from "../../../routes";
import configurations from "../../../configurations";
import { Link, useNavigate } from "react-router-dom";
import { UilEllipsisH, UilPlusSquare, UilEye, UilPrint, UilEnvelopeAlt, } from "@iconscout/react-unicons";

import { confirmCreate } from "../../utils/scripts";
import ActionBar from "../../components/global/ActionBar";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../layouts/PageHeader";
import ErrorPage from "../../components/errors/ErrorPage";
import { MetaDataContext } from "../../../web/context/metaData";

import { useInvoiceListQuery } from "../../stores/invoices/list";
import { useCreateOrderQuery } from "../../stores/invoices/createOrder";
import { useSendInvoiceQuery } from "../../stores/invoices/send";

export default function Listing() {

  const navigate = useNavigate();
  const {data: preferences} = useContext(MetaDataContext);
  const [ invoiceQuery, { searchParams, setSearchParams, setPageNumber, setSort, setFilters }, ] = useInvoiceListQuery();
  const [ createOrderQuery ] = useCreateOrderQuery();
  const [ sendInvoiceQuery ] = useSendInvoiceQuery();

  const createOrder = (id) => {
    confirmCreate(() => createOrderQuery.mutate(id));
  };

  const sendInvoice = (id) => {
    sendInvoiceQuery.mutate(id)
  };

  const editInvoice = (id) => {
    navigate(Paths.Invoice.pages.edit.path + id);
  };

  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: "invoice_no",
      name: "Invoice Number",
      cell: (row) => (
        _.isNull(row.order_id) ? <Link to={Paths.Invoice.pages.edit.path + row.id}>{row.invoice_no}</Link> : row.invoice_no
      ),
    },
    {
      id: "to",
      name: "To",
      cell: (row) => (
        <>
          {row.customer_detail.customer_first_name ?? "-"}{" "}{row.customer_detail.customer_last_name ?? "-"},{" "}
          {row.customer_detail.email ?? "-"}
        </>
      ),
    },
    {
      id: "date",
      name: "Date",
      cell: (row) => row.invoice_date,
    },
    {
      id: "due_date",
      name: "Due Date",
      cell: (row) => row.invoice_due_date,
    },
    {
      id: "total",
      name: "Total",
      cell: (row) => preferences.currency_symbol + row.invoice_total,
    },
    {
      name: "Status",
      cell: (row) => _.find(configurations.InvoiceStatus, { id: row.invoice_status }).status,
    },
    {
      name: "",
      id: "action",
      cell: (row) => (
        <div className="dropdown text-end">
          <>
            <button className="btn btn-link" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" >
              {<UilEllipsisH size="16" className="text-secondary" />}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu">
              {row.order_id == null ? (
                <li className="dropdown-item fw-bold cursor text-decoration-none">
                  <button onClick={() => createOrder(row.id)} className="btn btn-link p-0 me-2 text-decoration-none" >
                    <span className="fw-bold"> {<UilPlusSquare size="16" className="text-secondary me-1 mb-1" />}
                      Create order
                    </span>
                  </button>
                </li>
              ) : null}
              <li className="dropdown-item fw-bold cursor">
                <button onClick={() => editInvoice(row.id)} disabled={!_.isNull(row.order_id)} className="btn btn-link p-0 text-decoration-none" >
                    <span className="fw-bold">{<UilEye size="16" className="text-secondary me-1 mb-1" />} View/Edit Invoice </span>
                </button>
              </li>
              <a href={row.view_url} className="text-decoration-none" target="_blank" >
                <li className="dropdown-item fw-bold cursor text-decoration-none">
                  <span>{<UilPrint size="16" className="text-secondary me-1 mb-1" /> } Print Invoice </span>
                </li>
              </a>
              <li className="dropdown-item fw-bold cursor"  onClick={() => sendInvoice(row.id)} >
                <button className="btn btn-link p-0 text-decoration-none">
                  <span className="fw-bold">{<UilEnvelopeAlt size="16" className="text-secondary me-1 mb-1" />} Send Invoice </span>
                </button>
              </li>
            </ul>
          </>
        </div>
      ),
    },
  ];


  return (
    <>
      {invoiceQuery.isError && <ErrorPage innerPage={false} error="500" />}

      <PageHeader title="Invoices" subtitle="All Invoices"
       component={
          <div className="d-flex justify-content-end align-items-center mb-4">
            <Link to={Paths.Invoice.pages.create.path} className="text-decoration-none" >
              <button type="button" className="btn btn-secondary px-5 ms-3"> Add a new invoice </button>
            </Link>
          </div>
        }
      />

      <ActionBar searchParams={searchParams} setSearchParams={handleSearch} sortOptions={configurations.Sort} setSort={setSort} placeholder="Search by Invoice number" />

      <DataTable
        data={invoiceQuery?.data?.data}
        columns={columns}
        isLoading={invoiceQuery.isFetching}
        pagination={true}
        onChangePage={(page) => setPageNumber(page)}
        paginationMeta={invoiceQuery?.data?.meta}
      />
    </>
  );
}
