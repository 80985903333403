
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { objectToFormData } from "../../../scripts/utils";
import { Paths } from "../../../routes";
import { toast } from "react-toastify";

function editQuotation(data) {
  const formData = new FormData
  objectToFormData(data, formData);

  return apiClient.post(`quotations/edit`, formData)
  .then(
    (result) => result.data,
  );
}

function updateQuotation(data) {
  const formData = new FormData
  objectToFormData(data, formData);

  return apiClient.post(`quotations/update`, formData)
  .then(
    (result) => result.data,
  );
}

export function useQuotationEditQuery() {
	const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  var toastify;
  
  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true })
    if (data.status) {
      queryClient.invalidateQueries(['quotations-list']);
      setTimeout(() => {
        navigate(Paths.Quotations.path);
      }, 2000)
    } else {
      if (data.errors) {
        for (let [error, message] of Object.entries(data.errors)) {
          setErrors(prevProps => ({
          ...prevProps,
            [error]: message[0]
          }));
        }
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...")
  }

  function handleError(data) {
    toast.update(toastify, {
      render: 'Something went wrong',
      type: 'error',
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
  }
  
  const mutate =  useMutation((editQuotationData) => editQuotation(editQuotationData), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return [
    mutate,
    {
      'updateErrors': errors,
    },
  ]
}

export function useQuotationUpdateQuery() {
	const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  var toastify;
  
  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true })
    if (data.status) {
      queryClient.invalidateQueries(['quotations-list']);
      setTimeout(() => {
        navigate(Paths.Quotations.path);
      }, 2000)
    } else {
      if (data.errors) {
        for (let [error, message] of Object.entries(data.errors)) {
          setErrors(prevProps => ({
          ...prevProps,
            [error]: message[0]
          }));
        }
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...")
  }
  
  const mutate =  useMutation((editQuotation) => updateQuotation(editQuotation), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess
  });

  return [
    mutate,
    {
      'updateErrors': errors,
    },
  ]
}