
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { objectToFormData } from "../../../scripts/utils";
import { Paths } from "../../../routes";
import { toast } from "react-toastify";

function sendQuotation(quotationId) {  
  return apiClient.post(`quotations/send`, {
    id: quotationId
  })
  .then(
    (result) => result.data,
  );
}


export function useSendQuotationQuery() {
  const [errors, setErrors] = useState({});

  var toastify;

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true })
  }

  function handleError(data) {
    toast.update(toastify, { render: 'Oops something went wrong!', type: 'error', isLoading: false, autoClose: true, closeButton: true})
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...")
  }
  
  const mutate =  useMutation((quotationId) => sendQuotation(quotationId), {
    retry: false,
    onMutate: handleProcessing,
    onError: handleError,
    onSuccess: handleSuccess
  });

  return [
    mutate,
    {
      'createErrors': errors,
    },
  ]
}