import React, { useState, useEffect } from "react";


export default function IntervalSetter({ callback, previousData, intervalName, label, }) {

  const [intervalTime, setIntervalTime] = useState({
    t: previousData[intervalName].t,
    i: previousData[intervalName].i,
  });

  const handelTimeChange = (e) => {
    e.persist();
    var value = 0;
    if (e.target.value != "") {
      value = parseInt(e.target.value);
    }
    setIntervalTime((prevProps) => ({
      ...prevProps,
      [e.target.name]: value,
    }));
  };

  useEffect(() => {
    callback((prevProps) => ({
      ...prevProps,
      [intervalName]: intervalTime,
    }));
  }, [intervalTime]);


  return (
    <div className="row mb-4">
     <div className="col-12"> <label className="mb-2">{label}</label></div>
      <div className="col-md-6">
        <input
          className="form-control"
          name={"t"}
          onChange={(e) => handelTimeChange(e)}
          value={intervalTime.t}
        />
      </div>
      <div className="col-md-6">
        <select
          className="form-control"
          name={"i"}
          onChange={(e) => handelTimeChange(e)}
          value={intervalTime.i}
        >
          <option value={1}>Minute</option>
          <option value={2}>Hour</option>
          <option value={3}>Days</option>
        </select>
      </div>
    </div>
  );
}
