import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../header";

var defaultState = {
  pageNumber: 1,
  limit: 15,
};

export function getList(queryKey, signal) {
  const [ query_key, { limit, pageNumber }, ] = queryKey;
  const query = apiClient .get(`groups/get/all`, { params: { limit: limit, page: pageNumber, },
      signal,
    })
    .then((result) => result.data);
  return query;
}
export function useGroupListQuery({
  enabled = true,
  defaultValue = {},
} = {}) {
  defaultState = { ...defaultState, ...defaultValue };

  const [pageNumber, setPageNumber] = useState(defaultState.pageNumber);
  const [limit, setLimit] = useState(defaultState.limit);

  function handleSuccess(data) {
    document.dispatchEvent(
      new CustomEvent("group-list-fetched", { detail: data.data })
    );
  }

  const query = useQuery(
    [ "group-list", {  pageNumber, limit }, ], ({ queryKey, signal }) => getList(queryKey, signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
      enabled: enabled,
    }
  );

  return [
    query,
    {
      limit: limit,
      setLimit: setLimit,
      setPageNumber: setPageNumber,
    },
  ];
}

export function useGroupCreateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function create(data) {
    return apiClient.post(`groups/add`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(["group-list"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((data) => create(data), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}

export function useGroupUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function update(data) {
    return apiClient.post(`groups/edit`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(["group-list"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((data) => update(data), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      updateErrors: errors,
    },
  ];
}

export function useGroupDeleteQuery() {
  const queryClient = useQueryClient();

  var toastify;
  function remove(id) {
    return apiClient.post(`groups/delete`, id ).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["group-list"]);
    }
  }

  const mutate = useMutation((id) => remove(id), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return mutate;
}

export function usePermissionListQuery() {
  const queryClient = useQueryClient();

  function handleSuccess(data) {
    if (data.status) {
      queryClient.invalidateQueries(["permission-list"]);
    }
  }

  function getList(signal) {
    const query = apiClient
      .get(`groups/get/permissions`, {
        signal,
      })
      .then((result) => result.data);
    return query;
  }

  return useQuery(["permission-list", {}], ({ signal }) => getList(signal), {
    retry: 0,
    staleTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess: handleSuccess,
  });
}
