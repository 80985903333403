import React, { useState, useEffect } from "react";

import PageHeader from "../../../layouts/PageHeader";
import Loader from "../../../components/global/Loader";
import { useMailQuery, useMailUpdateQuery } from "../../../stores/setting/mail";
import { useValidator } from "../../../utils/validator";

export default function Form() {
  
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState();
  const [validator, { validate }] = useValidator(errors);

  const [mailUpdateQuery, { updateErrors }] = useMailUpdateQuery();
  const mailViewQuery = useMailQuery();

  const handleInputChange = (event) => {
    event.persist();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      mailUpdateQuery.mutate(inputs);
    }
      validator.current.showMessages();
      forceUpdate(true);

  };

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);

  useEffect(() => {
    if (mailViewQuery.isFetched) {
      setInputs(mailViewQuery.data.data[0]);
    }
  }, [mailViewQuery.data]);


  return (
    <div className="bg-white p-5 mb-4 rounded border">
      <PageHeader subtitle="Mail SMTP" subtext="Add your mail smtp settings" />
      {mailViewQuery.isFetching ? (
        <Loader />
      ) : (
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6 mb-4">
              <label htmlFor="host">Mail Host</label>
              <input
                type="text"
                className="form-control"
                id="host"
                name="host"
                placeholder="smtp.gmail.com"
                value={inputs.host || ""}
                onChange={handleInputChange}
              />
              {validate(inputs.host, "required|min:2|max:20", { title: "host" })}
            </div>
            <div className="col-md-6">
              <label htmlFor="port">Mail Port</label>
              <input
                type="text"
                className="form-control"
                id="port"
                name="port"
                placeholder="1025,36,etc"
                value={inputs.port || ""}
                onChange={handleInputChange}
              />
              {validate(inputs.port, "required|max:5", { title: "port" })}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-4">
              <label htmlFor="username">Mail Username</label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                placeholder="xxxxxxxxxx"
                value={inputs.username || ""}
                onChange={handleInputChange}
              />
              {validate(inputs.username, "required|min:2|max:50", { title: "username" })}
            </div>
            <div className="col-md-6">
              <label htmlFor="password">Mail Password</label>
              <input
                type="text"
                className="form-control"
                name="password"
                placeholder="**********"
                value={inputs.password || ""}
                onChange={handleInputChange}
              />
              {validate(inputs.password, "required|max:150", { title: "password" })}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-4">
              <label htmlFor="address">Mail From Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                placeholder="support@innoventory.io"
                value={inputs.address || ""}
                onChange={handleInputChange}
              />
              {validate(inputs.address, "required|email", { title: "address" })}
            </div>
            <div className="col-md-6">
              <label htmlFor="name">Mail From Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Innoventory"
                value={inputs.name || ""}
                onChange={handleInputChange}
              />
              {validate(inputs.name, "required|max:150", { title: "name" })}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div>
                <button className="btn btn-secondary px-5">Save changes</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
