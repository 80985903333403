import axios from "axios";

import localstorage from "./localstorage";
import { notify } from './scripts/notify';
import { Paths } from "./routes";


var header = {
  headers: {
    Authorization: "Bearer " + localstorage.token.get(),
		'X-Tenant': localstorage.business.get()
  }
};

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: header.headers
});

const UNAUTHORIZED = 401;
let user_auth = true;
API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status == UNAUTHORIZED && user_auth) {
      localstorage.token.logout()
			user_auth = false;
      notify('error', 'This action is unauthorized.');
			
      setTimeout(() => {
        window.location.href = Paths.Login.path;
      }, 2000)
     
      return axios.request(error.config);
    }
    
    return Promise.reject(error); 
  }
);

//TODO: nest password_reset to password => reset
const auth = {
  login: params => API.post("auth/login", params),
  logout: params => API.post("auth/logout", params, header),
  refresh: params => API.post("auth/refresh", params, header),
  password_reset: params => API.post("auth/password/email", params, header)
};

const user = {
  get: params => API.get("auth/profile", params)
};

const users = {
  get: params => API.get("users/get", params),
  edit: params => API.post("auth/edit", params, header),
  update: params => API.post("auth/password/change", params, header),
  user: {
    add: params => API.post("users/add", params, header),
    edit: params => API.post("users/edit", params, header),
  },
  remove: params => API.post("users/delete", params, header)
};

const groups = {
  // get: params => API.get('groups/get-groups', params),
  get: params => API.get("groups/get/all", params),
  add: params => API.post("groups/add", params, header),
  delete: params => API.post("groups/delete", params, header),
  edit: params => API.post("groups/edit", params, header)
};

const permissions = {
  get: params => API.get("groups/get/permissions", params)
};

const warehouse = {
  get: params => API.get("warehouse/get", params),
  add: params => API.post("warehouse/add", params, header),
  edit: params => API.post("warehouse/edit", params, header),
  delete: params => API.post("warehouse/delete", params, header)
};

const business = {
  get: params => API.get("businesses/get", params),
  update: params => API.post("businesses/edit", params, header),
  logo: {
    update: params => API.post("businesses/logo/edit", params, header)
  },
  metadata: params => API.get("businesses/metadata", params),
};

// TODO: udpate this api call get_warehouses to inventory => warehouses => get
const inventory = {
  all: params => API.get("inventory/all", params),
  get: params => API.post("inventory/get", params, header),

  list: params => API.get("inventory/list", params, header),
  // putOptions: params => API.post('inventory/options/add', params, header),
  // editOptions: params => API.post('inventory/options/edit', params, header),
  add: params => API.post("inventory/add", params, header),
  edit: params => API.post("inventory/edit", params, header),
  delete: params => API.post("inventory/delete", params, header),
  stock: {
    update: params => API.post("inventory/stock/update", params, header)
  },
  metadata: params => API.get("inventory/metadata", params),
};

const tax = {
  get: params => API.get("tax/get", params),
  add: params => API.post("tax/add", params, header),
  edit: params => API.post("tax/edit", params, header),
  delete: params => API.post("tax/delete", params, header)
};

const channel = {
  all: params => API.get('channels/all', params),
  get: params => API.get('channels/get', params),
  delete:params => API.post('channels/delete',params),
  update: params => API.post('channels/update', params),
  poll: params => API.get("channels/polling-channel", params),
  order: {
    get: params => API.get('channels/order/product/get', params)
  },
  product:{
    get:params => API.get('channels/product/get', params, header),
    map:params => API.post('channels/product/map', params, header),
    unlink:params => API.post('channels/product/unlink', params, header),
  },
  edit: {
    status: params => API.post('channels/edit/status', params),
  },
}

const amazon = {
  // getAccess: params => API.get('/amazon-access'),
  get: params => API.get("channels/get", params),
  add: params => API.post('channels/amazon/add', params),
  
  orders: {
    get: params => API.get("channels/amazon/order", params),
  },
  products: {
    get: params => API.get("channels/amazon/product", params),
  },
};


const opencart = {
  add: params => API.post('channels/opencart/add', params),
  // delete:params => API.post('channels/delete',params),

  products: {
    get: params => API.get('channels/opencart/product', params),
  },
  orders: {
    get: params => API.get('channels/opencart/order', params),

    status: {
      update: params => API.post('channels/opencart/update/order', params),
    }
  }
}

const ebay = {
	token: params => API.get('/ebay-fetch-token', params),
	orders: {
			get: params => API.get('channels/ebay/order', params),
		}
}

const fetch = {
  get: params => API.get("channels/order/get", params),
  invoice: params => axios.get("/download", params),

  products: {
    get: params => API.get("channels/order/product/get", params),
  }
};

// TODO: update these api calls
const general = {
  lowStock: params => API.get("general/lowStock", params),
  static: {
    get: params => API.get('general/dashboard', params),
  },
  filtered: {
    get: params => API.get('general/dashboard/filtered', params)
  } ,
  overview: {
    get: params => API.get('general/dashboard/overview', params)
  }

};

const report = {
  sales_analysis: params => API.get('report/salesAnalysis', params),
  metadata: params => API.get('report/metadata', params),
  export_xlsx: params => API.get('report/export-xlsx', params),
}

const order = {
  all: params => API.get('order/all', params),
  add: params => API.post("order/add", params, header),
  total: params => API.post('order/total', params, header),
  metadata: params => API.get('order/metadata', params),
  create: {
    invoice: params => API.post('order/create/invoice', params, header),
  },
}

const integration = {
  all: params => API.get('integrations/all', params),
  get: params => API.post('integrations/get', params),
  edit: params => API.post('integrations/edit', params, header),
  delete:params => API.post('integrations/delete', params, header),
  update: {
    status: params => API.post('integrations/edit/status', params),
  },
}

const trackpod = {
  add: params => API.post('integrations/trackpod/add', params, header),

  orders: {
    add: params => API.get('integrations/trackpod/orders', params),
    info: params => API.get('integrations/trackpod/order/info', params) 
  }
}

const notification = {
  get: params => API.get('notifications/all', params),
  read: params => API.post('notifications/mark/read', params, header),
  save: params => API.post('notifications/save/token', params),
  order: {
		send: params => API.post('notifications/order', params),
		all: params => API.get('notifications/order/all', params)
	}
}

const customer = {
  get: params => API.get("customer/get", params),
}

const settings = {
  get: params => API.get("settings/get", params),
}

const invoice = {
  all: params => API.get('invoices/all', params),
  get: params => API.get('invoices/get', params),
  add: params => API.post("invoices/add", params, header),
  edit: params => API.post("invoices/edit", params, header),
  create: {
    order: params => API.post("invoices/create/order", params, header),
    invoice: params => API.post("invoices/create/invoice", params, header),
  },
  metadata: params => API.get('invoices/metadata', params),
  delete: params => API.post('invoices/delete', params, header),
  send: params => API.post('invoices/send-invoice', params, header),
}

const quotation = {
  all: params => API.get('quotations/all', params),
  get: params => API.get('quotations/get', params),
  add: params => API.post("quotations/add", params, header),
  edit: params => API.post("quotations/edit", params, header),
  update: params => API.post("quotations/update", params, header),
  create: {
    order: params => API.post("quotations/create/order", params, header),
    invoice: params => API.post("quotations/create/invoice", params, header),
  },
  metadata: params => API.get('quotations/metadata', params),
  delete: params => API.post('quotations/delete', params, header),
  send: params => API.post('quotations/send-quotation', params, header),
}

const category = {
  all: params => API.get('categories', params),
  get: params => API.post('categories/get', params),
  add: params => API.post('categories/add', params),
  edit: params => API.post('categories/edit', params),
  delete: params => API.post('categories/delete', params),
  metadata: params => API.get('categories/metadata', params),
}

const manufacturer = {
  all: params => API.get('manufacturer', params),
  get: params => API.get('manufacturer/get', params),
  add: params => API.post('manufacturer/add', params),
  edit: params => API.post('manufacturer/edit', params),
  delete: params => API.post('manufacturer/delete', params),
}


const units = {
  all: params => API.get('units', params),
  get: params => API.get('units/get', params),
  add: params => API.post('units/add', params),
  edit: params => API.post('units/edit', params),
  delete: params => API.post('units/delete', params),
}

const coupons = {
  all: params => API.get('coupons', params),
  get: params => API.get('coupons/get', params),
  add: params => API.post('coupons/add', params),
  edit: params => API.post('coupons/edit', params),
  delete: params => API.post('coupons/delete', params),
}

const shippingMethod = {
  all: params => API.get('shippingMethod', params),
  get: params => API.get('shippingMethod/get', params),
  add: params => API.post('shippingMethod/add', params),
  edit: params => API.post('shippingMethod/edit', params),
  delete: params => API.post('shippingMethod/delete', params),
}

const paymentMethod = {
  all: params => API.get('paymentMethod', params),
  get: params => API.get('paymentMethod/get', params),
  add: params => API.post('paymentMethod/add', params),
  edit: params => API.post('paymentMethod/edit', params),
  delete: params => API.post('paymentMethod/delete', params),
}

const preferences = {
  all: params => API.get('preferences', params),
  get: params => API.get('preferences/get', params),
  add: params => API.post('preferences/add', params)
  
}

const customization = {
  all: params => API.get('customization', params),
  get: params => API.get("customization/get", params),
  add: params => API.post("customization/add", params),
  
}

const config = {
  get: params => API.get('configs/get', params),
  random_orders: params => API.get('configs/random-orders', params),
  toggle: params => API.post('configs/toggle', params),
	delete: params => API.post('configs/reset', params), 
	add: {
		order_notification : params => API.post('configs/add-order-notification', params), 
	},
  update: {
    mailer: params => API.post('configs/mailer', params), 
    nexmo: params => API.post('configs/nexmo', params), 
    order_notification: params => API.post('configs/edit-order-notification', params), 
  }
}

export default {
  auth,
  user,
  users,
  warehouse,
  business,
  groups,
  permissions,
  inventory,
  channel,
  fetch,
  tax,
  opencart,
  general,
  amazon,
  order,
  integration,
  trackpod,
  notification,
  customer,
  settings,
  invoice,
  quotation,
  category,
  manufacturer,
  units,
  coupons,
  shippingMethod,
  paymentMethod,
  config,
  preferences,
  customization,
  report,
	ebay,
};
