
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";


function updateProductStock(inputs) {  
  return apiClient.post(`inventory/stock/update`,{ inputs })
  .then(
    (result) => result.data,
  );
}

export function useProductStockUpdateQuery() {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  function handleSuccess(data) {
    if (data.status) {
      notify(data.type, data.message);
      queryClient.invalidateQueries(['product-list']);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors(prevProps => ({
        ...prevProps,
          [error]: message[0]
        }));
      }
    }
  }
  
  const mutate =  useMutation((inputs) => updateProductStock(inputs), {
    retry: false,
    onSuccess: handleSuccess
  });

  return [
    mutate,
    {
      'updateErrors': errors,
    },
  ]
}