import React, { useState, useContext } from 'react';
import { UilAngleDown, UilCog, UilPlus, UilCreateDashboard, UilStore, UilShoppingCart, UilSignout} from "@iconscout/react-unicons";
import { NavLink } from 'react-router-dom';
import { Paths } from '../../routes';
import { UsersContext } from "../../web/context/users";

import api from '../../api';
import LocalStorage from '../../localstorage';

import ProfileIcon from '../components/common/ProfileIcon';


export default function Navbar() {
  const users = useContext(UsersContext);
  const info = JSON.parse(LocalStorage.userData.get());
  const [open,setOpen] = useState(false);

  const logout = (event) => {
    event.preventDefault();
    api.auth.logout().then((response) => {
      if (response.data.status) {
        LocalStorage.token.logout();
        window.location.href = Paths.Login.path;
      }
    });
  }

  return (
    <nav className='navbar navbar-expand-md navbar-light bg-white mb-4 general-nav border-bottom'>
      <div className='ms-3 mt-2'>
        <div className='dropdown' >
          <button type="button" className="btn btn-link p-0" id="navbar-general" data-bs-toggle="dropdown" aria-expanded="false"
          onClick={() => setOpen(open => !open)} onBlur={() => setOpen(false)}>
            <ProfileIcon bgColor={'primary'}>
              <UilPlus size="16" className={`text-white navbar-rotate ${open ? 'down' : ''}`} />
            </ProfileIcon>
          </button>

          <div className='dropdown-menu p-0' aria-labelledby="navbar-general">
            <ul className='list-inline mb-0'>
              <li className='list-inline-item ' >
                <div className='mb-3 fw-bold'><UilCreateDashboard size="14" className='text-secondary me-2 mb-1' />General</div>
                <ul className='list-unstyled'>
                  <li className='p-2'><NavLink to={Paths.Settings.pages.UserManagement.path} className='text-decoration-none fw-bold'><UilPlus size="14" className='text-primary me-2 mb-1' />Add User</NavLink></li>
                  <li className='p-2'><NavLink to={Paths.Settings.pages.WarehouseManagement.path} className='text-decoration-none fw-bold'><UilPlus size="14" className='text-primary mb-1 me-2' />Add Warehouse</NavLink></li>
                </ul>
              </li>
              <li className='list-inline-item' >
                <div className='mb-3 fw-bold'><UilStore size="14" className='text-secondary me-2 mb-1' />Products</div>
                <ul className='list-unstyled'>
                  <li className='p-2'>
                    <NavLink to={Paths.Product.pages.create.path} className='text-decoration-none fw-bold'><UilPlus size="14" className='text-primary mb-1 me-2' />Add Product</NavLink>
                  </li>
                  <li className='p-2'>
                    <NavLink to={Paths.Product.pages.createComposite.path} className='text-decoration-none fw-bold'><UilPlus size="14" className='text-primary mb-1  me-2' />Add Composite Product</NavLink>
                  </li>
                </ul>
              </li>
              <li className='list-inline-item'>
                <div className='mb-3 fw-bold'><UilShoppingCart  size="14" className='text-secondary me-2 mb-1' />Sales</div>
                <ul className='list-unstyled'>
                  <li className='p-2'>
                    <NavLink to={Paths.Order.pages.create.path}  className='text-decoration-none fw-bold'><UilPlus size="14" className='text-primary mb-1 me-2' />Create Order</NavLink>
                  </li>
                  <li className='p-2'>
                    <NavLink to={Paths.Invoice.pages.create.path}  className='text-decoration-none fw-bold'><UilPlus size="14" className='text-primary mb-1 me-2' />Create Invoice</NavLink>
                  </li>
                  <li className='p-2'>
                    <NavLink to={Paths.Quotations.pages.create.path}  className='text-decoration-none fw-bold'><UilPlus size="14" className='text-primary mb-1 me-2' />Create Quotation</NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='collapse navbar-collapse'>
        <ul className='navbar-nav ms-lg-auto align-items-center'>
          <li className="profile py-2">
            <div className="d-flex px-3">
              <div className="me-2 align-self-center">
                <ProfileIcon text={info?.name ?? ''} />
              </div>
              <div className="dropdown text-break">
                <a className="text-decoration-none" href="#" role="button" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                  {users.users.data.name} <UilAngleDown width="16" />
                </a>
                <div className="small text-gray-500">{info?.group?.group_name}</div>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu">
                  {/* <NavLink to={Paths.Settings.pages.AccountSettings.path}  className='text-decoration-none'><li className="dropdown-item">My Account</li></NavLink> */}
                  {/* <NavLink to={Paths.Settings.pages.AccountSettings.path} className="text-decoration-none"  ><li className='dropdown-item'><UilCog size="16" className="mb-1 me-2" />Settings</li></NavLink> */}
                  <NavLink to={Paths.Setting.pages.AccountSettings.path} className="text-decoration-none"  ><li className='dropdown-item'><UilCog size="16" className="mb-1 me-2" />Settings</li></NavLink>
                  <NavLink to={Paths.Logout.path} onClick={logout} className=" text-decoration-none" > <li className='dropdown-item text-danger'><UilSignout size="16" className="mb-1 me-2" />Logout</li></NavLink>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </nav>
  );
}
