import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link, History, Navigate } from 'react-router-dom';

import api from '../api';
import { notify } from "../scripts/notify";
import localstorage from '../localstorage';
import { Paths } from '../routes';

import Logo from "../assets/img/branding/innoventory-logo-full-white.svg";
import FullLogo from "../assets/img/branding/innoventory-logo-color.svg";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => {

    val.length > 0 && (valid = false)

  });
  return valid;
};


function validate(email) {

  const errors = {};

  if (!validEmailRegex.test(email)) {
    errors.email = "The email format is invalid.";
  }

  return errors;
}


export default class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      info: '',
      errors: {
        email: '',
      },
      disabled: false,
      loadingMessage: "Sending reset mail..."
    }; 

    this.onChange = this.onChange.bind(this);
    this.SubmitHandler = this.SubmitHandler.bind(this);
  }

  // componentDidMount(props){
  //   if(this.props.location.state && this.props.location.state.messge){
  //     console.log("property_id",this.props.location.state.message);
  //   }
  // }

  onChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }


  SubmitHandler(event) {
    event.preventDefault();
    this.setState({ disabled: true });
    this.setState({ errors: '' });
    let validErrors = validate(this.state.email);

    if (JSON.stringify(validErrors).length <= 2) {
      const { email } = this.state;
      // const { history } = this.props;
      // console.log(process.env.MIX_APP_UR,)
      api.auth.password_reset({ email })
        .then((response) => {
          const { history } = this.props;
          if (response.data.message == 'passwords.sent') {
            notify('success', 'An email with a reset link has been sent to your email.');
            this.setState({ loadingMessage: 'Redirecting...' });
            setTimeout(() => {
              window.location.href='/login';
            }, 5000)
          } else if(response.data[0].status == 0) {
            validErrors.email = "There is no account associated with this email."
            this.setState({ errors: validErrors })
            this.setState({ disabled: false});
          }
        })
        .catch(error => {
          console.log("ERRRR:: ", error.response.data.error);
          const validErrors = {}
          this.setState({disabled: false});
          if (error.response.data.error == 'passwords.user') {
            validErrors.email = "There is no account associated with this email."
          }

          this.setState({ errors: validErrors })
        });
    } else {
      this.setState({disabled: false});
      this.setState({ errors: validErrors })
    }

  }

  render() {
    const logoutMessage = this.state.logoutMessage;

    if (localstorage.token.get()) {
      return <Navigate to={Paths.Root.path} />
    }

    return (
      <div className="login-page-wrapper">
        <div className="container-fluid">
          <div className="row">

            <div className="col-md-7">
              <div className="row align-items-center justify-content-center h-100">
                <div className="col-md-10 col-xl-6">
                  <div className="text-center my-5 mt-lg-0">
                    <img src={FullLogo} width={250} alt=""/>
                  </div>
                  <div className="d-flex flex-column bg-white p-5 mb-4">
                    <h5 >Reset Password</h5>
                    <p><small>Enter the email associated with your account. </small></p>
                    <form onSubmit={this.SubmitHandler}>
                      <div className="mb-4">
                        <p className="mb-2">Email</p>
                        <input
                          type='text'
                          className="form-control"
                          name='email'
                          onChange={this.onChange}
                          value={this.state.email}
                          placeholder="Email"
                        />
                        <small className='text-danger'>{this.state.errors.email}</small>
                        <small className='text-warning'>{this.state.info.message}</small>
                      </div>
                      {
                        !this.state.disabled ? (
                          <button type='submit' className="btn btn-secondary w-100" >
                            Reset Password
                          </button>) : (
                          <button type='submit' className="btn btn-secondary w-100" disabled>
                            {this.state.loadingMessage}
                          </button>
                        )
                      }
                      {logoutMessage &&
                        <div className="alert alert-success>">
                          {logoutMessage}
                        </div>
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 login-sidebar-container h-100 p-0">
              <div className=" login-textblock-wrapper">
                <div className="text-block p-3 px-lg-5">
                  <h3 className="text-white mb-4">Experience hassle-free management.</h3>
                  <p className="text-muted mb-5">More channels, more buyers and more profit. Connect your inventory with multiple store and sell your products on channels like Amazon, Ebay, Opencart, Magento etc.</p>
                  <h5 className="text-white">This is unbelievable. After using this dashboard my business skyrocketed!</h5>
                  <p className="text-muted">&mdash; Rick E.</p>
                </div>
                <div className="copyright-block px-3 px-lg-5">
                  <img className="mb-2" src={Logo} height={30} alt=""></img>
                  <p className="text-muted"><small>Copyrights Reserved by Agaetis Technologies Pvt. Ltd.</small></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
