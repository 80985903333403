
import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import moment from 'moment';
import _ from 'lodash';

import lang from "../../../lang/en/Theme/Colors";
import Skeleton from 'react-loading-skeleton';

export default function DateFilter(props) {
  const {
    onChange,
    filterKey, 
    isLoading = false, 
    defaultValue = null
   } = props
  
  const dateOption = [
    { label: 'Last 7 days', value: '7', startDate: moment().subtract('7', 'days').format('D-M-Y'), endDate: moment().format('D-M-Y'), key: filterKey },
    { label: 'Last 30 days', value: '30', startDate: moment().subtract('30', 'days').format('D-M-Y'), endDate: moment().format('D-M-Y'), key: filterKey },
    { label: 'Last 60 days', value: '60', startDate: moment().subtract('60', 'days').format('D-M-Y'), endDate: moment().format('D-M-Y'), key: filterKey },
  ]

  const selectStyle = {
    control: base => ({
      ...base,
      backgroundColor: "rgba(1, 1, 1, 0.0)",
      boxShadow: 'none',
      cursor: 'pointer',
      border: 0,
    }),

    menu: base => ({
      ...base,
      zIndex: 999
    }),

    placeholder: base => ({
      ...base,
      color: lang.colors.gray500,
    }),

    container: provided => ({
      ...provided,
      width: 150
    }),

    option: provided => ({
      ...provided,
      fontSize: '12px',
      cursor: 'pointer',
    }),

  };


  return (
    <>
      {isLoading ? (
        <Skeleton width={150} height={38}/>
      )
      : 
      (<Select
        id="filter_dropdown"
        name="date_range"
        closeMenuOnSelect={true}
        options={dateOption}
        styles={selectStyle}
        isSearchable={false}
        defaultValue={dateOption.find(element => element.value == '7')}
        components={{ IndicatorSeparator: () => null, }}
        onChange={(option) => { onChange(option.key, option.startDate, option.endDate); }}
      />)
      }
    </>
  );
}
