import React, { useState, useEffect, useRef, useContext } from "react";
import { UilPen, UilTrashAlt, UilTruck } from '@iconscout/react-unicons';
import { Modal } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Skeleton from 'react-loading-skeleton';

import { notify } from '../../../scripts/notify'
import api from "../../../api";

import Loader from '../../../refactoring/components/global/Loader';
import PageHeader from "../../../refactoring/layouts/PageHeader";
import PageInfo from "../../../components/pagination/PageInfo";
import ShipppingCreationForm from"./ShippingCreationForm";
import ReactPagination from "../../../components/pagination/ReactPagination";
import _ from "lodash";
import { MetaDataContext } from "../../../web/context/metaData";


export default function ShippingManagement() {
  const metaData = useContext(MetaDataContext);
  const [loading, setLoading] = useState(true);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState({
    currentPage: pageNumber,
    per_page: 0,
    total_count: 0,
    last_page: 0
  });
  const [isOpen, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({
    id: "",
    name: "",
    amount: "",
    tax_classes_id:"",
  });
  const refName = useRef();

  const pageChange = pageNo => {
    setPageNumber(pageNo);
  };

  async function fetchData() {
    await api.shippingMethod.all({
      params: {
        paginate: 15,
        page: pageNumber
      }
    })
      .then(response => {
        setShippingMethod(response.data.data);
        setPages({
          currentPage: response.data.current_page,
          per_page: response.data.per_page,
          total_count: response.data.total,
          lastPage: response.data.last_page
        });

        if (pageNumber >= response.data.last_page) {
          setPageNumber(response.data.last_page);
        }
        // console.log(response);
      });
    await setLoading(false);

  }

  const add = () => {
    setOpen(true);
    setInputs({
      id: "",
      name: "",
      amount: "",
      tax_classes_id: ""
    })
  }

  const edit = (id, name, amount, tax_classes_id) => {
    setOpen(true);
    setInputs({
      id: id,
      name: name,
      amount: amount,
      tax_classes_id: tax_classes_id,
    });
    // console.log(id, name, inputs);
  }

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  }

  const handleDeleteShippingMethodClass = (Id) => {
    api.shippingMethod.delete({ id: Id })
      .then(response => {
        notify(response.data.type, response.data.message);
        if (response.data.status === 1) {
          fetchData();
        }
      })
      .catch(error => {
        notify('error');
        // console.log('Error::', error);
      })
  }

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <React.Fragment>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Shipping Methods" subtext="Manage shipping methods" />
          
          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()} > Add Shipping Method</button>
          </div>
        </div>
       
          <div className="border rounded-3 overflow-hidden">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Shipping Method</th>
                  <th>Tax Name</th>
                  <th>Amount</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
                <tr>
                  <td colSpan='100%' className='text-center'>
                    <Skeleton count={5} height={30} className="mb-3"/>
                  </td>
                </tr> 
              ) : shippingMethod.length ? (
                  shippingMethod.map(shippingMethod => (
                  <tr key={shippingMethod.id}>
                    <td>{shippingMethod.name}</td>
                    { _.isNull(shippingMethod.shipping_tax) && shippingMethod.tax_classes_id == 0 ? (
                      <td>Tax is not applicable</td>
                      ): (<td>{shippingMethod.shipping_tax.tax_name}</td>
                    )}
                    <td>{metaData.metaData.preference.currency.currency_symbol}{shippingMethod.amount}</td>
                    <td className="d-flex justify-content-end">
                      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit shipping method</Tooltip>}>
                        <span className="me-3 active-icon" onClick={() => { setIsEdit(true); edit(shippingMethod.id, shippingMethod.name, shippingMethod.amount,shippingMethod.tax_classes_id) }} id="edit">
                          {<UilPen size="14" className="text-secondary"/>}
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete shipping method</Tooltip>}>
                        <span className="cursor active-icon" id="delete">
                          {<UilTrashAlt size="14" onClick={() => handleDeleteShippingMethodClass(shippingMethod.id)} className="text-danger" />}
                        </span>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))
                  ) : (

                  <tr>
                    <td colSpan="100%">
                      <div className="text-center bg-white py-5">
                        {<UilTruck size="38" className="mb-3 text-secondary" />}
                        <p className="h5 text-gray-700">No Shipping Methods are available</p>
                        <p className="small text-gray-600">Enter a Shipping Method.</p>
                      </div>
                    </td>
                  </tr>
            
        )} 
              </tbody>
              <tfoot>
                <tr className="border-top">
                  <td colSpan="2">
                    <PageInfo
                      perPage={pages.per_page}
                      currentPage={pages.currentPage}
                      total={pages.total_count}
                    />
                  </td>
                  {pages ? (
                    <td colSpan="6">
                      <ReactPagination pageNumber={pageChange} pages={pages} />
                    </td>
                  ) : null}
                </tr>
              </tfoot>
            </table>
          </div>
     
      </div>

      {/* TODO: fetch from component, below section */}
      {isOpen ? (
        <Modal show={isOpen} onEntered={() => refName.current.focus()} onHide={closeModal} centered dialogClassName="modal-md">
          <Modal.Header closeButton className="p-4">
            <Modal.Title>{ inputs.tax_id ? "Edit shipping method" : "Add Shipping Method" }</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <ShipppingCreationForm callback={fetchData} inputs={inputs} isEdit={isEdit} closeModal={closeModal} refName={refName}/>
          </Modal.Body>
        </Modal>
      ) : null
      }
    </React.Fragment>
  );
}
