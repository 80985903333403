import React, { useEffect, useRef } from "react";
import _ from "lodash";


import BusinessSummary from "../../components/report/BusinessSummary";
import StackedBarChartBlock from "../../components/charts/StackedBarChartBlock";
import LineChartBlock from "../../components/charts/LineChartBlock";
import CardComponent from "../../layouts/CardLayout";
import NoDataFound from "../../components/global/NoDataFound";
import SalesbyCategory from "../../components/report/SalesbyCategory";
import SalesbyCustomers from "../../components/report/SalesbyCustomers";
import PieChartBlock from "../../components/charts/PieChartBlock";
import NoDataTable from "../../components/global/NoDataTable";
import SimpleNumber from "../../components/report/SimpleNumber";
import TotalSales from "../../components/report/TotalSales";
import { useReportForRecurringCustomerQuery } from "../../stores/reports/recurringcustomer";
import RecurringCustomersbyOrderCount from "../../components/report/RecurringCustomersbyOrderCount";
import RecurringCustomersOrderFrequency from "../../components/report/RecurringCustomersOrderFrequency";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import DateFilter from "../../components/global/DateFilter";
import { abbreviateNumber } from "../../../scripts/utils";
import Customers from "../../components/report/Customers";
import RecurringCustomersOrderFrequencyByShippingPostcode from "../../components/report/RecurringCustomersOrderFrequencyByShippingPostcode";


export default function RecurringCustomerComponent({
  refs,
  reportData,
  preferences,
  chartSettings,
  setReportData,
  fetchFilteredData,
}) {

  return (
    <div ref={refs}>
      {!_.isEmpty(
        reportData,
        preferences,
        chartSettings,
        fetchFilteredData
      ) ? (
        <>
          <div className="row mb-4">
            <div className="col-4">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Percentage of sales
                </Card.Header>
                <Card.Body>
                  {reportData.isLoading ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) : (
                    <Card.Title as="h2" className="mb-0 py-3">{abbreviateNumber(reportData?.data?.repeat_sales_percentage?.data)?? 0}%</Card.Title>
                  )}
                </Card.Body>
                <Card.Footer className={`pb-3 border-0`}>
                  <div className='d-flex justify-content-between fw-bold'> 
                    {`Total sales ${abbreviateNumber(reportData?.data?.total_sales?.data, preferences) ?? 0}`}
                  </div>
                  <div className='d-flex justify-content-between fw-bold'> 
                    {`Repeat sales ${abbreviateNumber(reportData?.data?.repeat_sales?.data, preferences) ?? 0}`}
                  </div>
                </Card.Footer>
              </Card>
            </div>
            <div className="col-4">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Repeat Order Conversions
                </Card.Header>
                <Card.Body>
                  {reportData.isLoading ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) : (
                    <Card.Title as="h2" className="mb-0 py-3">{abbreviateNumber(reportData?.data?.repeat_order_percentage?.data)?? 0}%</Card.Title>
                  )}
                </Card.Body>
                <Card.Footer className={`pb-3 border-0`}>
                  <div className='d-flex justify-content-between fw-bold'> 
                    {`Total orders: `} {abbreviateNumber(reportData?.data?.total_orders?.data)?? 0}
                  </div>
                  <div className='d-flex justify-content-between fw-bold'> 
                    {`Repeat orders`} {abbreviateNumber(reportData?.data?.repeat_orders?.data)?? 0}
                  </div>
                </Card.Footer>
              </Card>
            </div>
            
            <div className="col-4">
              <Card>
                <Card.Header className='border-0 bg-white site-heading'>
                  Average Order Value
                </Card.Header>
                <Card.Body>
                  {reportData.isLoading ? (
                    <div className="mb-0 pb-3">
                      <Skeleton height={50} width={100}/>
                    </div>
                  ) : (
                    <Card.Title as="h4" className="mb-0 py-3">{`${abbreviateNumber(reportData?.data?.total_average_order_value?.data, preferences) ?? 0}`}</Card.Title>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row mb-4">
            {!_.isEmpty(reportData?.data?.recurring_customers?.data) ? (
              <div className="col">
                <RecurringCustomersbyOrderCount
                  data={
                    reportData?.data?.recurring_customers
                      ? reportData?.data?.recurring_customers?.data
                      : null
                  }
                  withCurrency={preferences}
                  title="recurring customers by order count"
                  setState={setReportData}
                  fetchFilteredData={fetchFilteredData}
                  filter_key="recurring_customer_orders"
                  isLoading={reportData.isLoading}
                />
              </div>
            ) : (
              <>
                <div className="col">
                  <NoDataTable 
                    title="recurring customers by order count" 
                    columnNames={['Customer First Name', 'Customer Last Name', 'Customer Email', 'No. of Orders']} 
                  />
                </div>
              </>
            )}
          </div>
          <div className="row mb-4">
              {!_.isEmpty(reportData?.data?.recurring_order_frequency?.data?.data) ? (
                <div className="col">
                  <RecurringCustomersOrderFrequency
                    data={
                      reportData?.data?.recurring_order_frequency
                        ? reportData?.data?.recurring_order_frequency?.data
                        : null
                    }
                    withCurrency={preferences}
                    title="Frequency of Orders placed by repeating customers"
                    setState={setReportData}
                    fetchFilteredData={fetchFilteredData}
                    filter_key="recurring_order_frequency"
                    isLoading={reportData.isLoading}
                  />
                </div>
              ) : (
                <>
                  <div className="col">
                    <NoDataTable
                      title="frequency of orders placed by repeating customers" 
                      columnNames={['Customer First Name', 'Customer Last Name', 'Customer Email', 'Frequency of Orders']} 
                    />
                  </div>
                </>
              )}
          </div>
          <div className="row mb-4">
              {!_.isEmpty(reportData?.data?.recurring_customers_by_shipping_postcode?.data?.data) ? (
                <div className="col">
                  <RecurringCustomersOrderFrequencyByShippingPostcode
                    data={
                      reportData?.data?.recurring_customers_by_shipping_postcode
                        ? reportData?.data?.recurring_customers_by_shipping_postcode?.data
                        : null
                    }
                    withCurrency={preferences}
                    title="Recurring orders by  shipping postcode"
                    setState={setReportData}
                    fetchFilteredData={fetchFilteredData}
                    filter_key="recurring_order_frequency"
                    isLoading={reportData.isLoading}
                  />
                </div>
              ) : (
                <>
                  <div className="col">
                    <NoDataTable
                      title="Recurring orders by postcode" 
                      columnNames={['No of Customers', 'Total no of orders',"Shipping Postcode"]} 
                    />
                  </div>
                </>
              )}
          </div>
        </>
      ) : (
        <div className="">
          <NoDataFound />
        </div>
      )}

      {!_.isEmpty(reportData?.data?.first_time_vs_returning_customers?.data) ? (
        <div className="col-lg-12 mb-4">
          <LineChartBlock
            data={
              reportData?.data?.first_time_vs_returning_customers?.data
                ? reportData?.data?.first_time_vs_returning_customers?.data
                : null
            }
            XAxisKey={"month"}
            YAxisKey={"order_sales"}
            isMultiLine={true}
            lines={[
              {
                name: 'first time',
                type:"monotone",
                dataKey:"firstTime"
              },
              {
                name: 'returing',
                type:"monotone",
                dataKey:"returning"
              },
            ]}
            title="First-time vs Returning Customer Sales by Month"
            settings={chartSettings}
            withCurrency={preferences}
            filter_key="first_time_vs_returning_customers"
            isLoading={reportData.isLoading}
          />
        </div>
        
      ) : (
        <div className="col mb-4">
          <CardComponent title="First-time vs Returning Customer Sales by Month">
            <NoDataFound />
          </CardComponent>
        </div>
      )}
      <div className="row mb-4">
        {!_.isEmpty(reportData?.data?.customers?.data?.data) ? (
          <div className="col">
            <Customers
              data={
                reportData?.data?.customers
                  ? reportData?.data?.customers?.data
                  : null
              }
              withCurrency={preferences}
              title="Customers"
              setState={setReportData}
              fetchFilteredData={fetchFilteredData}
              filter_key="customers"
              isLoading={reportData.isLoading}
            />
          </div>
        ) : (
          <>
            <div className="col">
              <NoDataTable
                title="customers" 
                columnNames={['Customer First Name', 'Customer Last Name', 'Customer Email', 'Frequency of Orders']} 
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
