import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import apiClient from "../header";


function get(signal) {
  const query = apiClient
    .get(`configs/get`, { params: { key: "mailer", }, signal, })
    .then((result) => result.data);
  return query;
}

function update(data) {
  return apiClient.post(`configs/mailer`, data).then((result) => result.data);
}

export function useMailQuery() {
  const queryClient = useQueryClient();
  function handleSuccess(data) {
    if (data.success) {
      queryClient.invalidateQueries(["mail-smtp"]);
    }
  }

  return useQuery(["mail-smtp", {}], ({ signal }) => get(signal), {
    retry: 0,
    staleTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess: handleSuccess,
  });
}

export function useMailUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["mail-smtp"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message,
        }));
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  const mutate = useMutation((updateMail) => update(updateMail), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      updateErrors: errors,
    },
  ];
}
