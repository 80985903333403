import Tooltip from "react-bootstrap/Tooltip";
import React, { useState, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { UilPen, UilTrashAlt } from "@iconscout/react-unicons";

import DataTable from "../../../components/common/DataTable";
import PageHeader from "../../../layouts/PageHeader";
import ModalComponent from "../../../components/common/ModalComponent";

import Form from "./Form";
import { useUnitDeleteQuery, useUnitListQuery, } from "../../../stores/setting/unit";


export default function Listing() {

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [inputs, setInputs] = useState({
    id: "",
    name: "",
    units: "",
  });
  const refName = useRef();

  const [query, { setPageNumber }] = useUnitListQuery();
  const deleteUnit = useUnitDeleteQuery();

  const add = () => {
    setOpen(true);
    setInputs({
      id: "",
      name: "",
      units: "",
    });
  };

  const edit = (id, name, units) => {
    setOpen(true);
    setInputs({
      id: id,
      name: name,
      units: units,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleDelete = (id) => {
    deleteUnit.mutate(id);
  };

  const columns = [
    {
      id: "unit_name",
      name: "Unit Name",
      selector: (row) => row.name,
    },
    {
      id: "unit",
      name: "Unit",
      selector: (row) => row.units,
    },
    {
      id: "action",
      name: "",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Edit manufacturer</Tooltip>}
          >
            <span
              className="me-3 cursor active-icon"
              onClick={() => {
                setIsEdit(true);
                edit(row.id, row.name, row.units);
              }}
              id="edit"
            >
              {<UilPen size="14" className="text-secondary" />}
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Delete units</Tooltip>}
          >
            <span className="cursor active-icon" id="delete">
              {
                <UilTrashAlt
                  size="14"
                  onClick={() => handleDelete(row.id)}
                  className="text-danger"
                />
              }
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];


  return (
    <React.Fragment>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Units" subtext="Manage units" />

          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()}>
              {" "}
              Add Units
            </button>
          </div>
        </div>

        <div className="border rounded-3 overflow-hidden">
          <DataTable
            data={query?.data?.data}
            columns={columns}
            isLoading={query.isLoading}
            pagination={true}
            paginationMeta={query?.data}
            onChangePage={(page) => setPageNumber(page)}
          />
        </div>
      </div>

      {open ? (
         <ModalComponent
          show={open}
          onHide={closeModal}
          onEntered={() => refName.current.focus()}
          title={inputs.id ? "Edit units" : "Add units"}
          body={
            <Form
              inputs={inputs}
              isEdit={isEdit}
              closeModal={closeModal}
              refName={refName}
            />
          }
        />
      ) : null} 
    </React.Fragment>
  );
}
