import React, { useState, useEffect, useRef } from "react";
import _ from 'lodash';

import api from "../../../api";

import Loader from "../global/Loader";
import SimpleReactValidator from 'simple-react-validator';
import { useValidator } from "../../utils/validator";

export default function CustomerDetailsForm(props) {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState({
    customer_first_name: "",
    customer_last_name: "",
    email: "",
    telephone: "",
    payment_firstname: "",
    payment_lastname: "",
    shipping_firstname: "",
    shipping_lastname: "",
    payment_address: "",
    shipping_address: "",
    payment_city: "",
    shipping_city: "",
    payment_country: "",
    shipping_country: "",
    payment_postcode: "",
    shipping_postcode: "",
    payment_territory: "",
    shipping_territory: "",
    payment_company: "",
    shipping_company: "",
  });
  
  const [validationSchema, setValidationSchema] = useState({
    customer_first_name: "required|max:50",
    customer_last_name: "required|max:50",
    email: "required|email",
    telephone: "required|telephone",
    payment_firstname: "required|max:50",
    payment_lastname: "required|max:50",
    shipping_firstname: "required|max:50",
    shipping_lastname: "required|max:50",
    payment_address: "required|max:250",
    shipping_address: "required|max:250",
    payment_city: "required|max:50",
    shipping_city: "required|max:50",
    payment_country: "required|max:50",
    shipping_country: "required|max:50",
    payment_postcode: "required|max:15",
    shipping_postcode: "required|max:15",
    payment_territory: "max:50",
    shipping_territory: "max:50",
    payment_company: "max:100",
    shipping_company: "max:100",
  });

  const [errors, setErrors] = useState([]);
  const [errorsPayment, setPaymentErrors] = useState([]);
  const [check, setCheck] = useState(true);
  const customerFormValidator = useRef(new SimpleReactValidator());
  const customerFormPaymentValidator = useRef(new SimpleReactValidator());

  const [validator, { validate }] = useValidator(errors);
  const handleInputChange = (event) => {
    event.persist();
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    })); 
  };

  const fetchCountry = async () => {
    api.business.get().then(response => {
      let buisnessData = response.data.business_data;
      setInputs(prevProps => ({
        ...prevProps,
        payment_country: !_.isNull(buisnessData.country) ? (buisnessData.country.name) : (''),
        shipping_country: !_.isNull(buisnessData.country) ? (buisnessData.country.name) : (''),
      })
      )
    });
  }

  useEffect(() => {
    if (_.size(props.customer)){
      setInputs(props.customer)
    }
   
    if(_.has(props.tempData,'customer_first_name') || _.has(props.tempData,'customer_last_name') ){
      if(props.tempData.customer_first_name !="" || props.tempData.customer_last_name !="")
      setInputs(prevProps => ({
        ...prevProps,
        ['customer_first_name']: props.tempData.customer_first_name,
        ['customer_last_name']: props.tempData.customer_last_name,
      }))
    }
  }, []);

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    if(validated){
      props.setCustomer(_.omitBy(inputs, validated));
      props.onHide('customer')
    }
  }, [validated])

  useEffect(() => {
    if (props.validationSchema) {
      setValidationSchema((prevSchema) => ({
        ...prevSchema,
        ...props.validationSchema,
      }));
    }
  }, [props.validationSchema]);

  function handleCustomerFormSubmit(event) {
    event.preventDefault();
    if ((check || customerFormPaymentValidator.current.allValid()) && validator.current.allValid()) {  
      if (check) {
        setInputs(prevProps => ({
          ...prevProps,
          payment_firstname: inputs.shipping_firstname,
          payment_lastname: inputs.shipping_lastname,
          payment_address: inputs.shipping_address,
          payment_city: inputs.shipping_city,
          payment_country:  inputs.shipping_country,
          payment_postcode: inputs.shipping_postcode,
          payment_territory: inputs.shipping_territory,
          payment_company: inputs.shipping_company
        }));
      }
      setValidated(true);
    } else {
      validator.current.showMessages();
      customerFormPaymentValidator.current.showMessages();
      setPaymentErrors(customerFormValidator.current.errorMessages); 
    }
    return false;
  }
 
  return (
    <React.Fragment>
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <form id="customer-details-form" onSubmit={(e) => handleCustomerFormSubmit(e)}>
          <div>
            <div className="row p-2">
              <span className="text-uppercase text-gray-600 small mb-3">Customer details</span>
              <div className="col-md-6 mb-4">
                <label className="fw-bold">First name</label>
                <input
                  placeholder="First Name"
                  type="text"
                  name="customer_first_name"
                  className="form-control"
                  value={inputs.customer_first_name}
                  onChange={handleInputChange}
                />
                {validate(inputs.customer_first_name, validationSchema.customer_first_name, {title: 'customer_first_name'})}
              </div>
              <div className="col-md-6 mb-4">
                <label className="fw-bold">Last name</label>
                <input
                  placeholder="Last Name"
                  type="text"
                  name="customer_last_name"
                  className="form-control"
                  value={inputs.customer_last_name}
                  onChange={handleInputChange}
                />
                {validate(inputs.customer_last_name, validationSchema.customer_last_name, {title: 'customer_last_name'})}
              </div>
              <div className="col-md-6">
                  <label className="fw-bold me-3">Email id</label>
                  <input
                    placeholder="Email Id"
                    type="email"
                    name="email"
                    className="form-control"
                    value={inputs.email}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.email, validationSchema.email, {title: 'email'})}
              </div>
              <div className="col-md-6">
                <label className="fw-bold">Telephone number</label>
                <input
                  placeholder="Telephone Number"
                  type="text"
                  name="telephone"
                  className="form-control"
                  value={inputs.telephone}
                  onChange={handleInputChange}
                />
                {validate(inputs.telephone, validationSchema.telephone, {title: 'telephone'})}
              </div>
            </div>
            <hr />
            <div className="row p-2">
              <span className="text-uppercase text-gray-600 small mb-3">Shipping details</span>
              <div className="col-md-6 mb-4">
                  <label className="fw-bold">First name</label>
                  <input
                    placeholder="First Name"
                    type="text"
                    name="shipping_firstname"
                    className="form-control"
                    value={inputs.shipping_firstname}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.shipping_firstname, validationSchema.shipping_firstname, {title: 'shipping_firstname'})}
              </div>
              <div className="col-md-6 mb-4">
                  <label className="fw-bold">Last name</label>
                  <input
                    placeholder="Last Name"
                    type="text"
                    name="shipping_lastname"
                    className="form-control"
                    value={inputs.shipping_lastname}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.shipping_lastname, validationSchema.shipping_lastname, {title: 'last_name'})}
              </div>
              <div className="col-md-12 mb-4">
                <label className="fw-bold me-3">Company</label>
                <input
                  placeholder="Company Name"
                  type="text"
                  name="shipping_company"
                  className="form-control"
                  value={inputs.shipping_company}
                  onChange={handleInputChange}
                />
                {validate(inputs.shipping_company, validationSchema.shipping_company, {title: 'shipping_company'})}
              </div>
              <div className="col-md-12 mb-4">
                <label className="fw-bold me-3">Address</label>
                <textarea
                  rows="4"
                  cols="50"
                  placeholder="Address"
                  name="shipping_address"
                  className="form-control"
                  value={inputs.shipping_address}
                  onChange={handleInputChange}
                />
                {validate(inputs.shipping_address, validationSchema.shipping_address, {title: 'shipping_address'})}
              </div>
              <div className="col-md-6 mb-4">
                <label className="fw-bold">City</label>
                <input
                  placeholder="City"
                  type="text"
                  name="shipping_city"
                  className="form-control"
                  value={inputs.shipping_city}
                  onChange={handleInputChange}
                  />
                {validate(inputs.shipping_city, validationSchema.shipping_city, {title: 'shipping_city'})}
              </div>
              <div className="col-md-6 mb-4">
                <label className="fw-bold">Territory</label>
                <input
                  type="text"
                  name="shipping_territory"
                  className="form-control"
                  placeholder="Territory"
                  value={inputs.shipping_territory}
                  onChange={handleInputChange}
                />
                {validate(inputs.shipping_territory, validationSchema.shipping_territory, {title: 'shipping_territory'})}
              </div>
              <div className="col-md-6">
                <label className="fw-bold">Country</label>
                <input
                  type="text"
                  name="shipping_country"
                  className="form-control"
                  placeholder="Country"
                  value={inputs.shipping_country}
                  onChange={handleInputChange}
                  disabled
                />
                {validate(inputs.shipping_country, validationSchema.shipping_country, {title: 'shipping_country'})}
              </div>
              <div className="col-md-6">
                <label className="fw-bold">Postcode</label>
                <input
                  type="text"
                  name="shipping_postcode"
                  className="form-control"
                  placeholder="Postcode"
                  value={inputs.shipping_postcode}
                  onChange={handleInputChange}
                />
                {validate(inputs.shipping_postcode, validationSchema.shipping_postcode, {title: 'shipping_postcode'})}
              </div>
            </div>
            <hr />
            <div className="row p-2">
              <div>

              </div>
              <span className="text-uppercase text-gray-600 small mb-3">Billing\Payment Details</span>
              <div className="mb-2">
                <label className="fw-bold">
                  <input
                    type="checkbox"
                    name="check"
                    className="form-check-input me-2"
                    value={check}
                    checked={check}
                    onChange={e => { setCheck(e.target.checked); }}
                  />
                  Same as Shipping Details
                </label>
              </div>
            </div>
            {!check ? (
              <div className="row p-2">
                <div className="col-md-6 mb-4">
                  <label className="fw-bold">First name</label>
                  <input
                    placeholder="First Name"
                    type="text"
                    name="payment_firstname"
                    className="form-control"
                    value={inputs.payment_firstname}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.payment_firstname, validationSchema.payment_firstname, {title: 'payment_firstname'})}
                </div>
                <div className="col-md-6 mb-4">
                  <label className="fw-bold">Last name</label>
                  <input
                    placeholder="Last Name"
                    type="text"
                    name="payment_lastname"
                    className="form-control"
                    value={inputs.payment_lastname}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.payment_lastname, validationSchema.payment_lastname, {title: 'payment_lastname'})}
                </div>
                <div className="col-md-12 mb-4">
                  <label className="fw-bold me-3">Company</label>
                  <input
                    placeholder="Company Name"
                    type="text"
                    name="payment_company"
                    className="form-control"
                    value={inputs.payment_company}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.payment_company, validationSchema.payment_company, {title: 'payment_company'})}
                </div>
                <div className="col-md-12 mb-4">
                  <label className="fw-bold me-3">Address</label>
                  <textarea
                    rows="4"
                    cols="50"
                    placeholder="Address"
                    name="payment_address"
                    className="form-control"
                    value={inputs.payment_address}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.payment_address, validationSchema.payment_address, {title: 'payment_address'})}
                </div>
                <div className="col-lg-6 mb-4">
                  <label className="fw-bold">City</label>
                  <input
                    placeholder="City"
                    type="text"
                    name="payment_city"
                    className="form-control"
                    value={inputs.payment_city}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.payment_city, validationSchema.payment_city, {title: 'payment_city'})}
                </div>
                <div className="col-md-6 ">
                  <label className="fw-bold">Territory</label>
                  <input
                    type="text"
                    name="payment_territory"
                    className="form-control"
                    placeholder="Territory"
                    value={inputs.payment_territory}
                    onChange={handleInputChange}
                  />
                  {validate(inputs.payment_territory, validationSchema.payment_territory, {title: 'payment_territory'})}
                </div>
                <div className="col-md-6 ">
                    <label className="fw-bold">Country</label>
                    <input
                      type="text"
                      name="payment_country"
                      className="form-control"
                      placeholder="Country"
                      value={inputs.payment_country}
                      onChange={handleInputChange}
                      disabled
                    />
                    {validate(inputs.payment_country, validationSchema.payment_country, {title: 'payment_country'})}
                </div>
                <div className="col-md-6 ">
                    <label className="fw-bold">Postcode</label>
                    <input
                      type="text"
                      name="payment_postcode"
                      className="form-control"
                      placeholder="Postcode"
                      value={inputs.payment_postcode}
                      onChange={handleInputChange}
                    />
                    {validate(inputs.payment_postcode, validationSchema.payment_postcode, {title: 'payment_postcode'})}
                  </div>
              </div>
              ) : (
                null
            )}
          </div>
        </form>
      )}
    </React.Fragment>
  );
}