import _ from "lodash";
import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Paginator from "../global/Paginator";
import { UilAngleDown, UilAngleUp } from "@iconscout/react-unicons";

export default function DataTable(props) {
  const {
    data = {}, 
    columns, 
    isLoading,
    pagination,
    paginationMeta = {}, 
    onChangePage,
    selectable = false,
    expandable = false,
    onSelectRow = () => {},
    expandableRowsComponent = () => {},
    noDataComponents =  (<> No Data Found </>)
  } = props;

  const emptyData = new Array(5).fill({});
  const [open, setOpen] = useState(false);
  const columnsLength = columns.length + (selectable ? 1 : 0) + (expandable ? 1 : 0);
  const [selectedData, setSelectedData] = useState([]);

  function handleSelectAll(checked, data) {
    if (checked) {
      if (typeof selectable == 'boolean') {
        setSelectedData(data)
      } 
      if (typeof selectable == 'string') {
        setSelectedData(_.map(data, selectable))
      }
    } else {
      setSelectedData([])
    }
  }

  function handleSelect(checked, item) {
    var data = selectedData;
  
    if (checked) {
      if (typeof selectable == 'boolean') {
        data = [...data, item];
      } 
      if (typeof selectable == 'string') {
        data = [...data, item[selectable]];
      }
    } else {
      if (typeof selectable == 'boolean') {
        data = _.filter(data, function(value) {  
          if (JSON.stringify(value) !== JSON.stringify(item)) {
            return value;
          }
        })
      } 
      if (typeof selectable == 'string') {
        data = _.filter(data, function(value) {  
          if (value !== item[selectable]) {
            return value;
          }
        })
      }
    }

    setSelectedData(data)
  }

  function validateSelectedData(validateValue) {
    if (typeof selectable == 'boolean') {
      return selectedData.some(selected => JSON.stringify(selected) == JSON.stringify(validateValue))
    } 
    if (typeof selectable == 'string') {
      return selectedData.includes(validateValue[selectable])
    }
  }

  useEffect(() => {
    if (selectable) {
      onSelectRow(_.sortBy(selectedData))
    }
  }, [selectedData])

  useEffect(() => {
    if (selectable) {
      setSelectedData([])
    }
  }, [data])
  
  function Header(){
    return (
      <thead>
        <tr>
          {selectable && <th><input type="checkbox" className="form-check-input" checked={data.length !=0 && data.length == selectedData.length} onChange={({target}) => handleSelectAll(target.checked, data)} disabled={isLoading}/></th>}
          {columns.map((column, key) => (
            <th scope="col" key={column.id ?? key} {...column.head}>{column.name}</th>
          ))}
          {expandable && <td></td>}
        </tr>
    </thead>
  )
  }

  function ColumnMap({ item }){
    return (
      <>
        {selectable && (
          <td>
            <input
              type="checkbox"
              className="form-check-input"
              checked={validateSelectedData(item)}
              onChange={({ target }) => handleSelect(target.checked, item)}
              disabled={isLoading}
            />
          </td>
        )}
        {columns.map((column, key) => (
          <td scope="row" key={column.id ?? key} {...column.body}>
            {isLoading ? (
              <Skeleton />
            ) : column.cell ? (
              column.cell(item)
            ) : column.selector ? (
              column.selector(item)
            ) : (
              "-"
            )}
          </td>
        ))}
        {expandable && (
          <td className="cursor text-end" onClick={() => setOpen(open != item.id ? item.id : false)} >
            {open != item.id ? (
              <UilAngleDown size="15" className="text-secondary" />
            ) : (
              <UilAngleUp size="15" className="text-secondary" />
            )}
          </td>
        )}
      </>
    );
  }

  function Footer() {
    return (
      <tfoot>
        <tr className="border-top">{pagination ? <Pagination /> : null}</tr>
      </tfoot>
    );
  }

  function Pagination() {
    return (
      <>
        <td colSpan={Math.floor(columnsLength / 2)}>
          <span>
            {isLoading ? (
              <Skeleton width={200} height={20} inline={true} />
            ) : (
              `Showing ${paginationMeta.to ?? 0} out of ${ paginationMeta.total ?? 0 } entries`
            )}
          </span>
        </td>
        <td colSpan={Math.ceil(columnsLength / 2) + (selectable && 1)} align="right" >
          {isLoading ? (
            <Skeleton width={200} height={30} inline={true} className={"me-1"} />
          ) : (
            <div>
              <Paginator
                onChangePage={onChangePage}
                currentPage={paginationMeta.current_page}
                totalPages={paginationMeta.last_page}
              />
            </div>
          )}
        </td>
      </>
    );
  }

  return (
    <>
      <table className="table">
        <Header />
        {!data.length && !isLoading ? (
          <tbody>
            <tr className="border-top">
              <td colSpan={columns.length} className="text-center p-5">
                {noDataComponents}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {(isLoading ? emptyData : data).map((item, key) =>
              expandable ? (
                <>
                  <tr className="border-top" key={"col" + item.id ?? key}>
                    <ColumnMap item={item} />
                  </tr>
                  <tr key={"expand-col" + item.id ?? key}>
                    <td className="pb-0 pt-0" colSpan={"100%"}>
                      <Collapse in={item.id == open}>
                        <div id="expandable">{expandableRowsComponent(item)}</div>
                      </Collapse>
                    </td>
                  </tr>
                </>
              ) : (
                <tr className="border-top" key={item.id ?? key}>
                  <ColumnMap item={item} />
                </tr>
              )
            )}
          </tbody>
        )}
        <Footer />
      </table>
    </>
  );
}
