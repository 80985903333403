import React from "react";
import { useLocation } from "react-router";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { Paths } from "../../../routes";

import PageHeader from "../../layouts/PageHeader";
import TabsComponent from "../../layouts/TabsLayout";

export default function Index() {
  const { pathname } = useLocation();

  return (
    <>
      <PageHeader title="Settings" subtitle="All Settings" />
      <section>
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-2">
              <div
                className="nav flex-column position-sticky"
                style={{ top: "20px" }}
                id="settings"
              >
                <TabsComponent
                  tabs={[
                    Paths.Setting.pages.AccountSettings,
                    Paths.Setting.pages.BusinessInformation,
                    Paths.Setting.pages.Customization,
                    Paths.Setting.pages.Preferences,
                    Paths.Setting.pages.User,
                    Paths.Setting.pages.Group,
                    Paths.Setting.pages.Warehouse,
                    Paths.Setting.pages.Manufacturer,
                    Paths.Setting.pages.Tax,
                    Paths.Setting.pages.Unit,
                    Paths.Setting.pages.Shipping,
                    Paths.Setting.pages.Payment,
                    Paths.Setting.pages.Coupon,
                    Paths.Setting.pages.Mail,
                  ]}
                  activeRoute={pathname}
                />
              </div>
            </div>
            <div className="col-10">
              <div>
                <Outlet />
                {/* <Navigate from={Paths.Settings.path} to={Paths.Settings.pages.AccountSettings.path} /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
