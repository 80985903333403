import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import _ from "lodash";


export function useChannelUpdateQuery() {

  const queryClient = useQueryClient(); 
  const [errors, setErrors] = useState({});
  
  function updateChannel(data) {
    return apiClient
      .post(`channels/update`, { inputs: _.pickBy(data, function(value) { return value }) })
      .then((result) => result.data);
  }

  function handleSuccess(data) {
    setErrors({})
    notify(data.type, data.message);
    if (data.status) {
      queryClient.invalidateQueries(["channel-list"]);
      queryClient.invalidateQueries(["channel-view"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((editChannel) => updateChannel(editChannel), {
    retry: false,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      updateErrors: errors,
    },
  ];
}


export function useSyncStatusQuery() {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  function syncTrackpodStatus(inputs) {
    return apiClient
      .post(`channels/edit/status`, inputs)
      .then((result) => result.data);
  }

  function handleSuccess(data) {
    if (data.status) {
      notify(data.type, data.message);
      queryClient.invalidateQueries(["integration-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((inputs) => syncTrackpodStatus(inputs), {
    retry: false,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      updateStatus: errors,
    },
  ];
}