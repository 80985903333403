import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import ErrorPage from "../../components/errors/ErrorPage";
import apiClient from "../header";

export function useUserViewQuery() {
  const [viewError, setViewError] = useState();

  function getView(signal) {
    const query = apiClient
      .get(`auth/profile`, { signal })
      .catch((error) => {
        setViewError(<ErrorPage error={error.response.status} />);
      })
      .then((result) => {
        if (result.data.status === 0) {
          setViewError(
            <>
              <ErrorPage />
            </>
          );
        }
        return result.data;
      });
    return query;
  }

  var query = useQuery(["user-view", {}], ({ signal }) => getView(signal), {
    retry: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return [
    query,
    {
      viewError: viewError,
    },
  ];
}

function update(data) {
  return apiClient.post(`auth/edit`, data).then((result) => result.data);
}

export function useUserUpdateQuery() {
  const queryClient = useQueryClient();
  var toastify;
  const [errors, setErrors] = useState({});

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["user-data"]);
      queryClient.invalidateQueries(["auth"]);
      queryClient.invalidateQueries(["preferences"]);
      queryClient.invalidateQueries(["user-list"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  const mutate = useMutation((data) => update(data), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}

function resetPassword(data) {
  return apiClient
    .post(`auth/password/change`, data)
    .then((result) => result.data);
}

export function useResetPasswordQuery() {
  const queryClient = useQueryClient();
  var toastify;
  const [errors, setErrors] = useState({});

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["user-data"]);
    } else {
      if (typeof data.field_error != "undefined") {
        setErrors(data.field_error);
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  const mutate = useMutation((data) => resetPassword(data), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}
