import React from 'react';
import { Link } from 'react-router-dom';

import { useTitle } from '../../../scripts/htmlOps';

import { Paths } from '../../../routes';


export default function ErrorPage({innerPage, error='404'}) {
  useTitle('The page you requested cannot be found!');

	var title, subTitle = ''
	switch (error) {
		case '404':
			title = 'Page does not exist';
			subTitle = 'Maybe you got a broken link, or you made a misprint in the address bar';
			
			break;
		case '403':
			title = 'Forbidden';
			subTitle = 'You don\'t have permission to access to page';

			break;
		case '500':
			title = 'Server Error';
			subTitle = 'Something went wrong';
	
			break;

		default:
			break;
	}
  return (
    <>
      
      <section className={`${innerPage ? 'bg-pattern-white m-n4' : 'bg-pattern-light'}`} >
        <div className="container text-center screen" style={{ height: '100vh' }}>
          <div className={`row justify-content-center align-items-center ${!innerPage && 'text-white'} h-100`} >
            <div className="col-md-6">
              <img src={require("../../../assets/img/branding/error/" + error + ".svg")} alt="" />
              <h2 className="pb-2 pt-4 mb-0">{title}</h2>
              <p className='pb-3'>{subTitle}</p>
              <div className="">
                <Link to={Paths.Root.path} className="btn btn-secondary p-2 px-4 text-white">
                  Take me back to the dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}
