import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import apiClient from "../header";
import { Paths } from "../../../routes";

function createOrder(id) {
  return apiClient
    .post(`invoices/create/order`, { id: id })
    .then((result) => result.data);
}

export function useCreateOrderQuery() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  var toastify;

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true });
    if (data.status) {
      queryClient.invalidateQueries(["invoice-list"]);
      queryClient.invalidateQueries(['order-list']);
      setTimeout(() => {
        navigate(Paths.Invoice.path);
      }, 2000);
    }
  }

  function handleError(data) {
    toast.update(toastify, { render: "Oops something went wrong!", type: "error", isLoading: false, autoClose: true, closeButton: true });
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  const mutate = useMutation((id) => createOrder(id), {
    retry: false,
    onError: handleError,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [mutate];
}
