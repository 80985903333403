import React, { useEffect, useState } from "react";

import { useValidator } from "../../../utils/validator";
import { usePaymentMethodCreateQuery, usePaymentMethodUpdateQuery } from "../../../stores/setting/payment";
import _ from "lodash";

export default function Form(props) {

  const [inputs, setInputs] = useState({
    id: props?.inputs?.id || null,
    name: props?.inputs?.name || "",
  });
  
  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);

  const [paymentMethodCreateQuery, {createErrors}] = usePaymentMethodCreateQuery();
  const [paymentMethodUpdateQuery, {updateErrors}] = usePaymentMethodUpdateQuery();

  const [validator, { validate }] = useValidator(inputs, errors);

  const handleInputChange = (event) => {
    event.preventDefault();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if (_.isNull(inputs.id)) {
        props.setInputs((prevProps) => ({
          ...prevProps,
          payment_method: inputs.name,
        }));
        paymentMethodCreateQuery.mutate({ ...inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                props.closeModal();
                if (props.callback) props.callback();
              }
            },
          }
        );
      } else {
        paymentMethodUpdateQuery.mutate({ ...inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                props.closeModal();
              }
            },
          }
        );
      }

      validator.current.showMessages();
      forceUpdate(true);
    }
  };


  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);

  return (
    <div>
      <section className="">
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-lg-12">
              <label className="fw-bold me-3 ">Payment Method</label>
              <div className="">
                <input
                  type="text"
                  name="name"
                  placeholder="Payment Method"
                  onChange={(event) => handleInputChange(event)}
                  className="form-control "
                  value={inputs.name}
                  ref={props.refName}
                />
                {validate(inputs.name, "required|min:3|max:100", { title: "name", onServer: errors['name'] })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <button type="submit" className="btn btn-secondary w-100">
                {props.isEdit ? "Save Changes" : "Add Payment Method"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
