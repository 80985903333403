import React  from "react";

import Password from "./Password";
import Form from "./Form";


export default function AccountSettings() {

  return (
    <>
      <Form />
      <Password />
      <section className="bg-white p-5 rounded mb-4 border">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <h6>Delete your account</h6>
            <p className="small text-gray-500 m-0">
              Please contact the admin to delete your account from this
              business.
            </p>
          </div>
          <div className="col-md-auto not-allowed">
            <button className="btn btn-outline-danger disabled">
              Delete account
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

