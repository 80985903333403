import _ from "lodash";
import React, { useState, useEffect } from "react";

import configurations from "../../../../configurations";
import { useValidator } from "../../../utils/validator";
import { useAmazonCreateQuery } from "../../../stores/channel/amazon/create";


export default function Add({ step, setStep, setId }) {

  let previousUrl = null;
  let windowObjectReference = null;

  const initial = {
    channel_name: "",
    regional_url: "",
    marketplace_id: "",
  };

  const [inputs, setInputs] = useState(initial);
  const [errors, setErrors] = useState({});
  const [validator, { validate }] = useValidator(errors);
  const [, forceUpdate] = useState();

  const [amazonCreateQuery, { createErrors, id }] = useAmazonCreateQuery();

  const getQueryParams = (url) => {
    let queryParams = {};
    //create an anchor tag to use the property called search
    let anchor = document.createElement("a");
    //assigning url to href of anchor tag
    anchor.href = url;
    //search property returns the query string of url
    let queryStrings = anchor.search.substring(1);
    let params = queryStrings.split("&");
    for (var i = 0; i < params.length; i++) {
      var pair = params[i].split("=");
      queryParams[pair[0]] = decodeURIComponent(pair[1]);
    }

    return queryParams;
  };

  function openSignInWindow(url, name) {
    // remove any existing event listeners
    window.removeEventListener("message", getOauthCode);
    // window features
    const strWindowFeatures =
      "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      windowObjectReference.focus();
    }

    window.addEventListener("message", (event) => getOauthCode(event), true);
    previousUrl = url;
  }

  const getOauthCode = (event) => {
    const { data } = event;
    const queryString = getQueryParams(data);
    if (_.has(queryString, "spapi_oauth_code")) {
      amazonCreateQuery.mutate({ inputs: inputs, query_params: queryString });
    }
  };

  const handleInputChange = (event) => {
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
    if (errors[event.target.name]) {
      setErrors((prevProps) => ({
        ...prevProps,
        [event.target.name]: "",
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      openSignInWindow(
        inputs.regional_url +
          "/apps/authorize/consent?application_id=amzn1.sp.solution.1892880e-cf90-4892-ba8a-aaa454d38949&state=9f93038cbc8deae9f695adfc52b8afaf9187759a0264bf12b11f0e7cf505f14e8dd450ddec83b2abbaf3aa128f7e821253273cf3e57f15423e3db0a6c023494790b3e32c075fe20a4454173a2eda5dba5eecfe0837a486961d3635b0fd6d029eb377c3465817307ef749ed75f750f355eadbb4023ca9e656aa718f86c5ceea4074315fdc09d0c93a9b10f36d0bf820c47563936a2a99e0e4cf8dbc8adde25a6a4648a75e4119a4e95751b823cba342c811194529c3e2de3d547513aa6e16979ce371a7ab962ea27380b4ebf669683a9da52ccdd88e50a44495ff1806f6a90947498f7319c301f940cb6091fbcbede6e2e7b50669c8163593a97a388a7897fb8c&version=beta",
        "Amazon Access"
      );
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (!_.isNull(id)) {
      setId(id);
      setStep(step + 1);
    }
  }, [id]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-6 amazon-channel-integration">
          <section className="bg-white p-5">
            <h4 className="mb-4">Channel Details</h4>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="" htmlFor="channel_name">
                  Channel Name
                </label>
                <input
                  placeholder="Enter Channel Name"
                  type="text"
                  name="channel_name"
                  className="form-control"
                  value={inputs.channel_name}
                  onChange={handleInputChange}
                />
                {validate(inputs.channel_name, "required", { title: "channel_name", onServer: errors["channel_name"] })}
              </div>

              <div className="mb-3">
                <label className="" htmlFor="channel_region">
                  Channel Region
                </label>
                <div className="dropdown">
                  <select
                    value={inputs.regional_url}
                    name="regional_url"
                    onChange={handleInputChange}
                    className="form-control"
                  >
                    <option>Select Region</option>
                    <option value="https://sellercentral.amazon.com/">
                      us-east-1
                    </option>
                    <option value="https://sellercentral.amazon.co.jp/">
                      us-west-2
                    </option>
                    <option value="https://sellercentral-europe.amazon.com">
                      eu-west-1
                    </option>
                  </select>
                </div>
                {validate(inputs.regional_url, "required", { title: "regional_url", })}
                </div>

              <div className="mb-3">
                <label htmlFor="channel_country" className=" me-4">
                  Channel Country
                </label>
                <div className="dropdown">
                  <select
                    value={inputs.marketplace_id}
                    name="marketplace_id"
                    onChange={handleInputChange}
                    className="form-control"
                  >
                    <option>Select Country</option>
                    {configurations.MarketplaceId.map((configs, index) => (
                      <option key={index} value={configs.key}>
                        {configs.name}
                      </option>
                    ))}
                  </select>
                </div>
                {validate(inputs.marketplace_id, "required", { title: "marketplace_id", })}
              </div>

              <div className="d-grid mb-3">
                <button className="btn btn-secondary">Authorize Channel</button>
              </div>

              <div>
                <p className="small text-light">
                  Once you click the authorize button, you will be redirected to
                  a new window to login to your Amazon sellers account. Once you
                  complete the authorization you will be redirected here.
                </p>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
}
