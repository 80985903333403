import React from "react";

import { Paths } from "../../../routes";
import List from "../../components/common/List";
import PageHeader from "../../layouts/PageHeader";
import Ebay from "../../../assets/img/pages/channel/ebay.svg";
import Amazon from "../../../assets/img/pages/channel/amazon.svg";
import Opencart from "../../../assets/img/pages/channel/opencart.svg";

export default function Add() {
  return (
    <div>
      <PageHeader title={"channels"} subtitle={"Add a Channel"} subtext={ "You can now easily intergrate multiple channels to our centralised inventory management system." }
      />

      <div className="row">
        <List
          link={Paths.ChannelsRefactor.pages.addOpencart.path}
          icon={Opencart}
          name="Opencart"
          option="integration"
        />

        <List
          link={Paths.ChannelsRefactor.pages.addAmazon.path}
          icon={Amazon}
          name="Amazon"
          option="integration"
        />

        <List
          link={Paths.ChannelsRefactor.pages.addEbay.path}
          icon={Ebay}
          name="Ebay"
          option="integration"
        />
        
      </div>
    </div>
  );
}
