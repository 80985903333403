import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";

import { useValidator } from "../../../utils/validator";
import { checkFloat } from "../../../../scripts/utils";
import { useTaxCreateQuery, useTaxUpdateQuery, } from "../../../stores/setting/tax";
import CustomSelect from "../../../components/global/CustomSelect";

export default function Form(props) {

  const [inputs, setInputs] = useState({
    tax_id: (props.inputs && props.inputs.tax_id) || null,
    tax_name: (props.inputs && props.inputs.tax_name) || "",
    tax_amount: (props.inputs && props.inputs.tax_amount) || "",
    tax_type: (props.inputs && props.inputs.tax_type.value) || "",
    tax_application: (props.inputs && props.inputs.tax_application.value) || "",
  });
  const [selectedData, setSelectedData] = useState({
    tax_type: (props.inputs && props.inputs.tax_type) || null,
    tax_application: (props.inputs && props.inputs.tax_application) || null,
  });


  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);

  const [validator, { validate }] = useValidator(errors);

  const tax_type = [
    { label: "VAT", value: "vat", name: "tax_type" },
    { label: "GST", value: "gst", name: "tax_type" },
  ];

  const tax_application = [
    { label: "Inclusive", value: "inclusive", name: "tax_application" },
    { label: "Exclusive", value: "exclusive", name: "tax_application" },
  ];

  const [taxCreateQuery, { createErrors }] = useTaxCreateQuery();
  const [taxUpdateQuery, { updateErrors }] = useTaxUpdateQuery();

  const handleInputChange = (event) => {
    event.persist();
    setErrors({});
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChange = (event) => {
    setSelectedData((prevProps) => ({
      ...prevProps,
      [event.name]: event,
    }));
    setInputs((prevProps) => ({
      ...prevProps,
      [event.name]: event.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if (_.isNull(inputs.tax_id)) {
        taxCreateQuery.mutate({ inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                setSelectedData({});
                props.closeModal();
              }
            },
          }
        );
      } else {
        taxUpdateQuery.mutate({ inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                setSelectedData({});
                props.closeModal();
              }
            },
          }
        );
      }
    }
    validator.current.showMessages();
    forceUpdate(true);
  };
  
  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);


  return (
    <div>
      <section className="">
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-xxl-12">
              <label className="fw-bold me-3 ">Tax Name</label>
              <div className="mb-3">
                <input
                  type="text"
                  name="tax_name"
                  placeholder="Tax Name"
                  onChange={handleInputChange}
                  className="form-control "
                  value={inputs.tax_name}
                  ref={props.refName}
                />
                {validate(inputs.tax_name, "required|min:3|max:15", {
                  title: "tax_name", onServer: errors['tax_name']
                })}
              </div>
            </div>
            <div className="col-xxl-12">
              <label className="fw-bold me-3 ">Tax Percentage</label>
              <div className="mb-3">
                <input
                  type="number"
                  name="tax_amount"
                  placeholder="Tax Percentage"
                  onChange={handleInputChange}
                  className="form-control "
                  value={inputs.tax_amount}
                  min="0"
                  onKeyPress={(e) => checkFloat(e)}
                />
                {validate(inputs.tax_amount, "required|min:1|max:100,num", {
                  title: "tax_amount",
                })}
              </div>
            </div>
            <div className="col-xxl-12">
              <label className="fw-bold me-3 ">Tax Type</label>
              <div className="mb-3">
                <CustomSelect
                  placeholder="Tax Type"
                  className={props.isEdit && "text-capitalize"}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  isSearchable={true}
                  options={tax_type}
                  value={selectedData.tax_type}
                  onChange={handleChange}
                />
                {validate(selectedData.tax_type, "required", {
                  title: "tax_type",
                })}
              </div>
            </div>
            <div className="col-xxl-12">
              <label className="fw-bold me-3 ">Tax Application</label>
              <div className="mb-3">
                <CustomSelect
                  className={props.isEdit && "text-capitalize"}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  isSearchable={true}
                  onChange={handleChange}
                  options={tax_application}
                  value={selectedData.tax_application}
                  placeholder="Tax Application"
                />
                {validate(selectedData.tax_application, "required", {
                  title: "tax_application",
                })}
              </div>
            </div>
            <div className="col-xxl-12">
              <button type="submit" className="btn btn-secondary w-100">
                {props.isEdit ? "Save changes" : "Add Tax Type"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
