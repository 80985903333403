import React, { useState, useEffect, useRef } from "react";
import { UilPen, UilTrashAlt, UilTransaction } from '@iconscout/react-unicons';
import Skeleton from 'react-loading-skeleton';
import SimpleReactValidator from 'simple-react-validator';

import { notify } from '../../../scripts/notify'
import api from "../../../api";

import PageHeader from "../../../refactoring/layouts/PageHeader";
import Loader from "../../../refactoring/components/global/Loader";


export default function MailManagement() {
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState([]);

  const fetchData = async () => {
    api.config.get({
      params: {
        key: 'mailer'
      }
    }).then(response => {
      setInputs(response.data.data[0]);
      setLoading(false);
    });
  }

  const validator = useRef(new SimpleReactValidator({
    validators: {
      website: {
        message: 'The website URL is invalid',
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/)
        }
      }
    }
  }));

  const onChange = (event) => {
    event.persist();
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  }

  const onSubmit = event => {
    event.preventDefault();

    if (validator.current.allValid()) {
      api.config.update.mailer(inputs).then((response) => {
        notify(response.data.type, response.data.message);
        fetchData();
      })
        .catch(error => {
          notify();
          console.log("ERRR: ", error.response);
        });
    } else {
      notify('error', 'Form error');
      setErrors(prevProps => ({
        ...prevProps,
        ...validator.current.errorMessages
      }));
      validator.current.showMessages();
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="bg-white p-5 mb-4 rounded border">
        <PageHeader subtitle="Mail SMTP" subtext="Add your mail smtp settings" />
        {loading ? (<Loader />) : (
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6 mb-4">
                <label htmlFor="host">Mail Host</label>
                <input
                  type="text"
                  className="form-control"
                  id="host"
                  name="host"
                  placeholder="smtp.gmail.com"
                  value={inputs.host || ""}
                  onChange={onChange}
                />
                {validator.current.message("host", inputs.host, "required|min:2|max:20", { className: "small text-danger" })}
              </div>
              <div className="col-md-6">
                <label htmlFor="port">Mail Port</label>
                <input
                  type="text"
                  className="form-control"
                  id="port"
                  name="port"
                  placeholder="1025,36,etc"
                  value={inputs.port || ""}
                  onChange={onChange}
                />
                {validator.current.message("port", inputs.port, "max:5", { className: "small text-danger" })}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4">
                <label htmlFor="username">Mail Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="xxxxxxxxxx"
                  value={inputs.username || ""}
                  onChange={onChange}
                />
                {validator.current.message("username", inputs.username, "required|min:2|max:20", { className: "small text-danger" }
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="password">Mail Password</label>
                <input
                  type="text"
                  className="form-control"
                  name="password"
                  placeholder="**********"
                  value={inputs.password || ""}
                  onChange={onChange}
                />
                {validator.current.message("password", inputs.password, "max:150", { className: "small text-danger" })}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4">
                <label htmlFor="address">Mail From Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  placeholder="support@innoventory.io"
                  value={inputs.address || ""}
                  onChange={onChange}
                />
                {validator.current.message("address", inputs.address, "required|email", { className: "small text-danger" })}
              </div>
              <div className="col-md-6">
                <label htmlFor="name">Mail From Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Innoventory"
                  value={inputs.name || ""}
                  onChange={onChange}
                />
                {validator.current.message("name", inputs.name, "max:150", { className: "small text-danger" })}
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div>
                  <button className="btn btn-secondary px-5">Save changes</button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>

    </React.Fragment>
  );
}
