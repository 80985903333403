
import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from "react";

export default function TinyMCE({editorRef,handleInputChange,value}){
  // const editorRef = useRef(null);
  return (
    <div>
      <Editor
        apiKey='is1cawqg9ilgqyhixzhngmlcrhufe1oijkoinq3ktl2y3kaw'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={handleInputChange}
        value={value}
        init={{
          placeholder:"Add your description here.",
          height: 200,
          menubar: false,
          branding: false,
          media_poster: false,
          media_alt_source: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px}'
        }}
      />
    </div>
  )
}
