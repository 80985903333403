import _ from "lodash";
import { useRef } from "react"; 
import SimpleReactValidator from "simple-react-validator";


export function useValidator() {
  const validator = useRef(new SimpleReactValidator({
    className: 'small text-danger',
      validators: {
        exist: {
        message: 'The :attribute is field is required.',
          rule: (val, params, validator) => {
          return Object.keys(val).length != 0
          },
        },
        file_type: {
        message: 'The :attribute must be :values file format.',
          rule: (val, params, validator) => {
            return params.includes(val.type);
          },
        messageReplace: (message, params) => message.replace(':values', params),
        },
        file_size: {
        message: 'The :attribute should not exceed :values MB.',
          rule: (val, params, validator) => {
            return val.size <= Math.pow(1024, params) * params;
          },
        messageReplace: (message, params) => message.replace(':values', params),
        },
        nested: {
        message: 'At least one :attribute needs to be added.',
          rule: (val, params, validator) => {
          var empty = _.filter(val, function (object) { return _.isEmpty(object) }).length;
            return empty == 0;
          },
        },
        enabled: {
          message: "Enable any one from the above.",
          rule: (val) => {
            return val;
          },
        },
        web: {
          message: "The website URL is invalid.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
            );
          },
        },
        telephone: {
          message: "The telephone must be a valid phone number.",
          rule: (val, params, validator) => {
            if (val.length > 20) {
              return false; 
            }
            return validator.helpers.testRegex(
              val,
              /^\+?[0-9\s()-]*$/
            );
          },
        },
        schema: {
          message: "The :attribute field is incomplete.",
          rule: (val, params, validator) => {
            var isValid = true;
            params.map(objectKey => {
              if(val[objectKey] == null || _.isEmpty(val[objectKey])) {
                isValid = false;
              }
            });
            return isValid;
          },
        }
      },
    })
  );
  
  function validate (value, rule, {title = null,  onServer = null} = {}) {
    if (!title) {
      title = value;
    }
    return (
      <>
        {validator.current.message(title, value, rule)}
        {onServer && validator.current.messageWhenPresent(onServer)}
      </>
    )
  }

  function validateIf(value, rule, condition, title = null) {
    if (!title) {
      title = value;
    }
    if (condition) {
      return (
        <>
          {validator.current.message(title, value, rule)}
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }

  function showErrorMessages() {
    validator.current.showMessages();
  }

  return [
    validator,
    {
      validate: validate,
      validateIf: validateIf,
      showErrorMessages: showErrorMessages,
    }
  ];
  
}
