import React from "react";

const PageHeader = ({ title, subtitle, subtext = null, component = null }) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="mb-4">
        <div className="letter-spacing-1 tiny-font text-uppercase mb-2">
        {title}
        </div>
        <h2 className="mb-2 text-capitalize">{subtitle} </h2>
        {subtext && (<p className="small text-dark mb-0">{subtext}</p>)}
      </div>

      {component &&
        <div className="mb-4 text-end d-flex align-items-center">
          {component}
        </div>
      }
    </div>
  )
};

export default PageHeader;
