import React from 'react';
import FilterDropdown from '../../components/filter/FilterDropdown';

export default function CardLayout(props) {

  return (
    <div className={`card  h-100 ${props.className}`}>
      <div className={`card-header border-0  ${props.className ? "bg-transparent" : "bg-white"}`}>
        <h6 className="site-heading mt-2 ">{props.title}</h6>
      </div>
      <div className="card-body pt-0 ">
        {props.children}
      </div>
      {props.footer ?
        <div className={`card-footer ${props.className ? "" : "bg-white" }`}>
          {props.footer}
        </div>
        :null
      }
    </div>

  )
}
