import React from 'react';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';

import { abbreviateNumber } from '../../../scripts/utils';

import CardComponent from '../../layouts/CardLayout';
import NoDataFound from '../global/NoDataFound';


export default function SampleCount(props) {
  return (
    <>
      <CardComponent filter_key={props.filter_key} title={props.title} footer={props.footer}>
        {props.isLoading ? (
          <>
            <Skeleton height={50} width={100} />
          </>
        ) : (
          !_.isNull(props.data) ? (
            <div className="d-flex justify-content-between align-items-center">
                <span className="h2 py-3">{abbreviateNumber(props.data)}</span>
            </div>
          ) : (
              <NoDataFound />
          )
        )}
      </CardComponent>
    </>
  );
}
