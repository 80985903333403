import  React, { Component }  from 'react';

export default class Loader extends Component{
  render(){
    return(
      <div className="d-flex justify-content-center align-items-center">
        <div className="loader"></div>
      </div>
    );
  }
}