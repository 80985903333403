import React, { useState, useRef } from "react";
import { UilBuilding , UilCompass } from '@iconscout/react-unicons';
import SimpleReactValidator from 'simple-react-validator';

import api from "../../../api";
import { notify } from '../../../scripts/notify';


export default function WarehouseCreationForm(props) {
  const initialState = {
    warehouse_name: "",
    warehouse_location: ""
  }
  const [errors, setErrors] = useState(initialState);
  const [, formError]=useState(false);
  const [input, setInput] = useState({
    warehouse_name: (props.inputs && props.inputs.warehouseName) || "",
    warehouse_location: (props.inputs && props.inputs.warehouseLocation) || "",
    warehouse_id: (props.inputs && props.inputs.warehouseID) || null
  });

  const validator = useRef(new SimpleReactValidator());

  const onChange = (e) => {
    e.preventDefault();
    setErrors({});
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  }

  const submitHandler = event => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.warehouse
        .add(input)
        .then(response => {
          
          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInput(initialState);
            setErrors(initialState);
            if (typeof props.closeModal === "function") props.closeModal();
            props.callback();
          }

          if (response.data.status === 0) {
            for (let [field, message] of Object.entries(response.data.field_error)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          notify();
        });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };

  const handleEditWarehouse = () => {
    if (validator.current.allValid()) {
      api.warehouse
        .edit(input)
        .then(response => {
          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            props.closeModal();
            props.callback();
          }
          if (response.data.status === 0) {
            for (let [field, message] of Object.entries(response.data.field_error)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          notify();
        });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };

  return (
    <section>
      <div className="row mb-4">
        <div className={`col-12 mb-3`}>
          <label className="fw-bold me-3 ">Warehouse Name</label>
          {/* <div className="input-group"> */}
            {/* <span className="input-group-text bg-white">{<UilBuilding size="16" className="text-secondary" />}</span>  */}
            <input
              type="text"
              className="form-control "
              name="warehouse_name"
              onChange={event => onChange(event)}
              value={input.warehouse_name}
              placeholder="Warehouse Name"
              ref={props.refName}
            />
          {/* </div> */}
          {validator.current.message("warehouse_name", input.warehouse_name, "required|min:2|max:25", {className: "small text-danger"})}
          <div className="small text-danger">{errors.warehouse_name}</div>

        </div>
        <div className={`col-12 mb-3`}>
          <label className="fw-bold me-3 ">Warehouse Location</label>
          {/* <div className="input-group"> */}
            {/* <span className="input-group-text bg-white">{<UilCompass size="16" className="text-secondary" />}</span>  */}
            <input
              type="text"
              className="form-control "
              name="warehouse_location"
              onChange={onChange}
              value={input.warehouse_location}
              placeholder="Warehouse Location"
            />
          {/* </div> */}
          {validator.current.message("warehouse_location", input.warehouse_location, "required|max:120", {className: "small text-danger"})}
        </div>
        <div className={`col-12 mb-3`}>
          <button onClick={props.isEdit ? handleEditWarehouse : submitHandler } className="btn btn-secondary w-100 "  >
            { props.isEdit ? "Save changes" : "Add Warehouse" }
          </button>
        </div>
      </div>
    </section>
  );
}