import React, { Component } from 'react';
import { UilCheckCircle } from '@iconscout/react-unicons'
import lang from "../../lang/en/Theme/Colors";
import { Link } from 'react-router-dom';



export default class ChannelSuccess extends Component {
  render(){
    return(
      <div>
        <div className="row">
          <div className="col-lg-6">
            <section className="my-4 bg-white p-5 text-center">
              <div className="mb-4 text-center">
                <UilCheckCircle size="32" color={lang.colors.success} />
              </div>
              <div>
                <h4 className="mb-3">{this.props.channelName} has been <br /> successfully integrated</h4>
                <Link to='/orders/manage'>
                  <small>View orders</small>
                </Link>
              </div>
            </section> 
          </div>
        </div>
      </div>
    )
  }
}

