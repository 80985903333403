
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notify } from "../../../scripts/notify";

import apiClient from "../header";

function deleteQuotation(id) {    
  const query =  apiClient.post(`quotations/delete`, {
    id: id,
  }).then(
    (result) => result.data,
  );
  return query;
}

export function useDeleteQuotationQuery() {
  const queryClient = useQueryClient()
  
  function handleSuccess(data) {
    notify(data.type, data.message);
    queryClient.invalidateQueries(['quotations-list']);
  }
  
  function handleError(data) {
    notify(data.type);
  }

  return useMutation((quotationId) => deleteQuotation(quotationId), {
    retry: false,
    onSuccess: handleSuccess,
    onError: handleError,
  });
}

