import React, { useEffect, useRef, useState } from "react";
import { UilPen, UilTrashAlt } from "@iconscout/react-unicons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import PageHeader from "../../../layouts/PageHeader";
import DataTable from "../../../components/common/DataTable";
import ModalComponent from "../../../components/common/ModalComponent";

import Form from "./Form";
import { useWarehouseListQuery, useWarehouserDeleteQuery, } from "../../../stores/setting/warehouse";

export default function Listing() {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({
    warehouseID: null,
    warehouseLocation: "",
    warehouseName: "",
  });

  const refName = useRef();

  const [query, { setPageNumber }] = useWarehouseListQuery();
  const deleteWarehouse = useWarehouserDeleteQuery();

  const add = () => {
    setOpen(true);
    setInputs({
      warehouseID: "",
      warehouseName: "",
      warehouseLocation: "",
    });
  };

  const edit = (warehouseName, warehouseLocation, warehouseID) => {
    setOpen(true);
    setInputs({
      warehouseID: warehouseID,
      warehouseName: warehouseName,
      warehouseLocation: warehouseLocation,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleDelete = (id) => {
    deleteWarehouse.mutate(id);
  };

  const columns = [
    {
      id: "warehouse_name",
      name: "Warehouse Name",
      selector: (row) => row.warehouse_name,
    },
    {
      id: "warehouse_location",
      name: "Warehouse Location",
      selector: (row) => row.warehouse_location,
    },
    {
      id: "action",
      name: "",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Edit warehouse</Tooltip>}
          >
            <span
              className="me-3 cursor active-icon"
              onClick={() => {
                setIsEdit(true);
                edit(row.warehouse_name, row.warehouse_location, row.id);
              }}
            >
              {<UilPen size="14" className="text-secondary" />}
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Delete warehouse</Tooltip>}
          >
            <span className="cursor active-icon" id="delete">
              {
                <UilTrashAlt
                  size="14"
                  onClick={() => handleDelete(row.id)}
                  className="text-danger"
                />
              }
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Warehouses" subtext="Manage warehouses" />
          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()}>
              Add Warehouse
            </button>
          </div>
        </div>

        <div className="border rounded-3 overflow-hidden">
          <DataTable
            data={query?.data?.data}
            columns={columns}
            isLoading={query.isLoading}
            pagination={true}
            paginationMeta={query?.data}
            onChangePage={(page) => setPageNumber(page)}
          />
        </div>
      </div>
      {open ? (
        <ModalComponent
          show={open}
          onHide={closeModal}
          onEntered={() => refName.current.focus()}
          title={inputs.warehouseID ? "Edit Warehouse" : "Add Warehouse"}
          body={
            <Form
              inputs={inputs}
              isEdit={isEdit}
              closeModal={closeModal}
              refName={refName}
            />
          }
        />
      ) : null}
    </div>
  );
}
