import React, { useState, useRef } from "react";
import { UilTrashAlt, UilPen } from "@iconscout/react-unicons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import lang from "../../../../lang/en/Theme/Colors";
import DataTable from "../../../components/common/DataTable";
import PageHeader from "../../../layouts/PageHeader";
import ModalComponent from "../../../components/common/ModalComponent";

import Form from "./Form";
import { useGroupDeleteQuery, useGroupListQuery } from "../../../stores/setting/group";


export default function Listing() {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const refName = useRef();

  const [inputs, setInputs] = useState({
    groupID: null,
    group_name: "",
    permissions: [],
  });

  const [query, { setPageNumber }] = useGroupListQuery({ defaultValue: { limit: 15 } });
  const deleteGroup = useGroupDeleteQuery();

  const add = () => {
    setOpen(true);
    setInputs({
      groupID: "",
      groupName: "",
      permissions: "",
    });
  };

  const edit = (groupID, group_name, permissions) => {
    setOpen(true);
    setInputs({
      groupID: groupID,
      groupName: group_name,
      permissions: permissions
    });
  };

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleDelete = (id) => {
    deleteGroup.mutate({ group_id: id });
  }; 

  const columns = [
    {
      id: "name",
      name: "Group Name",
      selector: (row) => row.group_name,
    },
    {
      id: "permission",
      name: "Permission",
      cell: (row) => (
        <div className="permissions-data">
          {row.permissions.map((permission, index) => (
            <span key={index}>{permission.permission_name}</span>
          ))}
        </div>
      ),
    },
    {
      id: "action",
      name: "",
      cell: (row) => (
        <div>
          {row.id === 1 ? (
            <></>
          ) : (
            <div className="d-flex justify-content-end">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Edit group</Tooltip>}
              >
                <span
                  className="me-3 cursor active-icon"
                  id="edit"
                  onClick={() => {
                    setIsEdit(true);
                    edit(row.id, row.group_name, row.permissions);
                  }}
                >
                  {<UilPen size="14" className="text-secondary" />}
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Delete group</Tooltip>}
              >
                <span
                  className="cursor active-icon"
                  id="delete"
                  onClick={() => handleDelete(row.id)}
                >
                  {<UilTrashAlt size="16" color={lang.colors.danger} />}
                </span>
              </OverlayTrigger>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader subtitle="User Groups" subtext="Manage user groups" />

          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()}>
              Add Group
            </button>
          </div>
        </div>

        <div className="border rounded-3 overflow-hidden">
          <DataTable
            data={query?.data?.data}
            columns={columns}
            isLoading={query.isLoading}
            pagination={true}
            paginationMeta={query?.data}
            onChangePage={(page) => setPageNumber(page)}
          />
        </div>
        {open ? (
          <ModalComponent
            show={open}
            onHide={closeModal}
            onEntered={() => refName.current.focus()}
            title={inputs.groupID ? "Edit Role" : "Add Role"}
            body={
              <Form
                inputs={inputs}
                isEdit={isEdit}
                closeModal={closeModal}
                refName={refName}
              />
            }
          />
        ) : null}
      </div>
    </>
  );
}
