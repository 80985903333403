import React, { useState, useRef } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { UilPen, UilTrashAlt } from "@iconscout/react-unicons";


import DataTable from "../../../components/common/DataTable";
import PageHeader from "../../../layouts/PageHeader";
import ModalComponent from "../../../components/common/ModalComponent";

import Form from "./Form";
import { usePaymentMethodDeleteQuery, usePaymentMethodListQuery } from "../../../stores/setting/payment";


export default function Listing() {

  const refName = useRef();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({
    id: "",
    name: "",
  });

  const [query, { setPageNumber }] = usePaymentMethodListQuery({ defaultValue: {limit: 15}});
  const deletePaymentMethod = usePaymentMethodDeleteQuery();

  const add = () => {
    setOpen(true);
    setInputs({
      id: "",
      name: "",
    });
  };

  const edit = (id, name) => {
    setOpen(true);
    setInputs({
      id: id,
      name: name,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleDelete = (id) => {
    deletePaymentMethod.mutate(id);
  };

  const columns = [
    {
      id: "name",
      name: "Payment Methods",
      selector: (row) => row.name,
    },
    {
      id: "action",
      name: "",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit Payment Method</Tooltip>} >
            <span className="me-3 cursor active-icon" onClick={() => { setIsEdit(true); edit(row.id, row.name); }} id="edit" >
              {<UilPen size="14" className="text-secondary" />}
            </span>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete Payment Method</Tooltip>} >
            <span className="cursor active-icon" id="delete">
              { <UilTrashAlt size="14" onClick={() => handleDelete(row.id) } className="text-danger" /> }
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="bg-white p-5 border rounded">
        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Payment Methods" subtext="Manage Payment Methods" />
          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()}>Add Payment Method</button>
          </div>
        </div>

        <div className="border rounded-3 overflow-hidden">
          <DataTable
            data={query?.data?.data}
            columns={columns}
            isLoading={query.isLoading}
            pagination={true}
            paginationMeta={query?.data}
            onChangePage={(page) => setPageNumber(page)}
          />
        </div>
      </div>


      {open ? (
        <ModalComponent
          show={open}
          onHide={closeModal}
          onEntered={() => refName.current.focus()}
          title={inputs.id ? "Edit Payment Method" : "Add Payment Method"}
          body={
            <Form
              inputs={inputs}
              isEdit={isEdit}
              closeModal={closeModal}
              refName={refName}
              setInputs={setInputs}
            />
          }
        />
      ) : null}
    </React.Fragment>
  );
}
