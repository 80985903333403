import React, { useEffect, useState } from "react";

import { useValidator } from "../../../utils/validator";
import { useUnitCreateQuery, useUnitUpdateQuery } from "../../../stores/setting/unit";


export default function Form(props) {
  
  const [inputs, setInputs] = useState(props.inputs ?? {});
  const [errors, setErrors] = useState({});
  const [, forceUpdate] = useState(false);
  const [validator, { validate }] = useValidator(errors);

  const [unitCreateQuery, { createErrors }] = useUnitCreateQuery();
  const [unitUpdateQuery, { updateErrors }] = useUnitUpdateQuery();

  const handleInputChange = (event) => {
    event.preventDefault();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      if (inputs.id) {
        unitUpdateQuery.mutate({ ...inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                props.closeModal();
              }
            },
          }
        );
      } else {
        unitCreateQuery.mutate({ ...inputs },
          {
            onSuccess: (data) => {
              if (data.status) {
                setInputs({});
                props.closeModal();
              }
            },
          }
        );
      }
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);


  return (
    <section className="">
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-6">
            <div className="">
              <label className="fw-bold me-3 ">Unit Name</label>
              <input
                type="text"
                name="name"
                placeholder="Unit Name"
                onChange={(event) => handleInputChange(event)}
                className="form-control "
                value={inputs.name ||""}
                ref={props.refName}
              />
              {validate(inputs.name, "required|min:1|max:15", {
                title: "name", onServer: errors['name']
              })}
            </div>
          </div>
          <div className="col-6">
            <label className="fw-bold me-3 ">Unit Type</label>
            <div className="">
              <input
                type="text"
                name="units"
                placeholder="Type"
                onChange={(event) => handleInputChange(event)}
                className="form-control "
                value={inputs.units || ""}
              />
              {validate(inputs.units, "required|min:1|max:50", {
                title: "units",
              })}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button type="submit" className="btn btn-secondary w-100">
              {props.isEdit ? "Save changes" : "Add Units"}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}
