import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UilBrowser, UilEllipsisH } from "@iconscout/react-unicons";

import { Paths } from "../../../routes";
import config from "../../../configurations";
import configurations from "../../../configurations";
import ActionBar from "../../components/global/ActionBar";
import DataTable from "../../components/common/DataTable";
import ErrorPage from "../../components/errors/ErrorPage";
import PageHeader from "../../layouts/PageHeader";
import ModalComponent from "../../components/common/ModalComponent";

import { useNotificationOrderListQuery } from "../../stores/notifications/list";
import { useSendNotificationQuery } from "../../stores/notifications/notificationsTrigger";


export default function Listing() {

  const [modalOpen, setModalOpen] = useState(false);
  const [ orderQuery, { searchParams, setSearchParams, setPageNumber, setSort, setFilters } ] = useNotificationOrderListQuery();
  const sendNotifications = useSendNotificationQuery();

  function setColor(value) {
    if (value) return "text-success";
    else return "text-danger";
  }

  function getBadgeStatus(object, trigger_type, param) {
    if (!!object) {
      let status = object.trigger_status;
      if (param == "color") return setColor(status);
      else if (param == "text") return status == "1" ? "Sent" : "Failed";
    }
  }

  function getBadgeMessage(status, trigger_type) {
    if (trigger_type == "overall-triggers")
      return status === 0 ? "There was an error in sending one or more notifications." : "Notification has been successfully sent";
    if (trigger_type == "triggers")
      return status === 0 ? "There was an error in triggering this notification type." : "Notification has been successfully sent";
  }

  const sendNotification = (id, notification, toSend) => {
    sendNotifications.mutate({ order_id: id, notify_type: notification, medium: toSend });
  };
  
  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: "channel_name",
      name: "Channel Name",
      selector: (row) => row.channel_name,
    },
    {
      id: "order_id",
      name: "Order Id",
      cell: (row) => ( <Link to={Paths.Order.pages.view.path + row.id}> #{row.order_id} </Link> ),
    },
    {
      id: "status",
      name: "Order Status",
      selector: (row) => (
        <div className={`badge rounded-pill ${ config.OrderStatus[row.order_status].class }`} >
          {config.OrderStatus[row.order_status].status}
        </div>
      ),
    },
    {
      id: "placed_at",
      name: "Order Placed",
      selector: (row) => moment(row.placed_at).format("MMMM D, YYYY"),
    },
    {
      id: "type",
      name: "Notification Type",
      cell: (row) => (
        <>
          {row.overall_statuses.map( ( notification, notification_index, { length, notification_last_index = length - 1 } ) => (
              <>
                <OverlayTrigger 
                  placement="top" 
                  overlay={ <Tooltip id="tooltip-top"> {getBadgeMessage( notification.status, "overall-triggers" )} </Tooltip> } >
                  <span className={`${setColor( notification.status )} cursor-default`} key={ "overall_notification_type" + row.order_id + notification_index } >
                    { _.find(config.NotificationType, [ "value", notification.type, ]).label }
                    {notification_index == notification_last_index ? "" : ", "}
                  </span>
                </OverlayTrigger>
              </>
            )
          )}
        </>
      ),
    },
  ];

  const ExpandedComponent = (option) => {
    return (
      <>
        <div className="text-uppercase text-gray-400 py-3 fw-bold">
          Notification Triggers
        </div>
        {config.OrderTriggers.map((triggers, triggers_index) => (
          <div className="row justify-content-between align-items-center py-3" key={"notification_triggers" + option.order_id + triggers_index} >
            {_.filter(option.notification_triggers, ["trigger", triggers.value]) .length !== 0 ? (
              <>
                <div className="col-6 text-gray-900" key={triggers.value + triggers_index} >
                  {triggers.label}
                  <span
                   className={`badge rounded-pill border ms-3 cursor-default 
                    ${getBadgeStatus( _.find(option.notification_triggers, [ "trigger", triggers.value, ]), triggers.value, "color" )}`}
                  >
                    {getBadgeStatus( _.find(option.notification_triggers, [ "trigger", triggers.value, ]), triggers.value, "text" )}
                  </span>
                </div>
                <div className="col-auto text-end" key={triggers.value + triggers_index + triggers_index} >
                  {_.filter(option.notification_triggers, [ "trigger", triggers.value, ]).map( ( notification, notification_index, { length, last = length - 1 } ) => (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={ <Tooltip id="tooltip-top"> {getBadgeMessage(notification.status, "triggers")} </Tooltip> }
                          key={triggers_index + notification_index}
                        >
                          <span className={`${setColor( notification.status )} cursor-default`} >
                            { _.find(config.NotificationType, [ "value", notification.type, ]).label }
                            {notification_index == last ? "" : ", "}
                          </span>
                        </OverlayTrigger>
                      </>
                    )
                  )}
                </div>
                <div
                  className="col-auto overflow-visible text-end d-none"
                  key={ "notification_menu" + option.order_id + triggers_index + triggers_index } >
                  <div className="dropdown ">
                    <button className="btn  p-0" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                      <span>{<UilEllipsisH size="16" className="text-secondary"/>}</span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu" >
                      {config.NotificationType.map( (type, type_key, { length, last = length - 1 }) => (
                          <li className="fw-bold cursor dropdown-item">
                            <button type="button" className="btn  p-0" id="send-sms" onClick={() => sendNotification( option.order_id, triggers.value, type.value ) } >
                              Send {type.label}
                            </button>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-6 text-gray-900" key={triggers.value + triggers_index} >
                  {triggers.label}
                  <span className={`badge rounded-pill border ms-3 text-gray-500`} > Pending </span>
                </div>
                <div className="col-auto  text-end" key={triggers.value + triggers_index + triggers_index} >
                  {config.NotificationType.map( (type, type_key, { length, last = length - 1 }) => (
                    <OverlayTrigger
                      placement="top"
                      overlay={ <Tooltip id="tooltip-top"> This notification has not been triggered yet. </Tooltip> }
                      key={triggers_index + type_key}
                    >
                      <span className="text-gray-400 cursor-default"> {type.label} {type_key == last ? "" : ", "} </span>
                    </OverlayTrigger>
                  )
                )}
                </div>
                <div className="col-auto overflow-visible text-end d-none" key={ "notification_menu" + option.order_id + triggers_index + triggers_index } >
                  <div className="dropdown ">
                    <button className="btn  p-0" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" >
                      <span>{<UilEllipsisH size="16" className="text-secondary" />}</span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu" >
                      {config.NotificationType.map( (type, type_key, { length, last = length - 1 }) => (
                          <li className="fw-bold cursor dropdown-item">
                            <button type="button" className="btn  p-0" id="send-sms" onClick={() => sendNotification( option.order_id, triggers.value, type.value )}>
                              Send {type.label}
                            </button>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </>
    ); 
  };


  return (
    <>
      {orderQuery.isError && <ErrorPage innerPage={false} error="500" />}

      <section>
        <PageHeader title="Notifications" subtitle="Orders" />
        <ActionBar
          searchParams={searchParams}
          setSearchParams={handleSearch}
          sortOptions={configurations.Sort}
          setSort={setSort}
          placeholder="Search by Order ID"
          component={
            <div className="col-md d-flex justify-content-end">
              <div className="dropdown me-3">
                <button
                  className="btn px-0"
                  type="button"
                  id="batch-setting"
                  onClick={() => setModalOpen(true)}
                >
                  <UilBrowser width="15" className="text-secondary" /> Batch
                  Notifications
                </button>
              </div>
            </div>
          }
        />

        <DataTable
          data={orderQuery?.data?.data}
          columns={columns}
          isLoading={orderQuery.isFetching}
          pagination={true}
          paginationMeta={orderQuery?.data?.meta}
          onChangePage={(page) => setPageNumber(page)}
          expandable
          expandableRowsComponent={ExpandedComponent}
        />
      </section>

      <ModalComponent
        show={modalOpen}
        title={"Batch notifications"}
        body={
          <div>
            <p>
              Notifications can be sent to customers from the below added
              triggers.
            </p>
            <div>Order Received</div>
            <div>Order Scheduled For Delivery</div>
            <div>Order Out For Delivery</div>
            <div>Order Delivered</div>
          </div>
        }
        onHide={() => setModalOpen(false)}
      />
    </>
  );
}
