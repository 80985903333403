import React, { useEffect, useRef, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { UilBuilding, UilPen, UilTrashAlt } from '@iconscout/react-unicons';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Skeleton from 'react-loading-skeleton';

import { notify } from '../../../scripts/notify';
import api from "../../../api";

import PageHeader from "../../../refactoring/layouts/PageHeader";
import PageInfo from "../../../components/pagination/PageInfo";
import WarehoueseCreationForm from "./WarehouseCreationForm";
import ReactPagination from "../../../components/pagination/ReactPagination";


export default function WarehouseManagment() {
  const [isLoading, setIsLoading] = useState(true);
  const [warehouses, setWarehouses] = useState(undefined)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState(false);
  const [warehouse, setWarehouse] = useState({
    warehouseID: null,
    warehouseLocation: "",
    warehouseName: ""
  });

  const refName = useRef();
  const pageChange = pageNo => {
    setPageNumber(pageNo);
  };

  const fetchWarehouses = () => {
    api.warehouse.get({
      params: {
        paginate: 15,
        page: pageNumber
      }
    })
    .then(response => {
      setIsLoading(false);
      let warehouse_data = response.data
      setWarehouses(warehouse_data.data);
      
      setPages({
        currentPage: warehouse_data.current_page,
        per_page: warehouse_data.per_page,
        total_count: warehouse_data.total,
        lastPage: warehouse_data.last_page
      });
      
      if (pageNumber >= response.data.last_page) {
        setPageNumber(response.data.last_page);
      }
    });
  }

  useEffect(() => {
    fetchWarehouses();
  }, [pageNumber]);

  const add = () => {
    setModalIsOpen(true);
    setWarehouse({
      warehouseID: "",
      warehouseName: "",
      warehouseLocation: ""
    });
  }

  const edit = (warehouseName, warehouseLocation, warehouseID) => {
    setModalIsOpen(true);
    setWarehouse({
      warehouseID: warehouseID,
      warehouseName: warehouseName,
      warehouseLocation: warehouseLocation
    });
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setIsEdit(false);
  }

  const handleDeleteWarehouse = (warehouseID) => {
    api.warehouse.delete({ warehouse_id: warehouseID })
      .then(response => {
        notify(response.data.type, response.data.message);
        if (response.data.status === 1) {
          fetchWarehouses();
        }
      })
      .catch(error => {
        if (error.response.status !== 'UNAUTHORIZED') {
          notify('error');
          // console.log('Error::', error);
        };
      })
  }

  return (
    <div>
      <div className="bg-white p-5 border rounded">

        <div className="d-flex justify-content-between">
          <PageHeader subtitle="Warehouses" subtext="Manage warehouses" />
          <div className="mb-4 text-end d-flex align-items-center">
            <button className="btn btn-secondary" onClick={() => add()} >Add Warehouse</button>
          </div>
        </div>

      
        <div>
        
            <Table className="table border mb-4 table-hover">
              <thead>
                <tr>
                  <th>Warehouse Name</th>
                  <th>Warehouse Location</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan='100%' className='text-center'>
                    <Skeleton count={5} height={30} className="mb-3"/>
                  </td>
                </tr> 
              ) : (

                warehouses && warehouses.length ? (
                  warehouses.map((warehouse, index) => (
                    <tr key={index}>
                      <td>{warehouse.warehouse_name}</td>
                      <td>{warehouse.warehouse_location}</td>
                      <td className="d-flex justify-content-end">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit warehouse</Tooltip>}>
                          <span className="me-3 cursor active-icon"
                            onClick={() => { setIsEdit(true); edit(warehouse.warehouse_name, warehouse.warehouse_location, warehouse.id) }}>
                            {<UilPen size="14" className="text-secondary" />}
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete warehouse</Tooltip>}>
                          <span className="cursor active-icon" id="delete">
                            {<UilTrashAlt size="14" onClick={() => handleDeleteWarehouse(warehouse.id)} className="text-danger" />}
                          </span>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="100%" className="text-center">
                      <div className="text-center bg-white py-5">
                        <UilBuilding size="38" className="mb-3 text-secondary" />
                        <p className="h5 text-gray-700">No warehouse created</p>
                        <p className="small text-gray-600">Enter warehouse name and it's location. All the added warehouses will be reflected here</p>
                      </div>
                    </td>
                  </tr>
                )

              )}
            </tbody>
            <tfoot>
              <tr className="border-top">
                <td colSpan="100%">
                  <div className="d-flex justify-content-between align-items-center">
                    <PageInfo perPage={pages.per_page} currentPage={pages.currentPage} total={pages.total_count} />
                    {pages ? ( <ReactPagination pageNumber={pageChange} pages={pages} /> ) : null}
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>

          {modalIsOpen &&
            <Modal show={modalIsOpen} onEntered={() => refName.current.focus()} onHide={closeModal} centered size="md">
              <Modal.Header closeButton className="p-4">
                <Modal.Title>{warehouse.warehouseID ? "Edit Warehouse" : "Add Warehouse"}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-4">
                <WarehoueseCreationForm callback={fetchWarehouses} inputs={warehouse} isEdit={isEdit} closeModal={closeModal} refName={refName} />
              </Modal.Body>
            </Modal>
          }
        </div>
      </div>
    </div>
  );
}