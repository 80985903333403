
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import apiClient from "../header";


export function useQuotationListQuery({
    enabled = true,
    defaultValue = {
      'pageNumber': 1,
      'limit': 15,
      'sort': null,
      'searchParams': undefined,
      'filters': [],
      'linked': undefined
    }
  } = {}) {

  const [pageNumber, setPageNumber] = useState(defaultValue.pageNumber)
  const [limit, setLimit] = useState(defaultValue.limit)
  const [searchParams, setSearchParams] = useState(defaultValue.searchParams)
  const [sort, setSort] = useState(defaultValue.sort)
  const [filters, setFilters] = useState(defaultValue.filters)
  const [linked, setLinked] = useState(defaultValue.linked);


  function getList(signal) {    
    const query =  apiClient.get(`quotations/all`, {
      params: { limit: limit, page: pageNumber, search: searchParams, sort:sort, filters: filters, linked_with: linked }, 
      signal,
    }).then(
      (result) => result.data,
    );
    return query;
  }

  function handleSuccess(data) {
    document.dispatchEvent(new CustomEvent('quotation-listing-fetched', {'detail': data.data}))
  }  

  const query = useQuery(['quotations-list', {searchParams, pageNumber, sort, filters, limit, linked }], 
  ({ signal }) => getList(signal), 
  {
    retry: 0,
    staleTime: Infinity,
    keepPreviousData : true,
    refetchOnWindowFocus: false,
    onSuccess: handleSuccess,
    enabled: enabled
  })  

  return [
    query, 
    {
      'searchParams': searchParams,
      'setSearchParams': setSearchParams, 

      'limit': limit,
      'setLimit': setLimit,

      'setPageNumber': setPageNumber, 

      'sort': sort,
      'setSort': setSort,
      
      'filters': filters,
      'setFilters': setFilters,

      'linked' : linked,
      'setLinked' : setLinked
    }
  ]
}

