
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import apiClient from "../header";

var defaultState = {
  'pageNumber': 1,
  'limit': 15,
  'sort': null,
  'searchParams': undefined,
  'filters': [],
  'linked': undefined
}

export function getList(queryKey, signal) {
  const [query_key, { limit, pageNumber, searchParams, sort, filters, linked }] = queryKey
  const query = apiClient.get(`customer/get`, {
    params: { limit: limit, page: pageNumber, search: searchParams, sort: sort, filters: filters, linked_with: linked },
    signal,
  }).then(
    (result) => result.data,
  );
  return query;
}

export function useCustomerListQuery({ enabled = true, defaultValue = {} } = {}) {
  defaultState = { ...defaultState, ...defaultValue };

  const [pageNumber, setPageNumber] = useState(defaultState.pageNumber)
  const [limit, setLimit] = useState(defaultState.limit)
  const [searchParams, setSearchParams] = useState(defaultState.searchParams)
  const [sort, setSort] = useState(defaultState.sort)
  const [filters, setFilters] = useState(defaultState.filters)
  const [linked, setLinked] = useState(defaultState.linked);

  function handleSuccess(data) {
    document.dispatchEvent(new CustomEvent('customer-fetched', { 'detail': data.data }))
  }

  const query = useQuery(['customer-list', { searchParams, pageNumber, sort, filters, limit, linked }],
    ({ queryKey, signal }) => getList(queryKey, signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
      enabled: enabled
    })

  return [
    query,
    query.data,
    {
      'searchParams': searchParams,
      'setSearchParams': setSearchParams,

      'limit': limit,
      'setLimit': setLimit,

      'setPageNumber': setPageNumber,

      'sort': sort,
      'setSort': setSort,

      'filters': filters,
      'setFilters': setFilters,

      'linked': linked,
      'setLinked': setLinked
    }
  ]
}

