import _ from "lodash";
import React, { useEffect, useState } from "react";

import Loader from "../../components/global/Loader";
import { useValidator } from "../../utils/validator";

import { useUpdateVonageQuery } from "../../stores/notifications/settings";
import { useVonageQuery } from "../../stores/notifications/view";

export default function GeneralSettings() {
  const initialState = {
    api_secret: "",
    api_username: "",
    sms_number: "",
  };

  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState(false);
  const [validator, { validate }] = useValidator(errors);
  const [, forceUpdate] = useState();

  const [updateVonageQuery, { createErrors }] = useUpdateVonageQuery();
  const [vonageData, { viewError }] = useVonageQuery();

  const handleInputChanges = (event) => {
    event.persist();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmitApi = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      updateVonageQuery.mutate({
        api_username: inputs.api_username,
        api_secret: inputs.api_secret,
        sms_number: inputs.sms_number,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };

  useEffect(() => {
    if (vonageData.isFetched) {
      if (vonageData.data.data.length != 0) {
        setInputs((prevProps) => ({
          ...prevProps,
          api_secret: vonageData.data.data[0].api_secret,
          api_username: vonageData.data.data[0].api_username,
          sms_number: vonageData.data.data[0].sms_number,
        }));
      }
    }
  }, [vonageData.isFetching]);

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors);
    }
  }, [createErrors]);

  return (
    <>
      <>
        <form onSubmit={onSubmitApi} encType="multipart/form-data">
          <section className="border bg-white rounded p-4 mb-4">
            <h6 className="fw-bold">General Settings</h6>
            {vonageData.isFetching ? (
              <Loader />
            ) : viewError ? (
              viewError
            ) : (
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label htmlFor="api_username">
                    <span className="text-danger">*</span>
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="api_username"
                    placeholder="Enter User name"
                    value={inputs.api_username || ""}
                    onChange={(event) => handleInputChanges(event)}
                  />
                  {validate(inputs.api_username, "required|max:150", {
                    title: "api_username",
                  })}
                </div>
                <div className="col-md-6 mb-4">
                  <label htmlFor="api_secret">
                    <span className="text-danger">*</span>
                    API Secret key
                  </label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      id="api_secret"
                      name="api_secret"
                      placeholder="Enter API Secret"
                      value={inputs.api_secret || ""}
                      onChange={(event) => handleInputChanges(event)}
                    />
                  </div>
                  {validate(inputs.api_secret, "required|min:2|max:20", {
                    title: "api_secret",
                  })}
                </div>

                <div className="col-md-6 mb-4">
                  <label htmlFor="sms_number">
                    <span className="text-danger">*</span>
                    SMS
                  </label>
                  <div className="">
                    <input
                      type="tel"
                      className="form-control me-4 rounded"
                      id="sms_number"
                      name="sms_number"
                      placeholder="Enter Mobile Number"
                      value={inputs.sms_number || ""}
                      onChange={(event) => handleInputChanges(event)}
                    />
                  </div>
                  {validate(inputs.sms_number, "required|phone|min:10|max:15", {
                    title: "sms_number",
                  })}
                </div>
                <div className="row">
                  <div className="col-auto">
                    <button className="btn btn-secondary px-5">Edit</button>
                  </div>
                </div>
              </div>
            )}
          </section>
        </form>
      </>
    </>
  );
}
