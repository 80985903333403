import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams, Link, useLocation, useSearchParams } from "react-router-dom";
import { UilPlusCircle, UilPen, UilLabel, UilPrint, UilPlusSquare, UilTimes } from "@iconscout/react-unicons";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";


import { Paths } from "../../../routes";
import config from "../../../configurations";
import lang from "../../../lang/en/Theme/Colors";
import { confirmCreate } from "../../utils/scripts";
import { useValidator } from "../../utils/validator";
import Loader from "../../components/global/Loader";
import PageHeader from "../../layouts/PageHeader";

import Total from "../../components/common/Total";
import { useUserListQuery } from "../../stores/setting/user";
import { UsersContext } from "../../../web/context/users";
import SelectCouponModal from "../../components/modal/SelectCouponModal";
import Payment from "../../components/payment/Payment";
import SelectDiscountModal from "../../components/modal/SelectDiscountModal";
import CustomerCreation from "../../components/customer/CustomerCreation";
import ModalComponent from "../../components/common/ModalComponent";
import FileAttachments from "../../components/global/FileAttachments";
import CustomerDetailsForm from "../../components/customer/CustomerDetailsForm";
import CreatableSelectProducts from "../../components/product/CreatableSelectProducts";
import CouponCreationForm from "../settings/coupon/Form";
import PaymentCreationForm from "../settings/paymentMethod/Form";
import ShippingCreationForm from "../settings/shippingMethod/Form";

import { useTotalQuery } from "../../stores/orders/total";
import { useMetaData } from "../../stores/orders/metadata";
import { useInvoiceViewQuery } from "../../stores/invoices/view";
import { useInvoiceCreateQuery } from "../../stores/invoices/create";
import { useInvoiceUpdateQuery } from "../../stores/invoices/update";
import { useCreateOrderQuery } from "../../stores/invoices/createOrder";
import SelectShippingModal from "../../components/modal/SelectShippingModal";
import { useQuotationViewQuery } from "../../stores/quotations/view";
import CustomerDetailsView from "../../components/customer/CustomerDetailsView";
import CustomerSearch from "../../components/customer/CustomerSearch";


const initialState = {
  customer: {},
  user_id: null,
  created_by: null,
  shipping_method: "",
  shipping_id: "",
  coupon_code: null,
  coupon_amount: null,
  payment_method: "",
  shipping_cost: null,
  invoice_status: null,
  comment: "",
  date: "",
  inclusive_tax_discount: false,
  discount: null,
  discount_type: null,
  payment_details: "",
  invoice_date: moment().format("yyyy-MM-DD"),
  invoice_due_date: moment().add(15, "d").format("yyyy-MM-DD"),
  products: [],
  attachments: [],
};

export default function Form() {

  const { id } = useParams();
  const {users} = useContext(UsersContext);
  const [ userListQuery ] = useUserListQuery({ defaultValue: { limit: "all" } });
  const user_data = users?.data;
  const [ queryParams ]= useSearchParams();
  const quotation_id = queryParams.get('quotation_id') ?? false;
  const location = useLocation();
  var pathname = location.pathname;
  
  const metaData = useMetaData();
  const queryClient = useQueryClient();
  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState({});
  const fileValidator = useValidator(errors)  
  const [tempData, setTempData] = useState({});
  const [customer, setCustomer] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [forceUpdate, setForceUpdate] = useState();
  const [ createOrderQuery ] = useCreateOrderQuery();
  const [validator, { validate }] = useValidator(errors);
  const selectProductValidatorInstance = useValidator(errors);

  const [invoiceViewQuery, { viewError }] = useInvoiceViewQuery(id);
  const [invoiceCreateQuery, { createErrors }] = useInvoiceCreateQuery();
  const [invoiceUpdateQuery, { updateErrors }] = useInvoiceUpdateQuery();
  
  const [quotationDataQuery] = useQuotationViewQuery(quotation_id);
  
  const [totalQuery, totalQueryData, { totalData, setTotalData }] = useTotalQuery();
  
  const [file, setFile] = useState([]);
  const [total, setTotal] = useState({
    sub_total: 0,
    total: 0,
    total_tax: 0,
    shipping_tax_amount: 0,
    shipping_gross_amount: 0,
    discount_type: null,
    discount_cost: 0,
    discount_percent: 0,
  });

  const [modelOpen, setModelOpen] = useState({
    customer: false,
    coupon: false,
    shipping: false,
    discount: false,
    newShipping: false,
    newCoupon: false,
    newPayment: false,
  });


  function fetchMetaData() {
    queryClient.invalidateQueries(['order-metadata']);
  }

  const openModel = (model) => {
    setModelOpen((prevProps) => ({
      ...prevProps,
      [model]: true,
    }));
  };

  const closeModel = (model) => {
    setModelOpen((prevProps) => ({
      ...prevProps,
      [model]: false,
    }));
    setTempData({});
  };

  const removeModelData = (event) => {
    if (event === "coupon") {
      setInputs((prevProps) => ({
        ...prevProps,
        coupon_code: null,
        coupon_amount: null,
      }));
    }
    if (event === "discount") {
      setInputs((prevProps) => ({
        ...prevProps,
        discount_type: null,
        discount: null,
        inclusive_tax_discount: 0,
      }));
      setTotal((prevProps) => ({
        ...prevProps,
        discount_type: null,
        discount: null,
        inclusive_tax_discount: 0,
      }));
    }
  };

  function handleCustomerChangeSelect(value, action) {
    if (action === "select-option") {
      setCustomer(value);
      setTempData({
        customer_first_name: value.customer_first_name,
        customer_last_name: value.customer_last_name,
      });
    }

    if (action === "create-option") {
      setCustomer({});
      setTempData({
        customer_first_name: value.customer_first_name,
        customer_last_name: value.customer_last_name,
      });
      openModel("customer");
    }
  }

  function handleSelectNewPayment(value, action) {
    if (action === "select-option") {
      setInputs((prevProps) => ({
        ...prevProps,
        payment_method: value.name,
      }));
    }
    if (action === "create-option") {
      setInputs((prevProps) => ({
        ...prevProps,
        id: "",
        name: value.value,
      }));
      openModel("newPayment");
    }
  }

  function handleSelectChange(label, value) {
    setInputs((prevProps) => ({
      ...prevProps,
      [label]: value,
    }));
  }

  function handleSelectChangeUser(value) {
    setInputs((prevProps) => ({
      ...prevProps,
      user_id: value.id,
      created_by: value.name ?? value.email,
    }));
  }

  const handleInputChanges = (event) => {
    event.persist();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const formatOptionLabelCreatedBy = (option, { context }) => {
    return (
      <div>
        <div>
          <span>{option.created_by ?? option.name} {option.email ?? ''}</span>
        </div>
      </div>
    );
  }

  const createOrder = (id) => {
    confirmCreate(() => createOrderQuery.mutate(id));
  };  

  const handleFiles = (file) => {
    const upload_files = [];
    const existing_upload_files = [];
    if (!_.isEmpty(file)) {
      file.map((file, index) => {
        if (file instanceof File) {
          upload_files.push(file);
        } else {
          existing_upload_files.push(file.id);
        }
      });
      setInputs((prevProps) => ({
        ...prevProps,
        existing_upload_files: existing_upload_files,
        upload_files: upload_files,
      }));
    } else {
      setInputs((prevProps) => ({
        ...prevProps,
        existing_upload_files: [],
        upload_files: [],
      }));
    }
  };
  const handleSubmit = () => {
    if ( validator.current.allValid() && selectProductValidatorInstance[0].current.allValid() && fileValidator[0].current.allValid() ) {
      if (id) {
        invoiceUpdateQuery.mutate(inputs);
      } else {
        invoiceCreateQuery.mutate(inputs);
      }
    } else {
      validator.current.showMessages();
      selectProductValidatorInstance[0].current.showMessages();
      fileValidator[0].current.showMessages();
      setForceUpdate(true);
    }
  };

  function handleProductChange(data) {
    setInputs(prevProps => ({
      ...prevProps,
     'products': data
   }));
  }

  const onChangeModal = (data) => {
    setInputs((prevProps) => ({
      ...prevProps,
      ...data,
    }));
  };

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors)
    }
  }, [createErrors])

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors)
    }
  }, [updateErrors])
  

  useEffect(() => {
    if (totalQueryData) {
      setTotal(totalQueryData);
      setIsCheck(true);
    }
  }, [totalQuery.data]);


  useEffect(() => {
    if (id) {
      if (invoiceViewQuery.isFetched) {
        setInputs(invoiceViewQuery.data.data)
        setCustomer(invoiceViewQuery.data.data.customer_detail)
      }
    } else {
      setInputs(initialState)
      setCustomer({})
    }
  }, [invoiceViewQuery.data])
  
  useEffect(() => {
    if (queryParams.get('quotation_id')) {
      if (quotationDataQuery.isFetched && !quotationDataQuery.data.hasOwnProperty("status")) {
        setInputs((prevProps) => ({
          ...prevProps,
          ...quotationDataQuery.data.data,
          'quotation_id': quotation_id
        }))
        setCustomer((prevProps) => ({
          ...prevProps,
          ...quotationDataQuery.data.data.customer_detail ?? {},
        }))
      }
    }
  }, [quotationDataQuery.data])


  useEffect(() => {
    if (customer) {
      delete customer.invoice_id;
      setInputs((prevProps) => ({
        ...prevProps,
        'customer_detail': customer,
      }));    }
  }, [customer]);

  useEffect(() => {
    if (!id) {
      setInputs((prevProps) => ({
        ...prevProps,
        user_id: user_data.id,
        created_by: user_data.name,
      }));
    }
  }, [id]);

  useEffect(() => {
      setTotalData({
        ...(selectProductValidatorInstance[0].current.allValid() && !_.isEmpty(inputs.products)) && {orderProducts: inputs.products},
        inclusive_tax_discount: inputs.inclusive_tax_discount,
        ...(inputs.discount) && {discount: parseFloat(inputs.discount)},
        ...(inputs.discount_type) && {discount_type: inputs.discount_type},
        ...(inputs.shipping_method) && {shipping_method: inputs.shipping_method},
        ...(inputs.shipping_cost) && {shipping_cost: parseFloat(inputs.shipping_cost)},
        ...(inputs.coupon_amount) && {coupon_amount: parseFloat(inputs.coupon_amount)},
        });
  }, [inputs.products, inputs.shipping_cost, inputs.coupon_amount, inputs.discount, inputs.inclusive_tax_discount, inputs.shipping_method, inputs.discount_type]);

  

  return id && invoiceViewQuery.isFetching ? (
    <Loader />
  ) : (
    <div>
      <PageHeader
        title="Invoices"
        subtitle={!id ? "Create Invoice" : "Edit Invoice"}
      />
      {queryParams.get("quotation_id") ? (
        <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert" >
          The provided data for creating an invoice is incomplete.
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" ></button>
        </div>
      ) : (
        <></>
      )}
      <React.Fragment>
        <div>
          <div className="row mb-4">
            <div className="col-md-5 "></div>
            {pathname === "view" ? (
              <div className="col-md d-flex justify-content-end ">
                {inputs.order_id === null ? (
                  <>
                    {" "}
                    <button
                      type="button"
                      onClick={() => createOrder(id)}
                      className="btn border-primary px-3 text-primary me-3"
                    >
                      {
                        <UilPlusSquare
                          size="14"
                          color={lang.colors.primary}
                          className="me-2"
                        />
                      }
                      Create order
                    </button>
                    {inputs.quotation_id === null ? (
                      <Link
                        to={Paths.Invoice.pages.edit.path + id}
                        className="text-decoration-none"
                      >
                        <button
                          type="button"
                          className="btn border-primary px-3 text-primary me-3"
                        >
                          {
                            <UilPen
                              size="14"
                              color={lang.colors.primary}
                              className="me-2"
                            />
                          }
                          Edit invoice
                        </button>
                      </Link>
                    ) : null}
                  </>
                ) : null}

                <button
                  type="button"
                  className="btn border-primary px-3 text-primary me-3"
                  disabled={_.isEmpty(inputs.view_url)}
                >
                  <a
                    href={inputs.view_url}
                    target="_blank"
                    className="text-decoration-none cursor"
                  >
                    {
                      <UilPrint
                        size="14"
                        color={lang.colors.primary}
                        className="me-2"
                      />
                    }
                    Print invoice
                  </a>
                </button>
              </div>
            ) : null}
          </div>
          <form encType="multipart/form-data">
            <div className="row product-creation-form">
            <div className="col-lg-12">
                <div className="bg-white p-4 border-bottom-0 border">
                    <div className="row mb-2">
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <div className="fw-bold mb-1 d-inline">Find or <span className="text-secondary btn btn-link fw-bold p-0" onClick={() => openModel('customer')}>Create Customer</span></div>
                        </div>
                        <CustomerSearch
                          handleSelect={handleCustomerChangeSelect}
                          value={customer.customer_first_name ? _.pick(customer, ['customer_first_name', 'customer_last_name']) : null}
                          isDisabled={pathname == "view"}
                        />
                      </div>
                      <div className="col-3 ps-0">
                        <label className="mb-1">Date</label>
                        <input type="date" placeholder="Date" className="form-control form-control-md " name="invoice_date" onChange={handleInputChanges} value={inputs.invoice_date} disabled={ pathname === 'view'}/>
                        {validate(inputs.invoice_date, 'required', {title: 'Invoice Date'})}
                      </div>
                      <div className="col-3 ps-0">
                        <label className="mb-1">Due Date</label>
                        <input type="date" placeholder="Due Date" className="form-control form-control-md " name="invoice_due_date" onChange={handleInputChanges} value={inputs.invoice_due_date} disabled={ pathname === 'view'} min= {inputs.invoice_date}/>
                        {validate(inputs.invoice_due_date, 'required', {title: 'Due Date'})}
                      </div>
                    </div>
                    {Object.keys(customer).length != 0 && 
                    <>
                      <div className="d-flex justify-content-between pe-0">
                        <div className="fw-bold my-3 d-inline ">Customer</div>
                        {pathname !== 'view' &&
                          <div className="d-flex justify-content-between">
                            <button type="button" className="btn btn-link px-2" onClick={() => openModel('customer')} >
                              <span className="text-secondary fw-bold d-inline">{<UilPen size="14" className="text-secondary" />}</span>
                            </button>
                            <button type="button" className="btn btn-link px-2" onClick={() => setCustomer(false)} >
                              <span className="text-secondary fw-bold d-inline">{<UilTimes size="14" className="text-danger" />}</span>
                            </button>
                          </div>}
                      </div>
                      <div className="row">
                        <div className="d-flex justify-content-between pe-0">
                          <CustomerDetailsView data={customer} />
                        </div>
                          {validate(customer, 'schema:shipping_firstname,shipping_lastname,shipping_address,shipping_city,shipping_country,payment_firstname,payment_lastname,payment_address,payment_city,payment_country', {title: 'customer details'})}
                      </div>
                    </>}
                    
                    {validate(customer, 'exist', {title: 'customer'})}
                </div>
              </div>
                {modelOpen.customer &&
                  <ModalComponent
                    size="modal-lg"
                    show={modelOpen.customer}
                    onHide={() => closeModel('customer')}
                    title='Create a new customer'
                    body={<CustomerDetailsForm customer={customer} setCustomer={setCustomer} tempData={tempData} onHide={closeModel}/> }
                    footer={
                      <div className="d-flex justify-content-end p-0" >
                        <button type="button" className="btn btn-outline-primary me-2" data-bs-dismiss="modal" onClick={()=>closeModel('customer')}>
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-secondary px-5" form="customer-details-form">
                          {Object.keys(customer).length ? 'Edit' : 'Create'} Customer
                        </button>
                      </div>
                    }
                  />
                }

              <div className="col-lg-12 align-self-center px-4">
                <div className="row bg-white p-4 mb-4 border-top-0 border">
                  <div className="col-md-12 p-0 mb-1">
                    <div className="">
                      <CreatableSelectProducts
                        selectedProducts={inputs.products}
                        validator={selectProductValidatorInstance}
                        forceUpdate={forceUpdate}
                        onChange={handleProductChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 p-0">
                    <div className="d-flex justify-content-start">
                      {validate(inputs.products, "required", { title: "products" })}
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="d-flex justify-content-end">
                      <div className="me-4">
                        {_.isNull(inputs.shipping_cost) && (
                          <button
                            type="button"
                            className="btn p-0 fw-normal text-primary"
                            onClick={() => {
                              openModel(
                                _.isEmpty(metaData?.data?.ShippingMethod)
                                  ? "newShipping"
                                  : "shipping"
                              );
                            }}
                          >
                            <UilPlusCircle
                              size="14"
                              className="text-primary mb-1"
                            />{" "}
                            Add Shipping Method
                          </button>
                        )}

                        {modelOpen.shipping && (
                          <SelectShippingModal
                            show={modelOpen.shipping}
                            defaultValue={{
                              shipping_method: inputs.shipping_method,
                              shipping_cost: inputs.shipping_cost,
                            }}
                            data={metaData?.data?.ShippingMethod}
                            onHide={() => closeModel("shipping")}
                            onChange={onChangeModal}
                          />
                        )}
                        <ModalComponent
                          show={modelOpen.newShipping}
                          onHide={() => closeModel("newShipping")}
                          title="Add new shipping method"
                          body={
                            <ShippingCreationForm
                              callback={fetchMetaData}
                              inputs={inputs}
                              closeModal={() => closeModel("newShipping")}
                            />
                          }
                        />

                        {validate(inputs.shipping_method, "required", {
                          title: "Shipping Method",
                        })}
                      </div>
                      <div className="me-4">
                        {_.isNull(inputs.coupon_code) ? (
                          <button
                            type="button"
                            className="btn p-0 fw-normal text-primary"
                            onClick={() => {
                              _.isEmpty(metaData?.data?.Coupon)
                                ? openModel("newCoupon")
                                : openModel("coupon");
                            }}
                          >
                            <UilPlusCircle
                              size="14"
                              className="text-primary mb-1"
                            />{" "}
                            Add Coupon/Voucher
                          </button>
                        ) : null}
                        {modelOpen.coupon && (
                          <SelectCouponModal
                            show={modelOpen.coupon}
                            onHide={() => closeModel("coupon")}
                            coupon={metaData?.data?.Coupon}
                            setInputs={setInputs}
                            inputs={inputs}
                          />
                        )}
                        {modelOpen.newCoupon && (
                          <ModalComponent
                            show={modelOpen.newCoupon}
                            onHide={() => closeModel("newCoupon")}
                            title="Add new coupon/Voucher"
                            body={
                              <CouponCreationForm
                                callback={fetchMetaData}
                                inputs={inputs}
                                closeModal={() => closeModel("newCoupon")}
                              />
                            }
                          />
                        )}
                      </div>
                      <div className="">
                        {_.isNull(inputs.discount_type) ? (
                          <button
                            type="button"
                            className="btn p-0 fw-normal text-primary"
                            onClick={() => openModel("discount")}
                          >
                            <UilPlusCircle
                              size="14"
                              className="text-primary mb-1"
                            />{" "}
                            Add Discount
                          </button>
                        ) : null}
                        {modelOpen.discount && (
                          <SelectDiscountModal
                            show={modelOpen.discount}
                            onHide={() => closeModel("discount")}
                            setInputs={setInputs}
                            setTotal={setTotal}
                            inputs={inputs}
                            total={total}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pt-3 ps-0">
                    <div className="">
                      <textarea
                        placeholder="Add notes from customer"
                        rows="7"
                        cols="180"
                        name="comment"
                        className="form-control"
                        value={inputs.comment || ""}
                        onChange={(event) => handleInputChanges(event)}
                      />
                      {validate(inputs.comment, "max:500", {
                        title: "comment",
                      })}
                    </div>
                  </div>
                  <div className="col-md-4 offset-1">
                    <Total
                      shipping_cost={inputs.shipping_cost}
                      coupon_code={inputs.coupon_code}
                      coupon_amount={inputs.coupon_amount}
                      total={total.total}
                      total_tax={total.total_tax}
                      shipping_tax_amount={total.shipping_tax_amount}
                      shipping_gross_amount={total.shipping_gross_amount}
                      discount={total.discount_percent}
                      discount_type={total.discount_type}
                      discount_cost={total.discount_cost}
                      sub_total={total.sub_total}
                      pathname={pathname}
                      isCheck={isCheck}
                      openModel={openModel}
                      removeModelData={removeModelData}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <Payment
                    key={inputs.payment_method}
                    paymentMethod={metaData?.data?.PaymentMethod}
                    payment_method={inputs.payment_method}
                    payment_details={inputs.payment_details}
                    handleInputChanges={handleSelectChange}
                    modal={false}
                    validator={validator}
                    handleSelectNewPayment={handleSelectNewPayment}
                  />
                  {modelOpen.newPayment && (
                    <ModalComponent
                      show={modelOpen.newPayment}
                      onHide={() => closeModel("newPayment")}
                      title="Add new Payment Method"
                      body={
                        <PaymentCreationForm
                          callback={fetchMetaData}
                          inputs={inputs}
                          setInputs={setInputs}
                          closeModal={() => closeModel("newPayment")}
                        />
                      }
                    />
                  )}
                  <div className="col-lg-6">
                    <div className="px-4 py-3 bg-white border">
                      <div className="row">
                        <div className="col-12 mb-3">
                          <div className="fw-bold d-flex align-items-center mb-1">
                            {
                              <UilLabel
                                size="14"
                                color={lang.colors.secondary}
                                className="me-1"
                              />
                            }{" "}
                            Invoice Status
                          </div>
                          <Select
                            options={config.InvoiceStatus}
                            placeholder="Select Invoice Status"
                            closeMenuOnSelect={true}
                            value={_.filter(config.InvoiceStatus, function (obj) {
                              return obj.id === inputs.invoice_status;
                            })}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.status}
                            components={{ IndicatorSeparator: () => null }}
                            onChange={(e) =>
                              handleSelectChange("invoice_status", e.id)
                            }
                            isDisabled={pathname === "view"}
                          />
                          {validate(inputs.invoice_status, "required", {
                            title: "Invoice Status",
                          })}
                        </div>
                        <div className="col-12">
                          <div className="fw-bold mb-1">
                          Created by
                          </div>
                          <Select
                            options={userListQuery.data}
                            placeholder="Select Created by"
                            closeMenuOnSelect={true}
                            value={_.find(userListQuery?.data, function (obj) { return obj.id === inputs.user_id; }) ?? {id:inputs.user_id , name:inputs.created_by}}
                            components={{ IndicatorSeparator: () => null }}
                            onChange={(value) =>
                              handleSelectChangeUser(value)
                            }
                            formatOptionLabel={formatOptionLabelCreatedBy}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                          />
                          {validate(inputs.user_id, "required", {title: "Created by"})}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-4 mb-4 bg-white border">
                  <div className="row">
                    <div className="col-lg-12">
                      <FileAttachments
                        value={inputs.attachments}
                        onChange={(file) => handleFiles(file)}
                        validator={fileValidator}
                        isDisabled={pathname.includes("view")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-4 bg-white text-center border">
                    <div className="p-4 add-new-product">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => handleSubmit(e)}
                      >
                        {!id ? "Create Invoice" : "Edit Invoice"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    </div>
  );
}
