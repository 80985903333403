
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { Paths } from "../../../routes";
import { objectToFormData } from "../../../scripts/utils";

export function useInvoiceCreateQuery() {

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  var toastify;

  function createInvoice(data) {
    const formData = new FormData();
    objectToFormData(data, formData);
    return apiClient.post(`invoices/add`, formData)
      .then(
        (result) => result.data,
      );
  }
  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(['invoice-list']);
      queryClient.invalidateQueries(['quotations-list']);
      setTimeout(() => {
        navigate(Paths.Invoice.pages.index.path);
      }, 2000)
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors(prevProps => ({
        ...prevProps,
          [error]: message[0]
        }));
      }
    }
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }
  
  function handleError(data) {
    toast.update(toastify, {
      render: 'Something went wrong',
      type: 'error',
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
  }
  
  const mutate = useMutation((newInvoice) => createInvoice(newInvoice), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return [
    mutate,
    {
      'createErrors': errors,
    },
  ]
}