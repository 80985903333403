import React, { useEffect } from 'react'

export default function ProfileIcon(props) {

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
 
    return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
  });
  
  return(
    <div className={`bg-${props.bgColor ? props.bgColor : 'warning'} rounded-circle position-relative d-inline-block p-3 me-2 align-middle`}>
      <div className="position-absolute text-white translate-middle">
        { props.text ? props.text.charAt(0).toUpperCase() : props.children }
      </div>
    </div>
  )
}