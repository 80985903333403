import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useValidator } from "../../../utils/validator";
import Loader from "../../../components/global/Loader";
import PageHeader from "../../../layouts/PageHeader";
import { useIntegrationViewQuery } from "../../../stores/integrations/view";
import { useIntegrationUpdateQuery, useSyncOrdersQuery, useSyncStatusQuery } from "../../../stores/integrations/update";

export default function Edit() {
  const { id } = useParams();

  const [integrationUpdateQuery, { updateErrors }] = useIntegrationUpdateQuery();
  const [syncOrdersQuery, { updateOrders }] = useSyncOrdersQuery();
  const [syncStatusQuery, { updateStatus }] = useSyncStatusQuery();
  const [integrationViewQuery, integrationViewQueryData, { viewError }] = useIntegrationViewQuery(id);

  const [inputs, setInputs] = useState({});
  const [, forceUpdate] = useState();

  const [errors, setErrors] = useState({});
  const [validator, { validate }] = useValidator(errors);

  const handleInputChange = (event) => {
    event.persist();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const handleStatusChange = (e) => {
    var status = e.target.checked ? 1 : 0;
    setInputs((prevProps) => ({
      ...prevProps,
      status: status,
    }));
    syncStatusQuery.mutate({ id: id, status: status });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      integrationUpdateQuery.mutate(inputs);
    }
    validator.current.showMessages();
    forceUpdate(true);
  };

  const syncOrder = () => {
    syncOrdersQuery.mutate(id);
  };

  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    } else if (updateOrders) {
      setErrors(updateOrders);
    } else if (updateStatus) {
      setErrors(updateStatus);
    }
  }, [updateErrors, updateOrders, updateStatus]);

  useEffect(() => {
    if (integrationViewQuery.isFetched) {
      setInputs(integrationViewQueryData.data);
    }
  }, [integrationViewQuery.data]);


  return (
    <div>
      {integrationViewQuery.isFetching ? (
        <Loader />
      ) : viewError ? (
        viewError
      ) : (
        <>
          <PageHeader title="integrations" subtitle={inputs.integration_name} />

          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-lg-10">
                <section className="p-5 border bg-white">
                  <h4 className="mb-4">Integration Details</h4>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <label className="mb-2" htmlFor="integration_name">
                        Integration Name
                      </label>
                      <input
                        type="text"
                        className="form-control not-allowed"
                        id="integration_name"
                        name="integration_name"
                        value={inputs?.integration_name || ''}
                        placeholder="Integration Name"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="mb-2" htmlFor="integration_username">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="integration_username"
                        name="integration_username"
                        value={inputs?.integration_username || ''}
                        placeholder="Username"
                        onChange={handleInputChange}
                      />
                      {validate(inputs.integration_username, "required", { title: "integration_username" })}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-6">
                      <label className="mb-2" htmlFor="api_key">
                        API Key
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="api_key"
                        name="api_key"
                        value={inputs.api_key || ''}
                        placeholder="API Key"
                        onChange={handleInputChange}
                      />
                      {validate(inputs.api_key, "required", { title: "api_key" })}
                    </div>
                    <div className="col-md-6">
                      <label className="mb-2" htmlFor="api_url">
                        Integration Url
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="api_url"
                        name="api_url"
                        value={inputs.api_url || ''}
                        placeholder="Integration URL"
                        onChange={handleInputChange}
                        readOnly={true}
                      />
                    </div>
                  </div>
                   <div className="row">
                    <div className="col-lg-6">
                      <div className="d-grid">
                        <button className="btn btn-secondary px-4">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div> 
                </section>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-lg-10">
              <section className="py-5 border-bottom">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h6>Enable sync orders</h6>
                    <p className="small text-gray-500 m-0">
                      Push all latest order from the innoventory to Track-POD.
                    </p>
                  </div>
                  <div className="col-md-auto">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="status"
                        checked={inputs.status}
                        onChange={handleStatusChange}
                      />
                      <label className="form-check-label"></label>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-5 border-bottom">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h6>Sync orders to Track-POD</h6>
                    <p className="small text-gray-500 m-0">
                      Orders with processing and shipped status will be synced
                      to Track-POD
                    </p>
                  </div>
                  <div className="col-md-auto">
                    {inputs.status == 1 ? (
                      <button
                        className="btn btn-secondary px-4"
                        onClick={syncOrder}
                      >
                        Sync Orders
                      </button>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        key="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {"Integration is disable, enable it to fetch order"}
                          </Tooltip>
                        }
                      >
                        <div>
                          <button
                            className="btn btn-outline-light px-4"
                            disabled
                          >
                            Sync Orders
                          </button>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
