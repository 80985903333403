import React, { Component } from 'react';
import { ResponsiveContainer, Legend, Tooltip, PieChart, Pie, Cell } from 'recharts';

import colors from "../../../lang/en/Theme/Colors";

import CardComponent from '../../layouts/CardLayout';
import NoDataFound from '../global/NoDataFound';
import { abbreviateNumber } from '../../../scripts/utils';
import _ from 'lodash';

export default function PieChartBlock(props) {
  const tickFormatter = (value) => {
    const limit = 20; // put your maximum character
    if (value.length < limit) return value;
    return value.substring(0, limit) + '...';
  }

  const CustomTooltip = ({ active, payload, label }) => {

    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-light p-2 ">
          <p className="text-secondary mb-0">{`${payload[0].name} : ${abbreviateNumber(payload[0].value, props.withCurrency)}`}</p>
        </div>
      );
    }
    return null;
  };
  return (

    <CardComponent title={props.title} footer={props.footer}>
      {/* <div> */}
        {
          !_.isEmpty (props.data) ? (
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie data={props.data}
                  cx="50%"
                  cy="50%"
                  minAngle={10}
                  innerRadius={60}
                  outerRadius={80}
                  nameKey="key"
                  dataKey="value"
                  isAnimationActive={false}
                  fill={colors.colors.chartColors[0]} 
                  legendType="square"
                  paddingAngle={1}
                  label= {({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    value,
                    index
                  }) => {
                    const RADIAN = Math.PI / 180;
                    const radius = 25 + innerRadius + (outerRadius - innerRadius);
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);
                    return (
                      <text
                        x={x}
                        y={y}
                        fill={colors.colors.chartColors[index]}
                        textAnchor={x > cx ? "start" : "end"}
                        dominantBaseline="central"
                      >
                        {abbreviateNumber(value, props.withCurrency)}
                      </text>
                    );
                  }}


                  tickFormatter={tickFormatter} >
                  {
                    props.data.map((entry, index)  =>
                    (
                      <Cell key={`cell-${index.counts}`} fill={colors.colors.chartColors[index]}  />
                    ))
                  }
                </Pie>
                <Tooltip content={CustomTooltip} wrapperStyle={{ width: '20%' }}/>
                <Legend iconSize={15} layout="vertical" verticalAlign="middle" align="right" />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            // <div className="h-100">
              <NoDataFound />
            // </div>
          )
        }
      {/* </div> */}
    </CardComponent>
  );
}
