import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";

export function useGetProductsQuery() {
  const queryClient = useQueryClient();
  const [success, setSuccess] = useState(false);

  function fetchProducts(data) {
    if (data.type == 1) {
      return apiClient
        .get(`channels/amazon/product`, { params: { channel_id: data.id } })
        .then((result) => result.data);
    }
    if (data.type == 3) {
      return apiClient
        .get(`channels/opencart/product`, { params: { channel_id: data.id } })
        .then((result) => result.data);
    }
    if (data.type == 2) {
      return apiClient
        .get(`channels/ebay/product`, { params: { channel_id: data.id } })
        .then((result) => result.data);
    }
  }

  function handleSuccess(data) {
    notify(data.type, data.message);
    if (data.status) {
      queryClient.invalidateQueries(["channel-list"]);
      setSuccess(true);
    }
  }

  const mutate = useMutation((data) => fetchProducts(data), {
    retry: false,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      fetched: success,
    },
  ];
}
