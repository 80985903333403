import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../header";

var defaultState = {
  pageNumber: 1,
  limit: 15,
};

function getList(signal, queryKey) {
  const [_key,{ pageNumber, sort, limit, isPaginated}] = queryKey;
  return apiClient
    .get(`/tax/get`, {
      params: {
        page: pageNumber,
        limit: limit,
      },
      signal,
    })
    .then((result) => 
    result.data);
}

export function useTaxListQuery({
  enabled = true,
  defaultValue = {},
} = {}) {
  defaultState = { ...defaultState, ...defaultValue };

  const [pageNumber, setPageNumber] = useState(defaultState.pageNumber);
  const [limit, setLimit] = useState(defaultState.limit);

  const query = useQuery( [ "tax-list", { pageNumber, limit,} ],
    ({ signal, queryKey }) => getList(signal, queryKey),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabled,
    }
  );

  return [
    query,
    {
      limit: limit,
      setLimit: setLimit,
      setPageNumber: setPageNumber,
    },
  ];
}


export function useTaxCreateQuery() {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  var toastify;

  function create(data) {
    return apiClient.post(`tax/add`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["tax-list"]);
      queryClient.invalidateQueries(["product-metadata"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((newTax) => create(newTax), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      createErrors: errors,
    },
  ];
}

export function useTaxUpdateQuery() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  var toastify;

  function update(data) {
    return apiClient.post(`tax/edit`, data).then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["tax-list"]);
      queryClient.invalidateQueries(["product-metadata"]);
    } else {
      for (let [error, message] of Object.entries(data.field_error)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((updateTax) => update(updateTax), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return [
    mutate,
    {
      updateErrors: errors,
    },
  ];
}

export function useTaxDeleteQuery() {
  const queryClient = useQueryClient();

  var toastify;
  function remove(id) {
    return apiClient
      .post(`tax/delete`, { tax_id: id })
      .then((result) => result.data);
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, {
      render: data.message,
      type: data.type,
      isLoading: false,
      autoClose: true,
      closeButton: true,
    });
    if (data.status) {
      queryClient.invalidateQueries(["tax-list"]);
      queryClient.invalidateQueries(["product-metadata"]);
    }
  }

  const mutate = useMutation((id) => remove(id), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
  });

  return mutate;
}
