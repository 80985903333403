import React, { useState } from 'react';

import Add from './Add';
import { Paths } from '../../../../routes';
import Success from '../../../components/common/Success';
import PageHeader from "../../../layouts/PageHeader";
import StepperComponent from '../../../components/global/Stepper';


export default function Stepper(){

  const[step, setStep] = useState(1);
  const[id, setId] = useState(null);

  function showStep(step){
    switch(step){
      case 1:
        return <Add step={step} setStep={setStep} setId={setId} />
      case 2:
        return <Success name='TrackPod' link= {Paths.Integration.pages.EditTrackpod.path + id} option='Integration'/> 
    }
  }

  return(
    <div>
      <PageHeader title="Integrations" subtitle="TrackPod" />
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-4">
            <StepperComponent steps=
            {[{
                title: 'Add an Integration',
                onClick: (e) => {
                  e.preventDefault()
                  setStep(1)
                }
              }]} 
              activeStep={step - 1}
            />
          </div>
        </div>
      </div>
      {showStep(step)}
    </div>
  )
}
 

