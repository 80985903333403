import React, { useState, useEffect } from "react";
import { UilBox } from "@iconscout/react-unicons";
import Select from "react-select";
import _ from "lodash";

import { useValidator } from "../../utils/validator";
import { preventNonNumeric } from "../../../scripts/utils";

import { useProductStockUpdateQuery } from "../../stores/products/updateStock";


export default function StockUpdateProduct(props) {

  const { data, onUpdate } = props

  const [productStockUpdateQuery, { updateErrors }] = useProductStockUpdateQuery();
  
  const [inputs, setInputs] = useState({
    product_id: data?.product_id,
    product_name: data?.product_name,
    warehouse: null,
    check:true
  });
  
  const [errors, setErrors] = useState({});

  const [, forceUpdate] = useState();
  const [validator, { validate }] = useValidator(errors);

  const handleInputChange = (name, value) => {
    setInputs((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()){
      productStockUpdateQuery.mutate(inputs);
      onUpdate();
    } 
    validator.current.showMessages();
    forceUpdate(true)
  };
  
  useEffect(() => {
    if (updateErrors) {
      setErrors(updateErrors);
    }
  }, [updateErrors]);

  return (
    <React.Fragment>
      <div>
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="mb-3">
                <Select
                  closeMenuOnSelect={true}
                  isMulti={false}
                  isSearchable={false}
                  options={data?.warehouses}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  placeholder="Warehouses"
                  onChange={(target) => handleInputChange('warehouse', target.id )}
                  />
               {validate(inputs.warehouse,'required', {title: 'warehouses'})}
            </div>
          </div>
          <div className="col-md-12">
            
            <div className="fw-bold mb-3">
              {inputs.warehouse ? `Current Warehouse Stock : ${_.find(data.warehouses, ['id', inputs.warehouse])?.stock}` : `Total Stock :  ${_.sumBy(data.warehouses, 'stock')}`} 
            </div> 
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <div className="input-group">
                <span className="input-group-text"><UilBox size="16" className="text-secondary" /></span>
                <input
                  type="number"
                  className="form-control"
                  name="stock"
                  min="1"
                  value={inputs.stock}
                  onKeyPress={(e) => preventNonNumeric(e)}
                  onChange={({ target }) => handleInputChange("stock", target.value)}
                />
              </div>
              {validate(inputs.stock,'required', {title: 'stock'})}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <label className="fw-bold ps-1">
              <input
                type="checkbox"
                name="check"
                className="form-check-input me-2"
                checked={inputs.check}
                onChange={({ target }) => handleInputChange("check", target.checked)}
              />
              Add to current stock
            </label>
          </div>

          <div className="col-md-12">
            <button onClick={handleSubmit} className="btn btn-secondary w-'100">
                Update Stock
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
