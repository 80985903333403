import React, { useState } from "react";
import _ from 'lodash';

import CardComponent from '../../layouts/CardLayout';
import { abbreviateNumber } from "../../../scripts/utils";

export default function ProductDetailsBlock(props) {
  return (
    <>
      <CardComponent title={props.title} footer={props.footer}>
        <div className='d-flex justify-content-between text-danger'>
          <p className=' '> Low Stock Items </p>
          <h4 className=''>{abbreviateNumber(props.data.low_stock_items, props.withCurrency)}</h4>
        </div>
        <div className='d-flex justify-content-between'>
          <p className=''> All Categories </p>
          <h4 className=''>{abbreviateNumber(props.data.all_category, props.withCurrency)}</h4>
        </div>
        <div className='d-flex justify-content-between'>
          <p className=''> All Products </p>
          <h4 className=''>{abbreviateNumber(props.data.all_products,props.withCurrency)}</h4>
        </div>
      </CardComponent>
    </>
  );
}
