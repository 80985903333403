
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { objectToFormData } from "../../../scripts/utils";
import { Paths } from "../../../routes";
import { toast } from "react-toastify";

function quotationToInvoice(quotationId) {
  return apiClient.get(`quotations/create/invoice`, {
    params: {id: quotationId}
  })
  .then(
    (result) => {
      result.data.quotationId = quotationId;
      result.data.redirect = Paths.Invoice.pages.create.path + "?quotation_id=" + quotationId;
      return result.data;
    }
  );
}

function quotationToOrder(quotationId) {
  return apiClient.get(`quotations/create/order`, {
    params: {id: quotationId}
  })
  .then(
    (result) => {
      result.data.quotationId = quotationId;
      result.data.redirect = Paths.Order.pages.create.path + "?quotation_id=" + quotationId;
      return result.data;
    } 
  );
}

export function useQuotationConversion() {
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
	const navigate = useNavigate();

  var toastify;
  
  function handleProcessing(data) {
    toastify = toast.loading("Please wait...")
  }
  
  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true})
    if (data.status) {
      queryClient.invalidateQueries(['order-list']);
      queryClient.invalidateQueries(['quotations-list']);
      queryClient.invalidateQueries(['invoice-list']);
    } else {
      if (data.errors) {
        setTimeout(() => {
          navigate(data.redirect);
        }, 2000)
        // for (let [error, message] of Object.entries(data.errors)) {
        //   setErrors(prevProps => ({
        //   ...prevProps,
        //     [error]: message[0]
        //   }));
        // }        
      }
    }
  }

  function handleError(data) {
    toast.update(toastify, { render: 'Oops something went wrong!', type: 'error', isLoading: false, autoClose: true, closeButton: true})
  }

  const convertToInvoice =  useMutation((quotationId) => quotationToInvoice(quotationId), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const convertToOrder =  useMutation((quotationId) => quotationToOrder(quotationId), {
    retry: false,
    onMutate: handleProcessing,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return [
    {
      convertToInvoice,
      convertToOrder,
    },
  ]
}