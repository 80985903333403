import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  UilReceiptAlt,
  UilPhoneAlt,
  UilAngleDown,
  UilEye,
  UilEllipsisH,
  UilDownloadAlt,
  UilEnvelopeAlt
} from "@iconscout/react-unicons";
import _ from "lodash";
import moment from "moment";

import {
  numberFormat,
  formatNumber,
  dateTimeFormat,
} from "../../../scripts/utils";
import PageHeader from "../../layouts/PageHeader";
import Loader from "../../components/global/Loader";
import lang from "../../../lang/en/Theme/Colors";
import config from "../../../configurations";

import { useCreateInvoiceQuery } from "../../stores/invoices/createInvoice";
import { useUpdateStatusQuery } from "../../stores/orders/updateStatus";
import { MetaDataContext } from "../../../web/context/metaData";
import {
  useCustomerOrderListQuery,
  useCustomerViewQuery,
} from "../../stores/customer/view";
import { Paths } from "../../../routes";
import configurations from "../../../configurations";
import ErrorPage from "../../components/errors/ErrorPage";
import DataTable from "../../components/common/DataTable";
import { useCustomerOrderHistoryQuery } from "../../stores/customer/orderHistory";

export default function CustomerView() {
  const { id } = useParams();
  const search = useLocation().search;
  const showSamples = new URLSearchParams(search).get('showSamples');
  const { data: preferences } = useContext(MetaDataContext);

  const [currency, setCurrency] = useState(preferences.currency_symbol);

  const [
    customerOrderHistoryQuery,
    customerOrderHistoryQueryData,
    {
      searchParams,
      setSearchParams,
      setPageNumber,
      setSort,
      filters,
      setFilters,
    },
  ] = useCustomerOrderHistoryQuery({ id: id, showSamples: showSamples ?? "false" });

  const [customerViewQuery, customerViewQueryData, { viewError }] = useCustomerViewQuery(id);

  const columns = [
    {
      id: "channel_name",
      name: "Channel Name",
      selector: (row) => row.channel_name,
    },
    {
      id: "order_id",
      name: "Order Id",
      cell: (row) => (
        <Link to={Paths.Order.pages.view.path + row.id}> #{row.order_id} </Link>
      ),
    },
    {
      id: "status",
      name: "Status",
      cell: (row) => (
        <div
          className={`badge rounded-pill ${
            configurations.OrderStatus[row.order_status].class
          }`}
        >
          {configurations.OrderStatus[row.order_status].status}
        </div>
      ),
    },
    {
      id: "order_placed",
      name: "Order Placed",
      cell: (row) => dateTimeFormat(row.placed_at, "long"),
    },
    {
      id: "order_items",
      name: "Order Items",
      cell: (row) => (
        <div>
          {" "}
          {row.order_products.slice(0, 2).map((product, index) => (
            <div className="d-flex justify-content-between" key={index}>
              <div className="text-truncate w-75">
                {product.name} {product.value ? "-" + product.value : ""}
              </div>
              <div>x{product.quantity}</div>
            </div>
          ))}
          {row.order_products.length <= 1 &&
          !_.isNull(row.addon) &&
          !_.isEmpty(row.addon)
            ? JSON.parse(row.addon).map((item, index) =>
                row.order_products.length != 0 ? (
                  row.order_products.length > index ? (
                    <div className="d-flex justify-content-between" key={index}>
                      <div className="text-truncate w-75">
                        {" "}
                        {item.product_name}{" "}
                      </div>
                      <div>x{item.quantity}</div>
                    </div>
                  ) : null
                ) : index <= 1 ? (
                  <div className="d-flex justify-content-between" key={index}>
                    <div className="text-truncate w-75">
                      {" "}
                      {item.product_name}{" "}
                    </div>
                    <div>x{item.quantity}</div>
                  </div>
                ) : null
              )
            : null}
          {row.order_products.length +
            (!_.isNull(row.addon) && !_.isEmpty(row.addon)
              ? JSON.parse(row.addon).length
              : 0) >
            2 && (
            <div className="mb-2">
              <Link to={Paths.Order.pages.view.path + row.id}>
                +
                {row.order_products.length +
                  (!_.isNull(row.addon) && !_.isEmpty(row.addon)
                    ? JSON.parse(row.addon).length
                    : 0) -
                  2}{" "}
                more items
              </Link>
            </div>
          )}
        </div>
      ),
    },
    {
      id: "order_total",
      name: "Order Total",
      cell: (row) => preferences.currency_symbol + row.order_total,
    },
  ];

  return (
    <>
      {customerOrderHistoryQuery.isLoading ? (
        <Loader />
      ) : viewError ? (
        viewError
      ) : (
        <>
          {customerOrderHistoryQuery.isError && (
            <ErrorPage innerPage={false} error="500" />
          )}

          <PageHeader
            title="Customer Order History"
            subtitle="Customer Orders"
          />

          <div className="card py-4 mb-5 px-3">
            <h5 className="mb-3">Customer Details</h5>
            {customerViewQueryData?.customer && (
              <div className="mb-4">
                {!_.isEmpty(
                  customerViewQueryData.customer.customer_first_name
                ) && (
                  <div>
                    {customerViewQueryData.customer.customer_first_name}{" "}
                    {customerViewQueryData.customer.customer_last_name}
                  </div>
                )}
                {!_.isEmpty(customerViewQueryData.customer.email) && (
                  <a
                    href={"mailto: " + customerViewQueryData.customer.email}
                    className="fw-bold mb-3 d-inline text-decoration-none text-info"
                  >
                    <div className="text-info">
                      {" "}
                      {
                        <UilEnvelopeAlt size="14" color={lang.colors.teal} />
                      }{" "}
                      <span className="ms-2">
                        {" "}
                        {customerViewQueryData.customer.email}{" "}
                      </span>
                    </div>
                  </a>
                )}
                {!_.isEmpty(customerViewQueryData.customer.telephone) && (
                  <a
                    href={"tel: " + customerViewQueryData.customer.telephone}
                    className="f1w-bold mb-3 d-inline text-decoration-none text-success"
                  >
                    <div>
                      {<UilPhoneAlt size="14" color={lang.colors.success} />}{" "}
                      <span className="ms-2">
                        {" "}
                        {customerViewQueryData.customer.telephone}
                      </span>
                    </div>
                  </a>
                )}
              </div>
            )}

            
          </div>
          <DataTable
            data={customerOrderHistoryQueryData?.data}
            columns={columns}
            selectable={true}
            isLoading={customerOrderHistoryQuery.isFetching}
            pagination={true}
            paginationMeta={customerOrderHistoryQueryData?.meta}
            onChangePage={(page) => setPageNumber(page)}
            currentPage={customerOrderHistoryQueryData?.meta?.current_page}
            totalPage={customerOrderHistoryQueryData?.meta?.last_page}
            perPage={customerOrderHistoryQueryData?.meta?.per_page}
          />
        </>
      )}
    </>
  );
}
