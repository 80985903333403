
import { useQuery } from "@tanstack/react-query";
import apiClient from "../header";

export function useMetaData() {
  function handleSuccess(data) {
    if (!data.taxes.find(tax => tax.id == 0)) {
      data.taxes.splice(0, 0, {
        "id": 0,
        "tax_name": "Tax not applicable",
        "tax_amount": "0.00",
      })
    }
  }
  
  function getMetaData(signal) {    
    const query =  apiClient.get(`inventory/metadata`, { 
      signal,
    }).then(
      (result) => result.data,
    );
    return query;
  }

  return useQuery(['product-metadata', {}], 
  ({ signal }) => getMetaData(signal), 
  {
    retry: 0,
    staleTime: Infinity,
    keepPreviousData : true,
    refetchOnWindowFocus: false,
    onSuccess:handleSuccess
  });
}
