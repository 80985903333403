import React, { useState, useRef } from "react";

import api from "../../../api";
import { notify } from "../../../scripts/notify"
import SimpleReactValidator from 'simple-react-validator';


export default function ManufactureCreationForm(props) {
  const [inputs, setInputs] = useState({
    id: (props.inputs && props.inputs.id) || "",
    name: (props.inputs && props.inputs.name) || "",
  });

  const [errors, setErrors] = useState({});
  const [, formError]=useState(false);

  const validator = useRef(new SimpleReactValidator());

  const handleInputChange = event => {
    event.preventDefault();
    setErrors({});
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
    //  console.log(event.target.name,event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.manufacturer
        .add(inputs)
        .then((response) => {
          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInputs({
              id: "",
              name: "",
            });
            props.callback();
            props.closeModal();
          } else {
            for (let [field, message] of Object.entries(response.data.errors)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(error => {
          // notify(error);
        });
    } else {
      // setErrors((prevProps) => ({
      //   ...prevProps,
      //   ...validator.current.errorMessages,
      // }));
      formError(true);
      validator.current.showMessages();
    }
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.manufacturer
        .edit(inputs)
        .then((response) => {
          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInputs({
              id: "",
              name: "",
            });
            props.closeModal();
          } else {
            for (let [field, message] of Object.entries(response.data.errors)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          // notify();
        });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };

  return (
    <div>
      <section className="">
        <form  onSubmit={props.isEdit ? (handleEditSubmit) : (handleSubmit) }>
          <div className="row mb-4">
            <div className="col-lg-12">
              <label className="fw-bold me-3 ">Manufacturer Name</label>
              <div className="">
                <input
                  type="text"
                  name="name"
                  placeholder="Manufacturer Name"
                  onChange={event => handleInputChange(event)}
                  className="form-control "
                  value={inputs.name}
                  ref={props.refName}
                />
                {validator.current.message("name", inputs.name, "required|min:3|max:100", {className: "small text-danger"})}
                <div className="small text-danger">{errors.name}</div>
              </div>
            </div>
           
          </div>
           <div className="row">
            <div className="col-xxl-12">
              <button type="submit" className="btn btn-secondary w-100" >
              { props.isEdit ? "Save changes" : "Add manufacturer" } 
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
