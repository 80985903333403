import React, { useState } from "react";

import Add from "./Add";
import GetProducts from "../GetProducts";
import Success from "../../../components/common/Success";
import PageHeader from "../../../layouts/PageHeader";
import StepperComponent from "../../../components/global/Stepper";
import AmazonAuthoriseAccount from "./AuthoriseAccount";
import GetOrders from "../GetOrders";
import { Paths } from "../../../../routes";

export default function Stepper() {


  const[step, setStep] = useState(1);
  const[id, setId] = useState(null);

  function showStep(step){
    switch(step){
      case 1:
        return <Add step={step} setStep={setStep} setId={setId} />;
      case 2:
        return <GetProducts step={step} setStep={setStep} id={id} name='Amazon' type={1} />; 
      case 3:
        return <GetOrders step={step} setStep={setStep} id={id} name='Amazon' type={1} />;
      case 4:
        return <Success name='Amazon' link={Paths.Order.pages.index.path} option="orders"/>
    }
  }

  return(
    <div>
      <PageHeader title="Channels" subtitle="Amazon" />
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-4">
            <StepperComponent steps={ 
              [{
                title: 'Add a Channel',
                onClick: (e) => {
                  e.preventDefault()
                  setStep(1)
                }
              },
              // {
              //   title: 'Authorize Amazon sellers account',
              //   onClick: (e) => {
              //     e.preventDefault()
              //     setStep(2)
              //   }
              // },  
              {
                title: 'Fetch Amazon Products',
                onClick: (e) => {
                  e.preventDefault()
                  setStep(2)
                }
              }, 
              {
                title: 'Fetch Amazon Orders',
                onClick: (e) => {
                  e.preventDefault()
                  setStep(3)
                }
                
              } ]} 
              activeStep={step - 1}
            />

          </div>
        </div>
      </div>
      {showStep(step)}
    </div>
  )
}
