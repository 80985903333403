import React, { useState, useRef } from "react";

import api from "../../../api";
import { notify } from "../../../scripts/notify"
import SimpleReactValidator from 'simple-react-validator';


export default function UnitCreationForm(props) {
  const [inputs, setInputs] = useState({
    id: (props.inputs && props.inputs.id) || "",
    name: (props.inputs && props.inputs.name) || "",
    units: (props.inputs && props.inputs.units) || "",
  });

  const [errors, setErrors] = useState({});
  const [, formError]=useState(false);

  const validator = useRef(new SimpleReactValidator());

  const handleInputChange = event => {
    setErrors({});
    event.preventDefault();
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
  
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.units
        .add(inputs)
        .then((response) => {
          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInputs({
              id: "",
              name: "",
              units: "",
            });
            props.callback();
            props.closeModal();
          } else {
            for (let [field, message] of Object.entries(response.data.errors)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          notify();
        });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.units
        .edit(inputs)
        .then((response) => {
          // console.log(response.data);

          if (response.data.status === 1) {
            notify(response.data.type, response.data.message);
            setInputs({
              id: "",
              name: "",
              units: "",
            });
            props.callback();
            props.closeModal();
          } else {
            for (let [field, message] of Object.entries(response.data.errors)) {
              setErrors((prevProps) => ({
                ...prevProps,
                [field]: message[0]
              }));
            }
          }
        })
        .catch(function () {
          notify();
        });
    } else {
      // setErrors((prevProps) => ({
      //   ...prevProps,
      //   ...validator.current.errorMessages,
      // }));
      formError(true);
      validator.current.showMessages();
    }
  };

  
  return (
    <div>
      <section className="">
        <form onSubmit={props.isEdit ? (handleEditSubmit) : (handleSubmit) }>
          <div className="row mb-3">
            <div className="col-6">
              <div className="">
                <label className="fw-bold me-3 ">Unit Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Unit Name"
                  onChange={event => handleInputChange(event)}
                  className="form-control "
                  value={inputs.name}
                  ref={props.refName}
                />
                {validator.current.message("name", inputs.name, "required|min:1|max:15", { className: "small text-danger" })}
                <div className="small text-danger">{errors.name}</div>
              </div>
            </div>
            <div className="col-6">
              <label className="fw-bold me-3 ">Unit Type</label>
              <div className="">
                <input
                  type="text"
                  name="units"
                  placeholder="Type"
                  onChange={event => handleInputChange(event)}
                  className="form-control "
                  value={inputs.units}
                />
                {validator.current.message("units", inputs.units, "required|min:1|max:50", { className: "small text-danger" })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <button type="submit" className="btn btn-secondary w-100" >
              { props.isEdit ? "Save changes" : "Add Units" } 
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
