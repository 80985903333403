import React  from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { UilEllipsisH, UilTrashAlt, UilPen,UilBox } from '@iconscout/react-unicons';

import { Paths } from '../../../routes';
import configurations from '../../../configurations';

import { useCategoryListQuery } from '../../stores/categories/list';
import { useDeleteCategoryQuery } from '../../stores/categories/delete';

import PageHeader from '../../layouts/PageHeader'
import DataTable from '../../components/common/DataTable';
import ActionBar from '../../components/global/ActionBar';
import ErrorPage from '../../components/errors/ErrorPage';
import _ from 'lodash';


export default function Listing() {
 
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  // const [categoryQuery, {searchParams, setSearchParams}, {setPageNumber}, {setSort}] = useCategoryListQuery();

    const [ categoryQuery, { searchParams, setSearchParams, setPageNumber, setSort }, ] = useCategoryListQuery();
  const deleteCategory = useDeleteCategoryQuery();
    
  function handleCancel() {
    queryClient.cancelQueries(['category-list'])
  }

  function handleRefetch() {
    categoryQuery.refetch()
  } 
  
  function handleDelete(id) {
    deleteCategory.mutate(id);
  } 

  function handleEdit(id) {
    navigate(Paths.Category.pages.edit.path + id)
  } 

  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: 'category_name',
      name: 'Category Name',
      selector: row => !_.isNull(row.parent) ? (row.parent.name + ' > '+ row.name) : row.name,
      width: '500px',
    },
    {
      id: 'stock',
      name: 'Stock',
      selector: row => row.stock,
    },
    {
      name: '',
      id: 'action',
      cell: row => (
        <ul className="dropdown text-end mb-0">
          <button className="btn btn-link" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" >
            <span><UilEllipsisH size="16" className="text-secondary"/></span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end align-items-center" aria-labelledby="dropdownMenu" >
            <li className="fw-bold cursor dropdown-item" onClick={() => handleEdit(row.id)} >
              <span><UilPen size="14" className="text-secondary me-1 mb-1"/>Edit Category</span>
            </li>
            <li className="fw-bold cursor dropdown-item" onClick={() => handleDelete(row.id)} >
              <span><UilTrashAlt size="14" className="text-danger me-1 mb-1"/>Delete Category</span>
            </li>
          </ul>
        </ul>
      ),
    }, 
  ]
  
  return (
    <>
      {categoryQuery.isError && <ErrorPage innerPage={false} error='500'/> }

      <PageHeader title='Categories' subtitle='All Categories' component={<Link to={Paths.Category.pages.create.path} className="btn btn-secondary">Create Category</Link>} />
      
      <ActionBar searchParams={searchParams} setSearchParams={handleSearch} sortOptions={configurations.Sort} setSort={setSort} placeholder='Search by Category name'/>
      
      <DataTable 
        data={categoryQuery?.data?.data} 
        columns={columns} 
        isLoading={categoryQuery.isFetching}
        pagination={true}
        paginationMeta={categoryQuery?.data?.meta}
        onChangePage={page=> setPageNumber(page)}
        currentPage={categoryQuery?.data?.meta?.current_page}
        totalPage={categoryQuery?.data?.meta?.last_page}
        perPage={categoryQuery?.data?.meta?.per_page}
      />
    </>
  )  

}