import React, { useState, useEffect } from "react";
import { UilCodeBranch } from "@iconscout/react-unicons";
import { useProductListQuery } from "../../../stores/products/list";
import { useLinkProductQuery } from "../../../stores/channel/productMapping";
import ActionBar from "../../../components/global/ActionBar";
import DataTable from "../../../components/common/DataTable";
import ErrorPage from "../../../components/errors/ErrorPage";


export default function Mapping(props) {

  const [checkedProduct, setCheckedProduct] = useState({
    system_product_id: null,
    api_product_id: [],
  });

  const [productQuery, productQueryData, {searchParams, setSearchParams, setPageNumber}] = useProductListQuery({defaultValue: {linked: true}});
  const [linkProductQuery, { linkProduct }] = useLinkProductQuery();

  const handleInputChange = (system_product_id, channel_product_id) => {
    setCheckedProduct({
      system_product_id: system_product_id,
      api_product_ids: props.isBulk ? (props.bulkProducts.map((products) => products.product_id) ): [{ product_id: channel_product_id }],
    });
  };

  const linkProducts = (id) => {
    linkProductQuery.mutate(id ? ({ api_product_ids: id }):  checkedProduct);
  };

  function handleSearch(params) {
    setPageNumber(1);
    setSearchParams(params)
  }

  const columns = [
    {
      id: 'check',
      body: { width: "100px" },
      cell: (row) => (
        <input
          className="form-check-input"
          type="checkbox"
          name={row.id}
          checked={row.id == checkedProduct.system_product_id ? true : false}
          onChange={() =>
            handleInputChange(row.id, props.productMap.channel_product_id)
          }
        />
      ),
    },
    {
      id: "product_name",
      selector: (row) => row.product_name,
    },
    {
      id: "sku",
      selector: (row) => row.sku,
    },
    {
      id: "action",
      body: { width: "100px" },
      cell: (row) => (
        <>
          <div className="text-info text-truncate">
            <UilCodeBranch width="16" />
            {row.channel_products.length > 2
              ? `(Linked with ${row.channel_products.length} Products)`
              : row.channel_products
                  .map((products) => products.product_name)
                  .join(", ")}
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (linkProduct) {
      props.closeModal();
    }
  }, [linkProduct]);
  
  return (
    <>
      {productQuery.isError && <ErrorPage innerPage={false} error="500" />}

      {!props.isBulk ? (
        <div className="alert-success p-3 mb-2">
          <div className="row">
            <div className="col-md">
              Product linked with - {props.productMap.system_product_name}
            </div>
            <div className="col-md-2">
              <a
                className="cursor"
                onClick={() =>
                  linkProducts(props.productMap.channel_product_id)
                }
              >
                Unlink product
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="alert-success p-3 mb-2">
          Products to link -{" "}
          {props.bulkProducts.map((product) => product.product_name).join(", ")}
        </div>
      )}
      <div className="row">
        <div className="col mb-3 mb-md-0">
          <ActionBar
            searchParams={searchParams}
            setSearchParams={handleSearch}
            placeholder="Search by Product name"
          />
        </div>
      </div>

      <DataTable
        data={productQueryData?.data}
        columns={columns}
        isLoading={productQuery.isFetching}
        pagination={true}
        paginationMeta={productQueryData?.meta}
        onChangePage={(page) => setPageNumber(page)}
        currentPage={productQueryData?.meta?.current_page}
        totalPage={productQueryData?.meta?.last_page}
        perPage={productQueryData?.meta?.per_page}
      />
      <div className="row">
        <div className="col text-end">
          <button
            className="btn btn-link px-5"
            onClick={() => props.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-secondary px-5"
            onClick={() => linkProducts()}
          >
            Complete Linking
          </button>
        </div>
      </div>
    </>
  );
}
