import React, {  useState, useRef } from "react";
import Select from 'react-select';

import api from "../../../api";
import { notify } from "../../../scripts/notify"
import SimpleReactValidator from 'simple-react-validator';
import CustomSelect from "../../../refactoring/components/global/CustomSelect";
import { checkFloat } from "../../../scripts/utils";


export default function TaxCreationForm(props) {
  const [inputs, setInputs] = useState({
    tax_id: (props.inputs && props.inputs.tax_id) || "",
    tax_name: (props.inputs && props.inputs.tax_name) || "",
    tax_amount: (props.inputs && props.inputs.tax_amount) || "",
    tax_type: (props.inputs && props.inputs.tax_type.value) || "",
    tax_application: (props.inputs && props.inputs.tax_application.value) || "",
  });

  const [selectedData, setSelectedData] = useState({
    tax_type: (props.inputs && props.inputs.tax_type) || null,
    tax_application: (props.inputs && props.inputs.tax_application) || null
  })

  const [errors, setErrors] = useState({});
  const [, formError]=useState(false);

  const tax_type = [
    {label: 'VAT', value: 'vat', name:'tax_type'},
    {label: 'GST', value: 'gst', name:'tax_type'},
  ];
  
  const tax_application = [
    {label: 'Inclusive', value: 'inclusive', name:'tax_application'},
    {label: 'Exclusive', value: 'exclusive', name:'tax_application'},
  ];

  const validator = useRef(new SimpleReactValidator());

  const handleInputChange = event => {
    event.persist();
    setErrors({});
    setInputs(prevProps => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }));
  };

  const handleChange = (event) => {
    setSelectedData(prevProps => ({
      ...prevProps,
      [event.name]: event
    }));
    setInputs(prevProps => ({
      ...prevProps,
      [event.name]: event.value
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (validator.current.allValid()) {
      api.tax.add({inputs})
      .then(response => {

        if(response.data.status === 1){
          notify(response.data.type, response.data.message);
          setInputs({
            tax_name: "",
            tax_amount: "",
            tax_type: null,
            tax_application: null,
          })
          setSelectedData({
            tax_type: null,
            tax_application: null
          });
          props.callback();
          props.closeModal();
          
        } else {
          for (let [field, message] of Object.entries(response.data.field_error)) {
            setErrors((prevProps) => ({
              ...prevProps,
              [field]: message[0]
            }));
          }
        }
      })
      .catch(function () {
        notify();
      });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };

  const handleEditSubmit = event => {
    event.preventDefault();
    if (validator.current.allValid()) {
    api.tax
      .edit({ inputs })
      .then(response => { 

        if(response.data.status === 1){
          notify(response.data.type, response.data.message);
          setInputs({
            tax_name: "",
            tax_amount: "",
            tax_type: null,
            tax_application: null,
          })
          setSelectedData({
            tax_type: null,
            tax_application: null
          });
          props.callback()
          props.closeModal();
        } else {
          if (typeof response.data.field_error != 'undefined') {
            setErrors(response.data.field_error);
          }
        }
      })
      .catch(function () {
        notify();
      });
    } else {
      formError(true);
      validator.current.showMessages();
    }
  };

  return (
    <div>
      <section className="">
        <form onSubmit={props.isEdit ? (handleEditSubmit) : (handleSubmit) }>
          <div className="row mb-4">
            <div className="col-xxl-12">
              <label className="fw-bold me-3 ">Tax Name</label>
              <div className="mb-3">
                <input
                  type="text"
                  name="tax_name"
                  placeholder="Tax Name"
                  onChange={handleInputChange}
                  className="form-control "
                  value={inputs.tax_name}
                  ref={props.refName}
                />
                {validator.current.message("tax_name", inputs.tax_name, "required|min:3|max:15", {className: "small text-danger"})}
                <div className="small text-danger">{errors.tax_name}</div>
              </div>
            </div>
            <div className="col-xxl-12">
              <label className="fw-bold me-3 ">Tax Percentage</label>
              <div className="mb-3">
                <input
                  type="number"
                  name="tax_amount"
                  placeholder="Tax Percentage"
                  onChange={handleInputChange}
                  className="form-control "
                  value={inputs.tax_amount}
                  min="0"
                  onKeyPress={e => checkFloat(e)}
                />
                {validator.current.message("tax_amount", inputs.tax_amount, "required|min:1|max:100,num", {className: "small text-danger"})}
              </div>
            </div>
            <div className="col-xxl-12">
              <label className="fw-bold me-3 ">Tax Type</label>
              <div className="mb-3">
                <CustomSelect
                  placeholder='Tax Type'
                  className={props.isEdit && "text-capitalize"}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  isSearchable={true}
                  options={tax_type}
                  value={selectedData.tax_type}
                  onChange={handleChange}
                />
                {validator.current.message("tax_type", selectedData.tax_type, "required", {className: "small text-danger"})}
              </div>
            </div>
            <div className="col-xxl-12">
              <label className="fw-bold me-3 ">Tax Application</label>
              <div className="mb-3">
              <CustomSelect
                  className={props.isEdit && "text-capitalize"}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  isSearchable={true}
                  onChange={handleChange}
                  options={tax_application}
                  value={selectedData.tax_application}
                  placeholder='Tax Application'
                />
                {validator.current.message("tax_application", selectedData.tax_application, "required", {className: "small text-danger"})}
              </div>
            </div>
            <div className="col-xxl-12">
              <button type="submit" className="btn btn-secondary w-100" >
              { props.isEdit ? "Save changes" : "Add Tax Type" } 
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
