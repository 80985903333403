import { useMutation, useQueryClient } from "@tanstack/react-query";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";
import { toast } from "react-toastify";

export function useCreateInvoiceQuery() {

  var toastify;
  
  const queryClient = useQueryClient();
  
  function generateInvoice(data) {
    return apiClient.post(`order/create/invoice`, data)
    .then(
      (result) => result.data,
    );
  }

  function handleProcessing(data) {
    toastify = toast.loading("Please wait...");
  }

  function handleSuccess(data) {
    toast.update(toastify, { render: data.message, type: data.type, isLoading: false, autoClose: true, closeButton: true, });
    if (data.status) {
      queryClient.invalidateQueries(['order-list']);
      queryClient.invalidateQueries(['invoice-list']);
    } 
  }

  function handleError(data) {
    toast.update(toastify, { render: "Oops something went wrong!", type: "error", isLoading: false, autoClose: true, closeButton: true, });
  }

  const mutate =  useMutation((data) => generateInvoice(data), {
    retry: false,
    onMutate: handleProcessing,
    onError: handleError,
    onSuccess: handleSuccess
  });

  return [ mutate ]
}