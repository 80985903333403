import React, { useEffect, useState } from "react";
import _ from 'lodash';

import api from "../../../api";
import configurations from "../../../configurations";
import { notify } from "../../../scripts/notify";
import { useLocation, useParams } from "react-router-dom";


export default function EbayPopup(){

	const search = useLocation().search;

	useEffect(() => {
    // get the URL parameters which will include the auth token
		const code = new URLSearchParams(search).get('code');
	
    if (window && window.opener) {
      // send them to the opening window
      window.opener.postMessage(code, '*');
      // close the popup
      window.close();
    }
  }, []);

  return (
    <div>
      Please Wait ...
    </div>
  );
}
 

