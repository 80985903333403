import React, { createContext, useState, useEffect, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

import apiClient from "../../refactoring/stores/header";

const UsersContext = createContext();

const UsersContextProvider = ({ children }) => {
  function getAuth(queryKey, signal) {
    const query = apiClient.get(`auth/profile`, { signal }).then(
      (result) => {
        return result.data;
      });
    return query;
  }

  const query = useQuery(['auth'], ({ queryKey, signal }) => getAuth(queryKey, signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    })

  return (
    <UsersContext.Provider value={{ users: query }}>
      {!query.isLoading && children}
    </UsersContext.Provider>
  );
};
export { UsersContext, UsersContextProvider };