
import React, { useContext, useEffect, useState } from "react";

import _ from 'lodash';

import innoventry from "../../../assets/img/innoventry/icon/innoventry-icon.png";
import amazon from "../../../assets/img/amazon/icon/amazon-icon.png";
import opencart from "../../../assets/img/opencart/icon/opencart-icon.png";
import ebay from "../../../assets/img/ebay/icon/ebay-icon.png";

import CardComponent from '../../layouts/CardLayout';
import NoDataFound from "../global/NoDataFound";
import { abbreviateNumber } from "../../../scripts/utils";
import Skeleton from "react-loading-skeleton";


export default function SalesByChannels(props) {


  const metaData = { metaData: {preference:  {currency: { currency_code: 'GBP', currency_symbol: '£', currency_locale: 'en-GB' }} } }
   
  const [currency, setCurrency] = useState(metaData.metaData.preference.currency.currency_symbol);
  const [channel, setChannel] = useState('');

  const images = {
    amazon,
    opencart,
    ebay,
  }
  function getImagesByKey(key) {
    return images[key]
  }

  var defaultChannel = _.find(props.data, { name: 'default' });
  var otherChannels = _.reject(props.data, { name: 'default' });
  var totalSum = _.sumBy(props.data, item => Number(item.orders_total))

  return (
    <CardComponent filter_key={props.filter_key} title={props.title} footer={props.footer}>
      <>

        <div className="row mt-2">

          {!_.isEmpty(props.data) ? (
            <>

              {defaultChannel && (
                <div className='col-auto border-end border-right-line    '>
                  {props.isLoading ? (
                    <>
                      <Skeleton height={30} width={250} />
                      <Skeleton height={20} width={250} />
                      <Skeleton height={30} width={50} />
                    </>
                  ) : (
                    <>
                      <div>
                          <span className="h2 mb-3">{abbreviateNumber(defaultChannel.orders_total, props.withCurrency)} </span>
                        <span className="text-gray-500 mb-5">{(_.round(((defaultChannel.orders_total / totalSum) * 100), 2))}%</span>
                      </div>
                      <p className="mb-2">Total sales from Innoventory</p>
                      <div className="logo">
                        <img src={innoventry} height={30} alt="" className="rounded-circle border p-1" />
                      </div>
                    </>
                  )}
                </div>
              )}

              {otherChannels && (
                otherChannels.map((index) => (
                  <div className='col-auto border-end border-right-line  ' key={index}>
                    {props.isLoading ? (
                      <>
                        <Skeleton height={30} width={250} />
                        <Skeleton height={20} width={250} />
                        <Skeleton height={30} width={50} />
                      </>
                    ) : (
                      <>
                        <div className="ps-3">

                          <div>
                            <span className="h2 mb-3">{abbreviateNumber(index.orders_total, props.withCurrency)} </span>
                            <span className="text-gray-500 mb-5">{(_.round(((index.orders_total / totalSum) * 100), 2))}%</span>
                          </div>
                          <p className="mb-2">Total sales from <span className="text-capitalize">{index.name}</span></p>
                          <img src={getImagesByKey(index.name)} height={30} alt="" className="rounded-circle border p-1" />
                        </div>
                      </>
                    )}
                  </div>
                ))
              )}
            </>
          ) : (
              <NoDataFound />
          )
          }
        </div>


      </>

    </CardComponent>
  );
}
