import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import apiClient from "../header";
import { notify } from "../../../scripts/notify";


export function useIntegrationUpdateQuery() {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  function updateIntegration(data) {
    return apiClient
      .post(`integrations/edit`, { inputs: data })
      .then((result) => result.data);
  }

  function handleSuccess(data) {
    setErrors({})
    notify(data.type, data.message);
    if (data.status) {
      queryClient.invalidateQueries(["integration-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((editIntegration) => updateIntegration(editIntegration),
    {
      retry: false,
      onSuccess: handleSuccess,
    }
  );

  return [
    mutate,
    {
      updateErrors: errors,
    },
  ];
}

export function useSyncOrdersQuery() {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  function syncTrackpodOrders(id) {
    return apiClient
      .get(`integrations/trackpod/orders`, { params: { id: id } })
      .then((result) => result.data);
  }

  function handleUpdateOrders(data) {
    if (data.status) {
      notify(data.type, data.message);
      queryClient.invalidateQueries(["integration-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((id) => syncTrackpodOrders(id), {
    retry: false,
    onSuccess: handleUpdateOrders,
  });

  return [
    mutate,
    {
      updateOrders: errors,
    },
  ];
}

export function useSyncStatusQuery() {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  function syncTrackpodStatus(inputs) {
    return apiClient
      .post(`integrations/update/status`, inputs)
      .then((result) => result.data);
  }

  function handleUpdateStatus(data) {
    if (data.status) {
      notify(data.type, data.message);
      queryClient.invalidateQueries(["integration-list"]);
    } else {
      for (let [error, message] of Object.entries(data.errors)) {
        setErrors((prevProps) => ({
          ...prevProps,
          [error]: message[0],
        }));
      }
    }
  }

  const mutate = useMutation((inputs) => syncTrackpodStatus(inputs), {
    retry: false,
    onSuccess: handleUpdateStatus,
  });

  return [
    mutate,
    {
      updateStatus: errors,
    },
  ];
}
