import React, { useState, useEffect, useContext, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import Select from "react-select";
import { useLocation, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import SimpleReactValidator from 'simple-react-validator';
import { UilPlusCircle, UilLabel, UilPen, UilTimes } from "@iconscout/react-unicons";

import config from "../../../configurations";
import { useValidator } from "../../utils/validator";

import Total from "../../components/common/Total";
import { useUserListQuery } from "../../stores/setting/user";
import { UsersContext } from "../../../web/context/users";
import Loader from "../../components/global/Loader";
import SelectCouponModal from "../../components/modal/SelectCouponModal";
import Payment from "../../components/payment/Payment";
import PageHeader from "../../layouts/PageHeader";
import SelectDiscountModal from "../../components/modal/SelectDiscountModal";
import CustomerCreation from "../../components/customer/CustomerCreation";
import ModalComponent from "../../components/common/ModalComponent";
import FileAttachments from "../../components/global/FileAttachments";
import CustomerDetailsForm from "../../components/customer/CustomerDetailsForm";
import CouponCreationForm from "../settings/coupon/Form";
import PaymentCreationForm from "../settings/paymentMethod/Form";
import ShippingCreationForm from "../settings/shippingMethod/Form";
import CreatableSelectProducts from "../../components/product/CreatableSelectProducts";
import SelectShippingModal from "../../components/modal/SelectShippingModal";

import { useTotalQuery } from "../../stores/orders/total";
import { useMetaData } from "../../stores/orders/metadata";
import { useQuotationCreateQuery } from "../../stores/quotations/create";
import { useQuotationEditQuery } from "../../stores/quotations/update";
import { useQuotationViewQuery } from "../../stores/quotations/view";
import CustomerDetailsView from "../../components/customer/CustomerDetailsView";
import CustomerSearch from "../../components/customer/CustomerSearch";
import { Modal } from "react-bootstrap";


  const initialState = {
    quotation_date: moment().format('yyyy-MM-DD'),
    status: 0,
    user_id: null,
    created_by: "",
    coupon_code: null,
    coupon_amount: null,
    payment_method: "",
    shipping_cost: null,
    shipping_id:"",
    comment: "",
    discount: null,
    discount_type:null,
    inclusive_tax_discount: false,
    products: [],
    attachments: [],
    customer_detail: {},
  };

export default function Form() {

  const location = useLocation();
  const { id } = useParams();
  var pathname = location.pathname;
  const { users } = useContext(UsersContext);
  const [userListQuery] = useUserListQuery({ defaultValue: { limit: "all" } });
  const user_data = users?.data;
  
  const [quotationCreateQuery, { createErrors }] = useQuotationCreateQuery();
  const [quotationEditQuery, { updateErrors }] = useQuotationEditQuery();
  const [quotationViewQuery, { viewError }] = useQuotationViewQuery(id);

  const [tempData, setTempData] = useState({});
  const [customer, setCustomer] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [errors, setErrors] = useState();

  const [total, setTotal] = useState({
    sub_total: 0,
    total: 0,
    total_tax: 0,
    shipping_tax_amount: 0,
    shipping_gross_amount:0,
    discount_type: null,
    discount_cost: 0,
    discount_percent: 0
  });

  const [modelOpen, setModelOpen] = useState({
    customer: false,
    coupon: false,
    shipping: false,
    discount: false,
    newShipping: false,
    newCoupon: false,
    newPayment: false,
  });

  const [inputs, setInputs] = useState(initialState);

  // const [inputs, dispatch] = useReducer(reducer, initialState)

  const [forceUpdate, setForceUpdate] = useState();
  const selectProductValidatorInstance = useValidator(errors);
  const queryClient = useQueryClient();
  const metaData = useMetaData();
  const [validator, { validate }] = useValidator(errors);
  const [totalQuery, totalQueryData, { totalData, setTotalData }] = useTotalQuery();
  const fileValidator = useValidator(errors);

  const customerDetailsFormValidator = useRef(new SimpleReactValidator());

  var customerValidationSchema = {
    payment_firstname: "max:50",
    payment_lastname: "max:50",
    shipping_firstname: "max:50",
    shipping_lastname: "max:50",
    payment_address: "max:250",
    shipping_address: "max:250",
    payment_city: "max:50",
    shipping_city: "max:50",
    payment_country: "max:50",
    shipping_country: "max:50",
    payment_postcode: "max:15",
    shipping_postcode: "max:15",
    payment_territory: "max:50",
    shipping_territory: "max:50",
    payment_company: "max:100",
    shipping_company: "max:100"
  }

  function fetchMetaData() {
    queryClient.invalidateQueries(['order-metadata']);
  }

  function handleSelectNewPayment(value, action) {
    if (action === "select-option") {
      setInputs(prevProps => ({
        ...prevProps,
        'payment_method':value.name
      }));
    }
    if (action === "create-option") {
      setInputs(prevProps => ({
        ...prevProps,
        id: "",
        name: value.value,
      }))
      openModel('newPayment')
    }
  }

  const openModel = (model) => {
    setModelOpen(prevProps => ({
      ...prevProps,
      [model]: true
    }));
  }

  const closeModel = (model) => {
    setModelOpen(prevProps => ({
      ...prevProps,
      [model]: false
    }));
    setTempData({})
  }

  const removeModelData = (event) => {
    if (event === "coupon") {
      setInputs(prevProps => ({
        ...prevProps,
        'coupon_code': null,
        'coupon_amount': null,
      }));
    }

    if (event === "discount") {
      setInputs(prevProps => ({
        ...prevProps,
        'discount_type': null,
        'discount': null,
        'inclusive_tax_discount' : 0
      }));
      setTotal(prevProps => ({
        ...prevProps,
        'discount_type': null,
        'discount': null,
        'inclusive_tax_discount' : 0
      }));
    }
  };

  function handleCustomerChangeSelect(value, action) {
    if (action === "select-option") {
      setCustomer(value);
      setTempData({
        'customer_first_name': value.customer_first_name,
        'customer_last_name': value.customer_last_name,
      })
    }

    if (action === "create-option") {
      setCustomer({});
      setTempData({
        'customer_first_name': value.customer_first_name,
        'customer_last_name': value.customer_last_name,
      })
      openModel('customer')
    }
  }

  const handleInputChanges = (event) => {
    event.persist();
    setInputs((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value
    }))
  }
  
  const refetch = (event) => {
    quotationViewQuery.refetch()
  }

  function handleSelectChange(label, value) {
    setInputs((prevProps) => ({
      ...prevProps,
      [label]: value
    }))
  }

  function handleSelectChangeUser(value) {
    setInputs((prevProps) => ({
      ...prevProps,
      user_id: value.id,
      created_by: value.name ?? value.email,
    }));
  }

  const handleFiles = (file) => {
    const upload_files = [];
    const existing_upload_files = [];
    if (!_.isEmpty(file)) {
      file.map((file, index) => {
        if (file instanceof File) {
          upload_files.push(file);
        } else {
          existing_upload_files.push(file.id);
        }
      });
      setInputs((prevProps) => ({
        ...prevProps,
        existing_upload_files: existing_upload_files,
        upload_files: upload_files,
      }));
    } else {
      setInputs((prevProps) => ({
        ...prevProps,
        existing_upload_files: [],
        upload_files: [],
      }));
    }
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    if (validator.current.allValid() && selectProductValidatorInstance[0].current.allValid() && fileValidator[0].current.allValid()) {
      if (!id) {
        quotationCreateQuery.mutate(inputs)
      } else {
        quotationEditQuery.mutate(inputs)
      }
    } else {
      validator.current.showMessages();
      selectProductValidatorInstance[0].current.showMessages();
      fileValidator[0].current.showMessages();
      setForceUpdate(true)
    }
  }

  const onChangeModal = (data) => {
    setInputs((prevProps) => ({
      ...prevProps,
      ...data
    }))
  }

  const formatOptionLabelCreatedBy = (option, { context }) => {
    return (
      <div>
        <div>
          <span>{option.created_by ?? option.name} {option.email ?? ''}</span>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (createErrors) {
      setErrors(createErrors)
    }
  }, [createErrors])

  useEffect(() => {
    if (customer) {
      delete customer.order_id
      setInputs(prevProps => ({
        ...prevProps,
        'customer_detail': customer
      }))
    }
  }, [customer]);


  useEffect(() => {
      setTotalData({
        ...(selectProductValidatorInstance[0].current.allValid() && !_.isEmpty(inputs.products)) && {orderProducts: inputs.products},
        inclusive_tax_discount: inputs.inclusive_tax_discount,
        ...(inputs.discount) && {discount: parseFloat(inputs.discount)},
        ...(inputs.discount_type) && {discount_type: inputs.discount_type},
        ...(inputs.shipping_method) && {shipping_method: inputs.shipping_method},
        ...(inputs.shipping_cost) && {shipping_cost: parseFloat(inputs.shipping_cost)},
        ...(inputs.coupon_amount) && {coupon_amount: parseFloat(inputs.coupon_amount)},
        });
  }, [inputs.products, inputs.shipping_cost, inputs.coupon_amount, inputs.discount, inputs.inclusive_tax_discount, inputs.shipping_method, inputs.discount_type]);

  useEffect(() => {
    if (totalQueryData) {
      setTotal(totalQueryData);
      setIsCheck(true);
    }
  }, [totalQuery.data]);
  
  function handleProductChange(data) {
    setInputs((prevProps) => ({
      ...prevProps,
      'products': data
    }))
  }

  //Components
  const quotationDate = () => {
    return (
      <div className="col-md-3 ps-0">
        <label className="mb-1">Date</label>
        <input type="date" className="form-control form-control-md py-2 " name="quotation_date" value={inputs.quotation_date} onChange={handleInputChanges} />
        {validate(inputs.quotation_date, "required", { title: "date" })}
      </div>
    );
  };  

  useEffect(() => {
    if (id) {
      if (quotationViewQuery.isFetched) {
        setInputs(quotationViewQuery.data.data)
        setCustomer(quotationViewQuery.data.data.customer_detail)
      }
    } else {
      setInputs(initialState)
      setCustomer({})
      setTotal({
        sub_total: 0,
        total: 0,
        total_tax: 0,
        shipping_tax_amount: 0,
        shipping_gross_amount:0,
        discount_type: null,
        discount_cost: 0,
        discount_percent: 0
      })
    }
  }, [quotationViewQuery.data])

  useEffect(() => {
    if (!id) {
      setInputs((prevProps) => ({
        ...prevProps,
        user_id: user_data.id,
        created_by: user_data.name,
      }));
    }
  }, [id]);
  
  return (
    id && quotationViewQuery.isFetching?
    <Loader />
    :
    <div className="">
        <PageHeader title="quotations" subtitle={!id ? "Create Quotation" : "Edit Quotation"} />
        <React.Fragment>
          <form encType="multipart/form-data">
            <div className="row product-creation-form">
              <div className="col-lg-12">
                <div className="bg-white p-4 border-bottom-0 border">
                    <div className="row mb-2">
                      <div className="col">
                        <div className="d-flex justify-content-between">
                          <div className="fw-bold mb-1 d-inline">Find or <span className="text-secondary btn btn-link fw-bold p-0" onClick={() => openModel('customer')}>Create Customer</span></div>
                        </div>
                        <CustomerSearch
                          handleSelect={handleCustomerChangeSelect}
                          value={customer.customer_first_name ? _.pick(customer, ['customer_first_name', 'customer_last_name']) : null}
                          isDisabled={pathname == "view"}
                        />
                      </div>
                      <div className="col-md-3 ps-0">
                        <label className="mb-1">Date</label>
                        <input type="date" className="form-control form-control-md py-2 " name="quotation_date" value={inputs.quotation_date} onChange={handleInputChanges} />
                        {validate(inputs.quotation_date, "required", { title: "date" })}
                      </div>
                    </div>
                    {Object.keys(customer).length != 0 && 
                    <>
                      <div className="d-flex justify-content-between pe-0">
                        <div className="fw-bold my-3 d-inline ">Customer</div>
                        {pathname !== 'view' &&
                          <div className="d-flex justify-content-between">
                            <button type="button" className="btn btn-link px-2" onClick={() => openModel('customer')} >
                              <span className="text-secondary fw-bold d-inline">{<UilPen size="14" className="text-secondary" />}</span>
                            </button>
                            <button type="button" className="btn btn-link px-2" onClick={() => setCustomer(false)} >
                              <span className="text-secondary fw-bold d-inline">{<UilTimes size="14" className="text-danger" />}</span>
                            </button>
                          </div>}
                      </div>
                      <div className="row">
                        <div className="d-flex justify-content-between pe-0">
                          <CustomerDetailsView data={customer} />
                        </div>
                      </div>
                    </>}
                    
                    {validate(customer, 'exist', {title: 'customer'})}
                </div>
              </div>
                {modelOpen.customer &&
                  <ModalComponent
                    size="modal-lg"
                    show={modelOpen.customer}
                    onHide={() => closeModel('customer')}
                    title='Create a new customer'
                    body={<CustomerDetailsForm customer={customer} setCustomer={setCustomer} tempData={tempData} onHide={closeModel} validationSchema={customerValidationSchema}/> }
                    footer={
                      <div className="d-flex justify-content-end p-0" >
                        <button type="button" className="btn btn-outline-primary me-2" data-bs-dismiss="modal" onClick={()=>closeModel('customer')}>
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-secondary px-5" form="customer-details-form">
                          {Object.keys(customer).length ? 'Edit' : 'Create'} Customer
                        </button>
                      </div>
                    }
                  />
                }

              <div className="col-lg-12 align-self-center px-4">
                <div className="row bg-white p-4 mb-4 border-top-0 border">
                  <div className="col-md-12 p-0 mb-1">
                    <CreatableSelectProducts selectedProducts={inputs.products} validator={selectProductValidatorInstance} forceUpdate={forceUpdate} onChange={handleProductChange}/>
                  </div> 

                  <div className="col-md-6 p-0">
                    <div className="d-flex justify-content-start">
                      {validate(inputs.products, "required", { title: "products" })}
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="d-flex justify-content-end">
                      <div className="me-4">
                        {_.isNull(inputs.shipping_cost) && (
                          <button 
                            type="button" 
                            className="btn p-0 fw-normal text-primary" 
                            onClick={() => { (openModel(_.isEmpty(metaData?.data?.ShippingMethod) ? "newShipping" : "shipping"))}}
                          >
                            <UilPlusCircle size="14" className="text-primary mb-1" /> Add Shipping Method
                          </button>
                        )} 

                        {modelOpen.shipping &&
                          <SelectShippingModal
                            show={modelOpen.shipping} 
                            defaultValue = {{
                              'shipping_method': inputs.shipping_method,
                              'shipping_cost': inputs.shipping_cost,
                            }}
                            data={metaData?.data?.ShippingMethod}
                            onHide={() => closeModel("shipping")}
                            onChange={onChangeModal}
                          />
                        }
                        <ModalComponent
                          show={modelOpen.newShipping}
                          onHide={() => closeModel('newShipping')}
                          title='Add new shipping method'
                          body={<ShippingCreationForm callback={fetchMetaData} inputs={inputs} closeModal={() => closeModel('newShipping')}/>}
                        />

                        {/* {validate(inputs.shipping_method, 'required', {title: 'Shipping Method'})} */}
                      </div>
                      <div className="me-4">
                        {_.isNull(inputs.coupon_code) ? (
                          <button 
                          type="button" 
                          className="btn p-0 fw-normal text-primary"
                          onClick={() => { _.isEmpty(metaData?.data?.Coupon) ? (openModel("newCoupon")) : (openModel("coupon"))}}
                          >
                            <UilPlusCircle size="14" className="text-primary mb-1" /> Add Coupon/Voucher
                          </button>
                        ) : (null)}
                        {modelOpen.coupon &&
                          <SelectCouponModal 
                            show={modelOpen.coupon} 
                            onHide={() => closeModel('coupon')} 
                            coupon={metaData?.data?.Coupon} 
                            setInputs={setInputs} 
                            inputs= {inputs} 
                          />
                        }
                        { modelOpen.newCoupon && 
                          <ModalComponent
                            show={modelOpen.newCoupon}
                            onHide={() => closeModel('newCoupon')} 
                            title='Add new coupon/Voucher'
                            body={<CouponCreationForm callback={fetchMetaData} inputs={inputs} closeModal={() => closeModel('newCoupon')}/>}
                          />
                        }
                      </div>
                      <div className="">
                        {_.isNull(inputs.discount_type) ? (
                          <button type="button" className="btn p-0 fw-normal text-primary" onClick={() => openModel('discount')}>
                            <UilPlusCircle size="14" className="text-primary mb-1"/> Add Discount
                          </button>
                        ) : (null)}
                        {modelOpen.discount &&
                          <SelectDiscountModal show={modelOpen.discount} onHide={() => closeModel("discount")} setInputs={setInputs} setTotal={setTotal} inputs= {inputs} total={total} />
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pt-3 ps-0">
                    <div className="">
                      <textarea
                        placeholder="Add notes from customer"
                        rows="7"
                        cols="180"
                        name="comment"
                        className="form-control"
                        value={inputs.comment ||""}
                        onChange={event => handleInputChanges(event)}
                      />
                      {validate(inputs.comment, 'max:500', {title: 'comment'})}

                    </div>
                  </div>
                  <div className="col-md-4 offset-1">
                    <Total
                      shipping_cost={inputs.shipping_cost}
                      coupon_code={inputs.coupon_code}
                      coupon_amount={inputs.coupon_amount}
                      total= {total.total}
                      total_tax= {total.total_tax}
                      shipping_tax_amount= {total.shipping_tax_amount}
                      shipping_gross_amount= {total.shipping_gross_amount}
                      discount={total.discount_percent}
                      discount_type= {total.discount_type}
                      discount_cost= {total.discount_cost}
                      sub_total= {total.sub_total}
                      pathname = {pathname}
                      isCheck={isCheck}
                      openModel={openModel}
                      removeModelData={removeModelData}
                    /> 
                  </div>
              
                </div>
                
                <div className="row mb-4">
                  <Payment
                    key={inputs.payment_method}
                    paymentMethod={metaData?.data?.PaymentMethod} 
                    payment_method={inputs.payment_method} 
                    payment_details={inputs.payment_details} 
                    handleInputChanges={handleSelectChange} 
                    modal={false} 
                    validator = {validator} 
                    shouldValidate={false}
                    handleSelectNewPayment={handleSelectNewPayment}/>
                  { modelOpen.newPayment &&
                    <ModalComponent
                      show={modelOpen.newPayment}
                      onHide={() => closeModel('newPayment')}
                      title='Add new Payment Method'
                      body={<PaymentCreationForm callback={fetchMetaData} inputs={inputs} setInputs={setInputs} closeModal={() => closeModel('newPayment')}/>}
                    />
                    }
                  <div className="col-lg-6">
                    <div className="px-4 py-3 bg-white border">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="fw-bold d-flex align-items-center mb-1">
                          {<UilLabel size="14" className="text-secondary me-1" />} Quotation Status
                        </div>

                        <Select
                          options={config.QuotationStatus}
                          placeholder="Select Quotation Status"
                          closeMenuOnSelect={true}
                          value={_.filter(config.QuotationStatus, function (obj) { return obj.id === inputs.status; })}
                          getOptionValue={option => option.id}
                          getOptionLabel={option => option.status}
                          components={{ IndicatorSeparator: () => null }}
                          onChange={(e) => handleSelectChange("status", e.id)}
                        />
                      </div>
                      <div className="col-12">
                        <div className="fw-bold mb-1">
                          Created by
                        </div>
                        <Select
                          options={userListQuery.data}
                          placeholder="Select Created by"
                          closeMenuOnSelect={true}
                            value={_.find(userListQuery?.data, function (obj) { return obj.id === inputs.user_id; }) ?? { id: inputs.user_id, name: inputs.created_by}}
                          components={{ IndicatorSeparator: () => null }}
                          onChange={(value) =>
                            handleSelectChangeUser(value)
                          }
                          formatOptionLabel={formatOptionLabelCreatedBy}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                        />
                        {validate(inputs.user_id, "required", {
                          title: "Created by",
                        })}
                      </div>
                    </div>
                    </div>
                  </div>
                </div>

                <div className="p-4 mb-4 bg-white border">
                  <div className="row">
                    <div className="col-lg-12">
                      <FileAttachments
                        value={inputs.attachments}
                        onChange={(file) => handleFiles(file)}
                        validator={fileValidator}
                        isDisabled={pathname.includes("view")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-4 bg-white text-center border">
                    <div className="p-4 add-new-product">
                      <button className="btn btn-secondary" type="button" onClick={(e) => handleSubmit(e)}>{!id ? 'Create Quotation' : 'Save Quotation'}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </React.Fragment>
    </div>
    
  );
}
