import Swal from "sweetalert2";

import { notify } from "../../scripts/notify";


export function confirmDelete(option, callback) {
  Swal.fire({
    icon: "warning",
    title: `Are you sure you want to delete this ${option} ?`,
    text: `By clicking yes, you will remove this ${option} from your business`,
    showCancelButton: true,
    confirmButtonText: `Yes, I am sure`,
  }).then((result) => {
    if (result.isConfirmed) {
      if(option == 'channel'){
        callback();
      } else {
        Swal.fire({
          title: "Please type 'confirm' to delete",
          input: "text",
          inputPlaceholder: "Type confirm",
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value.toLowerCase() === "confirm") {
              callback();
            } else {
              notify("error", "Try again.");
            }
          }
        });
      }
    }
  });
}

export function confirmCreate(callback) {
  Swal.fire({
    icon: "warning",
    title: "Are you sure you want to create this order?",
    text: "By clicking yes, you will Create Order",
    showCancelButton: true,
    confirmButtonText: `Yes, I am sure.`,
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}