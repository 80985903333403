import {
  UilApps,
  UilShoppingBag,
  UilChannel,
  UilChartLine,
  UilStore,
  UilReceiptAlt,
  UilUsdCircle,
  UilSwatchbook,
  UilChart,
  UilBell,
} from "@iconscout/react-unicons";

import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import OrderAnalysis from "./pages/reports/OrderAnalysis";
// import SalesAnalysis from "./pages/reports/SalesAnalysis";

import AccountSettings from "./pages/settings/user/AccountSettings";
import BusinessInfo from "./pages/settings/business/BusinessInfo";
import UserManagement from "./pages/settings/user/UserManagement";
import GroupManagement from "./pages/settings/user/GroupsManagement";
import WarehouseManagement from "./pages/settings/warehouse/WarehouseManagement";
import Preferences from "./pages/settings/preferences/Preferences";
import Customization from "./pages/settings/customization/Customization";
import TaxManagement from "./pages/settings/tax/TaxManagement";
import ManufacturerManagement from "./pages/settings/manufacturer/ManufacturerManagement";
import UnitsManagement from "./pages/settings/units/UnitManagement";
import ShippingManagement from "./pages/settings/shipping/ShippingManagement";
import CouponManagement from "./pages/settings/coupon/CouponManagement";
import PaymentManagement from "./pages/settings/payment/PaymentManagement";
import MailManagement from "./pages/settings/mail/MailManagement";

import Notifications from "./pages/notifications/Notifications";
import NotificationSettings from "./pages/notifications/NotificationSettings";
import NotificationOrders from "./pages/notifications/NotificationOrders";
import ProductsManagement from "./pages/inventory/product/ProductsManagement";
import CompositeProduct from "./pages/inventory/product/CompositeProduct";
import Settings from "./pages/settings/Settings";
import ProductsCreationForm from "./pages/inventory/product/ProductsCreationForm";
import OrdersManagement from "./pages/inventory/order/OrdersManagement";
import OrdersCreationForm from "./pages/inventory/order/OrdersCreationForm";
// import OrderView from "./pages/inventory/order/OrderView";
import CategoryManagement from "./pages/inventory/category/CategoryManagement";
import CategoryCreation from "./pages/inventory/category/CategoryCreation";
import ChannelEditForm from "./pages/channel/ChannelEditForm";
// import AddChannel from "./pages/channel/AddChannel";
import OpenCartMain from "./pages/channel/Opencart/OpenCart";
import AmazonMain from "./pages/channel/Amazon/Amazon";
import EbayMain from "./pages/channel/Ebay/Main";
import ProductMapping from "./pages/channel/ProductMapping";
import PopUp from "./pages/channel/popup";
import InvoiceManagement from "./pages/inventory/invoice/InvoiceManagement";
import InvoiceCreation from "./pages/inventory/invoice/InvoiceCreation";
import QuotationManagement from "./pages/inventory/quotation/QuotationManagement";
import QuotationCreation from "./pages/inventory/quotation/QuotationCreation";
// import AddIntegration from "./pages/integration/AddIntegration";
import IntegrationManagment from "./pages/integration/IntegrationManagement";
import EbayPopup from "./pages/channel/Ebay/EbayPopup";
import TrackPodMain from "./pages/integration/Trackpod/TrackPod";
import ChannelManagment from "./pages/channel/ChannelManagement";
import Report from "./pages/reports/Report";
import IntegrationEditForm from "./pages/integration/IntegrationEditForm";

//Refactor Settings
import SettingsIndex from "./refactoring/pages/settings/index";
import AccountSettingsRefactor from "./refactoring/pages/settings/accountSettings/AccountSettings";
import BusinessInformation from "./refactoring/pages/settings/businessInformation/BusinessInfo";
import CustomizationRefactor from "./refactoring/pages/settings/customization/Form";
import PreferencesRefactor from "./refactoring/pages/settings/preference/Form";
import User from "./refactoring/pages/settings/user/Listing";
import Group from "./refactoring/pages/settings/group/Listing";
import Warehouse from "./refactoring/pages/settings/warehouse/Listing";
import Manufacturer from "./refactoring/pages/settings/manufacturer/Listing";
import Tax from "./refactoring/pages/settings/tax/Listing";
import Unit from "./refactoring/pages/settings/unit/Listing";
import Shipping from "./refactoring/pages/settings/shippingMethod/Listing";
import Payment from "./refactoring/pages/settings/paymentMethod/Listing";
import Coupon from "./refactoring/pages/settings/coupon/Listing";
import Mail from "./refactoring/pages/settings/mail/Form";

//Refactor
import Dashboard from "./refactoring/pages/dashboard/index";

import ProductIndex from "./refactoring/pages/products/index";
import ProductListing from "./refactoring/pages/products/Listing";
import ProductForm from "./refactoring/pages/products/Form";
import CompositeProductForm from "./refactoring/pages/products/composite/Form";
import ProductView from "./refactoring/pages/products/View";

import CategoriesIndex from "./refactoring/pages/categories/index";
import CategoriesListing from "./refactoring/pages/categories/Listing";
import CategoriesForm from "./refactoring/pages/categories/Form";
import CategoriesView from "./refactoring/pages/categories/View";

import OrderIndex from "./refactoring/pages/orders/index";
import OrderListing from "./refactoring/pages/orders/Listing";
import OrderForm from "./refactoring/pages/orders/Form";
import OrderView from "./refactoring/pages/orders/View";

import InvoiceIndex from "./refactoring/pages/invoices/index";
import InvoiceListing from "./refactoring/pages/invoices/Listing";
import InvoiceForm from "./refactoring/pages/invoices/Form";

import IntegrationIndex from "./refactoring/pages/integrations/index";
import IntegrationsListing from "./refactoring/pages/integrations/Listing";
import AddIntegration from "./refactoring/pages/integrations/Add";
import TrackpodStepper from "./refactoring/pages/integrations/trackpod/Stepper";
import EditTrackpod from "./refactoring/pages/integrations/trackpod/Edit";

import ChannelIndex from "./refactoring/pages/channels/index";
import ChannelListing from "./refactoring/pages/channels/Listing";
import AddChannel from "./refactoring/pages/channels/Add";
import ChannelEdit from "./refactoring/pages/channels/Edit";
import ProductMap from "./refactoring/pages/channels/mapping/ProductMappingModal";
import AmazonStepper from "./refactoring/pages/channels/amazon/Stepper";
import EbayStepper from "./refactoring/pages/channels/ebay/Stepper";
import OpencartStepper from "./refactoring/pages/channels/opencart/Stepper";

import QuotationIndex from "./refactoring/pages/quotations/index";
import QuotationForm from "./refactoring/pages/quotations/Form";
import QuotationListing from "./refactoring/pages/quotations/Listing";

import NotificationIndex from "./refactoring/pages/notifications/index";
import NotificationForm from "./refactoring/pages/notifications/Settings";
import NotificationListing from "./refactoring/pages/notifications/Listing";

import ReportIndex from "./refactoring/pages/reports/index";
import SalesAnalysis from "./refactoring/pages/reports/SalesAnalysis";
import RecurringCustomerAnalysis from "./refactoring/pages/reports/RecurringCustomerAnalysis";
import SampleOrderAnalysis from "./refactoring/pages/reports/SampleOrderAnalysis";

import CustomerView from "./refactoring/pages/customers/View";

export const Paths = {
  Root: { path: "/", component: <Dashboard />, permissions: ["*"] },

  // pages
  Login: { path: "/login", component: <Login />, permissions: ["*"] },
  Logout: { path: "/logout", permissions: ["*"] },
  ResetPassword: {
    path: "/reset-password",
    permissions: ["*"],
    component: <ResetPassword />,
  },
  NotFound: { path: "/404-not-found", permissions: ["*"] },
  User: { path: "/user", permissions: ["*"] },

  Pages: {
    PrivacyPolicy: { path: "/privacy-policy" },
  },

  Dashboard: {
    path: "dashboard",
    isVisible: true,
    name: "Dashboard",
    permissions: ["*"],
    component: <Dashboard />,
    icon: <UilChartLine size="16" className="me-2 mb-1" />,
  },

  Settings: {
    path: "/settings",
    isVisible: false,
    name: "Settings",
    component: <Settings />,
    permissions: ["*"],
    isTabs: true,
    pages: {
      AccountSettings: {
        path: "/settings/account-settings",
        name: "Account Settings",
        permissions: ["*"],
        component: <AccountSettings />,
      },
      BusinessInfo: {
        path: "/settings/business-info",
        name: "Business Information",
        permissions: ["1"],
        component: <BusinessInfo />,
      },
      Customization: {
        path: "/settings/customization",
        name: "Customization",
        permissions: ["1"],
        component: <Customization />,
      },
      Preferences: {
        path: "/settings/preferences",
        name: "Preferences",
        permissions: ["1"],
        component: <Preferences />,
      },
      UserManagement: {
        path: "/settings/users",
        name: "Users",
        permissions: ["1", "2", "3", "4", "5", "6"],
        component: <UserManagement />,
      },
      GroupManagement: {
        path: "/settings/user-groups",
        name: "User Groups",
        permissions: ["1", "2", "3", "4", "5", "6"],
        component: <GroupManagement />,
      },
      WarehouseManagement: {
        path: "/settings/warehouse",
        name: "Warehouses",
        permissions: ["1"],
        component: <WarehouseManagement />,
      },
      ManufacturerManagement: {
        path: "/settings/manufacturer",
        name: "Manufacturers",
        permissions: ["1"],
        component: <ManufacturerManagement />,
      },
      Tax: {
        path: "/settings/tax-types",
        name: "Tax Types",
        permissions: ["1"],
        component: <TaxManagement />,
      },
      UnitsManagement: {
        path: "/settings/units",
        name: "Units",
        permissions: ["1"],
        component: <UnitsManagement />,
      },
      ShippingManagement: {
        path: "/settings/shipping",
        name: "Shipping Methods",
        permissions: ["1"],
        component: <ShippingManagement />,
      },
      PaymentManagement: {
        path: "/settings/payment",
        name: "Payment Methods",
        permissions: ["1"],
        component: <PaymentManagement />,
      },
      CouponManagement: {
        path: "/settings/coupon",
        name: "Coupons",
        permissions: ["1"],
        component: <CouponManagement />,
      },
      MailManagement: {
        path: "/settings/mail",
        name: "Mail SMTP",
        permissions: ["1"],
        component: <MailManagement />,
      },
    },
  },

  Setting: {
    path: "/setting",
    isVisible: false,
    name: "Settings Refactor",
    component: <SettingsIndex />,
    permissions: ["*"],
    isTabs: true,
    pages: {
      AccountSettings: {
        path: "/setting/account-settings",
        name: "Account Settings",
        permissions: ["*"],
        component: <AccountSettingsRefactor />,
      },
      BusinessInformation: {
        path: "/setting/business-info",
        name: "Business Information",
        permissions: ["1"],
        component: <BusinessInformation />,
      },
      Customization: {
        path: "/setting/customization",
        name: "Customization",
        permissions: ["1"],
        component: <CustomizationRefactor />,
      },
      Preferences: {
        path: "/setting/preferences",
        name: "Preferences",
        permissions: ["1"],
        component: <PreferencesRefactor />,
      },
      User: {
        path: "/setting/users",
        name: "Users",
        permissions: ["1", "2", "3", "4", "5", "6"],
        component: <User />,
      },
      Group: {
        path: "/setting/user-groups",
        name: "User Groups",
        permissions: ["1", "2", "3", "4", "5", "6"],
        component: <Group />,
      },
      Warehouse: {
        path: "/setting/warehouse",
        name: "Warehouses",
        permissions: ["1"],
        component: <Warehouse />,
      },
      Manufacturer: {
        path: "/setting/manufacturer",
        name: "Manufacturers",
        permissions: ["1"],
        component: <Manufacturer />,
      },
      Tax: {
        path: "/setting/tax-types",
        name: "Tax Types",
        permissions: ["1"],
        component: <Tax />,
      },
      Unit: {
        path: "/setting/units",
        name: "Units",
        permissions: ["1"],
        component: <Unit />,
      },
      Shipping: {
        path: "/setting/shipping",
        name: "Shipping Methods",
        permissions: ["1"],
        component: <Shipping />,
      },
      Payment: {
        path: "/setting/payment",
        name: "Payment Methods",
        permissions: ["1"],
        component: <Payment />,
      },
      Coupon: {
        path: "/setting/coupon",
        name: "Coupons",
        permissions: ["1"],
        component: <Coupon />,
      },
      Mail: {
        path: "/setting/mail",
        name: "Mail SMTP",
        permissions: ["1"],
        component: <Mail />,
      },
    },
  },

  //Refactor Product
  Product: {
    path: "/products",
    permissions: ["1", "7"],
    isVisible: true,
    name: "Product",
    icon: <UilStore size="16" className="me-2 mb-1" />,
    component: <ProductIndex />,
    isTabs: true,
    pages: {
      index: {
        index: true,
        path: "/products",
        name: "All Product",
        permissions: ["1", "7"],
        isVisible: true,
        component: <ProductListing />,
      },
      create: {
        path: "/products/create",
        name: "Create Product",
        permissions: ["1", "9"],
        isVisible: true,
        component: <ProductForm />,
      },
      edit: {
        path: "/products/edit/",
        name: "Edit Product",
        permissions: ["1", "8", "10"],
        isParam: ":id",
        component: <ProductForm />,
      },
      createComposite: {
        path: "/products/composite/create/",
        name: "Create Composite",
        permissions: ["1", "9"],
        isVisible: true,
        component: <CompositeProductForm />,
      },
      editComposite: {
        path: "/products/composite/edit/",
        name: "Edit Composite",
        permissions: ["1", "8", "10"],
        isParam: ":id",
        component: <CompositeProductForm />,
      },
    },
  },

  // Products: {
  //   path: "/products",
  // 	isVisible: true,
  // 	name: 'Products',
  // 	component:<ProductsManagement/>,
  // 	icon: <UilStore size="16" className="me-2 mb-1" />,
  // 	permissions: ['1', '7'],
  // 	pages: {
  // 		ProductsManagement: { name: 'All Products', path: "/products/manage", permissions: ['1', '7'], isVisible: true, component:<ProductsManagement/> },
  // 		ProductsCreation: { name: 'Add Product', path: "/products/add" , permissions: ['1', '9'], isVisible: true, component:<ProductsCreationForm/> },
  // 		CompositeCreation: { name: 'Add Composite Product', path: "/products/composite/add" , permissions: ['1', '9'], isVisible: true, component:<CompositeProduct/>  },

  // 		ProductsEditForm: { name: 'Edit Product' , path: "/products/edit/", isParam: ":id", permissions: ['1', '8', '10'], component:<ProductsCreationForm/> },
  // 		ProductsCompositeEditForm: { name: 'Edit Composite Product', path: "/products/composite/edit/" , isParam: ":id", permissions: ['1', '8', '10'], component:<CompositeProduct/> },

  // 		ProductsDelete: { name: 'Delete Product', path: "/products/delete" , permissions: ['1', '11'], },
  // 		OptionsManagement: { name: 'Option Product', path: "/products/options" },
  // 	}
  // },

  //Refactor Category
  Category: {
    path: "/categories",
    permissions: ["1", "12"],
    isVisible: true,
    name: "Categories",
    icon: <UilSwatchbook size="16" className="me-2 mb-1" />,
    component: <CategoriesIndex />,
    isTabs: true,
    pages: {
      index: {
        index: true,
        path: "/categories",
        name: "All Categories",
        permissions: ["1", "12"],
        isVisible: true,
        component: <CategoriesListing />,
      },
      create: {
        index: true,
        path: "/categories/create",
        name: "Add Category",
        permissions: ["1", "14"],
        isVisible: true,
        component: <CategoriesForm />,
      },
      edit: {
        index: true,
        path: "/categories/edit/",
        name: "Edit",
        permissions: ["1", "13", "15"],
        isParam: ":id",
        component: <CategoriesForm />,
      },
      //view: { index:true, path : "/categories/view", name: 'View', permissions: ['1', '12'], isVisible: true, component: <CategoriesView/>},
    },
  },

  // Category: {
  //   path: "/category",
  //   isVisible: true,
  //   name: 'Categories',
  //   component: <CategoryManagement/>,
  //   icon: <UilSwatchbook size="16" className="me-2 mb-1" />,
  //   permissions: ['1', '12'],
  //   pages:{
  //     CategoryManagement: { name: 'All Categories', path: "/category/manage", permissions: ['1', '12'], isVisible: true, component: <CategoryManagement/> },
  //     CategoryCreation: { name: 'Add Category', path: "/category/add", permissions: ['1', '14'], isVisible: true, component:<CategoryCreation/> },
  //     CategoryEdit: { name: 'Edit Category', path: "/category/edit/", permissions: ['1', '13', '15'], isParam: ":id", component: <CategoryCreation/> },
  //   }
  // },

  //Refactor Order
  Order: {
    path: "/orders",
    permissions: ["1", "17"],
    isVisible: true,
    name: "Order",
    icon: <UilShoppingBag size="16" className="me-2 mb-1" />,
    component: <OrderIndex />,
    isTabs: true,
    pages: {
      index: {
        index: true,
        path: "/orders",
        name: "All Orders",
        permissions: ["1", "17"],
        isVisible: true,
        component: <OrderListing />,
      },
      create: {
        path: "/orders/create",
        name: "Create Order",
        permissions: ["1", "19"],
        isVisible: true,
        component: <OrderForm />,
      },
      view: {
        path: "/orders/view/",
        name: "Order View",
        permissions: ["1", "18"],
        isVisible: false,
        isParam: ":id",
        component: <OrderView />,
      },
    },
  },

  //Refactor Invoice
  Invoice: {
    path: "/invoices",
    permissions: ["1", "22"],
    isVisible: true,
    name: "Invoice",
    icon: <UilReceiptAlt size="16" className="me-2 mb-1" />,
    component: <InvoiceIndex />,
    isTabs: true,
    pages: {
      index: {
        index: true,
        path: "/invoices",
        name: "All Invoices",
        permissions: ["1", "22"],
        isVisible: true,
        component: <InvoiceListing />,
      },
      create: {
        path: "/invoices/create",
        name: "Create Invoice",
        permissions: ["1", "24"],
        isVisible: true,
        component: <InvoiceForm />,
      },
      view: {
        path: "/invoices/view/",
        name: "View Invoice",
        permissions: ["1", "23"],
        isVisible: false,
        isParam: ":id",
        component: <InvoiceForm />,
      },
      edit: {
        path: "/invoices/edit/",
        name: "Edit Invoice",
        permissions: ["1", "23", "25"],
        isParam: ":id",
        component: <InvoiceForm />,
      },
    },
  },

  // Orders: {
  //   path: "/orders",
  // 	isVisible: true,
  // 	name: 'Orders',
  // 	component: <OrdersManagement/>,
  // 	icon: <UilShoppingBag size="16" className="me-2 mb-1" />,
  // 	permissions: ['1', '17'],
  // 	pages: {
  // 		OrdersManagement: { name: 'All Orders', path: "/orders/manage", permissions: ['1', '17'], isVisible: true, component: <OrdersManagement/> },
  // 		OrdersCreation: { name: 'Create Order', path: "/orders/create", permissions: ['1', '19'], isVisible: true, component:<OrdersCreationForm/> },
  // 		OrderView: { name: 'Order View', path: "/orders/view/", permissions: ['1', '18'], isVisible: false, isParam: ":id", component: <OrderView/> },
  // 	}
  // },

  // Invoices: {
  //   path: "/invoices",
  // 	// isVisible: true,
  // 	name: 'Invoices',
  // 	component:<InvoiceManagement/>,
  // 	icon: <UilReceiptAlt size="16" className="me-2 mb-1" />,
  // 	permissions: ['1', '22'],
  // 	pages:{

  // 	}
  // },

  // Quotation: {
  //   path: "/quotation",
  // 	isVisible: true,
  // 	name: 'Quotations',
  // 	component:<QuotationManagement/>,
  // 	icon: <UilUsdCircle size="16" className="me-2 mb-1" />,
  // 	permissions: ['1', '27'],
  // 	pages: {
  // 		QuotationManagement: { name: 'All Quotations', path: "/quotation/manage", permissions: ['1', '27'], isVisible: true, component:<QuotationManagement/> },
  // 		QuotationCreation: { name: 'Create Quotation', path: "/quotation/create", permissions: ['1', '29'], isVisible: true, component:<QuotationCreation/> },
  // 		QuotationEdit: { path: "/quotation/edit/", permissions: ['1', '28', '30'], isVisible: false, isParam: ":id", component:<QuotationCreation/> },
  // 		QuotationView: { path: "/quotation/view/", permissions: ['1', '28'], isVisible: false, isParam: ":id", component:<QuotationCreation/> },
  // 	}
  // },

  Quotations: {
    path: "/quotations",
    permissions: ["1", "27"],
    isVisible: true,
    name: "Quotation",
    icon: <UilUsdCircle size="16" className="me-2 mb-1" />,
    component: <QuotationIndex />,
    isTabs: true,
    pages: {
      index: {
        index: true,
        path: "/quotations",
        name: "All Quotation",
        permissions: ["1", "27"],
        isVisible: true,
        component: <QuotationListing />,
      },
      create: {
        path: "/quotations/create",
        name: "Create Quotation",
        permissions: ["1", "29"],
        isVisible: true,
        component: <QuotationForm />,
      },
      edit: {
        path: "/quotations/edit/",
        name: "Edit Quotation",
        permissions: ["1", "28", "30"],
        isParam: ":id",
        component: <QuotationForm />,
      },
      // view: { path: "/quotations/view/", name: 'Quotation View',  permissions: ['*'], isVisible: false, isParam: ":id", component: <OrderView/> },
    },
  },

  //Refactor channel
  ChannelsRefactor: {
    path: "/channels",
    isVisible: true,
    name: "Channels",
    component: <ChannelIndex />,
    icon: <UilChannel size="16" className="me-2 mb-1" />,
    permissions: ["1", "32"],
    isTabs: true,
    pages: {
      index: {
        index: true,
        path: "/channels",
        name: "All Channels",
        permissions: ["1", "32"],
        isVisible: true,
        component: <ChannelListing />,
      },
      select: {
        index: true,
        path: "/channels/add",
        name: "Add Channel",
        permissions: ["1", "34"],
        isVisible: true,
        component: <AddChannel />,
      },
      edit: {
        index: true,
        path: "/channels/edit/",
        name: "Edit Channel",
        isParam: ":id",
        permissions: ["1", "33", "35"],
        component: <ChannelEdit />,
      },

      ProductMap: {
        index: true,
        path: "/channels/product/map/",
        name: "Products Mapping",
        permissions: ["1", "34"],
        isParam: ":id",
        component: <ProductMap />,
      },

      addOpencart: {
        index: true,
        path: "/channels/opencart",
        name: "Add Channel",
        permissions: ["1", "34"],
        component: <OpencartStepper />,
      },
      addEbay: {
        index: true,
        path: "/channels/ebay",
        name: "Add Channel",
        permissions: ["1", "34"],
        component: <EbayStepper />,
      },
      addAmazon: {
        index: true,
        path: "/channels/amazon",
        name: "Add Channel",
        permissions: ["1", "34"],
        component: <AmazonStepper />,
      },
    },
  },

  // Channels: {
  //   path: "/channels",
  // 	isVisible: true,
  // 	name: 'Channels',
  // 	component:<ChannelManagment/>,
  // 	icon: <UilChannel size="16" className="me-2 mb-1" />,
  // 	permissions: ['1', '32'],
  // 	pages: {
  // 		ChannelManagement: { name: 'All Channels', path: "/channels/manage", permissions: ['1', '32'], isVisible: true, component: <ChannelManagment/> },
  // 		ChannelEditForm: { name: 'Edit Channel', path: "/channels/edit/", permissions: ['1', '33', '35'], isVisible: false, isParam: ":id", component: <ChannelEditForm/>  },
  // 		AddChannel: { name: 'Add Channel', path: '/channels/add', permissions: ['1', '34'], isVisible: true, component: <AddChannel/>  },
  // 		OpenCartMain: { path: '/channels/opencart', permissions: ['1', '34'], component:<OpenCartMain/> },
  // 		AmazonMain: { path: "/channels/amazon/", permissions: ['1', '34'], component: <AmazonMain/> },
  // 		EbayMain: { path: "/channels/ebay/", permissions: ['1','34'], component: <EbayMain/>},
  // 		ProductMap: { path: "/channels/product/map/", permissions: ['1', '34'], isParam: ":id", component:<ProductMapping/> },
  // 		PopUp: { path: "/channels/popup", permissions: ['*'], component:<PopUp/>},
  // 	}
  // },

  //Refactor Integrations
  Integration: {
    path: "/integrations",
    permissions: ["1", "37"],
    isVisible: true,
    name: "Integrations",
    icon: <UilApps size="16" className="me-2 mb-1" />,
    component: <IntegrationIndex />,
    isTabs: true,
    pages: {
      index: {
        index: true,
        path: "/integrations",
        name: "All Integrations",
        permissions: ["1", "37"],
        isVisible: true,
        component: <IntegrationsListing />,
      },
      select: {
        path: "/integrations/add",
        name: "Add Integration",
        permissions: ["1", "39"],
        isVisible: true,
        component: <AddIntegration />,
      },
      createTrackpod: {
        path: "/integrations/trackpod",
        name: "Add Integration",
        permissions: ["1", "39", "38", "40"],
        component: <TrackpodStepper />,
      },
      EditTrackpod: {
        path: "/integrations/edit/",
        name: "Edit Integration",
        isParam: ":id",
        permissions: ["1", "38", "40"],
        component: <EditTrackpod />,
      },
    },
  },

  // Integrations: {
  //   path: "/integrations",
  // 	isVisible: true,
  // 	name: 'Integrations',
  // 	component:<IntegrationManagment/>,
  // 	icon: <UilApps size="16" className="me-2 mb-1" />,
  // 	permissions: ['1', '37'],
  // 	pages: {
  // 		IntegrationManagement: { name: 'All Integrations', path: "/integrations/manage", permissions: ['1', '37'], isVisible: true, component:<IntegrationManagment/> },
  // 		AddIntegration: { name: 'Add Integration', path: "/integrations/add", permissions: ['1', '39'], isVisible: true, component:<AddIntegration/> },
  // 		IntegrationEditForm: { path: "/integrations/edit/", permissions: ['1', '38', '40'], isParam: ":id", component:<IntegrationEditForm/>  },
  // 		TrackPodMain: { path: "/integrations/trackpod", permissions: ['1', '39', '38', '40'], component:<TrackPodMain/> },
  // 	}
  // },

  // Report:{
  //   path:"/report",
  // 	name: 'Report',
  // 	isVisible: true,
  // 	permissions: ['1'],
  // 	icon: <UilChart size="16" className="me-2 mb-1" />,
  // 	// component: <Report/>,
  // 	component: <SalesAnalysis/>,
  // 	pages:{
  //   	SalesAnalysis: { name:"Sales Analysis", path:"/report/sales-analysis", component: <SalesAnalysis/>, isVisible: true, permissions: ['1']},
  // 	}
  // },

  Reports: {
    path: "/report",
    name: "Reports",
    isVisible: true,
    isTabs: true,
    permissions: ["1"],
    icon: <UilChart size="16" className="me-2 mb-1" />,
    // component: <Report/>,
    component: <ReportIndex />,
    pages: {
      index: {
        name: "Sales Analysis",
        path: "/report/sales-analysis",
        component: <SalesAnalysis />,
        isVisible: true,
        permissions: ["1"],
      },
      recurringCustomer: {
        name: "Recurring Customer",
        path: "/report/recurring-customer-analysis",
        component: <RecurringCustomerAnalysis />,
        isVisible: true,
        permissions: ["1"],
      },
      sampleOrder: {
        name: "Sample Order Analysis",
        path: "/report/sample-order-analysis",
        component: <SampleOrderAnalysis />,
        isVisible: true,
        permissions: ["1"],
      },
    },
  },

  // SalesAnalysis: {
  //   path:"/sales-analysis",
  // },
  OrderAnalysis: {
    path: "/order-analysis",
    permissions: ["*"],
    component: <OrderAnalysis />,
  },

  // Notifications: {
  //   path: "/notifications",
  // 	isVisible: true,
  // 	isTabs: true,
  // 	name: 'Notifications',
  // 	component:<Notifications/>,
  // 	permissions: ['1'],
  // 	icon: <UilBell size="16" className="me-2 mb-1" />,
  // 	pages: {
  // 		Orders: { path : "/notifications/orders", name: 'Orders', permissions: ['1'], isVisible: true, component: <NotificationOrders/>},
  // 		Settings: { path : "/notifications/settings", name: 'Settings', permissions: ['1'], isVisible: true, component: <NotificationSettings/>}
  // 	}
  // },

  //Notification refactor

  Notification: {
    path: "/notifications",
    isVisible: true,
    isTabs: true,
    name: "Notifications",
    component: <NotificationIndex />,
    permissions: ["1"],
    icon: <UilBell size="16" className="me-2 mb-1" />,
    pages: {
      index: {
        index: true,
        path: "/notifications",
        name: "Orders",
        permissions: ["1"],
        isVisible: true,
        component: <NotificationListing />,
      },
      settings: {
        path: "/notifications/settings",
        name: "Settings",
        permissions: ["1"],
        isVisible: true,
        component: <NotificationForm />,
      },
    },
  },

  Ebay: {
    path: "/ebay",
    permissions: ["*"],
    component: <EbayPopup />,
  },

  Customer: {
    path: "/customers",
    permissions: ["1", "17"],
    isVisible: false,
    name: "Customer",
    icon: <UilShoppingBag size="16" className="me-2 mb-1" />,
    component: <CustomerView />,
    isTabs: true,
    pages: {
      index: {
        index: true,
        path: "/customers",
        name: "All Customers",
        permissions: ["1", "17"],
        isVisible: true,
        component: <OrderListing />,
      },
      view: {
        path: "/customers/view/",
        name: "Customer View",
        permissions: ["1", "18"],
        isVisible: false,
        isParam: ":id",
        component: <CustomerView />,
      },
    },
  },
};
